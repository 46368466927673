import React, { useState } from 'react';
import CustomBottonComponent from './CustomBottonComponent.web';
import { Box, Dialog, MenuItem, Select, TextField, Theme, Typography, createStyles, makeStyles, styled } from '@material-ui/core';
//@ts-ignore
import iconClose from "./cross.svg";
//@ts-ignore

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        "& .MuiDialog-paperScrollPaper": {
            borderRadius: "8px 8px 0 0",
            width: "600px"
        },
        "& .MuiIconButton-root": {
            '&:hover': {
                backgroundColor: '#FFFFFF',
            },
        },
        '& .MuiListItem-root': {
            fontFamily: "Arial",
            fontSize: "14px",
            color: "#17365D",
            fontWeight: 400,
        }
    },
    placeholder: {
        "color": "#9C9C9C;",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px"
    },
    options: {
        '&:hover': {
            background: "#1F497D !important",
            color: "#FFFFFF !important",
            fontWeight: "400 !important",
            borderRadius: "8px"
        },
    },
}))

type Props = {
    reportType?: string,
    handleClose: () => void,
    onCancleText?: string,
    onDeleteText?: string,
    handleSubmit: () => void,
    handleSelect: (reason: {
        id: number,
        reason: string,
        created_at: string,
        updated_at: string,
        otherReason: string
    }) => void,
    list: {
        id: number,
        reason: string,
        created_at: string,
        updated_at: string
    }[],
    selectedReason: {
        id: number,
        reason: string,
        created_at: string,
        updated_at: string,
        otherReason: string
    }
}
export default function ChatReportDialog({ reportType = "chat", handleClose, onCancleText = "Cancel", onDeleteText = "Report", handleSubmit, list, handleSelect, selectedReason }: Props) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.root}
        >
            <ReasonBox style={{ minWidth: "600px", background: "#fff", borderRadius: "8px" }}>
                <Box style={{ padding: "24px 40px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography style={{ fontFamily: "Arial", fontWeight: 700, fontSize: "24px", color: "#17365D" }}>Report this {reportType}</Typography>
                    <img src={iconClose} onClick={handleClose} />
                </Box>
                <Box style={{ padding: "24px 40px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", position: "relative", gap: "4px" }}>
                    <Typography style={{ color: "#1F497D", fontSize: "14px", fontWeight: 700, fontFamily: "Arial" }}>Select a reason that applies</Typography>
                    <Select
                        style={{
                            border: "1px solid #9C9C9C",
                            borderRadius: "8px",
                            cursor: "pointer"
                        }}
                        placeholder="Select"
                        variant="outlined"
                        onClick={() => setOpen(!open)}
                        value={selectedReason.reason || ""}
                        displayEmpty
                        fullWidth
                        renderValue={(selected: any) => {
                            if (!selected) {
                                return <Typography className={classes.placeholder}>Select</Typography>;
                            }
                            return selected
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        {list.map((item: {
                            id: number;
                            reason: string;
                            created_at: string;
                            updated_at: string;
                        }, index: number) => (
                            <MenuItem
                                key={index}
                                className={classes.options}
                                data-test-id="changeCountry"
                                onClick={() => {
                                    handleSelect({ ...item, otherReason: "" })
                                    setOpen(!open)
                                }}
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "14px",
                                    color: "#17365D",
                                    fontWeight: 400,
                                }}
                            >
                                {item.reason}
                            </MenuItem>
                        ))}

                    </Select>
                </Box>
                {selectedReason.reason.toLowerCase() === "other" && <Box style={{ padding: "24px 40px", display: "flex", flexDirection: "column", gap: "4px", alignItems: "flex-start", justifyContent: "flex-start", position: "relative" }}>
                    <Typography style={{ color: "#1F497D", fontSize: "14px", fontWeight: 700, fontFamily: "Arial" }}>Please specify your reason</Typography>
                    <TextField
                        style={{
                            border: "1px solid #9C9C9C",
                            borderRadius: "8px",
                            cursor: "pointer"
                        }}
                        placeholder="Enter your reason here"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                            const updatedObj = { ...selectedReason, otherReason: event.target.value }
                            handleSelect(updatedObj)
                        }}
                    />
                </Box>}
                <Box style={{ padding: "24px 40px", borderTop: "1px solid #F4F4F4", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "16px" }}>
                    <CustomBottonComponent title={onCancleText} type="secondary" onClick={handleClose} style={{ fontSize: "16px", padding: "0px 30px" }} />
                    <CustomBottonComponent title={onDeleteText} type={selectedReason.reason !== "" ? "confirm" : "confirmDisabled"} onClick={() => selectedReason.reason !== "" && handleSubmit()} style={{ fontSize: "16px", padding: "0px 30px" }} />
                </Box>
            </ReasonBox>
        </Dialog>
    );
}

const ReasonBox = styled(Box)({
    '& .options': {
        cursor: "pointer",
        color: "#17365D", borderRadius: "8px", padding: "9px 20px 9px 16px",
        fontWeight: 400, fontFamily: "Arial", fontSize: "12px", marginTop: "2px",
        '&:hover': {
            background: "#1F497D",
            color: "#fff",
        },
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
        cursor: "pointer",
        caretColor: "transparent",
        fontWeight: 400,
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#17365D"
    },
    '& .MuiOutlinedInput-input': {
        fontWeight: 400,
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#17365D"
    },

});