import React from "react";

// Customizable Area Start
import { Typography, Grid } from "@material-ui/core";
import { down, downreIcon, likedUpvote, up } from "./assets";
// Customizable Area End

import UpvotedownvoteController, {
  Props,
  configJSON,
} from "./UpvotedownvoteController";

export default class Upvotedownvote extends UpvotedownvoteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid item style={{ display: "flex", alignItems: "center", cursor: "pointer" }} id="upvote" onClick={() => { this.upvotePost(this.props.post.id, "BxBlockPosts::Post") }}>
          <img src={this.props.post.attributes.isliked ? likedUpvote : up} style={{height: "16px",}} />&nbsp;
          <Typography style={webStyle.footerText}>{this.props.post.attributes.likes_count}</Typography>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center", paddingLeft: "5px", cursor: "pointer" }} id="downvote" onClick={() => { this.downvotePost(this.props.post.id, "BxBlockPosts::Post") }}>
          <img src={this.props.post.attributes.isdisliked ? downreIcon : down} style={{height: "16px",}}/>&nbsp;
          <Typography style={webStyle.footerText}>{this.props.post.attributes.dislikes_count}</Typography>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  footerText: {
    color: "#17365D",
    fontSize: "12px",
    fontFamily: "Arial"
  },
};
// Customizable Area End
