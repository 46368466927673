import React from "react";

// Customizable Area Start
import { Helmet } from 'react-helmet';
import {
    Container,
    Box,
    Button,
    Input,
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
    CircularProgress,
    TextField,
    Grid,
    MenuItem,
    ClickAwayListener,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { dropdown, groupImage, tickedImage } from "./assets";
import { META_TAGS, PasswordScema } from "../../../../packages/components/src/Utils.web";

const ForgotBlock = styled(Box)({
    '& .heading': {
      '@media (max-width: 960px)': {
        marginLeft: '0 !important',marginTop: '30px',textAlign: 'center'
      },
    },
    "& .roleInput": {
        "& .MuiOutlinedInput-inputAdornedEnd": {
          cursor: "pointer",
          caretColor: "transparent"
        }
      },
    '& .bgImage': {
      '@media (max-width: 960px)': {display: 'none'}
    }
  })  

// Customizable Area End

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController";
import { ToastContainer } from "react-toastify";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class ForgotPasswordBlock extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });
    validationSchemaForStep4 = Yup.object().shape({
        password:PasswordScema,
    });
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                        <Helmet>
          <meta
            name="description"
            content={`
            Hire Real Estate Investment Specialists: Join Us Today 
            Connect with Credit Professionals: Join Our Recruiting Platform 
            Real Estate Investment Careers on Our Professional Network 
            Explore Credit Opportunities on Our Networking Platform 
          `}
          />
          <meta
            content={META_TAGS}
            name="keywords"
          />
        </Helmet>
                <Container maxWidth={false} style={{ width: '100%', padding: '0px' }}>
                    <ForgotBlock sx={{ bgcolor: '#6D97C1', height: '100vh', display: { md: 'flex', lg: 'flex', xl: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container justifyContent="center" style={{ background: '#6D97C1', flexWrap: 'wrap-reverse' }}>
                            <Grid item md={4} xs={10} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                <Box
                                    sx={{
                                        bgcolor: '#EEECE1',
                                        padding: '40px 20px 20px 20px',
                                        borderRadius: '20px',
                                        color: "#1F497D",
                                        marginTop: { md: '25%', lg: '25%', xl: '25%' }
                                    }}
                                >
                                    <Typography style={{
                                        color: "#1F497D",
                                        fontSize: "24px",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontFamily: "Arial"
                                    }}>
                                        Forgot Password
                                    </Typography>
                                    {this.handleTwoCondition(this.state.emailSteps === 3, <Typography style={{
                                        color: "#64748B",
                                        fontSize: "0.9rem",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        margin: '30px 0px 20px 0px'
                                    }}>
                                        Hello User!
                                    </Typography>)}
                                    {this.handleTwoCondition(this.state.emailSteps === 5, <div style={{ display: 'flex' }}><Typography style={{
                                        color: "#64748B",
                                        fontSize: "0.9rem",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        margin: '30px 0px 0px 0px'
                                    }}>
                                        Password Reset
                                    </Typography> <img src={tickedImage} style={{ width: '15px', height: '15px', margin: '33px 0px 0px 5px' }} /></div>)
                                    }
                                    <Typography style={{
                                        color: "#64748B",
                                        fontSize: "0.8rem",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        margin: '30px 0px 20px 0px',
                                        fontFamily: "Arial"
                                    }}>
                                        {this.handleTwoCondition(this.state.emailSteps === 2, 'You will receive an email with instructions to reset your password if an account exists for this email address.')}
                                        {this.handleTwoCondition(this.state.emailSteps === 3, 'A request has been made to reset your password. If you have made this request, please click on the button below. ')}
                                        {this.handleTwoCondition(this.state.emailSteps === 5, 'Your password has been reset you can login securely to your account.')}
                                    </Typography>
                                    {(this.state.emailSteps === 1 ||
                                        this.state.emailSteps === 4) && <Formik
                                            enableReinitialize={true}
                                            data-testid='formik'
                                            initialValues={{ email: '', password: '', role: '', roleId: '' }}
                                            validationSchema={this.handleCondition(this.state.emailSteps === 4, this.validationSchemaForStep4, this.validationSchema)}
                                            onSubmit={(values, actions) => this.handleOnSubmit(values)}
                                        >
                                            {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, setTouched }) => (
                                                <form onSubmit={handleSubmit}>
                                                    {this.state.emailSteps === 4 ?
                                                        <Box sx={{ width: "100%", paddingTop: "7px" }}>
                                                            <Typography style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Arial", marginBottom: "4px" }}>New Password</Typography>
                                                            <TextField variant="outlined"
                                                                inputProps={{
                                                                    style: {
                                                                        padding: '12px 10px 12px 10px',
                                                                        background: '#FFF',
                                                                        fontSize: '14px',
                                                                        borderRadius: "8px",
                                                                        fontFamily: "Arial"
                                                                    }
                                                                }}
                                                                type="password"
                                                                name="password"
                                                                value={values.password}
                                                                onFocus={()=> { setTouched({
                                                                    ...touched,
                                                                    password: true
                                                                })
                                                                this.setMailError()
                                                                }
                                                                }
                                                                id="password-field"
                                                                onChange={handleChange("password")}
                                                                error={(!!(touched?.password && errors.password) || this.state.mailError)}
                                                                fullWidth placeholder="*************" />
                                                            {!!(touched?.password && errors.password) && <Typography style={{ color: 'red', fontSize: "0.8rem", textAlign: 'right', display: 'block ruby', fontWeight: 400 }}>{errors.password}</Typography>}
                                                            {this.handleTwoCondition(this.state.mailError, <Typography style={{ color: 'red', fontSize: "0.8rem", textAlign: 'right', display: 'block ruby', fontWeight: 400 }}>{this.state.errorFromBackend}</Typography>)}
                                                        </Box> : 
                                                        <>
                                                        <Box sx={{ width: "100%", paddingTop: "7px" }}>
                                                            <Typography style={{ fontSize: "14px", fontWeight: 700, fontFamily: "Arial", marginBottom: "4px" }}>Email</Typography>
                                                            <TextField variant="outlined"
                                                                inputProps={{
                                                                    style: {
                                                                        padding: '12px 10px 12px 10px',
                                                                        background: '#FFF',
                                                                        fontSize: '14px',
                                                                        borderRadius: "8px",
                                                                        color: "#0F172A"
                                                                    }
                                                                }}
                                                                name="email"
                                                                value={values.email}
                                                                onChange={handleChange("email")}
                                                                onFocus={()=> {
                                                                    setTouched({...touched, email: true});
                                                                    this.setMailError()
                                                                }}
                                                                id="email-field"
                                                                error={(!!(touched?.email && errors.email) || this.state.mailError)}
                                                                fullWidth placeholder="Enter your email" />
                                                            {!!(touched?.email && errors.email) && <Typography style={{ color: 'red', fontSize: "0.8rem", textAlign: 'right', display: 'block ruby', fontWeight: 400 }}>{errors.email}</Typography>}
                                                            {this.handleTwoCondition(this.state.mailError, <Typography style={{ color: 'red', fontSize: "0.8rem", textAlign: 'right', display: 'block ruby', fontWeight: 400 }}>Account not found</Typography>)}
                                                        </Box>
                                                            <Box sx={{ width: "100%", paddingTop: "20px" }}>
                                                                <Typography style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Arial", marginBottom: "4px" }} >Roles</Typography>
                                                                <ClickAwayListener onClickAway={this.handleRoleClose}>
                                                                <TextField variant="outlined"
                                                                    className="roleInput"
                                                                    data-test-id="roleField"
                                                                    fullWidth size="small"
                                                                    onClick={this.handleRoleClick}
                                                                    name="role"
                                                                    value={values.role}
                                                                    style={{ background: '#FFF', borderRadius: "8px", border: "1px solid  #CBD5E1" }}
                                                                    error={this.handleCondition(touched?.role, errors.role, "")}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <img src={dropdown} alt="dropdown" 
                                                                                onClick={this.handleRoleClick}
                                                                                 />
                                                                            </InputAdornment>
                                                                        ),
                                                                        style: {
                                                                            fontFamily: 'Arial',
                                                                            fontWeight: '400', fontSize: '14px',
                                                                            color: this.handleCondition(values.role, '#64748B', '#A6B0BF'),
                                                                            cursor: "pointer",
                                                                        }
                                                                    }}>
                                                                </TextField>
                                                                </ClickAwayListener>
                                                                <Box style={{
                                                                    position: "relative",
                                                                    paddingTop: "2px"
                                                                }}>
                                                                    {this.handleCondition(this.state.rolesOpen, <Box style={{
                                                                        position: "absolute",
                                                                        width: "100%", zIndex: 2,
                                                                        background: "#fff",
                                                                        border: "1px solid #CBD5E1",
                                                                        borderRadius: "8px"
                                                                    }}>
                                                                        {this.state.availableRoles.map((item: {id: number, attributes: {name: string}}) => {
                                                                            return <MenuItem
                                                                                value={values.role}
                                                                                data-test-id="roles"
                                                                                onClick={() => {
                                                                                    setFieldValue("roleId", item.id);
                                                                                    setFieldValue("role", this.handleCondition(item.attributes.name === 'Candidate', 'Job Applicant', item.attributes.name));

                                                                                    this.setState({ rolesOpen: false });
                                                                                }}
                                                                                style={{
                                                                                    fontSize: '16px',
                                                                                    fontFamily: 'Arial',
                                                                                    color: "#64748B",
                                                                                    fontWeight: 400
                                                                                }}>
                                                                                {this.handleCondition(item?.attributes?.name === 'Candidate', 'Job Applicant', item?.attributes?.name)}
                                                                            </MenuItem>
                                                                        })}
                                                                    </Box>, "")}
                                                                </Box>
                                                            </Box>
                                                        </>
                                                    }
                                                    <Grid container spacing={1} justifyContent="center" >
                                                        <Grid item md={5}>
                                                            <Button
                                                                disabled={this.checkForDisabledButton(values)}
                                                                type="submit"
                                                                data-test-id="submitbutton"
                                                                variant="contained"
                                                                style={{
                                                                    background: this.handleCondition(this.checkForDisabledButton(values), "rgb(203, 213, 225)", "#6D97C1"),
                                                                    color: "white",
                                                                    textTransform: 'capitalize',
                                                                    fontSize: "16px",
                                                                    marginTop: '30px',
                                                                    fontWeight: 700,
                                                                    fontFamily: "Arial",
                                                                }}
                                                                fullWidth
                                                            >
                                                                {this.handleCondition(this.state.emailSteps === 4, 'Reset Password', 'Send Link to Email')}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </form>
                                            )}
                                        </Formik>}
                                    {(this.state.emailSteps === 3 ||
                                        this.state.emailSteps === 5) &&
                                        <Grid container spacing={1} justifyContent="center" >
                                            <Grid item md={this.state.emailSteps === 5 ? 4 : 5}>
                                                <Button
                                                    data-test-id="resetbutton"
                                                    variant="contained"
                                                    onClick={() => { this.handleCondition(this.state.emailSteps === 5, this.goToLoginPage(), this.handleStepsScreens(4))
                                                    }}
                                                    style={{
                                                        background: "#6D97C1",
                                                        color: "white",
                                                        textTransform: 'capitalize',
                                                        fontSize: "12px",
                                                        margin: '15px 0px 15px 0px'
                                                    }}
                                                    fullWidth
                                                >
                                                    {this.handleCondition(this.state.emailSteps === 5, 'Login Now', 'Reset Password')}
                                                </Button>
                                            </Grid>
                                        </Grid>}
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={10} style={{ marginLeft: '5%' }} className="heading">
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        fontSize: '54px',
                                        fontWeight: 600,
                                        fontFamily: 'Arial'
                                    }}
                                >
                                    Work Centivo
                                </Typography>
                                <img
                                    src={groupImage}
                                    alt="login"
                                    height="530px"
                                    className="bgImage"
                                />
                            </Grid>
                        </Grid>
                    </ForgotBlock>
                </Container>
                <ToastContainer />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}