import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { filterIcon } from '../../blocks/leaderboard/src/assets';
import SkillSelect from './SkillSelect';
import ExperienceSelect from './ExperienceSelect';
import LocationSelect from './LocationSelect';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '4px',
    transition: 'background-color 0.3s, border 0.3s',
  },
  filterButtonActive: {
    backgroundColor: '#EEECE1', 
    border:' 1px solid #6D97C1',
    borderRadius:'6px'
  }
}));
interface Props {
  handleCountryChange: (value: string) => void;
  handleCityChange: (value: string) => void;
  handleExperienceChange: (opt: { id: number | string, title: string }) => void;
  handleSkillChange: (opt: { id: number | string, title: string }) => void;
  selectedSkills: string[];
  selectedExperiences: string[];
  selectedCountry: string;
  selectedCity: string;
  experienceList: { id: number | string, title: string }[],
  skillsList: string[],
  isoCode: string,
  setISOCode: (value: string) => void,
  clearFilter: () => void
}

const FilterToggle: React.FC<Props> = (props) => {
  let { experienceList, skillsList, handleCityChange, handleCountryChange, handleExperienceChange, handleSkillChange, selectedCity, selectedCountry, selectedExperiences, selectedSkills, isoCode, setISOCode, clearFilter } = props
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);

  const handleFilterToggle = () => {
    if(showFilters){
      clearFilter()
    }
    setShowFilters(!showFilters);
  };

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {showFilters && (
        <Box style={{ display: 'flex', gap: '10px', marginRight: '5px' }}>
          
          <ExperienceSelect
            options={experienceList}
            fieldName="Year of Experience"
            handleMenuItemClick={handleExperienceChange}
            values={selectedExperiences}
          />

          <SkillSelect
            options={skillsList}
            fieldName="Skills"
            handleMenuItemClick={handleSkillChange}
            values={selectedSkills}
          />

          <LocationSelect
            fieldName="Location"
            handleCountryChange={handleCountryChange}
            handleCityChange={handleCityChange}
            selectedCity={selectedCity}
            selectedCountry={selectedCountry}
            isoCode={isoCode}
            setISOCode={setISOCode}
          />
        </Box>
      )}

      <Box
        className={`${classes.filterButton} ${showFilters ? classes.filterButtonActive : ''}`}
        onClick={handleFilterToggle}
      >
        <img src={filterIcon} alt="filterIcon" />
      </Box>
    </Box>
  );
};

export default FilterToggle;
