import moment from "moment/moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Linking } from "react-native";
import { Project } from "./model/Project";
import { Award } from "./model/Award";
import { DataType } from "./model/DataType";
import { EducationalQualification } from "./model/EducationalQualification";
import { PublicationPatent } from "./model/PublicationPatent";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { ChangeEvent } from "react";

export enum Tab {
  Projects = "1",
  Awards = "2",
  Patents = "3",
}

export interface DealExperienceType {
  deal_type: string,
  involvement_type: string,
  transaction_side: string,
  target_name: string,
  buyer_investor_name: string,
  transaction_summary: string,
  enterprise_value: string,
  percentage_stake: string,
  valuation_method: string,
  valuation_multiple: string,
  main_contributions: string[],
  transaction_merits: string,
  potential_risks: string,

  debt_type: string,
  borrower_name: string,
  lender_name: string,
  debt_size: string | null,
  leverage_level: string | null,
  interest_rate: string
}

export type SetFieldTouchedType = (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
export type SetFieldsType = (field: string, value: any, shouldValidate?: boolean | undefined) => void

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userName: string;
  isVisible: boolean;
  apiToken: string | null;
  educationQualificationList: EducationalQualification[];
  projectList: Project[];
  awardList: Award[];
  patentList: PublicationPatent[];
  loadingEQ: boolean;
  activeTab: string;
  loadingProject: boolean;
  loadingAwards: boolean;
  loadingPub: boolean;
  isModalOpen: boolean;
  modalItem: DataType<unknown> | null;
  initialValues: DealExperienceType;
  companyList: { value: string, label: string }[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EducationalUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelTitle: string = "";
  getDealExperienceApiId: string = "";
  updateDealExperienceApiId: string = "";
  companyListApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      userName: "",
      isVisible: false,
      apiToken: null,
      educationQualificationList: [],
      projectList: [],
      awardList: [],
      patentList: [],
      activeTab: "1",
      loadingEQ: true,
      loadingProject: true,
      loadingAwards: true,
      loadingPub: true,
      isModalOpen: false,
      modalItem: null,
      initialValues: {
        deal_type: 'equity',
        involvement_type: '',
        transaction_side: '',
        target_name: '',
        buyer_investor_name: '',
        transaction_summary: '',
        enterprise_value: '',
        percentage_stake: '',
        valuation_method: '',
        valuation_multiple: '',
        main_contributions: [],
        transaction_merits: '',
        potential_risks: '',

        debt_type: '',
        borrower_name: '',
        lender_name: '',
        debt_size: '',
        leverage_level: '',
        interest_rate: ''
      },
      companyList: [],
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const dealExperienceId = this.props.navigation.getParam("navigationBarTitleText");

    this.getDealExperience(dealExperienceId);
    this.companyListHandler({target: {value: 'a'}})
    const containerDiv = document.getElementById('pageContainer')
    if (containerDiv) { containerDiv.scrollIntoView() }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.getDealExperienceApiId:
          return this.handleGetDealExperience(responseJson);
        case this.updateDealExperienceApiId: 
          return this.handleUpdateResponse(responseJson);
        case this.companyListApiId:
          return this.handleCompanyResponse(responseJson);

        default:
          return null

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  hideModal = () => {
    this.setState({ isModalOpen: false, modalItem: null });
  };

  showModal = (modalItem: DataType<unknown>) => {
    this.setState({ isModalOpen: true, modalItem });
  };

  setActiveTab = (activeTab: string) => {
    this.setState({ activeTab });
  };

  openUrl = async (targetUrl?: string) => {
    if (!targetUrl) {
      return;
    }

    const supported = await Linking.canOpenURL(targetUrl);
    if (supported) {
      Linking.openURL(targetUrl);
    }
  };

  formatDate = (value: string) => {
    return moment.utc(value).format("YYYY-DD-MM");
  };

  handleGetDealExperience = (responseJson: {deal_experience: {data : { attributes: DealExperienceType } } } | {error : string} ) => {
    if('deal_experience' in responseJson && responseJson.deal_experience.data) {
      let experienceData = responseJson.deal_experience.data.attributes 

      Object.keys(experienceData).forEach((keyValue) => {
        const key = keyValue as keyof DealExperienceType;
        if( experienceData[key] === null && key !== "main_contributions") { experienceData[key] = '' }
      });

      this.setState({
        initialValues: experienceData
      })
    } 
    if ('error' in responseJson) {
      toast.error(responseJson.error)
    }
  }

  handleCompanyResponse = (responseJson: {company_listing: {name: string}[]}) => {
    if (responseJson?.company_listing && responseJson?.company_listing.length > 0) {
      const companyList = responseJson?.company_listing?.map((company) => ({
          value: company.name || '',
          label: company.name || '',
      }));
      this.setState({ companyList })
  }
  }

  handleUpdateResponse = (responseJson: {errors: {[key: string]: string}[]} | {message: string} ) => {
    if ('errors' in responseJson) {
      toast.error(Object.values(responseJson.errors[0])[0])
    }
    if('message' in responseJson) {
      toast.success(responseJson.message)
      this.handleNavigateOnCancel()
    }
  }

  handleChangeField = (event: ChangeEvent<{}>, setFieldValue: (fieldName: string, value: string) => void, fieldName: string) => {
    const selectedValue = (event.target as HTMLInputElement).textContent || '';
    setFieldValue(fieldName, selectedValue);
  }

  handleBackClick = () => {
    this.props.navigation.goBack()
  }

  handleNavigateOnCancel = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `JobApplicantProfile`);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  companyListHandler = (event: {target: {value: string}}) => {
    const { value } = event.target;

    if (value) {
        this.handleInputChangeDebounced(value);
    }
  };

  handleInputChangeDebounced = debounce(async (value: string) => {
    const authToken = await getStorageData("authToken")

    const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": authToken
    };
    let requestBody = { search_name: value || '' };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.companyListApiId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.CompanyListApiEndPoint}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }, 500)

  updateDealExperience = async (updatedData: DealExperienceType) => {
    const dealExperienceId = this.props.navigation.getParam("navigationBarTitleText");
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formData = new FormData();

    formData.append("deal_experience[deal_type]", `${updatedData.deal_type}`);
    formData.append("deal_experience[involvement_type]", `${updatedData.involvement_type}`);
    formData.append("deal_experience[transaction_merits]", `${updatedData.transaction_merits}`);
    formData.append("deal_experience[potential_risks]", `${updatedData.potential_risks}`);

    updatedData.main_contributions.forEach((item: string) => {
      formData.append("deal_experience[main_contributions][]", `${item}`);
    })

    
    if (updatedData.deal_type === 'equity') {
      formData.append("deal_experience[transaction_side]", `${updatedData.transaction_side}`);
      formData.append("deal_experience[target_name]", `${updatedData.target_name}`);
      formData.append("deal_experience[buyer_investor_name]", `${updatedData.buyer_investor_name}`);
      formData.append("deal_experience[transaction_summary]", `${updatedData.transaction_summary}`);
      formData.append("deal_experience[enterprise_value]", `${updatedData.enterprise_value}`);
      formData.append("deal_experience[percentage_stake]", `${updatedData.percentage_stake}`);
      formData.append("deal_experience[valuation_method]", `${updatedData.valuation_method}`);
      formData.append("deal_experience[valuation_multiple]", `${updatedData.valuation_multiple}`);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.dealExperienceApi}/${dealExperienceId}/edit_equity_deal_experience`
      );
    }

    if (updatedData.deal_type === 'debt') {
      formData.append("deal_experience[debt_type]", `${updatedData.debt_type}`);
      formData.append("deal_experience[transaction_side]", `${updatedData.transaction_side}`);
      formData.append("deal_experience[borrower_name]", `${updatedData.borrower_name}`);
      formData.append("deal_experience[lender_name]", `${updatedData.lender_name}`);
      formData.append("deal_experience[transaction_summary]", `${updatedData.transaction_summary}`);
      formData.append("deal_experience[debt_size]", `${updatedData.debt_size}`);
      formData.append("deal_experience[leverage_level]", `${updatedData.leverage_level}`);
      formData.append("deal_experience[interest_rate]", `${updatedData.interest_rate}`);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.dealExperienceApi}/${dealExperienceId}/edit_debt_deal_experience`
      );
    }

    const headers = {
      "token": authToken,
    };

    this.updateDealExperienceApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDealExperience = async (dealExperienceId: string | number) => {
    const authToken = await getStorageData("authToken")
    const headers = {
      "token": authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealExperienceApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.dealExperienceApi}/${dealExperienceId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
