export const text = require("../assets/text.png");
export const dot = require("../assets/dot.svg");
export const more = require("../assets/more.svg");
export const plus = require("../assets/plus.svg");
export const minus = require("../assets/minus.svg");
export const up = require("../assets/up.svg");
export const down = require("../assets/down.svg");
export const commentIcon = require("../assets/comment.svg");
export const save = require("../assets/save.svg");
export const saveBlue = require("../assets/saveblue.svg");
export const gallary = require("../assets/gallary.svg");
export const remove = require("../assets/remove.svg");
export const likedUpvote = require("../assets/likedUpvote.svg");
export const downreIcon = require("../assets/downred.svg");
export const saveBlueOutlineIcon = require("../assets/saveblueoutline.svg")
