Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.postApiMethod = 'POST';
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.httpGetMethod = "GET";
exports.httpPatchMethod = "PATCH";
exports.getAllPosts = "/bx_block_posts/posts"
exports.createPosts =  "bx_block_posts/posts"
exports.selectTopicsApi = "bx_block_categories/sub_categories/sub_category_dropdown"
exports.aplyFiltersAPiEndPoint = "bx_block_joblisting/jobs/landing_page_job_listing";
exports.getCreatedPost = "bx_block_posts/posts"
exports.selectedGroupContextApi = "bx_block_profile/profiles/select_group_context"
exports.putApiMethod = "PUT"
exports.deleteApiMethod = "DELETE"
exports.getProfile = 'bx_block_profile/profiles'
exports.baseURL = require('../../../framework/src/config')
exports.getCandidateProfilesAPIEndPoint = 'bx_block_profile/profiles/get_candidates_list'
exports.skillsListingApi = "bx_block_joblisting/jobs/skills_listing";
exports.talentPipelineCandidateEndPoint = "bx_block_cfrecruitersdashboard/talent_pipelines";
exports.getCategoriesListApiEndPoint = "/bx_block_categories/categories";
exports.getSubCategoriesApiendPoint = "/bx_block_categories/sub_categories";

exports.topicsList = {
  "Buy-Side" : ["Private Equity", "Growth Equity", "Venture Capital", "Infrastructure Investment", "Real Estate Investment", "Investor Relations and Capital Raising", "Corporate Development" ],
  "Sell-Side" : ["Investment Banking", "Equity Research", "Sales and Trading", "Market Analysis"],
  "Career Development" : ["Breaking into Buy-side", "Breaking into Sell Side", "Career Advancement", "Work-Life Balance", "Networking and Mentorship"]
} 
exports.postDescriptionMaxLimit = 3000
// Customizable Area End