Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.votePostMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Upvotedownvote";
exports.labelBodyText = "Upvotedownvote Body";

exports.upvotesLabelText = "upvotes";
exports.downvotesLabelText = "downvotes";
exports.checkVoteStatusEndpoint = "bx_block_upvotedownvote/upvotedownvotes/status";
exports.upvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/upvote";
exports.downvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/downvote";
exports.errorAlertTitle = "Error";
exports.errorAlertMessage = "Something went wrong";
exports.requestHeaderContentType = "multipart/form-data";
// Customizable Area End
