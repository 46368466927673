import React from "react";
// Customizable Area Start
import {
  ScrollView,
  TouchableWithoutFeedback,
  KeyboardAvoidingView
} from "react-native";

// Customizable Area End

import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <KeyboardAvoidingView
      >
        <ScrollView >
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Customizable Area End */}
            {/* Merge Engine UI Engine Code */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.requestSessionData();
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End
  
}
// Customizable Area Start
// Customizable Area End
