import React from "react";
// Customizable Area Start
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { imgFilter } from './assets';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import Filteritems from "./Filteritems.web";
// Customizable Area End

import FilteroptionsController, {
  Props,
  configJSON
  // Customizable Area Start
  // Customizable Area End
} from "./FilteroptionsController";

export default class Filteroptions extends FilteroptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.root}>
        <Popper
          open={this.state.open}
          // anchorEl={buttonRef} 
          placement="bottom-start"
          role={undefined}
          transition
          disablePortal
          style={webStyle.filterSection as React.CSSProperties}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'left' ? 'right top' : 'right bottom' }}
            >
              <Paper style={webStyle.paper}>
                <div style={{ margin: '0px 20px' }}>
                  <Grid container spacing={1} justifyContent='space-between'>
                    <Grid item>
                      <Typography style={webStyle.heading}>Filters</Typography>
                    </Grid>
                    <Grid item><CloseRoundedIcon style={{ cursor: 'pointer' }} onClick={() => this.handleToggle(false)} /></Grid>
                  </Grid>
                  <Filteritems
                    navigation={undefined}
                    id={""}
                    country={(val: string) => { this.setCountryvalue(val) }}
                    city={(val: string) => { this.setCityValue(val) }}
                    date={(val: string) => { this.setDatevalue(val) }}
                    postBy={(val: string) => { this.setPostedByValue(val) }}
                    skills={(val: string[]) => { this.setSkillValue(val) }}
                    experience={(val: string[]) => { this.setExperienceValue(val) }}
                    disableButton={(val: boolean) => { this.setButtonState(val) }}
                    experienceSelect={(val: string[]) => { this.setExperienceCheckValue(val) }}
                    ref={this.childRef} />
                </div>
                <Divider style={webStyle.divider} />
                <Grid container justifyContent='space-around'>
                  <Grid item md={3}>
                    <Button disabled={this.state.disableButton} fullWidth style={webStyle.buttonRef} onClick={() => this.resetState()} >
                      <RefreshRoundedIcon style={{ color: this.state.disableButton ? '#CBD5E1' : '#6D97C1' }} />
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button disabled={this.state.disableButton} fullWidth style={this.state.disableButton ? webStyle.buttonAppDisable : webStyle.buttonApp} onClick={() => this.applyfilters()}>
                      <Typography style={webStyle.applyButtonText as React.CSSProperties}>Apply</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    display: 'flex',
  },
  filterSection: {
    top: '110px',
    left: '5%',
    position: 'absolute',
    scrollbarWidth: 'thin',
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    zIndex: 2,
  },
  paper: {
    padding: '15px 0px',
    boxShadow: 'none'
  },
  heading: {
    fontSize: '18px',
    color: '#17365D',
    fontWeight:700
  },
  divider: {
    color: '#CBD5E1',
    height: '2px',
    margin: '0px 0px 15px 0px'
  },
  buttonApp: {
    padding:'12px',
    background:'#6D97C1',
    color:'#FFFFFF',
    fontWeight:700,
    borderRadius:'10px',
    width: '120px',
    height: '56px'
  },
  buttonAppDisable: {
    padding:'12px',
    background:'#CBD5E1',
    color:'#FFFFFF',
    fontWeight:700,
    borderRadius:'10px',
    width: '120px',
    height: '56px'
  },
  buttonRef: {
    padding:'12px',
    background:'#F4F4F4',
    color:'#6D97C1',
    borderRadius:'10px',
    width: '85px',
    height: '56px'
  },
  applyButtonText: {
    color: 'var(--Basic-White, #FFF)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none'
  }
};
// Customizable Area End
