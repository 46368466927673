import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid } from '@material-ui/core';
import { bookicon, brifcaseBlueicon, bulbicon, cancleicon, rightgreenicon, suitecaseicon, crossBlueIcon, dropIcon, editBlueIcon } from '../assets';
import CustomChipComponent from '../../../../components/src/CustomChipComponent.web';
import { S } from '../JobListingController';
import { TruncateWithTooltip, getCommaSeparatedValue } from '../../../../../packages/components/src/Utils.web';
import BootstrapTooltip from '../../../../../packages/blocks/catalogue/src/components/BootstrapTooltip';


const useStyles = makeStyles({
    root: {
        padiing: "56px",
        minWidth: "60%",
        position: "relative",
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#1F497D",
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: "#1F497D",
        },
        "& .MuiDialog-paperWidthSm" : {
            minWidth: "35vw",
            minHeight: "80vh"
        }
    },
    jobDetailsContainer: {
        "borderRadius": "16px",
        "border": "1px solid #F4F4F4",
        "background": "#FFF",
        padding: "16px 30px",
        display: "flex",
        "flexDirection": "column",
        "margin": "0px",
        "width": "100%",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all"
    },
    title0: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "display": "flex",
        "alignItems": "center",
        "paddingTop": "7px"
    },
    title1: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "paddingTop": "7px",
        "display": "flex",
        "alignItems": "center",
    },
    title2: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "textAlign": "left",
        "minWidth": "100px"
    },
    description: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px"
    },
    descriptionOne: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "whiteSpace": "pre-wrap"
    },
    expandBtn: {
        "color": "#1F497D",
        "textAlign": "right",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "textTransform": "none",
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    noBtn: {
        "borderRadius": "8px",
        "border": "1px solid #1F497D",
        "color": "#17365D",
        "padding": "8px",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        }
    },
    yesBtn: {
        "borderRadius": "8px",
        "border": "1px solid #C7D9F0",
        "color": "#17365D",
        "padding": "8px",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        background: "#C7D9F0",
        "&:hover": {
            background: "#C7D9F0"
        }
    },
    questionText: {
        "color": "#1F497D",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px"
    },
    refBtn: {
        padding: "0px",
        margin: "0px",
        "minWidth": "12px"
    },
    title3: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px"
    },
    root1: {
        "borderBottom": "1px solid #F4F4F4",
        "background": "#FFF",
        "padding": "0px 20px 20px 22px !important",
        "position": "relative",
        "marginTop": "10px"
    },
    title: {
        "color": "#17365D",
        "fontSize": "24px",
        "fontWeight": "bold",
        "lineHeight": "26px",
        "fontFamily": "Arial",
        "display": "flex",
        "alignItems": "center"
    },
    des: {
        "color": "#6D97C1",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "marginTop": "15px"
    },
    expTxt: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
    }
});

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    state: S;
    handleEditClick: (value: string | number) => void
}

export default function JobDetailsModal(props: SimpleDialogProps) {
    const classes = useStyles();
    const { onClose: handleCloseModal, open, handleEditClick } = props;
    const { JobDetails } = props.state;
    const [initial, setInitial] = React.useState(6);
    let onClose = () => {
        handleCloseModal();
        setInitial(6)
    }
    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root} >
            <Grid item container className={classes.root1} sm={12} 
            id= "scroll-handle-id"
            tabIndex={0}
            onKeyDown={(event) => {
                const container = document.getElementById('dialogueContainer');
                if (container) {
                    if (event.key === 'ArrowDown') {
                        container.scrollBy(0, 50);
                    } else if (event.key === 'ArrowUp') {
                        container.scrollBy(0, -50);
                    }
                }
            }}>
                <Grid item container>
                    <Grid item sm={12} xs={12} style={{ padding: "13px" }}>
                        <Grid item container sm={12} style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography className={classes.title}>
                                {
                                    <BootstrapTooltip title={JobDetails.attributes.job_title.length >= 30 ? JobDetails.attributes.job_title : "" }>
                                        <span>
                                            {TruncateWithTooltip(JobDetails.attributes.job_title, 30)}
                                        </span>
                                    </BootstrapTooltip>
                                }&nbsp;&nbsp; <img id="open-edit-modal-icon" src={editBlueIcon} alt="edit" style={{ cursor: "pointer" }} onClick={ () => {
                                    onClose();
                                    handleEditClick(JobDetails.id);
                                }
                                }/>
                            </Typography>
                            <img src={crossBlueIcon} alt="icon" style={{ position: "absolute", right: "30px", top: "20px" }} onClick={onClose} />
                        </Grid>
                        <Typography className={classes.des}>
                            {JobDetails.attributes.company_name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container style={{ display: "flex", justifyContent: "space-between", padding: "0px 13px 13px 13px", flexWrap: "wrap" }}>
                    <Grid item style={{ flex: 1, display: "flex", alignItems: "center" }} >
                        <img alt="icon" src={brifcaseBlueicon} />&nbsp;
                        <Typography className={classes.expTxt}> {JobDetails.attributes.experience_required}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item style={{ flex: 1, paddingLeft: "20px", display: "flex", alignItems: "center" }}>
                        <Typography className={classes.expTxt} style={{ fontWeight: 700, marginRight: "4px"}}>{JobDetails.attributes.currency}</Typography>
                        <Typography className={classes.expTxt} data-test-id = "price-text">{!JobDetails.attributes.disclose ? "Not Disclosed" : `${getCommaSeparatedValue(JobDetails.attributes.salary_from)} to ${getCommaSeparatedValue(JobDetails.attributes.salary_to)}`}</Typography></Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item style={{ flex: 1, paddingLeft: "20px", display: "flex", alignItems: "center" }}>
                        <img alt="icon" src={dropIcon} />&nbsp;
                        <Typography className={classes.expTxt}>{JobDetails.attributes.city}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.jobDetailsContainer}>
                <Grid item>
                    <Typography className={classes.title0}>Job Description</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.descriptionOne}>{JobDetails.attributes.job_description}</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.title1}>Experience</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.description}>{JobDetails.attributes.experience_required}</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.title1}><img src={suitecaseicon} alt="icon" />&nbsp;Job Type</Typography>
                </Grid>
                <Grid item container>
                    <Grid item>
                        <CustomChipComponent style={{
                            fontSize: "12px"
                        }} title={JobDetails.attributes.job_categories} type="approve" icon={rightgreenicon} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className={classes.title1}><img src={bookicon} alt="icon" />&nbsp;Education</Typography>
                </Grid>
                <Grid item container>
                    <Grid item>
                        <CustomChipComponent style={{
                            fontSize: "12px"
                        }} title={JobDetails.attributes.education} type="approve" icon={rightgreenicon} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className={classes.title1}>Industry Type</Typography>
                </Grid>
                <Grid item container>
                    <Typography className={classes.title3}>
                        {JobDetails.attributes.industry_type}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.title1}>Department</Typography>
                </Grid>
                <Grid item container>
                    <Typography className={classes.title3}>
                        {JobDetails.attributes.department}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.title1}><img src={bulbicon} alt="icon" />&nbsp;Skills</Typography>
                </Grid>
                <Grid item container spacing={2}>
                    {
                        JobDetails.attributes.skills_required.slice(0, initial).map((item: string, index: number) => <Grid id="chips" item key={item + index}><CustomChipComponent title={item} type="decline" icon={cancleicon} style={{ color: "#1F497D", fontSize: "12px" }} /></Grid>)
                    }
                    {JobDetails.attributes.skills_required.length > initial && <Grid item><Button id="more-data" className={classes.expandBtn} onClick={() => setInitial(prev => prev + 3)}>+ three more</Button></Grid>}
                </Grid>
            </Grid>
        </Dialog>
    );
}

