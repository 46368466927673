Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Followers";
exports.labelBodyText = "Followers Body";
exports.searchBoxText = "Search a follower";
exports.userListTitle = "User List";
exports.followingTitle = "Following";
exports.followTitle = "Follow";
exports.unFollowTitle = "UnFollow";
exports.ddTitle = "dd";
//custom
exports.labelTitleTextFollowing = "Following";
exports.labelTitleTextFollowers = "Followers";
exports.followersApiEndPoint = "bx_block_followers/follows/followers";
exports.followingApiEndpoint = "bx_block_followers/follows/following";
exports.unFollowApisApiEndPoint = "bx_block_followers/follows"
exports.addFollowersApiEndPoint = "bx_block_followers/follows"
exports.addFollowingApiEndPoint = "bx_block_followers/follows"
exports.getFollowersApiMethodType = "GET";
exports.getFollowingApiMethodType = "GET";
exports.deleteApiMethodType = "DELETE"
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.userListEndPoint = 'account_block/accounts'
exports.userListFollowersEndPoint = '/bx_block_followers/follows/following'
exports.userListFollowingEndPoint = '/bx_block_followers/follows/followers'
exports.receivedInvitations = "bx_block_friends/received_connection_requests"
exports.sentInvitations = "bx_block_friends/sent_connection_requests"
exports.connectionEndPoint = "bx_block_friends/connections_list"
exports.ignoreRquestEndPoint = "bx_block_friends/:connection_request_id/reject_connection_request"
exports.accepteRquestEndPoint= "bx_block_friends/:connection_request_id/accept_connection_request"
exports.withdrawRequestEndPoint = "bx_block_friends/:connection_request_id/withdraw_connection_request"
exports.removeConnectionRequest = "bx_block_friends/:connection_request_id/remove_connection"
exports.btnExampleTitle = "CLICK ME";
exports.createChat="bx_block_chat/chats";
exports.checkForSubscriptionAPI = "bx_block_subscriptions/subscriptions/user_subscription_details"
// Customizable Area End