import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import {message} from "../../user-profile-basic/src/assets"
import CustomisableSubscriptionAdapter from "../../adapters/src/CustomisableUserSubscriptionAdapter";
interface SubscriptionDetails {
  id: number;
  name: string;
  price: string;
  chat_limits: string | null;
  description: string;
  features: Feature[];
}
interface PriviousPlan {
  id: number;
  name: string;
  
}

interface Feature {
  id: number;
  name: string;
  description: string;
  subscription_id: number;
}

interface Attributes {
  start_date: string;
  end_date: string;
  status: string;
  user_type: string;
  subscription_details: SubscriptionDetails;
  previous_subscription_details: PriviousPlan;
}

interface UserSubscription {
  data: {
    id: string;
    type: string;
    attributes: Attributes;
  }
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedSetting:string
  mainBoxContent: string;
  isModalOpen: boolean;
  resend: boolean;
  emailVarified: boolean;
  showVerifiyEmail: boolean
  otpError: boolean;
  passerror:boolean;
  otp: string;
  timmer: boolean;
  verifyloader: boolean;
  emailExist: string;
  newEmail:string;
  password:string;
  forgotPassword:boolean
  showOTPModal: boolean;
  showThirdModal: boolean;
  showSubscriptionContent:boolean;
  profileEmail:string;
  subscriptionData?: UserSubscription;
  userRole: string
  showPassword: boolean;
  showConfirmPassword: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendOtpCallId:string
  verifyOtpCallId:string
  forgotVerifyOtpId:string;
  updateEmailCallId:string
  changePasswordCallId: string
  forgotPasswordCallId:string
  sendForgotEmailCallId: string
  subscriptionCallId:string
  cancelSubscriptionCallId:string
  userEmailId:string
  data:any
  dataNonCandidate:any
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.verifyOtpCallId = "";
    this.forgotVerifyOtpId=""
    this.sendOtpCallId="";
    this.updateEmailCallId="";
  
   
    this.changePasswordCallId=""
    this.forgotPasswordCallId=""
    this.cancelSubscriptionCallId=""
    this.sendForgotEmailCallId=""
    this.subscriptionCallId=""
    this.userEmailId=""
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",

      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedSetting:'Account Settings',
      mainBoxContent: 'default',
      isModalOpen: false,
      resend: false,
      emailVarified: false,
      showVerifiyEmail: true,
      otpError: false,
      passerror:false,
      otp:'',
      timmer:true,
      verifyloader: false,
      emailExist: '',
      newEmail:'',
      password:'',
      forgotPassword:false,
      showOTPModal: false,
      showThirdModal: false,
      showSubscriptionContent:true,
      profileEmail:"",
      subscriptionData: undefined, 
      userRole:'',
      showPassword: false,
      showConfirmPassword: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     
      if (apiRequestCallId && responseJson) {
        this.sendOtpCallIdResponse(apiRequestCallId, responseJson);
       this.verifyOtpResponse(apiRequestCallId, responseJson)
       this.updateEmailCallIdResponse(apiRequestCallId, responseJson)
     this.forgotEmailVerifyOtpResponse(apiRequestCallId, responseJson)
      this.changePasswordCallIdResponse(apiRequestCallId, responseJson)
       this.sendForgotEmailCallIdResponse(apiRequestCallId, responseJson)
      this.subscriptionCallIdResponse(apiRequestCallId, responseJson)
    this.userEmalIdResponse(apiRequestCallId, responseJson)
    
      this.cancelSubscriptionCallIdResponse(apiRequestCallId,responseJson)
this.forgotPasswordCallIdResponse(apiRequestCallId, responseJson)
  
   }
   else  if(responseJson.error){
toast.error(responseJson.error)
      }
    
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  userEmalIdResponse=(apiRequestCallId:string, responseJson:{data:{attributes:{account_details:{email:string}}}})=>{
    if(apiRequestCallId ===this.userEmailId){
      this.setState({
        profileEmail: responseJson.data?.attributes?.account_details?.email,  
      })
      
  

    }
  }
  sendOtpCallIdResponse=(apiRequestCallId:string, responseJson:{error:string,message:string})=>{

    if (apiRequestCallId === this.sendOtpCallId) {

      if (!responseJson.error) {
       toast.success(responseJson.message)

       this.setState({ isModalOpen: true });
       this.setState({
         showVerifiyEmail: true,
         
       })
     
     } else {
       toast.error(responseJson.error)
     }
 }
  }

  forgotPasswordCallIdResponse=(apiRequestCallId:string, responseJson:{error:string,message:string})=>{
    if (apiRequestCallId===this.forgotPasswordCallId){
      if (!responseJson.error) {
        toast.success(responseJson.message)
        this.setState({ isModalOpen: false });
  
      
      } else {
        toast.error(responseJson.error)
      }
     }
  }
verifyOtpResponse=(apiRequestCallId:string, responseJson:{error:string,message:string})=>{
  if (apiRequestCallId === this.verifyOtpCallId) {
    if (responseJson?.message === "Valid OTP entered") {
      toast.success(responseJson.message)
      this.setState({
        showVerifiyEmail: false,
        otpError: false,
        verifyloader:false
        
      });
    } else if(responseJson?.message === "Invalid OTP entered") {
      toast.error(responseJson?.message)

      this.setState({
        otpError: true, verifyloader: false, otp: ''
      });
    }
  }
}
forgotEmailVerifyOtpResponse=(apiRequestCallId:string, responseJson:{error:string,message:string})=>{
  if (apiRequestCallId === this.forgotVerifyOtpId) {
    if (responseJson?.message === "Valid OTP entered") {
      toast.success(responseJson.message)
      this.setState({
        showOTPModal: false,
        showThirdModal: true,
        otpError: false,
        verifyloader:false
        
      });
    } else if(responseJson?.message === "Invalid OTP entered") {
      toast.error(responseJson?.message)

      this.setState({
        otpError: true, verifyloader: false, otp: ''
      });
    }
  }
}
changePasswordCallIdResponse=(apiRequestCallId:string, responseJson:{error:string,message:string})=>{
  if (apiRequestCallId === this.changePasswordCallId) {
    if (!responseJson?.error) {
      toast.success(responseJson.message)
      this.setState({
        passerror: false,
        
      });
    } else if(responseJson?.error) {
      toast.error(responseJson.error)

    }
  }
}
sendForgotEmailCallIdResponse=(apiRequestCallId:string, responseJson:{error:string,message:string})=>{
  if (apiRequestCallId === this.sendForgotEmailCallId) {

    if (!responseJson.error) {
      toast.success(responseJson.message)

      this.setState({
        
        showVerifiyEmail: false,
        showOTPModal: true,
  
      });
   } else {
     toast.error(responseJson.error)
   }
}
}
updateEmailCallIdResponse=(apiRequestCallId:string, responseJson:{error:string,message:string, errors:string,
  account:{data:{attributes:{email:string}}}
})=>{
  if (apiRequestCallId === this.updateEmailCallId) {
    if (!responseJson.errors && !responseJson.error) {
      toast.success(responseJson.message)
      this.handleModalClose()
      this.setState({profileEmail: responseJson.account.data.attributes.email})
     ;      
    } else if (responseJson?.errors ) {
      toast.error(responseJson.errors)

    }
    else if(responseJson?.error){
      toast.error(responseJson.error)

    }
  }
}

subscriptionCallIdResponse=(apiRequestCallId:string, responseJson:any)=>{
  if(apiRequestCallId ===this.subscriptionCallId){
    if(responseJson.message==="User subscription details fetched successfully"){
      this.setState({
        subscriptionData: responseJson.user_subscription,
        showSubscriptionContent: false 

      })
       }
  

    }
}
cancelSubscriptionCallIdResponse=(apiRequestCallId:string, responseJson:{error:string,message:string})=>{
  if(apiRequestCallId===this.cancelSubscriptionCallId){
    if(responseJson.message==="Your subscription has been cancelled successfully"){
      toast.success(responseJson.message)
      this.subscriptionDetails();
      dispatchEvent(new CustomEvent("UPDATE_ACTIVE_SUB", { detail: { value: false } }))
    }
    else {
      toast.error(responseJson.error)
    }
  }
}
  async componentDidMount() {
    this.subscriptionDetails();
    const userRole = localStorage.getItem('userRole');
    if (userRole) {
    this.setState({ userRole });
  }
    this.userEmailFetch()
  }

  setSetting=(value:string)=>{
    this.setState({selectedSetting:value})
  }
  handleMainBoxContentChange = (content: string) => {
    this.setState({ mainBoxContent: content });
  };

  setShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    })
  };

  setShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  };

  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };
  handleModalOpen2 = (email: string) => {
    this.handleShowVerifyEmail(email, false);

  };
 
  
  handleModalClose = () => {
    this.setState({ 
      isModalOpen: false ,
      showVerifiyEmail: true,
      showOTPModal: false,
      showThirdModal: false,
      otp: '',
      otpError: false,
      timmer: true,
      resend: false,
      verifyloader: false,
    });
  };
  handleCondition = (condition:any, truePart:any, falsePart:any) => {
    return condition ? truePart : falsePart
  }
  handleOperator = (condition: boolean, truePart: JSX.Element, falsePart: JSX.Element): JSX.Element => {
    return condition ? truePart : falsePart;
  };
  handleChangeOTP = (value: string) => {
    this.setState({ otp: value });
  };


 
  handleNextModal=(val:boolean)=>{
    this.setState({showVerifiyEmail:val})
  }
  handleShowOTPModal = (email: string) => {
    this.handleShowForgotOtpModal(email, false);

  };
  
  
  formSubmit = () => {

  };
 

  handleShowPlans = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Customisableusersubscriptions');
    message.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props
    );
this.send(message);
  };
  handleOTPTimmer = (val : boolean) => {
    this.setState({
      timmer: val,
    });
  };
  handleShowVerifyEmail = (val : string, resend: boolean) => {
    if(resend){
      this.setState({resend: resend, otpError: false, otp:''});
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiSendOtp,
      "token": localStorage.getItem("authToken")
    };
  
    const httpBody = {
      "setting":{"email": val}
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleShowForgotOtpModal = (val : string, resend: boolean) => {
    if(resend){
      this.setState({resend: resend, otpError: false, otp:''});
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiSendOtp,
      "token": localStorage.getItem("authToken")
    };
  
    const httpBody = {
      "setting":{"email": val}
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendForgotEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  doEmailVarification = () => {
    this.setState(prevState => ({
      ...prevState, 
      verifyloader: true ,
      
    })
  );
    
    const header = {
      "Content-Type": configJSON.contentTypeApiSendOtp,
      "token": localStorage.getItem("authToken")

    };
  
    const httpBody = {
     "setting":{"otp": Number(this.state.otp)}
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  doforgotEmalVerification = () => {
    this.setState(prevState => ({
      ...prevState, 
      verifyloader: true ,
      
    })
  );
    
    const header = {
      "Content-Type": configJSON.contentTypeApiSendOtp,
      "token": localStorage.getItem("authToken")

    };
  
    const httpBody = {
     "setting":{"otp": Number(this.state.otp)}
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotVerifyOtpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updateEmailPassword = (email : string, password: string) => {
    this.userEmailFetch()
    const header = {
      "Content-Type": configJSON.contentTypeEmailUpdate,
      "token": localStorage.getItem("authToken")

    };
  
    const httpBody = {
     "setting":{"email": email, "current_password": password}
     
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateEmailUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodUpdateEmail
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
 handlePasswordChange  = (current_password : string, new_password: string, confirm_new_password: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiChangePassword,
      "token": localStorage.getItem("authToken")

    };
  
    const httpBody = {
     "setting":{"current_password": current_password, "new_password": new_password,"confirm_new_password": confirm_new_password }
     
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodChangePassword
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleForgotPassChange  = (new_password: string, confirm_new_password: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiChangePassword,
      "token": localStorage.getItem("authToken")

    };
  
    const httpBody = {
     "setting":{ "new_password": new_password,"confirm_new_password": confirm_new_password }
     
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodChangePassword
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  subscriptionDetails  = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiChangePassword,
      "token": localStorage.getItem("authToken")

    };
  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodSubscriptionDetails
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCancelSubcription  = (reason: string, other_reason: string) => {
    this.handleModalClose()

    const header = {
      "Content-Type": configJSON.contentTypeApiChangePassword,
      "token": localStorage.getItem("authToken")

    };
  
    const httpBody = {
     "setting":{ "reason": reason,"other_reason": other_reason }
     
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodChangePassword
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  userEmailFetch  = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiChangePassword,
      "token": localStorage.getItem("authToken")

    };
  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userEmailId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userEmailUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodSubscriptionDetails
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
