import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Post } from "../../../../packages/blocks/comments/src/CommentsController";
import { getLoginToken, isLoggedIn } from "../../../../packages/components/src/Utils.web";
export interface ImageOrVideo {
  id: number;
  url: string;
}

export interface Account {
  account_name: string;
  account_profile: string;
  account_id: string | number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  post: Post
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  disliked: boolean;
  liked: boolean;
  _upvote: number;
  _downvote: number;
  token: string;
  userId: string;
  postId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UpvotedownvoteController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  checkVoteStatusApiCallId: string = "";
  downvotePostApiCallId: string = "";
  upvotePostApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      disliked: false,
      liked: false,
      _downvote: 0,
      _upvote: 0,
      token: "",
      postId: "",
      userId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    await this.getToken();
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.downvotePostApiCallId && (responseJson.content && responseJson.message)) {
        window.dispatchEvent(new CustomEvent("UPVOTE_DOWNVOTE", { detail: responseJson.content }))
      }
      if (apiRequestCallId === this.upvotePostApiCallId && (responseJson.content && responseJson.message)) {
        window.dispatchEvent(new CustomEvent("UPVOTE_DOWNVOTE", { detail: responseJson.content }))
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  btnLikeProps = {
    testID: "likeBtn",
    onPress: () => this.upvotePostHandler(this.state.postId, this.state.userId),
  };

  btnDisLikeProps = {
    testID: "disLikeBtn",
    onPress: () => this.downvotePostHandler(this.state.postId, this.state.userId),
  };

  webBtnLikeProps = {
    "data-testid": "likeBtn",
    onClick: () => this.upvotePostHandler(this.state.postId, this.state.userId),
  };

  webBtnDisLikeProps = {
    "data-testid": "disLikeBtn",
    onClick: () => this.downvotePostHandler(this.state.postId, this.state.userId),
  };

  getToken = async () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async upvotePostHandler(postId: string, userId: string): Promise<void> {
    const token = this.state.token;
    const upvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upvotePostApiCallId = upvotePostMessage.messageId;

    const header = {
      token: token,
    };

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formData = new FormData();
    formData.append("likeable_id", postId);
    formData.append("like_by_id", userId);

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upvoteEndpoint
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
  }

  async downvotePostHandler(postId: string, userId: string): Promise<void> {
    const token = this.state.token;
    const downvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downvotePostApiCallId = downvotePostMessage.messageId;

    const header = {
      token: token,
      "Content-Type": configJSON.requestHeaderContentType,
    };

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formData = new FormData();
    formData.append("dislikeable_id", postId);
    formData.append("dislike_by_id", userId);

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downvoteEndpoint
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
  }

  async checkVoteStatusHandler(postId: string): Promise<void> {
    const token = this.state.token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkVoteStatusApiCallId = requestMessage.messageId;

    const header = {
      token: token,
      "Content-Type": configJSON.requestHeaderContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkVoteStatusEndpoint}?postId=${postId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  upvotePost = (id: number | string, type: string) => {

    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }

    const header = {
      token: getLoginToken(),
    };


    let formData = new FormData();
    formData.append("likeable_id", `${id}`);
    formData.append("likeable_type", type);

    const upvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upvotePostApiCallId = upvotePostMessage.messageId;

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upvoteEndpoint
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
  }

  downvotePost = (id: number | string, type: string) => {

    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }

    const header = {
      token: getLoginToken(),
    };

    let formData = new FormData();
    formData.append("dislikeable_id", `${id}`);
    formData.append("dislikeable_type", type);

    const downvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downvotePostApiCallId = downvotePostMessage.messageId;

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downvoteEndpoint
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
  }

  goToLoginPage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
