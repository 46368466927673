import React from "react";
import { FileUploader } from "react-drag-drop-files";
//@ts-ignore
import uploadIcon from "./upload_grey.svg";
import { Grid, InputLabel, Theme, Typography, makeStyles } from "@material-ui/core";
import RadioSelectComponent from "./RadioSelectComponent";

const fileTypes = ["JPG", "PNG", "GIF", "DOC", "DOCX", "PDF", "JPEG"];
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1px dashed #9C9C9C",
    padding: "20px 8px 20px 8px",
    borderRadius: "8px"
  },
  textone: {
    fontFamily: "Arial",
    fontSize: "14px",
    color: "#9C9C9C",
    fontWeight: "lighter"
  },
  texttwo: {
    fontFamily: "Arial",
    fontSize: "12px",
    color: "#9C9C9C",
    fontWeight: "lighter"
  },
  inputLabel: {
    color: '#1F497D',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '22px',
    paddingBottom: '4px',
  },
  errorText: {
    "color": "#DC2626",
    "fontFamily": "Arial",
    "fontSize": "12px",
    "fontStyle": "normal",
    "fontWeight": "lighter",
    "lineHeight": "18px",
    "display": "flex"
  },
  resumeError: {
    'border': '1px dashed #DC2626',
  },
}))

type Props = {
  selectedId : number | string | undefined,
  error: any,
  name: string,
  value: any,
  title: string,
  step: number,
  onChange: (file: any)=> void,
  resumeList?:{ url: string | File, name: string, size: number, selectId: string | number }[],
  resumeRadioButtonSelected?: (file: any) => void
  handleInputChange: (value: { url: string | File, name: string, size: number, selectId: string | number }) => void,
  handleRemoveFile: (value: string | number) => void;
}

function DragAndDrapFile(props: Props) {

  const classes = useStyles();
  const { onChange, error, name, title, step, value, resumeList, selectedId , handleInputChange, handleRemoveFile} = props;
  return (
    <>
      {
        ((step === 3 && value) || step === 1) && <InputLabel
          className={classes.inputLabel}
        >
          {title}
        </InputLabel>
      }
      {
        step === 1 && <FileUploader  handleChange={onChange} name={name} types={fileTypes}>
          <Grid container className={`${classes.root} ${error ? classes.resumeError : ''}`}>
            <img src={uploadIcon} alt="upload" />
            <Typography className={classes.textone}>Upload or Drag here</Typography>
            <Typography className={classes.texttwo}>Max size per file: 5MB </Typography>
          </Grid>
        </FileUploader>
      }
      {
        !!error && <Typography className={classes.errorText}>
          {error}
        </Typography>
      }
      { (step === 3 || step === 1 ) && resumeList?.map((item, index)=> <RadioSelectComponent handleRemoveFile={handleRemoveFile} handleInputChange={handleInputChange} key={index} value={item} resumeList={resumeList} index={index} selectedId={selectedId}/>)}
    </>
  );
}

export default DragAndDrapFile;