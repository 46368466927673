export const searchicon = require("../assets/searchIcon.svg");
export const locationicon = require("../assets/locationIcon.svg");
export const selectedRighticon = require("../assets/selectedRight.svg");
export const openMenuicon = require("../assets/openMenuIcon.svg");
export const openMenuiconWhite = require("../assets/openMenuWhite.svg");
export const emptyLocation = require("../assets/emptyLocation.svg");
export const chaticon = require("../assets/chaticon.svg");
export const unlikeicon = require("../assets/unlikeicon.svg");
export const bulbicon = require("../assets/bulbicon.svg");
export const cancleicon = require("../assets/cancleicon.svg");
export const bookicon = require("../assets/bookIcon.svg");
export const suitecaseicon = require("../assets/suitecaseicon.svg");
export const rightgreenicon = require("../assets/rightgreenicon.svg");
export const reficon = require("../assets/reficon.svg");
export const saveicon = require("../assets/save.svg");
export const saveselectedicon = require("../assets/saveselected.svg");
export const brifcaseBlueicon = require("../assets/brifcaseBlue.svg");
export const dollaricon = require("../assets/dollar.svg");
export const leftblueicon = require("../assets/leftArrow.svg");
export const successTick = require("../assets/successTick.png");
export const unlikedselected = require("../assets/unlikedselected.svg");
export const locGrey = require("../assets/locGrey.svg");
export const closeIcon = require("../assets/close.svg");

