import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { toast } from "react-toastify";
import { ICountry, Country } from 'country-state-city'
import { debounce } from 'lodash';
import { getLoginToken } from "../../../components/src/Utils.web";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader: boolean;
    progress: number;
    remainingTime: number;
    token: string;
    profileBlob: string | ArrayBuffer | null;
    isProfileUploaded: boolean;
    countries: { value: string; label: string }[];
    companyList: { value: string, label: string }[]
    initialValues: {
        company_name: string,
        company_description: string,
        company_website: string,
        company_indusry: string,
        company_size: string,
        company_headquarters: string
    },
    cities: { value: string; label: string; }[]
    isDivVisible: boolean,
    open: boolean,
    comapnyNameList: {
        claimed: boolean,
        domain: string,
        icon: string,
        brandId: string
        name: string
    }[],
    comapnyIndustryList: {
        id: number,
        name: string
    }[],
    industryOpen: boolean,
    selectedCompanyName: string,
    selectedCompanyIcon: string,
    selectedIndustryName: string,
    isOtherCountry: boolean,
    companySizeOpen: boolean,
    comapnySizeList: {
        name: string
    }[],
    selectedCompanySizeName: string,
    description: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

type FormValues = {
    company_name: string,
    company_description: string,
    company_website: string,
    company_indusry: string,
    company_size: string,
    company_headquarters: string

}
export default class UserProfileEditController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    updateUserDetailsApiId: Message | string = ""
    getUserProfileDataApiId: Message | string = ""
    companyListApiId: Message | string = ""
    getCompanyDataApiId: Message | string = ""
    updateCompanyDetailId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.SessionResponseToken),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.SessionSaveMessage)
        ];

        this.state = {
            progress: 0,
            loader: false,
            token: '',
            remainingTime: 0,
            initialValues: {
                company_name: "",
                company_description: "",
                company_website: "",
                company_indusry: "",
                company_size: "",
                company_headquarters: ""
            },
            cities: [],
            countries: [],
            companyList: [{ value: '', label: '' }],
            profileBlob: "",
            isProfileUploaded: false,
            isDivVisible: false,
            open: false,
            comapnyNameList: [],
            comapnyIndustryList: configJSON.companyIndustryList,
            industryOpen: false,
            selectedCompanyName: "",
            selectedCompanyIcon: "",
            selectedIndustryName: "",
            isOtherCountry: false,
            companySizeOpen: false,
            comapnySizeList: [
                {
                    name: "2-10 Employees"
                },
                {
                    name: "11-50 Employees"
                },
                {
                    name: "51-200 Employees"
                },
                {
                    name: "201-1000 Employees"
                },
                {
                    name: "1000-5000 Employees"
                },
                {
                    name: "5001+ Employees"
                },
            ],
            selectedCompanySizeName: "",
            description: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        this.handleGetUserData(from, message);
        this.handleUpdateCompnayResponse(from, message);
        this.handleGetCompanyData(from, message);
    }

    handleGetUserData = (from: string, message: Message) => {
        if (
            this.companyListApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!apiResponse.errors) {
                this.setState({ comapnyNameList: apiResponse.company_listing })
                this.handleOpen();
            }
        }
    }

    handleCompanyClose = () => {
        this.setState({companySizeOpen : false})
    }
    handleindustryClose = () => {
        this.setState({industryOpen: false})
    }

    handleUpdateCompnayResponse = (from: string, message: Message) => {
        if (
            this.updateCompanyDetailId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!apiResponse.errors) {
                this.handleNavigateToRecruiter();
                if(this.state.selectedCompanyName) {
                    setStorageData("setProfile", "true");
                    setStorageData("companyName", this.state.selectedCompanyName); 
                }
            }
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            }
        }
    }

    handleGetCompanyData = (from: string, message: Message) => {
        if (
            this.getCompanyDataApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!apiResponse.errors) {
                this.setState({
                    selectedCompanyName: apiResponse.data.attributes.company_name,
                    initialValues: {
                        company_name: this.handleCondition(apiResponse.data.attributes.company_name, apiResponse.data.attributes.company_name , ""),
                        company_indusry: this.handleCondition(apiResponse.data.attributes.company_indusry, apiResponse.data.attributes.company_indusry , ""),
                        company_size: this.handleCondition(apiResponse.data.attributes.company_size, apiResponse.data.attributes.company_size , ""),
                        company_description: this.handleCondition(apiResponse.data.attributes.company_description, apiResponse.data.attributes.company_description , ""),
                        company_website: this.handleCondition(apiResponse.data.attributes.company_website, apiResponse.data.attributes.company_website , ""),
                        company_headquarters: this.handleCondition(apiResponse.data.attributes.company_headquarters, apiResponse.data.attributes.company_headquarters , "")
                    }
                })
            }
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            }
        }
    }

    handleCondition = (condition: any, truePart: any, falsePart: any) => {
        return condition ? truePart : falsePart
    }

    handleResponse = (response: { error?: string, errors?: [{ token: string }] | string }) => {
        if (response.error) {
            toast.error(response.error);
        } else if (response.errors) {
                Object.values(response.errors[0]).forEach((item: string) => toast.error(item))
                if (Object.keys(response.errors[0]).includes("token")) {
                    this.handleNavigateToSignin()
                }
        }
    }

    handleNavigateToSignin = () => {
        const toNavigate: Message = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }

    handleNavigateToRecruiter = () => {
        const toNavigate: Message = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "RecruiterProfile");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }

    async componentDidMount(): Promise<void> {
        const containerDiv = document.getElementById('pageContainer')
        if (containerDiv) { containerDiv.scrollIntoView()}
        this.getCompanydata();
        this.setState({
            countries: Country.getAllCountries().map((country: ICountry) => ({
                value: country.name,
                label: country.name
            }))
        })
        document.querySelector("#company_name")?.addEventListener('keypress', (event:any) => {
            if(event.keyCode === 13) {
                event.preventDefault();
            }
        })
    }

    handleCancel = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "RecruiterProfile");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ description: e.target.value });
    };

    handleProfileUpdate() {
        this.setState({
            isProfileUploaded: false
        });
    }

    handleCountryClick = (value: { value: string; label: string; }[]) => {
        this.setState({
            cities: value
        })
    }

    handleOpen = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleSelectCompany = (name: string, icon: string) => {
        this.setState({
            selectedCompanyName: name,
            selectedCompanyIcon: icon,
            isOtherCountry: name.toLowerCase() === 'other',
            open: false
        });
    };
    handleSelectedIndustryName = (name: string) => {
        this.setState({
            selectedIndustryName: name,
            industryOpen: false

        })

    }
    handleIndustry = () => {
        this.setState({ industryOpen: !this.state.industryOpen })
    }
    handleCompany = () => {
        this.setState({ companySizeOpen: !this.state.companySizeOpen })
    }
    handleSelectedCompany = (name: string) => {
        this.setState({
            selectedCompanySizeName: name,
            companySizeOpen: false

        })

    }

    handleInputDebounce = debounce(async (value: string) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem("authToken")
        };
        let userData = { search_name: this.handleCondition(value , value, '' )};
        const updateUserDetails1 = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.companyListApiId = updateUserDetails1.messageId;
        updateUserDetails1.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CompanyListApiEndPoint}`);
        updateUserDetails1.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers));
        updateUserDetails1.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData));
        updateUserDetails1.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod);
        runEngine.sendMessage(updateUserDetails1.id, updateUserDetails1);
    }, 200)


    companyListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.handleInputDebounce(value);
        }
    };

    handleFormSubmit = (data: FormValues) => {
        const formData = {
            "profile": {
                "api_name": "company_update",
                "company_name": this.state.selectedCompanyName,
                "company_icon": this.state.selectedCompanyIcon,
                "company_description": data.company_description,
                "company_website": data.company_website,
                "company_indusry": data.company_indusry,
                "company_size": data.company_size,
                "company_headquarters": data.company_headquarters,
                "company_founded_year": 2020
            }
        }
        const updateCompnayDetail = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": getLoginToken()
        };
        this.updateCompanyDetailId = updateCompnayDetail.messageId;
        updateCompnayDetail.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.updateCompanyDetail}`
        );
        updateCompnayDetail.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        updateCompnayDetail.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(formData));
        updateCompnayDetail.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiUpdateUserType
        );
        runEngine.sendMessage(updateCompnayDetail.id, updateCompnayDetail);
    }
    getCompanydata = () => {
        const updateUserDetails2 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": getLoginToken()
        };
        this.getCompanyDataApiId = updateUserDetails2.messageId;
        updateUserDetails2.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.userCompanyApi}`
        );
        updateUserDetails2.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        updateUserDetails2.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );
        runEngine.sendMessage(updateUserDetails2.id, updateUserDetails2);
    }

    // Customizable Area End
}
