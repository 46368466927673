Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.commentsContentType = "application/json";
exports.commentsApiMethodType = "GET";
exports.commentPOSTAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Comments";
exports.labelBodyText = "Comments Body";
exports.hintCountryCode = "Select Country";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.commentEndPoint = "/comments/comments"
exports.likeCommentEndPoint = "/comments/like_comment"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.btnExampleTitle = "CLICK ME";
exports.httpDeleteMethod = "DELETE";
exports.httpGetMethod = "GET";
exports.getAllPosts = "/bx_block_posts/posts";
exports.getAllSavedPosts = "bx_block_profile/wishlists/get_saved_items";
exports.upvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/upvote";
exports.downvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/downvote";
exports.votePostMethod = "POST";
exports.deletePostApi = "bx_block_posts/posts";
exports.deleteCommentApiEndpoint="/bx_block_comments/comments";
exports.httpPatchMethod = "PATCH";
exports.savePostEndpoint = "bx_block_communityforum/posts"
exports.sendInvitationEndpoint = "bx_block_friends/create_connection_request";
exports.followUserApiEndPoint = "bx_block_followers/follows";
exports.removeInvitationEndpoint = "bx_block_friends";

exports.createCommentEndpoint = 'bx_block_comments/comments'
exports.baseURL = require('../../../framework/src/config')
exports.getSinglePost = "bx_block_posts/posts"

exports.resonEndPointApi ="bx_block_contentflag/contents/get_flag_reasons"
exports.addReasonsApiEndPoint ="bx_block_contentflag/contents"

// Customizable Area End
