Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.settingTitle ="Settings";
exports.accountSettingsTitle='Account Settings';
exports.emailAddress="Email Address";
exports.emailAddedTitle="Emails you've added";
exports.personalEmail="Personal Email";
exports.dummyEmail="recruiter@email.com";
exports.updateEmailTitle="Update Email Address";
exports.checkEmail="Check your email";
exports.invalidOtp="Invalid OTP entered";
exports.left="left";
exports.newEmail="New Email";
exports.changePassTitle="Change Password";
exports.btnExampleTitle = "CLICK ME";
exports.submit="Submit";
exports.back="Back";
exports.next="Next"
exports.forgotPass="Forgot Password?"
exports.passError="New Password and Confirm New Password don't match"
exports.currentPass="Current Password"
exports.newPass="New Password"
exports.confirmNewPass="Confirm New Password";
exports.didntGetOtp="Didn't receive OTP?";
exports.subscription="Subscription";
exports.subscriptionDescription="You don’t have any subscribed plan.";
exports.subscriptionSubDescription="To enjoy extra benefits, please view our plans and subscribe them";
exports.explorePlans="Explore All Plans";
exports.cancel="Cancel";
exports.upgradePlan="Upgrade Plan";
exports.sendOtpUrl = "bx_block_settings/settings/send_email_otp";
exports.verifyOtpUrl = "bx_block_settings/settings/verify_email_otp";
exports.updateEmailUrl = "bx_block_settings/settings/update_email_address";
exports.changePasswordUrl = "bx_block_settings/settings/change_password";
exports.subscriptionUrl= "bx_block_settings/settings/get_user_subscription";
exports.cancelSubscriptionUrl="bx_block_settings/settings/cancel_user_subscription"
exports.userEmailUrl="bx_block_profile/profiles"
exports.contentTypeApiChangeOtp = "application/json";
exports.contentTypeApiChangePassword = "application/json";

exports.contentTypeApiSendOtp = "application/json";
exports.contentTypeEmailUpdate = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts";
  exports.apiMethodTypeAddDetail = "POST";
  exports.apiMethodUpdateEmail = "PUT";
  exports.apiMethodChangePassword = "PUT";
  exports.apiMethodSubscriptionDetails="GET"


// Customizable Area End