
import React, { CSSProperties } from "react";
// Customizable Area Start
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Button, Typography, TextField, Box, InputAdornment, styled, TextareaAutosize, ClickAwayListener } from "@material-ui/core";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelectField from "./components/CustomSelectField";
// Customizable Area End

import CompanyDetailsEditController, {
    Props,
} from "./CompanyDetailsEditController.web";
import CustomTextField from "./components/CustomTextField";
import { arrowDown, bottomLeft, topRight, } from "./assets";
import { Autocomplete } from "@material-ui/lab";
import { Country, ICountry } from "country-state-city";
import AutoCompleteInputText from "./components/AutoCompleteInputText";

export class CompanyDetailsEdit extends CompanyDetailsEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const validationSchema = Yup.object().shape({
            company_name: Yup.string().required('Company name is a required field'),
            company_description: Yup.string().required('Description is a required field'),
            company_website: Yup.string().required('Website is a required field'),
            company_indusry: Yup.string().required('Industry is required field'),
            company_size: Yup.string().required('Company Size is required field'),
            company_headquarters: Yup.string().required('Headquarter is a required field'),
        })
        return (
            // Customizable Area Start
            <>
                <style>
                    {
                    `
                    .company-details-container-56 .MuiSelect-select.MuiSelect-select {
                        height: 42px;
                    }
                    `
                    }
                </style>
                <Grid container style={webStyle.page} id="pageContainer" >
                    <Formik
                        id="formik"
                        initialValues={this.state.initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={this.handleFormSubmit}
                    >
                        {({ values, errors, setFieldValue, touched, handleBlur, handleChange }) => (
                            <Form style={{ width: "inherit" }}>
                                <Grid style={webStyle.form as CSSProperties} className="company-details-container-56">
                                    <img src={topRight} alt="icons" style={webStyle.topRight as CSSProperties} />
                                    <img src={bottomLeft} alt="icons" style={webStyle.bottomLeft as CSSProperties} />
                                    <Grid container style={{ paddingLeft: "12px", paddingBottom: "30px" }}>
                                        <Grid item sm={12}>
                                            <Typography style={webStyle.cardTitle}>Update/Edit Company Details</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={webStyle.formfieldCont as CSSProperties} spacing={3} >
                                        <Grid item sm={12} md={6} xs={12} lg={6} style={{ position: "relative" }}>
                                            <Typography style={{
                                                color: '#1F497D',
                                                fontFamily: 'Arial',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                padding: "2px"
                                            }}>
                                                Company Name
                                            </Typography>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                            <InputFieldStyle
                                                style={webStyle.inputText}
                                                placeholder="Select Company"
                                                name="company_name"
                                                id="company_name"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                data-test-id="companyName"
                                                onChange={(e) => {
                                                    setFieldValue('company_name', e.target.value)
                                                    this.setState({ selectedCompanyName: e.target.value })
                                                    e.target.value && this.handleInputDebounce(e.target.value)
                                                }}
                                                value={this.handleCondition(this.state.selectedCompanyName, this.state.selectedCompanyName,"")}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img
                                                                onClick={this.handleOpen}
                                                                src={arrowDown}
                                                                alt="dropdown"
                                                                style={{
                                                                    padding: "0",
                                                                    width: "1rem",
                                                                    left: 4,
                                                                    margin: "0",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            </ClickAwayListener>
                                            {this.handleCondition(this.state.open, <Grid style={{
                                                    marginTop: "18px", borderRadius: "8px", position: "absolute",
                                                    border: "1px solid #CBD5E1", background: "#fff", zIndex: 100,
                                                    width: "84%", padding: "14px 20px 9px 16px", display: "flex", flexDirection: "column",
                                                    gap: "20px", alignItems: "flex-start"
                                                }}>
                                                    {this.state.comapnyNameList?.map((item, index) => (
                                                        <Box
                                                        data-test-id={`companyName${index}`}
                                                            style={{
                                                                color: "#17365D", fontSize: "14px", fontWeight: 400,
                                                                fontFamily: "Arial", cursor: "pointer"
                                                            }}
                                                            onClick={() => this.handleSelectCompany(item.name, item.icon)}
                                                        >{item.name}</Box>
                                                    ))}
                                                </Grid>, "")}
                                            {
                                                errors.company_name && touched.company_name && <Typography style={webStyle.errorText}>
                                                    {errors.company_name}
                                                </Typography>
                                            }
                                        </Grid>
                                        {this.handleCondition(this.state.isOtherCountry, <Grid item sm={12} md={6} xs={12} lg={6}>
                                                <Field
                                                    name="full_phone_number"
                                                    component={CustomTextField}
                                                    type="string"
                                                    variant="outlined"
                                                    title="Please specify the company name here"
                                                    placeHolder="Company abc"
                                                    rootStyle={{ height: "10px !important" }}
                                                />
                                            </Grid>, "")}
                                    </Grid>
                                    <Grid style={{ padding: "11px 10px 2px 10px" }} >
                                        <Typography style={{
                                            color: '#1F497D',
                                            fontFamily: 'Arial',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: 700,
                                            padding: "2px"
                                        }}>Description</Typography>
                                        <TextareaAutosizeStyle
                                            minRows={3}
                                            className="textArea"
                                            name="company_description"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_description}
                                            placeholder="Enter description"
                                            style={webStyle.descriptionBox} />
                                        {
                                            errors.company_description && touched.company_description && <Typography style={webStyle.errorText}>
                                                {errors.company_description}
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid container style={webStyle.formfieldCont as CSSProperties} spacing={3}>
                                        <Grid item sm={12} md={6} xs={12} lg={6} style={{ position: "relative" }}>
                                            <Typography style={{
                                                color: '#1F497D',
                                                fontFamily: 'Arial',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                padding: "2px"
                                            }}>
                                                Website
                                            </Typography>
                                            <InputFieldStyle
                                                style={webStyle.inputText}
                                                placeholder="https://company.tech/"
                                                name="company_website"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.company_website}
                                                fullWidth
                                            />
                                            {
                                                errors.company_website && touched.company_website && <Typography style={webStyle.errorText}>
                                                    {errors.company_website}
                                                </Typography>
                                            }
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6} style={{ position: "relative" }}>
                                            <Field
                                                name="company_indusry"
                                                component={CustomSelectField}
                                                type="string"
                                                variant="outlined"
                                                title="Industry"
                                                placeHolder="Industry Name"
                                                data-test-id="company0"
                                                options={this.state.comapnyIndustryList.map(item => ({value: item.name, label: item.name}))}
                                            />
                                        </Grid >
                                        <Grid item sm={12} md={6} xs={12} lg={6} style={{ position: "relative" }}>
                                             <Field
                                                name="company_size"
                                                component={CustomSelectField}
                                                type="string"
                                                variant="outlined"
                                                title="Company Size"
                                                placeHolder="51-100 employees"
                                                data-test-id="companySize0"
                                                options={this.state.comapnySizeList.map(item => ({value: item.name, label: item.name}))}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Autocomplete
                                                data-test-id="countryAutoComplete"
                                                id="combo-box-demo"
                                                options={Country.getAllCountries().map((country: ICountry) => ({
                                                    value: country.name,
                                                    label: country.name,
                                                    cId: country.isoCode
                                                }))}
                                                value={{ value: values.company_headquarters, label: values.company_headquarters, cId: "0" }}
                                                getOptionLabel={(option) => option.label || ''}
                                                onChange={(event, value) => {
                                                    setFieldValue("company_headquarters", (event.target as HTMLElement).textContent, true);
                                                }}
                                                onBlur={handleBlur}
                                                renderInput={(params) => <AutoCompleteInputText errors={errors}
                                                    params={params}
                                                    touched={touched.company_headquarters}
                                                    title="Headquarter" name="company_headquarters" placeHolder="Country"
                                                />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={webStyle.btncontainerMain}>
                                        <Grid item sm={12} style={webStyle.btnContainer}>
                                            {localStorage.getItem("setProfile") === 'true' && <Button style={webStyle.noBtn as CSSProperties} onClick={this.handleNavigateToRecruiter}>Cancel</Button>}
                                            <Button style={webStyle.yesBtn as CSSProperties} type="submit">Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <ToastContainer />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Star
export default CompanyDetailsEdit

const webStyle = {
    form: {
        width: "auto",
        background: "#FFFFFF",
        padding: "24px 40px 10px 40px",
        margin: "0px",
        position: "relative",
        borderRadius: "8px 8px 32px 8px",
    },
    page: {
        padding: "50px 100px",
        maxWidth: "1280px",
        background: "#F3F4F8",
    },
    noBtn: {
        fontFamily: "Arial",
        color: "#17365D",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "22px",
        fontWeight: "bold",
        "&:hover": {
            background: "white"
        },
        marginLeft: "20px",
        border: "1px solid  #1F497D",
        "borderRadius": "8px",
        "width": "120px",
        "background": "#FFF",
        "padding": "10px 16px",
        "textTransform": "none",
        "height": "56px",
    },
    yesBtn: {
        "color": "#FFFFFF",
        "border": "1px solid #C7D9F0",
        "fontSize": "16px",
        "fontWeight": "bold",
        "fontStyle": "normal",
        "fontFamily": "Arial",
        "marginLeft": "20px",
        "lineHeight": "22px",
        "&:hover": {
            background: "#C7D9F0",
        },
        "background": "#6D97C1",
        "width": "120px",
        "borderRadius": "8px",
        "display": "flex",
        "padding": "16px",
        "height": "56px",
        "justifyContent": "center",
        "alignItems": "center",
        "textTransform": "none",
        "gap": "8px",
    },
    btncontainerMain: { margin: "0px", width: "auto", paddingTop: "22px" },
    btnContainer: {
        justifyContent: "flex-end",
        display: "flex",
        marginBottom: "5px",
        paddingTop: "25px !important",
        alignItems: "center",
    },
    btnContainer1: {
        justifyContent: "flex-end",
        display: "flex",
        paddingTop: "170px !important",
        marginBottom: "5px",
        alignItems: "center",
    },
    cardTitle: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontStyle": "normal",
        "fontSize": "14px",
        "lineHeight": "22px",
        // "marginBottom": "32px",
        "fontWeight": "bold",
    },
    uploadTxt: {
        "width": "121px",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "flex-end",
        "borderRadius": "4px",
        "gap": "6px",
        "color": "#17365D",
        "background": "#F4F4F4",
        "fontSize": "12px",
        "fontFamily": "Arial",
        "fontWeight": "lighter",
        "fontStyle": "normal",
        "whiteSpace": "nowrap",
        "marginLeft": "13px",
        "lineHeight": "18px",
    },
    errorText: {
        "fontFamily": "Arial",
        "color": "#DC2626",
        "fontStyle": "normal",
        "fontSize": "12px",
        "lineHeight": "18px",
        "fontWeight": "lighter",
        "marginTop": "10px",
        "display": "flex",
    },
    bottomLeft: {
        bottom: "0px",
        left: "0px",
        position: "absolute",
    },
    topRight: {
        top: "0px",
        right: "0px",
        position: "absolute",
    },
    formfieldCont: {
        width: "auto",
        margin: "0px",
    },
    descriptionBox: {
        minHeight: '50px',
        width: '100%',
        borderRadius: '8px',
        padding: '10px',
        fontFamily: 'Arial',
        border: '1px solid #C7D9F0',
        fontSize: '14px',
        color: "#94A3B8",
        fontWeight: 400,
        fontStyle: 'normal',
        resize: "none" as "none",
        lineHeight: '22px',
        cursor: "pointer"
    },
    inputText: {
        background: "#FFF",
        borderRadius: "8px",
        height: "40%",
        padding: "0",
        color: "#6D97C1",
        cursor: "pointer"
    },
}
const InputFieldStyle = styled(TextField)({
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9C9C9C",
    },
    "& .MuiOutlinedInput-input": {
        border: "0px solid #9C9C9C",
        borderRadius: "9px",
        color: "#6D97C1",
        background: "#FFF",
        "fontSize": "14px",
        "fontFamily": "Arial",
        cursor: "pointer",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "fontStyle": "normal",
        "&:hover": {
            border: "0px solid #6D97C1",
        },
        "&:focus": {
            border: "0px solid #1F497D",
        },
        "&::placeholder": {
            "color": "#64748B",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px"
        },
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: '0px'
    },
    "& .MuiOutlinedInput-root": {
        height: "46px",
        borderRadius: "9px",
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9C9C9C",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9C9C9C",
        },
    },
})

const TextareaAutosizeStyle = styled(TextareaAutosize)({
    color: "#94A3B8",
    "&::placeholder": {
        color: "#94A3B8"
    }
})

// Customizable Area End
