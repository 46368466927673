import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ClickAwayListener, Grow, Popper, MenuItem, MenuList, Button, Typography } from "@material-ui/core";
import { openMenuicon, selectedRighticon } from "../assets";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      display: "flex",
      justifyContent: "space-between",
      fontWeight: 400,
      alignItems: "center",
      borderRadius: "30px",
      background: "#C7D9F0",
      padding: "10px 16px",
      '&:hover': {
        backgroundColor: '#C7D9F0',
      },
      "textTransform": "none",
      "color": "rgba(31, 73, 125, 1) ",
      "textAlign": "right",
      "fontFamily": "Arial",
      "fontSize": "16px",
      "fontStyle": "normal",
      "lineHeight": "20px",
    },
    popover: {
      "borderRadius": "16px",
      "border": "2px solid #CBD5E1",
      "background": "#FFF",
      "padding": "0px 5px",
    },
    menuitem: {
      "padding": "10px"
    },
    opttitle: {
      "paddingRight": "60px",
      "fontWeight": "initial",
      "lineHeight": "18px",
      "color": "#17365D",
      "fontFamily": "Arial",
      "fontSize": "12px",
      "fontStyle": "normal",
    },
    opttitleSelected: {
      "paddingRight": "60px",
      "fontStyle": "normal",
      "fontWeight": "bold",
      "lineHeight": "18px",
      "color": "#17365D",
      "fontFamily": "Arial",
      "fontSize": "12px",
     
    }
  })
);

type Option = {
  id: number | string;
  title: string;
};

type Props = {
  fieldName: string;
  options: Array<Option>;
  handleMenuItemClick?: (index: number, obj: Option)=>void;
  value: string[] | string;
};

export default function CustomSelect(props: Props) {
  const { fieldName, options, handleMenuItemClick, value } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div style={{ marginRight: "10px" }}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.btn}

      >
        {fieldName} <img src={openMenuicon} alt="open" style={{ paddingLeft: "10px" }} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ marginTop: "10px", background: "white", zIndex: 1000000 }}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "top right",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
                className={classes.popover}
              >
                {
                  options.map((opt: Option, index: number) => <MenuItem className={classes.menuitem} onClick={() => handleMenuItemClick && handleMenuItemClick(index, opt)}>
                    <Typography className={value.includes(opt.title) ? classes.opttitleSelected : classes.opttitle}>{opt.title}</Typography>
                    {value.includes(opt.title) && <img src={selectedRighticon} alt="selected" />}
                  </MenuItem>)
                }
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
