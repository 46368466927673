import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import BootstrapTooltip from './BootstrapTooltip';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "display": "flex",
            "height": "48px",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#C7D9F0",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#C7D9F0',
            },
        },
        title: {
            "color": "#17365D",
            "fontFamily": "Arial",
            "fontSize": "16px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "24px"
        },
        iconOnly: {
            "display": "flex",
            "padding": "3px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#C7D9F0",
            "minWidth": "0px !important",
            '&:hover': {
                backgroundColor: '#C7D9F0',
            },
        },
        selectedIconOnly: {
            "display": "flex",
            "padding": "3px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#1F497D",
            "minWidth": "0px !important",
            '&:hover': {
                backgroundColor: '#1F497D',
            },
        }
    }),
);
type Props = {
    iconORName: string,
    onlyIcon: boolean,
    showTooltip?: boolean,
    tooltipTitle?: string,
    selected?: boolean,
    handleClick?: () => void,
}
export default function CustomButton(props: Props) {
    const classes = useStyles();
    const { iconORName, onlyIcon, showTooltip, tooltipTitle = "", selected = false, handleClick } = props;

    let buttonClassName = classes.root;
    if (onlyIcon) {
        buttonClassName = selected ? classes.selectedIconOnly : classes.iconOnly;
    }

    const buttonContent = onlyIcon
        ? <img src={iconORName} alt={iconORName} className={classes.title} />
        : <Typography className={classes.title}>{iconORName}</Typography>;

    const buttonElement = (
        <Button onClick={handleClick} variant="contained" disableElevation className={buttonClassName}>
            {buttonContent}
        </Button>
    );

    return showTooltip ? <BootstrapTooltip title={tooltipTitle} placement="bottom">{buttonElement}</BootstrapTooltip> : buttonElement;
}
