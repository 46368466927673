import { Box, Button, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { showAll, topRight } from "../../src/assets";
import { Post, UserDetails } from "../RecruiterProfileController.web";
import CustomPost from "./CustomPost.web";

interface Activity {
    posts: [],
    comments: [],
    reactions: [],
}

interface AllActivityJobApplicantProps {
    handleUpdateActivityType: (status: string) => void;
    activityType: string;
    upvotePost: (postId: number | string, type: string, activityType: string) => void;
    activity: Activity;
    toggleComments: (post: Post) => void;
    downvotePost: (postId: number | string, type: string, activityType: string) => void;
    createComment: () => void;
    postShowID: number | string;
    downvotePostComment: (postId: number | string, type: string) => void;
    upvotePostComment: (postId: number | string, type: string) => void;
    handleFeedModalOpen: (post?: Post) => void;
    userDetails: UserDetails;
    handleShowAllPosts: () => void;
    handleSavePostApi: (postId: number | string, isSaved?: boolean) => void;
    openPostDeleteDialog: (postId: number | string) => void;
    getInitials: (status?: string | undefined) => string;
    remainingTimeComment: number;
    progressComment: number;
    updateComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string) => void;
    handleDeleteComment: (id: string | number) => void;
    loaderComment: boolean;
    expandedPosts?: string[],
    toggleReadMore?: (postId: string) => void,  
    showReadMore: boolean,
    handleUserNavigation: (accountId: number | string) => void
}

export default class AllActivityJobApplicant extends Component<AllActivityJobApplicantProps> {
    constructor(props: AllActivityJobApplicantProps) {
        super(props);
    }
    render() {
        return (
            <Box style={{ ...styles.items, position: 'relative' }} className="allActivityInnerBox">
                <img src={topRight} alt="icons" style={styles.topRight} />
                <Typography style={styles.blockHeading5}>All Activity</Typography>
                <Box>
                    <Button data-test-id="postsButton"
                        style={{
                            ...styles.activityButtonJob, textTransform: 'none', marginLeft: '0',
                            ...(this.props.activityType === "posts" ? {} : styles.inactiveButtonJob)
                        }} onClick={() => this.props.handleUpdateActivityType("posts")}>Posts</Button>
                    <Button data-test-id="commentsButton" style={{
                            ...styles.activityButtonJob,
                            margin: '10px 8px',
                            textTransform: 'none',
                            ...(this.props.activityType === "comments" ? {} : styles.inactiveButtonJob)
                        }} onClick={() => this.props.handleUpdateActivityType("comments")}>Comments</Button>
                    <Button data-test-id="reactionsButton" style={{
                            ...styles.activityButtonJob,
                            textTransform: 'none',
                            ...(this.props.activityType === "reactions" ? {} : styles.inactiveButtonJob)
                        }} onClick={() => this.props.handleUpdateActivityType("reactions")}>Reactions</Button>
                </Box>
                {(this.props.activityType === 'posts' || this.props.activityType === 'comments' || this.props.activityType === 'reactions') 
                && (this.props.activity[this.props.activityType].length > 0) ?
                        <><CustomPost
                                data-test-id="activityList"
                                posts={this.props.activity[this.props.activityType].slice(0, 2)}
                                upvotePost={this.props.upvotePost}
                                downvotePost={this.props.downvotePost}
                                activityType={this.props.activityType}
                                toggleComments={this.props.toggleComments}
                                postShowID={this.props.postShowID}
                                createComment={this.props.createComment}
                                upvotePostComment={this.props.upvotePostComment}
                                downvotePostComment={this.props.downvotePostComment}
                                openConfirmDialog={this.props.openPostDeleteDialog}
                                userDetails={this.props.userDetails}
                                openEditFeedModal={this.props.handleFeedModalOpen}
                                handleSavePost={this.props.handleSavePostApi}
                                getInitials={this.props.getInitials}
                                progressComment={this.props.progressComment}
                                remainingTimeComment={this.props.remainingTimeComment}
                                loaderComment={this.props.loaderComment}
                                handleDeleteComment={this.props.handleDeleteComment}
                                updateComment={this.props.updateComment}
                                showReadMore={true}
                                expandedPosts={this.props.expandedPosts}
                                toggleReadMore={this.props.toggleReadMore}
                                handleNameClick={this.props.handleUserNavigation}
                            />
                            <Box style={{ display: 'flex', justifyContent: 'end', paddingTop: '10px' }}>
                                <Box
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', }}
                                    onClick={this.props.handleShowAllPosts}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Arial',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            color: '#1F497D',
                                            paddingRight: '10px'
                                        }}
                                    > Show all {this.props.activityType}</Typography>
                                    <img src={showAll} />
                                </Box>
                            </Box>
                        </>
                        : null
                }
            </Box>
        );
    }
}

const styles = {
    items: {
      background: "#ffffff",
      borderRadius: '20px',
      padding: '20px',
      minHeight: '200px'
    },
    blockHeading5: {
      fontFamily: 'Arial',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#17365D'
    },
    topRight: {
      position: "absolute" as "absolute",
      top: "0px",
      right: "0px"
    },
    activityButtonJob: {
      height: "24px",
      fontSize: '16px',
      fontWeight: '700',
      fontFamily: 'Arial',
      color: '#17365D',
      borderRadius: '20px',
      padding: '6px 10px 6px 10px',
      backgroundColor: '#C7D9F0',
      margin: '10px'
    },
    inactiveButtonJob: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #6D97C1',
      fontSize: '14px',
      fontWeight: '400',
      color: "#9C9C9C"
    },
  }