import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldProps } from 'formik';
import { Chip, Typography } from '@material-ui/core';
//@ts-ignore
import cancleIcon from "./cancleiconchip.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "8px",
            "& .MuiOutlinedInput-input": {
                "padding": "14px",
                borderRadius: "8px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#6D97C1",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "&:hover": {
                    border: "0px solid #6D97C1",
                },
                "&:focus": {
                    border: "0px solid #1F497D",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "0px"
            },
        },
        errorRoot: {
            borderRadius: "8px",
            "& .MuiOutlinedInput-input": {
                "padding": "14px",
                borderRadius: "8px",
                border: "0px solid #DC2626",
                background: "#FFF",
                color: "#6D97C1",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "&:hover": {
                    border: "0px solid #6D97C1",
                },
                "&:focus": {
                    border: "0px solid #DC2626",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },

            },
            "& .MuiOutlinedInput-root": {
                borderColor: "#DC2626",
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DC2626",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DC2626",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#DC2626",
                },
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "0px"
            },
        },
        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginBottom": "32px",
            "marginTop": "6px",
            "marginleft": "0px"
        },
        tagContainer: {
            overflowX: "scroll",
            overflowY: "hidden",
            display: "flex",
            padding: "10px",
            "&::-webkit-scrollbar": {
                height: "3px !important",
                width: "3px !important",
                display: "block !important"
            },
            "&::-webkit-scrollbar-track": {
                background: "#F4F4F4",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "rgb(23, 54, 93)",
                borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
                background: "rgb(23, 54, 93)",
            }
        },
        removeTag: {
            justifyContent: 'center',
            background: "#EEECE1",
            borderRadius: "4px",
            display: "flex",
            height: "24px",
            padding: "6px 8px",
            alignItems: "center"
        }
    })
);

type Props = FieldProps & {
    placeHolder: string;
    title: string;
    multiline?: boolean;
};

const CustomTextFieldMultipleInput: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    title,
    multiline = false
}) => {
    const classes = useStyles();
    const [newTag, setNewTag] = React.useState('');
    const lastItemRef = React.useRef(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewTag(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const key = e.key;

        if ((key === 'Enter' || key === ',') && newTag.trim() !== '') {
            let trimmedTag = newTag.trim();
            if (!field.value.includes(trimmedTag)) {
                form.setFieldValue(field.name, [...field.value, trimmedTag]);
                setNewTag('');
                setTimeout(() => {
                    if (lastItemRef.current) {
                        (lastItemRef.current as unknown as HTMLElement).scrollIntoView({ behavior: 'smooth', inline: "end" });
                    }
                }, 0);
            }
        }
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleRemoveTag = (e: React.MouseEvent<HTMLElement>) => {
        let target = e.target as HTMLElement;
        let tag = target.parentNode?.textContent?.trim();
        if (tag) {
            let index = field.value.indexOf(tag);
            if (index !== -1) {
                let updatedTags = [...field.value.slice(0, index), ...field.value.slice(index + 1)];
                form.setFieldValue(field.name, updatedTags);
            }
        }
    };

    return (
        <>
        <style>
            {
                `

                * {
                  scrollbar-width: auto;
                  -ms-overflow-style: none;
                }
                `
            }
        </style>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={field.name}
            >
                {title}
            </InputLabel>
            <TextField
                className={(!!form.errors[field.name] && !!form.touched[field.name]) ? classes.errorRoot : classes.root}
                fullWidth
                id={field.name}
                variant="outlined"
                InputLabelProps={{ shrink: true, }}
                error={!!form.errors[field.name] && !!form.touched[field.name]}
                helperText={
                    !!form.errors[field.name] &&
                    form.touched[field.name] &&
                    form.errors[field.name]
                }
                name={field.name}
                InputProps={{
                    classes: { root: classes.root },
                    style: { cursor: 'default' },
                    startAdornment: <div className={classes.tagContainer}>
                        {
                            field.value.map((value: string, index: number) => (
                                <Chip key={index} deleteIcon={<img src={cancleIcon} alt="icon" style={{ height: "8px", width: "8px" }} />} className={classes.removeTag} label={value} onDelete={handleRemoveTag} style={{ marginRight: 5 }} ref={index === field.value.length - 1 ? lastItemRef : null} />
                            ))
                        }
                    </div>
                }}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={newTag}
                type=""
                placeholder={placeHolder}
                onBlur={form.handleBlur}
                multiline={multiline}
            />
            {
                !!form.errors.skills && <Typography className={classes.errorText}>
                    {form.errors.skills}
                </Typography>
            }
        </>
    );
};

export default CustomTextFieldMultipleInput;
