import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldProps } from 'formik';
import { TextareaAutosize, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "8px",
            width: "100%", // Set width to 100%
            maxWidth: "100%", // Set maxWidth to 100%
            minWidth: "100%",
            border: "1px solid #9C9C9C",
            "makeStyles-root-72": {
                "&:focus": {
                    border: "1px solid #9C9C9C",
                },
            },
            color: "#1F497D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "padding": "10px"
        },
        root1: {
            borderRadius: "8px",
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            border: "1px solid #DC2626",
            "makeStyles-root-72": {
                "&:focus": {
                    border: "1px solid #9C9C9C",
                },
            },
            color: "#1F497D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "padding": "10px"
        },
        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626 !important",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginTop": "6px"
        }
    })
);

type Props = FieldProps & {
    placeHolder: string;
    title: string;
    multiline?: boolean;
};

const CustomTextAreaComponent: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    title,
    multiline = false
}) => {
    const classes = useStyles();

    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={field.name}
            >
                {title}
            </InputLabel>
            <TextareaAutosize
                aria-label="maximum height"
                className={!!form.errors[field.name] && !!form.touched[field.name] ? classes.root1 : classes.root}
                minRows={4}
                id={field.name}
                {...field}
                placeholder={placeHolder}
            />
            {
                !!form.errors[field.name] && !!form.touched[field.name] && <Typography className={classes.errorText} style={{ marginTop: "3px" }}>{form.errors[field.name]}</Typography>
            }
        </>
    );
};

export default CustomTextAreaComponent;
