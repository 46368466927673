import React, { CSSProperties } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
Modal,
TextField,
Divider,

  // Customizable Area End
} from "@material-ui/core";
import { paymentIcon, profileIcon } from "./assets";
// Customizable Area Start
import CircularProgress from '@material-ui/core/CircularProgress';
import OtpInput from 'react-otp-input';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikErrors, FormikTouched } from 'formik';
import { makeStyles, styled } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { useTimer } from "react-timer-hook";
import { ToastContainer } from "react-toastify";
import Select, { StylesConfig } from 'react-select';

interface OptionType {
  value: string;
  label: string;
}

interface MyTimerProps {
  expiryTimestamp: Date;
  handleShowVerifyEmail: any;
  handleOTPTimmer: any;
}

interface MyTimerHandlarProps {
  handleShowVerifyEmail: any;
  handleOTPTimmer: any;
}
function MyTimer({
  expiryTimestamp,
  handleShowVerifyEmail,
  handleOTPTimmer,
}: MyTimerProps) {
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => handleOTPTimmer(false),
  });
  const isTimeUp = seconds === 0 && minutes === 0;
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography
          style={{
            color: "#1F497D",
            fontSize: "0.8rem",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {minutes}:{seconds} left
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            color: "#6D97C1",
            fontSize: "0.8rem",
            fontStyle: "normal",
            fontWeight: 400,
          }}
        >
          Didn’t receive OTP?{" "}
          <span
            onClick={() => {
              if (isTimeUp) {
                handleShowVerifyEmail();
                handleOTPTimmer(true);
                const time = new Date();
                time.setSeconds(time.getSeconds() + 180);
                restart(time);
              }
            }}
            data-test-id={"btnTimmerOTP"}
            style={{
              fontWeight: 600,
              cursor: !isTimeUp ? "" : "pointer",
              opacity: !isTimeUp ? 0.5 : 1,
              color: "#1F497D",
              fontSize: "0.9rem",
              fontStyle: "normal",
            }}
          >
            Resend
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
}

function AppTimer({ handleShowVerifyEmail, handleOTPTimmer }: MyTimerHandlarProps) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);
  return (
    <MyTimer
    handleShowVerifyEmail={handleShowVerifyEmail}
    handleOTPTimmer={handleOTPTimmer}
      expiryTimestamp={time}

    />
  );
}

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { color } from "react-native-elements/dist/helpers";
import { Visibility, VisibilityOff } from "@material-ui/icons";
export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
 
  
   StyledLabelStyle = styled('div')({
    fontWeight: 'bold',
    marginTop: '15px'
  });
  
   reasonOptions = [
    { value: "didn't use or didn't understand my premium features", label: "I didn't use or didn't understand my premium features" },
    { value: "found a job and don't need this subscription anymore", label: "I found a job and don't need this subscription anymore" },
    { value: "didn't intend to be billed after the free trial", label: "I didn't intend to be billed after the free trial" },
    { value: "upgraded for a one-time use/project", label: "I upgraded for a one-time use/project" },
    { value: "too expensive", label: "It's too expensive" },
    { value: "other", label: "Other" }
  ];
  
   useStyles = makeStyles((theme) => ({
    textField: {
      backgroundColor: "#FFFFFF",
      height:'44px',
      "& .MuiOutlinedInput-input":{
        padding:"13px 14px",
        fontSize:'14px',
        fontFamily:'Arial',
        color:'rgba(100, 116, 139, 1)'
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        "& fieldset": {
          borderColor: (props: { error: boolean }) => (props.error ? "red" : "rgba(156, 156, 156, 1)"),
        },
        "&:hover fieldset": {
          borderColor: (props: { error: boolean }) => (props.error ? "red" : "rgba(156, 156, 156, 1)"), 
        }
      },
    },
  }));
   customStyles: StylesConfig<OptionType, false> = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: 'none',
      border: '1px solid rgba(156, 156, 156, 1)',
   padding:'3px 0px',
   '&:hover': {
    border: '1px solid rgba(156, 156, 156, 1)',

   }
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'rgba(23, 54, 93, 1)',
      backgroundColor: state.isSelected ? 'rgba(31, 73, 125, 1)' :  'white',  
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: 'rgba(31, 73, 125, 1)',
        color: 'white',
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'rgba(23, 54, 93, 1)',
      fontSize: '14px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px 12px',

    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      border: 'none',
      
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '8px'
    }),
    indicatorSeparator: () => ({
      display: 'none' 
    }),
  
    
  };
  
  
  getFieldClasses(fieldName: string, errors: FormikErrors<any>, touched: FormikTouched<any>) {
    const error = Boolean(errors[fieldName] && touched[fieldName]);
    const classes = this.useStyles({ error });
    return classes.textField;
  }
  
  validationSchema = Yup.object().shape({
    newEmail: Yup.string().email('Please enter correct mail id').required('Email is required'),
    password: Yup.string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, "Please enter Valid Password!"),
  });
   changePasswordValidationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter correct mail id').required('Email is required'),
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string().required('New Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, "Please enter password in 8 characters, one uppercase letter, and one lowercase letter"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm New Password is required'),
  });
  cancelSubscriptionSchema = Yup.object().shape({
    reason: Yup.string().required('Reason is required'),
    specifyReason: Yup.string().when('reason', {
      is: 'other',
      then: Yup.string().required('Please specify your reason')
    })
  });

  
  getSelected = (title: string): CSSProperties => {
    return {
      backgroundColor: this.state.selectedSetting === title ? 'rgba(109, 151, 193, 0.2)' : 'transparent',
    };
  };

  getStatus = (): CSSProperties => {
    return {
      backgroundColor: this.state.subscriptionData?.data.attributes?.status === "cancelled" ? 'rgba(220, 38, 38, 1)' : 'rgba(109, 151, 193, 1)',
    };
  };
  mergeStyles = (...styles: CSSProperties[]): CSSProperties => {
    return Object.assign({}, ...styles);
  };
  
  confirmPasswordValues={
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  data=[
    {
      image: profileIcon,
      title:'Account Settings',
      description:"Settings related to your personal information and account credentials"

  },
  {
    image: paymentIcon,
    title:'Subscription',
    description:"View and manage your subscription."

  }]
  

  // Customizable Area End

  render() {

   const price = this.state.subscriptionData?.data?.attributes?.subscription_details?.price;
  const status = this.state.subscriptionData?.data?.attributes?.status;
  const isButtonDisabled = price == "0.0" || status == "cancelled";
    return (
      // Customizable Area Start
  
    <StyledBox>
    <Grid container >
      <Grid item md={3} sm={12} >
      <StyledSideBox>
        <StyledTitle>{configJSON.settingTitle}</StyledTitle>
        <StyledInsideBox>
          {this.data.map((item :any) => (
            <Box data-test-id={`setting-${item.title}`} onClick={this.setSetting.bind(this,item.title)} display="flex" flexDirection="column"
              style={this.mergeStyles({ gap: 5, padding: '15px 20px', borderRadius: 8, cursor:'pointer' }, this.getSelected(item.title))}
              key={item.title}
            >
              <StyledImageContainer>
                <Box>
                  <img src={item.image} alt={`${item.title} icon`} />
                </Box>
                <StyledHeading>{item.title}</StyledHeading>
              </StyledImageContainer>
              <Box>
                <StyledDescription>{item.description}</StyledDescription>
              </Box>
            </Box>
          ))}
        </StyledInsideBox>
      </StyledSideBox>
      </Grid>
     
      {this.handleOperator(
        this.state.selectedSetting === "Account Settings",
        <Grid item md={9} sm={12} style={{padding:'20px'}} data-test-id="account-settings-content" >
          {this.handleOperator(
            this.state.mainBoxContent === 'default',

               <StyledMainBox>
              <StyledTitle>{configJSON.accountSettingsTitle}</StyledTitle>
              <StyledBoxContainer>
                <StyledBoxStyle data-test-id="email-box" onClick={this.handleMainBoxContentChange.bind(this, 'email')}>
                  <StyledSubTitle>{configJSON.emailAddress}</StyledSubTitle>
                  <StyledIconStyle />
                </StyledBoxStyle>
                <StyledBoxStyle data-test-id="password-box" onClick={this.handleMainBoxContentChange.bind(this, 'password')}>
                  <StyledSubTitle>{configJSON.changePassTitle}</StyledSubTitle>
                  <StyledIconStyle />
                  </StyledBoxStyle>
                </StyledBoxContainer>
              </StyledMainBox>
           ,
              <></>
            )}
          {this.handleOperator(
            this.state.mainBoxContent === 'email',
            <Grid style={{margin:'auto'}} item md={8} sm={10}>
               <StyledEmailBox
                data-test-id="email-settings-content">
              <StyledFlex>
              <StyledFlexBox>
                  <ArrowBackIosIcon data-test-id="back-button" style={{ width: '15px', height: '15px' }} onClick={this.handleMainBoxContentChange.bind(this,'default')} />
                  <StyledTitle style={{padding:'10px'}}>{configJSON.emailAddress}</StyledTitle>
                </StyledFlexBox>
                <Box>
                  <StyledFontStyle>{configJSON.emailAddedTitle}</StyledFontStyle>
                  <StyledGrayFont>{configJSON.personalEmail}</StyledGrayFont>
                  <StyledEmailStyle>{this.state.profileEmail}</StyledEmailStyle>
                </Box>
                <Box>
                  <StyledButtonStyle data-test-id="update-email-button" 
                  type="submit"
                   onClick={this.handleModalOpen2.bind(this,this.state.profileEmail)}
                   >
                    {configJSON.updateEmailTitle}</StyledButtonStyle>
                </Box>
                <Modal
                  open={this.state.isModalOpen}
                  onClose={this.handleModalClose}
                  style={{ width: '546px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '33%',  }}
                >
                  <Grid container>
                    <Grid item md={4} xs={10}>
                      <Formik
                        enableReinitialize={true}
                        data-test-id='Formik'
                        initialValues={{
                          email: this.state.profileEmail,
                          password: '',
                          newEmail: "",
                        }}
                        validationSchema={this.validationSchema}
                        validateOnBlur={true}
                        validateOnChange={true}
                        onSubmit={this.formSubmit}
                      >
                        {({ setFieldValue, values, errors, touched,  handleSubmit, }) => {

                      
                     
                          return (
                            <Form  onSubmit={handleSubmit} translate="no">
                              {this.handleCondition(this.state.showVerifiyEmail,
                                <StyledModalStyle>
                                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography style={{ color: "#1F497D", fontSize: "18px", fontFamily: "Arial", fontWeight: 700, marginTop: "20px" }}>
                                      {configJSON.checkEmail}
                                    </Typography>
                                    <CloseIcon data-test-id="close-modal" onClick={this.handleModalClose} />
                                  </Box>
                                  <Typography style={{ color: "#1F497D", fontSize: "0.95rem", fontFamily: "Arial", fontWeight: 500, marginTop: "10px" }}>
                                    We have {this.handleCondition(this.state.resend, 'resend', 'sent')} an OTP to {values.email}
                                  </Typography>
                                  {this.handleCondition(this.state.otpError,
                                    <StyledErrorBox>
                                     {configJSON.invalidOtp}
                                    </StyledErrorBox>, "")}
                                  <Box>
                                    <Grid container spacing={4}>
                                      <OtpInput
                                        value={this.state.otp}
                                        data-test-id={"handleChangeOTP"}
                                        onChange={this.handleChangeOTP}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                          border: `1px solid ${this.handleCondition(this.state.otpError, '#DC2626', '#9C9C9C')}`,
                                          borderRadius: "5px",
                                          width: "44px",
                                          height: "44px",
                                          fontSize: '0.85rem',
                                          color: "#000",
                                          gap: "8px"
                                        }}
                                        containerStyle={{
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                          width: '100%',
                                          marginTop: '30px',
                                          paddingLeft: '10px',
                                          paddingRight: '10px'
                                        }}
                                        renderInput={(props) => <Grid item xs={1}><input {...props} /></Grid>}
                                      />
                                    </Grid>
                                  </Box>
                                  <Box style={{ marginTop: "30px" }}>
                                  <AppTimer handleShowVerifyEmail={this.handleShowVerifyEmail.bind(this,values.email, true) } handleOTPTimmer={(val: boolean) => this.handleOTPTimmer(val)} />
                                   <Divider style={{ marginTop: '20px' }}/>
                                    <Grid container justifyContent="flex-end">
                                      <Grid item md={4}>
                                        <Button data-test-id={"btnVerifyOTP"}  onClick={this.doEmailVarification} variant="contained" style={{ background: this.state.otp.length < 6 || !this.state.timmer ? "#CBD5E1" : "rgba(31, 73, 125, 1)", color: "white", textTransform: 'capitalize', fontSize: "16px", marginTop: '20px', padding: '13px', borderRadius: '8px' }} fullWidth disabled={this.state.otp.length < 6 || !this.state.timmer}     >
                                          Verify {this.state.verifyloader ? <CircularProgress style={{ color: 'white', marginLeft: '2px' }} size="1rem" /> : null}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </StyledModalStyle>,
                                <StyledModalStyle>
                                  <Grid>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
                                      <Typography style={{ color: "#1F497D", fontSize: "18px", fontFamily: "Arial", fontWeight: 700 }}>
                                        {configJSON.updateEmailTitle}
                                      </Typography>
                                      <CloseIcon data-test-id="close-modal2" onClick={this.handleModalClose} />
                                    </Box>
                                  </Grid>
                                  <Box sx={{ width: "100%" }}>
                                    <Box sx={{ width: "100%", paddingTop: "15px" }}>
                                      <StyledLabelStyle>{configJSON.newEmail}</StyledLabelStyle>
                                      <Field as={TextField} id="emailInput"
                                       name="newEmail"
                                        fullWidth 
                                        variant="outlined"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("newEmail", event.target.value)} 
                                        FormHelperTextProps={{ style: { margin: 0, marginTop:2} }}                   
                                        className={this.getFieldClasses('newEmail', errors, touched)} 
                                      helperText={<ErrorMessage name="newEmail" />} error={Boolean(errors.newEmail && touched.newEmail)} />
                                    </Box>
                                    <StyledFieldBox>
                                      <StyledLabelStyle>Password</StyledLabelStyle>
                                      <Field as={TextField}
                                       id='passwordInput' 
                                       name="password" 
                                       fullWidth 
                                       variant="outlined"
                                        type="password" 
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("password", event.target.value)} 
                                        className={this.getFieldClasses('passwordInput', errors, touched)} 
                                        FormHelperTextProps={{ style: { margin: 0, marginTop:2 } }}                   
                                        helperText={<ErrorMessage name="password" />} error={Boolean(errors.password && touched.password)} />
                                    </StyledFieldBox>
                                    <Divider style={{ marginTop: '20px' }} />
                                    <StyledButtonContainer>
                                      <StyledBackButton type="submit" onClick={this.handleNextModal.bind(this,true)}>{configJSON.back}</StyledBackButton>
                                      <StyledDarkButton type="submit" data-test-id="update-email-submit"
                                       onClick={this.updateEmailPassword.bind(this,values.newEmail, values.password)}
                                       >
                                        {configJSON.submit}</StyledDarkButton>
                                    </StyledButtonContainer>
                                  </Box>
                                </StyledModalStyle>
                              )}
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                </Modal>
              </StyledFlex>
            </StyledEmailBox>
            </Grid>
           ,
            <></>
          )}
          {this.handleOperator(
            this.state.mainBoxContent === 'password',
            <Grid style={{margin:'auto'}} item md={8} sm={10}>
            <StyledEmailBox data-test-id="password-settings-content">
              <StyledFlex >
                <StyledFlexBox>
                  <ArrowBackIosIcon data-test-id="back-button" style={{ width: '15px', height: '15px' }} onClick={this.handleMainBoxContentChange.bind(this,'default')} />
                  <StyledTitle style={{padding:'10px'}}>{configJSON.changePassTitle}</StyledTitle>
                </StyledFlexBox>
              </StyledFlex>
              <Formik initialValues={this.confirmPasswordValues} validationSchema={this.changePasswordValidationSchema}
               onSubmit={this.formSubmit}
              >
                {({ setFieldValue, errors, touched, values }) => (
                    <Form data-test-id="password-form" translate="no">
                      <StyledFieldBox>
                      {values.confirmNewPassword && values.newPassword !== values.confirmNewPassword && (
              <StyledErrorBox>
                {configJSON.passError}
              </StyledErrorBox>
            )}
                        <StyledLabelStyle>{configJSON.currentPass}</StyledLabelStyle>
                        <Field as={TextField}id="currentPassword" name="currentPassword" fullWidth variant="outlined" placeholder="Current Password" type="password" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("currentPassword", event.target.value)}
                                        className={this.getFieldClasses('currentPassword', errors, touched)} 
                                        helperText={<ErrorMessage name="currentPassword" />}
                         error={ this.handleCondition(Boolean(errors.currentPassword), touched.currentPassword, false)}
                         FormHelperTextProps={{ style: { margin: 0, marginTop:2 } }}                   

                        />
                      </StyledFieldBox>
                      <StyledFieldBox>
                        <StyledLabelStyle>{configJSON.newPass}</StyledLabelStyle>
                        <Field as={TextField} id="newPassword" name="newPassword"
                         fullWidth variant="outlined" placeholder="New Password"
                         type={this.state.showPassword ? "text" : "password"}
                         changeTogle={this.setShowPassword}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={this.setShowPassword}
                                 onMouseDown={this.setShowPassword}
                                 edge="end"
                               >
                                 {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                               </IconButton>
                             </InputAdornment>
                           ),
                         }}
                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("newPassword", event.target.value)}
                         className={this.getFieldClasses('newPassword', errors, touched)} 
                         helperText={<ErrorMessage name="newPassword" />} 
                        error={ this.handleCondition(Boolean(errors.newPassword), touched.newPassword, false)}
                        FormHelperTextProps={{ style: { margin: 0, marginTop:2 } }}                   

                         />
                      </StyledFieldBox>
                      <StyledFieldBox>
                        <StyledLabelStyle>{configJSON.confirmNewPass}</StyledLabelStyle>
                        <Field as={TextField} id="confirmNewPassword" 
                        name="confirmNewPassword" 
                        fullWidth variant="outlined"
                         placeholder="Confirm New Password"
                         type={this.state.showConfirmPassword ? "text" : "password"}
                         changeTogle={this.setShowConfirmPassword}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={this.setShowConfirmPassword}
                                 onMouseDown={this.setShowConfirmPassword}
                                 edge="end"
                               >
                                 {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                               </IconButton>
                             </InputAdornment>
                           ),
                         }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("confirmNewPassword", event.target.value)} 
                          className={this.getFieldClasses('confirmNewPassword', errors, touched)} 
                          helperText={<ErrorMessage name="confirmNewPassword" />} 
                        error={ this.handleCondition(Boolean(errors.confirmNewPassword), touched.confirmNewPassword, false)}
                        FormHelperTextProps={{ style: { margin: 0, marginTop:2 } }}                   

                        />
                      </StyledFieldBox>
                      <StyledFieldBox >
                        <StyledLabelStyle onClick={this.handleModalOpen} data-test-id="forgot-password">{configJSON.forgotPass}</StyledLabelStyle>
                      </StyledFieldBox>
                      <StyledFieldBox>
                        <StyledButtonStyle data-test-id="submit-password-button" type="submit" onClick={this.handlePasswordChange.bind(this,values.currentPassword, values.newPassword, values.confirmNewPassword)}>{configJSON.submit}</StyledButtonStyle>
                      </StyledFieldBox>
                      <Modal
                        open={this.state.isModalOpen}
                        onClose={this.handleModalClose}
                        style={{ width: '546px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '33%' }}
                      >
                        <Grid container>
                          <Grid item md={4} xs={10}>
                            <Formik
                              enableReinitialize={true}
                              data-test-id='Formik'
                              initialValues={{
                                forgotEmail: '',
                                forgotNewPassword: '',
                                forgotNewConfirmPassword: "",
                              }}
                              validationSchema={this.changePasswordValidationSchema}
                              validateOnBlur={true}
                              validateOnChange={true}
                              onSubmit={this.formSubmit}
                            >
                              {({ setFieldValue, values, errors, touched,  handleSubmit, }) => (

                                  <Form data-test-id="forgot-password-form" translate="no">
                                    {this.handleOperator(
                                      this.state.showVerifiyEmail,
                                      <StyledModalStyle>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <Typography style={{ color: '#1F497D', fontSize: '18px', fontFamily: "Arial", fontWeight: 700 }}>Forgot Password</Typography>
                                          <CloseIcon data-test-id="close-forgot-password-modal" onClick={this.handleModalClose} />
                                        </Box>
                                        <Typography style={{ fontFamily: 'Arial', fontSize: '16px', fontWeight: 400, color: 'rgba(15, 23, 42, 1)', marginTop: '25px' }}>We'll send a verification code to this email if it matches an existing account.</Typography>
                                        <Box sx={{ width: '100%' }}>
                                          <Box sx={{ paddingTop: '15px', width: '100%' }}>
                                            <StyledLabelStyle>Email</StyledLabelStyle>
                                            <Field id="forgotEmail" as={TextField} name="forgotEmail" fullWidth variant="outlined" type="email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue("forgotEmail", event.target.value)} 
                          className={this.getFieldClasses('forgotEmail', errors, touched)} 
                          helperText={<ErrorMessage name="forgotEmail" />} error={Boolean(errors.forgotEmail && touched.forgotEmail)}
                          FormHelperTextProps={{ style: { margin: 0, marginTop:2 } }}                   
                          />
                                          </Box>
                                          <Divider style={{ marginTop: '30px' }} />
                                          <StyledButtonContainer>
                                            <StyledBackButton data-test-id="forgot-password-back-button" type="button" onClick={this.handleModalClose}>{configJSON.back}</StyledBackButton>
                                            <StyledDarkButton data-test-id="forgot-password-submit-button" type="submit" onClick={this.handleShowOTPModal.bind(this, values.forgotEmail)} variant="contained" color="primary">{configJSON.next}</StyledDarkButton>
                                          </StyledButtonContainer>
                                        </Box>
                       
                              </StyledModalStyle>,
                                      <></>
                                    )}
                                    {this.handleOperator(
                                      this.state.showOTPModal,
                                      <StyledModalStyle>
                                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography style={{ color: "#1F497D", fontSize: "18px", fontFamily: "Arial", fontWeight: 700, marginTop: "20px" }}>
                                      {configJSON.checkEmail}
                                    </Typography>
                                    <CloseIcon data-test-id="close-modal" onClick={this.handleModalClose} />
                                  </Box>   
                                     <Box>
                                          <Typography>We have {this.handleCondition(this.state.resend, 'resend', 'sent')} an OTP to{" "}{values.forgotEmail}</Typography>
                                          {this.handleOperator(
                                            this.state.otpError,
                                            <StyledErrorBox>
                                             {configJSON.invalidOtp}
                                            </StyledErrorBox>,
                                            <></>
                                          )}
                                          <Box style={{ marginTop: "30px" }}>
                                            <Grid container spacing={4}>
                                              <OtpInput
                                                inputStyle={{
                                                  border: `1px solid ${this.state.otpError ? '#DC2626' : '#9C9C9C'}`,
                                                  borderRadius: '5px',
                                                  width: '44px',
                                                  height: '44px',
                                                  fontSize: '0.85rem',
                                                  color: '#000',
                                                  gap: '8px'
                                                }}
                                                containerStyle={{
                                                  display: 'flex',
                                                  justifyContent: 'space-evenly',
                                                  width: '100%',
                                                  marginTop: '30px',
                                                  paddingLeft: '10px',
                                                  paddingRight: '10px'
                                                }}
                                                value={this.state.otp}
                                                onChange={this.handleChangeOTP}
                                                numInputs={6}
                                                shouldAutoFocus={true}
                                                renderInput={(props) => <Grid item xs={1}><input {...props} /></Grid>}
                                              />
                                            </Grid>
                                          </Box>
                                          <Box style={{ marginTop: '30px' }}>
                                          <AppTimer handleShowVerifyEmail={this.handleShowForgotOtpModal.bind(this,values.forgotEmail, true) } handleOTPTimmer={(val: boolean) => this.handleOTPTimmer(val)} />
                                   <Divider style={{ marginTop: '20px' }}/>
                                            <Grid container justifyContent="flex-end">
                                              <Grid item md={4}>
                                                <Button onClick={this.doforgotEmalVerification} data-test-id={"btnVerifyOTP2"} variant="contained" style={{ background: this.state.otp.length < 6 || !this.state.timmer ? "#CBD5E1" : "rgba(31, 73, 125, 1)", color: "white", textTransform: 'capitalize', fontSize: "16px", marginTop: '20px', padding: '13px', borderRadius: '8px' }} fullWidth disabled={this.state.otp.length < 6 || !this.state.timmer} >
                                                  Verify {this.state.verifyloader ? <CircularProgress style={{ color: 'white', marginLeft: '2px' }} size="1rem" /> : null}
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Box>
                                      </StyledModalStyle>,
                                      <></>
                                    )}
                                    {this.handleOperator(
                                      this.state.showThirdModal,
                                      <StyledModalStyle>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
                                          <Typography style={{ color: '#1F497D', fontSize: '18px', fontFamily: "Arial", fontWeight: 700 }}>Choose a new password</Typography>
                                          <CloseIcon data-test-id="close-update-email-modal" onClick={this.handleModalClose} />
                                        </Box>
                                        <Box sx={{ width: '100%' }}>
                                          <Box sx={{ width: '100%', paddingTop: '15px' }}>
                                            <StyledLabelStyle>{configJSON.newPass}</StyledLabelStyle>
                                            <Field as={TextField} id="forgotNewPassword" name="forgotNewPassword" fullWidth variant="outlined" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('forgotNewPassword', event.target.value)} helperText={<ErrorMessage name="forgotNewPassword" />}
                          className={this.getFieldClasses('forgotNewPassword', errors, touched)} 
                          error={ this.handleCondition(Boolean(errors.forgotNewPassword), touched.forgotNewPassword, false)}                     
                                             
                          FormHelperTextProps={{ style: { margin: 0, marginTop:2 } }}                   
                          />
                                          </Box>
                                          <Box sx={{ width: '100%', paddingTop: '15px' }}>
                                            <StyledLabelStyle>{configJSON.confirmNewPass}</StyledLabelStyle>
                                            <Field as={TextField} id="forgotNewConfirmPassword" name="forgotNewConfirmPassword" fullWidth variant="outlined" type="forgotNewConfirmPassword" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('forgotNewConfirmPassword', event.target.value)} 
                                                                    FormHelperTextProps={{ style: { margin: 0, marginTop:2 } }}                   
                                            helperText={<ErrorMessage name="forgotNewConfirmPassword" />}
                          className={this.getFieldClasses('forgotNewConfirmPassword', errors, touched)} 
                          error={ this.handleCondition(Boolean(errors.forgotNewConfirmPassword), touched.forgotNewConfirmPassword, false)}                     
                                             />
                                          </Box>
                                          <Divider style={{ marginTop: '30px' }} />
                                          <StyledButtonContainer>
                                            <StyledDarkButton data-test-id="forgot-modal-submit" type="submit" onClick={this.handleForgotPassChange.bind(this, values.forgotNewPassword, values.forgotNewConfirmPassword)}>{configJSON.submit}</StyledDarkButton>
                                          </StyledButtonContainer>
                                        </Box>
                                      </StyledModalStyle>,
                                      <></>
                                    )}
                                  </Form>
                                
                              )}
                            </Formik>
                          </Grid>
                        </Grid>
                      </Modal>
                    </Form>
                  )
                }
              </Formik>
            </StyledEmailBox>
            </Grid>,
            <></>
          )}
        </Grid>,
        <Grid style={{marginLeft:'180px',marginTop:'25px', maxHeight:'550px'}} item md={6} sm={12} data-test-id="subscription-content">
        {this.handleOperator(
        this.state.showSubscriptionContent,
        <StyledEmailBox>
          <StyledFlex>
            <Box>
              <StyledSubscriptionTitle>{configJSON.subscription}</StyledSubscriptionTitle>
            </Box>
            <StyledBorderBox>
              <Box>
                <StyledFontStyle>{configJSON.subscriptionDescription}</StyledFontStyle>
                <StyledFontStyle>{configJSON.subscriptionSubDescription}</StyledFontStyle>
              </Box>
              <Box>
                <StyledExpoloreButtonStyle data-test-id="update-email-button" type="submit" onClick={this.handleShowPlans}>
                  {configJSON.explorePlans}
                </StyledExpoloreButtonStyle>
              </Box>
            </StyledBorderBox>
          </StyledFlex>
        </StyledEmailBox>
        ,
    <StyledEmailBox data-test-id="explore-plan-content">
    <StyledFlex>
         <Box>
          <StyledSubscriptionTitle>{configJSON.subscription}</StyledSubscriptionTitle>
        </Box>
        <Box style={{
            padding: '20px',
            borderRadius: '8px',
            border:"1px solid rgba(244, 244, 244, 1)",
            display:'flex',
            flexDirection:'column',
        }} >
          <Box style={{display:'flex', justifyContent:'space-between', alignItems:"center"}}>
            <Box>
            <StyledSubTitle>{this.state.subscriptionData?.data.attributes?.subscription_details?.name}</StyledSubTitle>
            </Box>

          <Box 
                        style={this.mergeStyles({
                        borderRadius:'26px',
                         color:'#FFFFFF', 
                        padding:'10px 20px', 
                        fontFamily:'Arial', 
                        fontSize:"16px", 
                        fontWeight:700,
                        textTransform: 'capitalize',},this.getStatus() )}

          >{this.state.subscriptionData?.data.attributes?.status}</Box>
          </Box>
          <StyledAmount>${this.state.subscriptionData?.data.attributes?.subscription_details?.price}<StyledSubAmount>/month</StyledSubAmount></StyledAmount>
       <StyledSubDescripton>
        {this.state.subscriptionData?.data?.attributes?.subscription_details?.description}
       </StyledSubDescripton>
       <StyledCheckContainer>
       {this.state.subscriptionData?.data?.attributes?.subscription_details?.features.map((feature) => (
         <Grid style={{display:'flex'}} >
         <CheckCircleRoundedIcon style={{height:'25px',width:'25px', color: 'rgba(52, 211, 153, 1)' }}/>
         <StyledSub key={feature.id}>{feature.description}</StyledSub>
         </Grid >
       ))}

       
     

        </StyledCheckContainer>
        <Divider style={{marginTop:"20px", height:'2px', color:'rgba(244, 244, 244, 1)'}}/>
        <Box style={{display:'flex', justifyContent:'space-between', marginTop:'20px'}}>

        
          <StyledLabelStyle>  
          {this.handleCondition(this.state.subscriptionData?.data?.attributes?.previous_subscription_details?.name,
                                            
                                            <>
                                            Amount payable on {this.state.subscriptionData?.data?.attributes?.end_date} for your previous plan ({this.state.subscriptionData?.data.attributes?.subscription_details?.name}) and current plan ({this.state.subscriptionData?.data.attributes?.previous_subscription_details?.name})
                                             </>,
                                             <>   
                                             Amount payable on {this.state.subscriptionData?.data?.attributes?.end_date} 2024 for your current plan
                                    </>
                                          )}
         
         </StyledLabelStyle>
         <StyledLabelStyle>${this.state.subscriptionData?.data.attributes?.subscription_details?.price}</StyledLabelStyle>
        </Box>
        </Box>
        <Box style={{ display:'flex', gap:'20px'}}>
        <StyledUpgradeButtonStyle  onClick={this.handleShowPlans} type="submit" >{configJSON.upgradePlan}</StyledUpgradeButtonStyle>

        <StyledCancelButtonStyle   
            
          disabled={isButtonDisabled}
          data-test-id="cancel-modal2" 
          onClick={this.handleModalOpen}>
           {configJSON.cancel}
           </StyledCancelButtonStyle>
         
        </Box>

    </StyledFlex>
  </StyledEmailBox>
      )}
                    <Modal
                        open={this.state.isModalOpen}
                        onClose={this.handleModalClose}
                        style={{  borderRadius:'8px',width: '546px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '33%' }}
                      >
                        <Grid container>
                          <Grid item md={4} xs={10}>
                            <Formik
                              enableReinitialize={true}
                              data-testid='Formik'
                              initialValues={{
                                reason: '',
                                specifyReason:''
                                
                              }}
                              validationSchema={this.cancelSubscriptionSchema}
                              validateOnBlur={true}
                              validateOnChange={true}
                              onSubmit={this.formSubmit}
                            >
                              {({ setFieldValue, values, errors, touched,  handleSubmit, }) => {
                                const isReasonSelected = values.reason !== ''
                                return (
                                  <Form data-test-id="upgrade-plan" translate="no">
                                      <StyledModalStyle>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <StyledCancelModalTitle >Cancel Subscription</StyledCancelModalTitle>
                                          <CloseIcon data-test-id="close-forgot-password-modal" style={{marginTop: '5px'}} onClick={this.handleModalClose} />
                                        </Box>
                                        <Box style={{marginTop:'35px'}}>
                                        <StyledCancelModalTitle >
                                        We're Sorry to See You Go - Quick Feedback?                                        
                                          </StyledCancelModalTitle>
                                        </Box>
                                        
                                        <Box sx={{ width: '100%' }}>
                                          <Box sx={{ paddingTop: '15px', width: '100%' , color:'yellow'}}>
                                            <StyledLabel>Select a reason that applies</StyledLabel>


                                            <Select
                                                styles={this.customStyles}
                                                options={this.reasonOptions}
                                                onChange={(option) => setFieldValue('reason', (option as OptionType).value)}
                                                classNamePrefix="react-select"
                                                placeholder="Select"
                                                className={this.getFieldClasses('reason', errors, touched)} 
                        error={Boolean(errors.reason && touched.reason)}
                        helperText={<ErrorMessage name="reason" />}

                                              />
                                                             </Box>
                                                             {values.reason == 'other' && (
                            <Box sx={{ paddingTop: '15px', width: '100%' }}>
                              <StyledLabel>Please specify your reason</StyledLabel>
                              <Field   
                               id="specifyReason"
          as={StyledTextField}
          name="specifyReason"
          fullWidth
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('specifyReason', event.target.value)
          }
          helperText={<ErrorMessage name="specifyReason" />}
          error={Boolean(errors.specifyReason && touched.specifyReason)}
          FormHelperTextProps={{ style: { margin: 0, marginTop: 2 } }}
          placeholder="Enter your reason here"
                          />
                            </Box>
                         )}     
                       <Divider style={{ marginTop: '30px' }} />
                          <StyledButtonContainer>
                            <StyledDarkButton data-test-id="cancel-subscription-back-button" onClick={this.handleModalClose} variant="contained" color="primary">Keep Subscription</StyledDarkButton>
                            <StyledBackButton data-test-id="cancel-subscription-submit-button"  disabled={!isReasonSelected} type="submit" onClick={this.handleCancelSubcription.bind(this, values.reason, values.specifyReason)}>Cancel Subscription</StyledBackButton>
                            </StyledButtonContainer>
                           </Box>
                                      </StyledModalStyle>
                                  
                                  </Form>
                                );
                              }}
                            </Formik>
                          </Grid>
                        </Grid>
                      </Modal>
  </Grid>
      )}
      <ToastContainer/>
    </Grid>
  </StyledBox>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledBox = styled(Box)(({ theme }) => ({
  fontFamily: "Roboto-Medium",
  maxWidth:'100%',
  width:"100%"

}));
const StyledCheckContainer= styled(Box)(({theme})=>({
  display:'flex',
  flexDirection:'column',
  gap:'10px',
marginTop:"15px"
}))


const StyledFlex = styled(Box)(({theme})=>({
   display: 'flex', 
   flexDirection: 'column',
    gap: '40px', 
    margin: "10px" }))
const StyledTitle = styled(Typography)(({ theme }) => ({
  padding: '20px',
  fontFamily: 'Arial',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  color: 'rgba(23, 54, 93, 1)',
}));
const StyledSubscriptionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  color: 'rgba(23, 54, 93, 1)',
}));

const StyledSideBox = styled(Box)(({ theme }) => ({
height:'85vh',
  background: "rgba(255, 255, 255, 1)",
}));

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  margin: '20px 10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

const StyledInsideBox = styled(Box)(({ theme }) => ({
  margin: '10px',
  gap: '20px',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledMainBox = styled(Grid)(({ theme }) => ({
  background: "rgba(255, 255, 255, 1)",
  borderRadius: '16px',
  height: '77vh',
}));
const StyledAmount= styled(Box)(({theme})=>({
  fontFamily: 'Arial',
  fontSize: '24px',
  fontWeight: 700,
  display:'flex',
  alignItems:'center'
}))

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
}));

const StyledHeading = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 700,
  color: '#17365D',
  fontFamily: 'Arial',
}));
const StyledSubAmount = styled(Box)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: 400,
  color: 'rgba(156, 156, 156, 1)',
}));
const StyledTextField = styled(TextField)({
  backgroundColor: "#FFFFFF",
  height: '44px',
  '& .MuiOutlinedInput-input': {
    padding: '13px 14px', 
    fontSize:'14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& fieldset': {
      borderColor: (props: { error: boolean }) => (props.error ? 'red' : 'rgba(156, 156, 156, 1)'),
    },
    '&:hover fieldset': {
      borderColor: (props: { error: boolean }) => (props.error ? 'red' : 'rgba(156, 156, 156, 1)'),
    },
                            },
});
const StyledSubDescripton= styled(Box)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '13px',
  fontWeight: 400,
  color: 'rgba(156, 156, 156, 1)',
  marginTop:'10px'
}));
const StyledDescription = styled(Box)(({ theme }) => ({
  paddingLeft: "34px",
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: 400,
  color: 'rgba(156, 156, 156, 1)',
}));
const StyledErrorBox = styled(Box)(({theme})=>({
    marginTop: "10px",
    marginBottom: '10px',
    background: '#FEE2E2',
    borderLeft: '4px solid #DC2626',
    padding: '15px 10px 15px 10px',
    color:'rgba(220, 38, 38, 1)'

}))
const StyledSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: 'rgba(23, 54, 93, 1)',
}));
const StyledCancelModalTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  color: 'rgba(23, 54, 93, 1)',
}));
const StyledSub = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '24px',
  color: 'rgba(23, 54, 93, 1)',
  marginLeft:'10px'
}));

const StyledBoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  padding: '18px',
  cursor: 'pointer',
  marginLeft:'10px'
}));
const StyledBorderBox = styled(Box)(({ theme }) => ({
  width:  '644px',
  minHeight:  '130px',
  padding: '20px',
  gap: '20px',
  borderRadius: '8px',
  border:"1px solid rgba(244, 244, 244, 1)",
  display:'flex',
  flexDirection:'column',
  justifyContent:'space-between'
}));

const StyledIconStyle = styled(ArrowForwardIosIcon)(({ theme }) => ({
  width: '15px',
  height: '15px',
  margin:'5px'
}));

const StyledEmailBox = styled(Box)(({ theme }) => ({
  padding: '20px 20px',
  gap: '40px',
  background: "rgba(255, 255, 255, 1)",
  borderRadius: '16px',
}));

const StyledFlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

}));



const StyledFontStyle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: 'rgba(23, 54, 93, 1)',
}));

const StyledEmailStyle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '22px',
  color: 'rgba(23, 54, 93, 1)',
}));

const StyledGrayFont = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 400,
  color: 'rgba(156, 156, 156, 1)',
  margin: '15px 0px',
}));

const StyledButtonStyle = styled(Button)(({ theme }) => ({
  padding: "10px 16px",
  background: "#6D97C1",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: "bold",
  alignSelf: "flex-end",
  textTransform: "none",
  fontSize:'16px',
  fontFamily:'Arial',
  '&:hover': {
    background: "#6D97C1",
  },
}));
const StyledUpgradeButtonStyle = styled(Button)(({ theme }) => ({
  padding: "10px 26px",
  background: "#6D97C1",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: "bold",
  alignSelf: "flex-end",
  textTransform: "none",
  '&:hover': {
    background: "#6D97C1",
  },
  width:'50%'
}));
const StyledCancelButtonStyle = styled(Button)(({ theme }) => ({
  padding: "10px 26px",
  background: "#FFFFFF",
  borderRadius: "8px",
  border:'1px Solid rgba(31, 73, 125, 1)',
  color: "rgba(31, 73, 125, 1)",
  fontWeight: "bold",
  textTransform: "none",
  alignSelf: "flex-end",
  '&:hover': {
    background: "#FFFFFF",
  },
  width:'50%'

}));
const StyledExpoloreButtonStyle = styled(Button)(({ theme }) => ({
  padding: "10px 85px",
  background: "#6D97C1",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: "bold",
  alignSelf: "flex-end",
  textTransform: "none",
  '&:hover': {
    background: "#6D97C1",
  },
  marginTop:'20px'
}));


const StyledDarkButton = styled(Button)(({ theme }) => ({
  fontFamily:'Arial',
  fontSize:'16px',
  padding: "10px 16px",
  background: "rgba(31, 73, 125, 1)",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: "bold",
  alignSelf: "flex-end",
  textTransform: "none",
  '&:hover': {
    background: "rgba(31, 73, 125, 1)",
  },
}));

const StyledBackButton = styled(Button)(({ theme }) => ({
  fontFamily:'Arial',
  fontSize:'16px',
  padding: "10px 18px",
  background: "rgba(244, 244, 244, 1)",
  borderRadius: "8px",
  color: "rgba(31, 73, 125, 1)",
  fontWeight: "bold",
  alignSelf: "flex-end",
  textTransform: "none",
  '&:hover': {
    background: "rgba(244, 244, 244, 1)",
  },
}));

const StyledFieldBox = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingTop: "10px",
  marginTop: '15px',
}));

const StyledLabelStyle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "Arial",
  marginBottom: '4px',
  color: 'rgba(23, 54, 93, 1)',
  
}));
const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "Arial",
  marginBottom: '4px',
  color: 'rgba(31, 73, 125, 1)',
  
}));

const StyledModalStyle = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  padding: '25px 35px',
  borderRadius: '8px',
  color: "#1F497D",
  marginTop: '20%',
  minWidth: '546px',
}));

const StyledButtonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingTop: "10px",
  display: "flex",
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '10px',
}));


// Customizable Area End
