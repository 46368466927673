import React from 'react';
import { Box, Button, Grid, Modal, styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
    isModalOpen: boolean,
    handleModalClose: () => void,
    handleShowPlans: () => void
}

export default function JobPostSubscriptionDialog({ isModalOpen, handleModalClose, handleShowPlans }: Props) {

    return (
        <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            style={{ width: '546px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '33%', }}
        >
            <Grid container style={{outline: 'none'}}>

                <StyledModalStyle>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box ></Box>
                        <CloseIcon
                            data-test-id="close-forgot-password-modal"
                            style={{ marginTop: '5px', color: 'rgba(51, 65, 85, 1)', height: '24px', width: '24px', cursor: "pointer" }}
                            onClick={handleModalClose}
                        />
                    </Box>

                    <StyledTitle>
                        Subscribe or upgrade now for exclusive
                        <StyledTitle>
                            benefits!
                        </StyledTitle>
                    </StyledTitle>
                    <GreyFont>
                        Seeking top talent? You've hit the job post limit! Don't miss out on connecting with the best candidates. Subscribe now or upgrade your plan to unlock more job postings and supercharge your recruitment efforts!                                        </GreyFont>
                    <Box sx={{ width: '100%' }}>
                        <StyledButtonContainer>
                            <StyledBackButton
                                data-test-id="modal-close"
                                onClick={handleModalClose}
                                variant="contained"
                                color="primary">
                                Cancel
                            </StyledBackButton>
                            <StyledDarkButton
                                data-test-id="navigate"
                                onClick={handleShowPlans}
                                type="button" >
                                Subscribe now
                            </StyledDarkButton>
                        </StyledButtonContainer>
                    </Box>
                </StyledModalStyle>
            </Grid>
        </Modal>
    );
}

const StyledModalStyle = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    padding: '25px 35px',
    borderRadius: '8px',
    color: "#1F497D",
    minWidth: '546px',
}));

const StyledTitle = styled(Grid)(({ theme }) => ({
    fontFamily: 'Arial',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color: 'rgba(23, 54, 93, 1)'
}));

const GreyFont = styled(Grid)(({ theme }) => ({
    fontFamily: 'Arial',
    fontSize: '16px',
    fontWeight: 400,
    color: 'rgba(156, 156, 156, 1)',
    marginTop: '25px',
    marginBottom: '25px'

}));

const StyledButtonContainer = styled(Box)(({ theme }) => ({
    fontFamily: 'Arial',
    fontSize: '16px',
    width: "100%",
    paddingTop: "10px",
    display: "flex",
    justifyContent: 'flex-end',
    gap: '20px',
    marginTop: '10px',
}));

const StyledDarkButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Arial',
    height: '56px',
    fontSize: '16px',
    padding: "10px 16px",
    background: "rgba(31, 73, 125, 1)",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "bold",
    alignSelf: "flex-end",
    textTransform: "none",
    '&:hover': {
        background: "rgba(31, 73, 125, 1)",
    },
}));

const StyledBackButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    height: '56px',
    fontFamily: 'Arial',
    fontSize: '16px',
    padding: "10px 18px",
    background: "rgba(244, 244, 244, 1)",
    borderRadius: "8px",
    color: "rgba(31, 73, 125, 1)",
    fontWeight: "bold",
    alignSelf: "flex-end",
    textTransform: "none",
    '&:hover': {
        background: "rgba(244, 244, 244, 1)",
    },
}));
