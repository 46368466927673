import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { toast } from "react-toastify";
import { debounce } from "lodash";

type FormValuesType = {
    onRole: boolean;
    endRole: boolean;
    title: string;
    currentTitle: string;
    companyName: string;
    startMonth: string;
    startYear: string | number;
    endMonth: string;
    endYear: string | number;
}

interface ExperienceData {
    account_id: number,
    company_name: string,
    currently_working: boolean,
    end_current_position: boolean,
    ended_time: string,
    id: number,
    position: string,
    profile_id: number
    specialization: string,
    started_time: string,
}
// Customizable Area End

const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    pageType: string,
    initialValues: FormValuesType,
    companyList: { value: string, label: string, icon: string }[]
    resetForm: () => void
    imageIconFromResponse: string | null
    backRedirection: boolean
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

type FormValues = {
    onRole: boolean;
    endRole: boolean;
    title: string;
    currentTitle: string;
    companyName: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
}

export default class AddWorkExperienceController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    addWorkExperienceApiId: Message | string = "";
    editWorkExperienceApiId: Message | string = "";
    getWorkExperienceApiId: Message | string = ""
    companyListApiId: Message | string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            pageType: "Add",
            initialValues: {
                onRole: false,
                endRole: false,
                title: "",
                currentTitle: "",
                companyName: "",
                startMonth: "",
                startYear: "",
                endMonth: "",
                endYear: "",
            },
            companyList: [{ value: '', label: '', icon: '' }],
            resetForm: () => { },
            imageIconFromResponse: '',
            backRedirection: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: String, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const data = message.getData(
              getName(MessageEnum.NavigationPayLoadMessage)
            );
            if(data.backRedirection) {
                this.setState({
                    backRedirection: data.backRedirection
                })
            }
        }

        if (responseJson && apiRequestCallId) {
            switch(apiRequestCallId) {
                case this.addWorkExperienceApiId:
                    return this.handleAddExperienceResponse(responseJson);
                
                case this.editWorkExperienceApiId:
                    return this.handleEditExperienceResponse(responseJson);
    
                case this.getWorkExperienceApiId:
                    return this.handleGetExperienceResponse(responseJson);
    
                case this.companyListApiId:
                    return this.handleGetCompanyListResponse(responseJson);
    
                default:
                    return null
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        const containerDiv = document.getElementById('pageContainer')
        if (containerDiv) {
            containerDiv.scrollIntoView();
        }
        const workExperienceId = this.props.navigation.getParam("workExperienceId")
        if (workExperienceId) {
            this.getWorkExperience(workExperienceId)
            this.setState({
                pageType: "Edit"
            })
        }
    }

    handleAddExperienceResponse = (responseJson: {errors: [{error: string}], error: {}, }) => {
        if (!responseJson.errors) {
            this.state.resetForm()
            const containerDiv = document.getElementById('pageContainer')
            if (containerDiv) {
                containerDiv.scrollIntoView();
            }
            if(this.state.backRedirection) {
                const msg = new Message(getName(MessageEnum.NavigationMessage));
                msg.addData(getName(MessageEnum.NavigationTargetMessage), "JobApplicantProfile");
                msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
                this.send(msg);                   
            }

            toast.success("Work experience added successfully.")
        } else if (responseJson?.errors && responseJson?.errors.length > 0){
            const errorMessage = (Object.values(responseJson?.errors[0])[0] as string) || 'Something went wrong';
            toast.error(errorMessage);
        } else {
            responseJson?.error ? toast.error(responseJson?.error) : toast.error("Something went wrong")
        }
    }

    handleEditExperienceResponse = (responseJson: { error: string }) => {
        if (!responseJson.error) {
            const containerDiv = document.getElementById('pageContainer')
            if (containerDiv) {
                containerDiv.scrollIntoView();
            }
            this.handleGoBack()
        } else {
            toast.error(responseJson?.error)
        }
    }

    handleGetExperienceResponse = (responseJson: { error: string, company_icon_url: string, work_experience: ExperienceData }) => {
        if (!responseJson.error) {
            if (Object.values(responseJson?.work_experience).length > 0) {
                const experienceData = responseJson.work_experience;
                this.setState({
                    initialValues: {
                        onRole: experienceData.currently_working,
                        endRole: experienceData.end_current_position,
                        title: experienceData.position,
                        currentTitle: experienceData.specialization,
                        companyName: experienceData.company_name,
                        startMonth: new Date(experienceData.started_time).toLocaleString('en-US', { month: 'long' }),
                        startYear: new Date(experienceData.started_time).getFullYear(),
                        endMonth: new Date(experienceData.ended_time).toLocaleString('en-US', { month: 'long' }),
                        endYear: new Date(experienceData.ended_time).getFullYear(),
                    },
                    imageIconFromResponse: responseJson.company_icon_url ?? ""
                })
            } else {
                toast.error("Work experience not found.")
            }
        } else {
            toast.error(responseJson?.error)
        }
    }

    handleGetCompanyListResponse = (responseJson: { company_listing: { name: string, icon: string }[] }) => {
        if (responseJson?.company_listing && responseJson?.company_listing.length > 0) {
            const companyList = responseJson?.company_listing?.map((company: { name: string, icon: string }) => ({
                value: company.name || '',
                label: company.name || '',
                icon: company.icon || ''
            }));
            this.setState({ companyList })
        }
    }

    monthsData = () => {
        const months = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
        ];
        return months.map((month, index) => ({ value: month, label: month, index }));
    }

    yearsData = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 100; i++) {
            const year = currentYear - i;
            years.push({ value: year, label: year.toString() });
        }
        return years;
    }

    handleGoBack = () => {
        this.props.navigation.goBack()
    }

    companyListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.setState({
                imageIconFromResponse: null
            })
            this.handleInputChangeDebounced(value);
        }
    };

    handleSubmitForm = (data: FormValuesType, resetFunction: any) => {
        this.setState({
            resetForm: resetFunction
        })
        const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
        const companyIcon = this.state.companyList.find((company) => company?.value === data.companyName)?.icon ?? ''

        const experienceData = {
            work_experience: {
                position: data.title,
                specialization: data.currentTitle,
                company_name: data.companyName,
                started_time: `${data.startMonth} ${data.startYear}`,
                ended_time: data.onRole ? currentMonth : `${data.endMonth} ${data.endYear}`,
                currently_working: data.onRole,
                end_current_position: data.endRole,
                company_icon: companyIcon
            }
        }
        const authToken = localStorage.getItem("authToken")
        const headers = {
            "token": authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addWorkExperienceApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.workExperienceApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(experienceData)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    editWorkExperience = (data: FormValuesType) => {
        const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
        const companyIcon = (this.state.imageIconFromResponse ?? this.state.companyList.find((company) => company?.value === data.companyName)?.icon) || ""

        const experienceData = {
            work_experience: {
                position: data.title,
                specialization: data.currentTitle,
                company_name: data.companyName,
                started_time: `${data.startMonth} ${data.startYear}`,
                ended_time: data.onRole ? currentMonth : `${data.endMonth} ${data.endYear}`,
                currently_working: data.onRole,
                end_current_position: data.endRole,
                company_icon: companyIcon
            }
        }
        const authToken = localStorage.getItem("authToken")
        const headers = {
            "token": authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editWorkExperienceApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/${this.props.navigation.getParam("workExperienceId")}/edit_work_experience`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(experienceData)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleInputChangeDebounced = (value: string) => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem("authToken")
        };
        let userData = { search_name: value || '' };

        const updateUserDetails = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.companyListApiId = updateUserDetails.messageId;

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CompanyListApiEndPoint}`
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
    }

    getWorkExperience = (id: string | number) => {
        const workExperienceId = this.props.navigation.getParam("workExperienceId")

        const authToken = localStorage.getItem("authToken")
        const headers = {
            "token": authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getWorkExperienceApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.workExperienceApi}/${workExperienceId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}
