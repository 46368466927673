import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldProps } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { openMenuicon } from '../assets';

const style1 = makeStyles((theme: Theme) => createStyles({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        border: '0px solid',
        "& .PrivateNotchedOutline-root-15": {
            border: "0px"
        },
        color: "#1F497D",
        background: "white",
        "& .MuiSelect-outlined": {
            background: "white"
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            'Arial',
            'sans-serif',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: 'none',
            border: "0px",
            background: "white"
        },
        color: "#1F497D",
        background: "white"
    },
}))
const SelectWithoutBorder: React.FC<{ currency: string, handleCurrencyChange: (event: React.ChangeEvent<{ value: unknown }>) => void }> = ({ currency, handleCurrencyChange }) => {
    const classes = style1();
    return <Select
        input={<InputBase />}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        IconComponent={CustomDropdownIcon}
        value={currency}
        onChange={handleCurrencyChange}
        className={classes.root}
        defaultValue={"$"}
    >
        <MenuItem value="₹">₹</MenuItem>
        <MenuItem value="$">$</MenuItem>
        <MenuItem value="€">€</MenuItem>
        <MenuItem value="£">£</MenuItem>
        <MenuItem value="¥">¥</MenuItem>
</Select>

}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "8px",
            height: "44px",
            "& .MuiOutlinedInput-input": {
                borderRadius: "8px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#6D97C1",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px",
                "&:hover": {
                    border: "0px solid #6D97C1",
                },
                "&:focus": {
                    border: "0px solid #1F497D",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
        },

        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginBottom": "32px",
            "marginTop": "6px"
        }
    })
);

type Props = FieldProps & {
    placeHolder: string;
    title: string;
};
const CustomDropdownIcon = (props: any) => {
    return <img src={openMenuicon} alt="open" {...props} style={{ padiingRight: "10px" }} />;
};

function formatNumberWithCommas(value: string) {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const CurrencyField: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    title,
}) => {
    const classes = styles();

    const handleCurrencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newCurrency = event.target.value as string;
        form.setFieldValue(field.name, { amount: field.value.amount || '', currency: newCurrency });
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatNumberWithCommas(event.target.value);
        form.setFieldValue(field.name, { currency: field.value.currency || "$", amount: formattedValue });
    };
    
    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={field.name}
            >
                {title}
            </InputLabel>
            <TextField
                className={classes.root}
                fullWidth
                id={field.name}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    classes: { root: classes.root }, startAdornment: <SelectWithoutBorder currency={field.value.currency || "$"} handleCurrencyChange={handleCurrencyChange} />
                }}
                error={!!form.errors[field.name] && !!form.touched[field.name]}
                helperText={
                    !!form.errors[field.name] &&
                    form.touched[field.name] &&
                    form.errors[field.name]
                }
                {...field}
                value={field.value.amount}
                placeholder={placeHolder}
                onChange={handleValueChange}                
            />
        </>
    );
};

export default CurrencyField;
