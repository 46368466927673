export const Home = require("../assets/homeIcon.svg");
export const Popular = require("../assets/popularIcon.svg");
export const Saved = require("../assets/savedIcon.svg");
export const Following = require('../assets/followingIcon.svg');
export const userImg = require("../assets/user_img.png");
export const searchImg = require("../assets/image_search.png");
export const skipImg = require("../assets/skip_avatar.png");
export const bookmarkImg = require("../assets/image_bookmark.png");
export const centerImg = require("../assets/view_rectangle.png");
export const imgF = require("../assets/image_F.png");
export const imgB = require("../assets/image_B.png");
export const imgChat = require("../assets/image_chat.png");
export const imgFilter = require("../assets/image_filter.png");
export const filter = require("../assets/filter.svg");
export const starIC = require("../assets/star.svg");
export const imgViewAll = require("../assets/image_viewAll.svg");
export const text = require("../assets/text.png");
export const dot = require("../assets/dot.svg");
export const more = require("../assets/more.svg");
export const plus = require("../assets/plus.svg");
export const minus = require("../assets/minus.svg");
export const up = require("../assets/up.svg");
export const down = require("../assets/down.svg");
export const commentIcon = require("../assets/comment.svg");
export const save = require("../assets/save.svg");
export const saveBlue = require("../assets/saveblue.svg");
export const gallary = require("../assets/gallary.svg");
export const remove = require("../assets/remove.svg");
export const sundayVideo = require("../assets/sunday.mp4");
export const crossIcon = require("../assets/crossIcon.svg");
export const likedUpvote = require("../assets/likedUpvote.svg");
export const userIcon = require("../assets/userIcon.svg");
export const locationIcon = require("../assets/location.svg");
export const scrollDown = require("../assets/scroll-down.svg");
export const openMenuicon = require("../assets/openMenuWhite.svg");
export const SearchIcon = require("../assets/search.svg");
export const EndSearchIcon = require("../assets/endsearch.svg");
export const buySideIcon = require("../assets/buySideIcon.svg");
export const sellSideIcon = require("../assets/sellSideIcon.svg");
export const careerDevelopmentIcon = require("../assets/careerDevelopmentIcon.svg");