import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

const styleObjectone = makeStyles((theme: Theme) => createStyles({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        border: '0px solid',
        "& .PrivateNotchedOutline-root-15": {
            border: "0px"
        },
        color: "#1F497D",
        background: "white",
        "& .MuiSelect-outlined": {
            background: "white",
            paddingRight: "0px !important"
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            'Arial',
            'sans-serif',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: 'none',
            border: "0px",
            background: "white"
        },
        color: "#1F497D",
        background: "white"
    },
}))

const stylesObject = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "12px",
            height: "44px",
            "& .MuiOutlinedInput-input": {
                borderRadius: "12px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#6D97C1",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px",
                "&:hover": {
                    border: "0px solid #6D97C1",
                },
                "&:focus": {
                    border: "0px solid #1F497D",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
                paddingLeft: "8px"
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
        },

        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginBottom": "32px",
            "marginTop": "6px"
        }
    })
);

type PropsCurrency = {
    setCuurency: (value: string) => void,
    currency_value: string
};
const SelectWithoutBorder = (props: PropsCurrency) => {
    const classes = styleObjectone();
    return <Select
        input={<InputBase />}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        IconComponent={CustomDropdownIcon}
        defaultValue={10}
        className={classes.root}
        value={props.currency_value}
        onChange={(event) => {
            props.setCuurency(event.target.value as string)
        }}
    >
        <MenuItem value={"₹"}>₹</MenuItem>
        <MenuItem value={"$"}>$</MenuItem>
        <MenuItem value={"€"}>€</MenuItem>
        <MenuItem value={"£"}>£</MenuItem>
        <MenuItem value={"¥"}>¥</MenuItem>
    </Select>
}



type Props = {
    placeHolder: string;
    title: string;
    name: string,
    value: string,
    error: string,
    helperText: string,
    handleChange: (value: string) => void,
    setCuurency: (value: string) => void,
    currency_value: string,
    onKeyDown: (event: React.KeyboardEvent) => void
};
const CustomDropdownIcon = () => {
    return <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.8492 0.655781L5.7292 4.53578L9.6092 0.655781C9.9992 0.265781 10.6292 0.265781 11.0192 0.655781C11.4092 1.04578 11.4092 1.67578 11.0192 2.06578L6.4292 6.65578C6.0392 7.04578 5.4092 7.04578 5.0192 6.65578L0.429204 2.06578C0.241952 1.87895 0.136719 1.6253 0.136719 1.36078C0.136719 1.09626 0.241952 0.842613 0.429204 0.655781C0.819204 0.275781 1.4592 0.265781 1.8492 0.655781Z" fill="#1F497D" />
    </svg>
        ;
};

function formatNumberWithCommas(value: string) {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const CurrenyFieldWithCurrency: React.FC<Props> = ({
    name,
    value,
    placeHolder,
    title,
    error,
    helperText,
    handleChange,
    setCuurency,
    currency_value,
    onKeyDown
}) => {
    const classes = stylesObject();

    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={name}
            >
                {title}
            </InputLabel>
            <TextField
                className={classes.root}
                fullWidth
                id={name}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    classes: { root: classes.root },
                    startAdornment: <SelectWithoutBorder
                        setCuurency={setCuurency}
                        currency_value={currency_value}
                    />
                }}
                error={!!error}
                helperText={helperText}
                value={formatNumberWithCommas(value)}
                placeholder={placeHolder}
                onChange={(event) => handleChange(event.target.value)}
                onKeyDown={onKeyDown}
            />
        </>
    );
};

export default CurrenyFieldWithCurrency;
