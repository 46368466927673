import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

interface Post {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        description: string;
        body: string;
        location: string;
        account_id: number;
        category_id: number;
        images: ImageOrVideo[];
        created_at: string;
        updated_at: string;
        model_name: string;
        isliked: boolean;
        isdisliked: boolean;
        likes_count: number;
        dislikes_count: number;
        following_account: boolean;
        comment_count: number;
        is_saved: boolean;
        account: Account;
        comment: Comment[];
        connection_request_details: {
            id?: number,
            sender_id?: number,
            receiver_id?: number,
            status?: string | "accepted" | "pending",
        } | null
    };
}

interface ImageOrVideo {
    url: string;
    id: number;
}

interface Account {
    account_id: string | number;
    account_name: string;
    account_profile: string;
}

interface Props {
    postLoading: boolean;
    allPosts: Post[];
    handleLoadMore: () => void;
    isLoadingPost: boolean;
}

interface Comment {
    commentable_type: string;
    id: number;
    commentable_id: number;
    parent_id: number | null;
    created_at: string;
    comment: string;
    account_id: number;
    updated_at: string;
    dislikes_count: number;
    likes_count: number;
    isliked: boolean;
    comment_images: { id: number | string, url: string }[];
    isdisliked: boolean;
    reply_to: number | null;
    account: Account;
    reply_count: number;
    replies: Reply[];
}

interface Reply {
    parent_id: number | null;
    id: number;
    commentable_id: number;
    commentable_type: string;
    created_at: string;
    comment: string;
    updated_at: string;
    comment_images: { id: number | string, url: string }[];
    dislikes_count: number;
    likes_count: number;
    isliked: boolean;
    isdisliked: boolean;
    account: Account;
    reply_to: number | null;
    replies: Reply[];
    reply_count: number;
}

class LoadMoreButton extends React.Component<Props> {
    render() {
        const { postLoading, allPosts, isLoadingPost, handleLoadMore } = this.props;

        return (
            !postLoading && allPosts.length > 19 &&
            <Grid item sm={12} style={webStyle.grid}>
                <button
                    data-test-id="loadMore"
                    disabled={isLoadingPost}
                    style={webStyle.loadmoreBtn}
                    onClick={handleLoadMore}
                >
                    Load More
                    &nbsp;&nbsp;
                    {isLoadingPost && <CircularProgress style={webStyle.btn} />}
                </button>
            </Grid>
        );
    }
}

const webStyle = {
    loadmoreBtn: {
        padding: "6px 10px",
        height: "32px",
        width: "137px",
        color: "#17365D",
        backgroundColor: "#F1F5F9",
        borderRadius: "8px",
        border: "0px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    grid: { display: "flex", justifyContent: "center", alignItems: "center", width: "100%" },
    btn: { color: '#1F497D', width: "15px", height: "15px" }
};

export default LoadMoreButton;
