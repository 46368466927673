Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "/bx_block_subscriptions/subscriptions?user_type=";
  exports.subscriptionAPiEndPoint = "/bx_block_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.subscribeApiEndPoint = "/bx_block_subscriptions/subscriptions/subscribe";
exports.upgradeApiEndPoint = "/bx_block_settings/settings/upgrade_user_subscription";
exports.postSubscriptionAPiMethod = "POST";
exports.putSubscriptionAPiMethod = "PUT";
exports.getUserSubscriptionDetailsApiEndpoint = "/bx_block_subscriptions/subscriptions/user_subscription_details";
exports.optionOfStripeField = {
  style: {
    base: {
      fontFamily: 'Arial',
      lineHeight: '22px',
      color: "#17365D",
      fontSize: '14px',
      '::placeholder': {
        color: '#9C9C9C',
        fontFamily: 'Arial',
      },
      ':-webkit-autofill': {
        color: '#17365D',
        fontFamily: 'Arial',
      },
    },
    invalid: {
      color: '#DC2626',
      fontFamily: 'Arial',
    },
  }
}

// Customizable Area End
