import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import BootstrapTooltip from './BootstrapTooltip';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "display": "flex",
            "height": "48px",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#6D97C1",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#6D97C1',
            },
            "color": "#FFF",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "22px"
        },
        outLined: {
            "display": "flex",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "borderRadius": "8px",
            "border": "1px solid #1F497D",
            "background": "#FFF",
            "color": "#1F497",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#FFF',
            },
        },
        applied: {
            "display": "flex",
            "height": "48px",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#F1F5F9",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#F1F5F9',
            },
            "color": "#1F497D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "22px"
        },
        roundedBtn: {
            "display": "flex",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "16px",
            "background": "#F1F5F9",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#F1F5F9',
            },
            "color": "#1F497D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "height": "32px"
        }
    }),
);
type Props = {
    title: string,
    type: "outlined" | "normal" | "secondary" | "rounded",
    applyJobHandler?: (status: boolean)=> void
}

export default function CustomButtonOne(props: Props) {
    const classes = useStyles();
    const { title, type, applyJobHandler } = props;
    const getClassName = () => {
        switch (type) {
            case "normal":
                return classes.root;
            case "outlined":
                return classes.outLined;
            case "secondary":
                return classes.applied;
            case "rounded":
                return classes.roundedBtn;
        }
    }
    return (<Button variant="contained" disableElevation className={getClassName()} onClick={()=> applyJobHandler && applyJobHandler(true)}>
        {title}
    </Button>
    );
}