import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function ProgressCircleComp(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    style={{
                        color: "#17365D",
                        fontFamily: "Arial",
                        fontSize: "14px",
                        fontWeight: "bold"
                    }}
                    variant="caption"
                    component="div"
                >{
                    props.value
                }</Typography>
            </Box>
        </Box>
    );
}

interface Props {
    progress: number
}
export default function ProgressCircle({ progress }: Props) {
    return <ProgressCircleComp value={progress} style={{ color: "#538DD3" }} size={70} />;
}
