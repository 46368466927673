import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    "color": "white",
    "fontFamily": "Arial",
    "fontSize": "8px",
    "fontStyle": "normal",
    "fontWeight": "lighter",
    "lineHeight": "13px",
    "border": "1px solid #F4F4F4"
  },
  tooltip: {
    backgroundColor: "white",
    "border": "1px solid #F4F4F4",
    "color": "#17365D"
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default BootstrapTooltip