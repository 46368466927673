import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { UserProfileInterface, UserDetails } from "../RecruiterProfile1Controller";
import { handleConditionFunction, TruncateWithTooltip } from "../../../../../packages/components/src/Utils.web";
import { add, report, message, connect, recruiterIcon, followingIcon } from "../assets"
import CompanyDetails from "./CompanyDetails.web";
import BootstrapTooltip from "../../../../../packages/blocks/catalogue/src/components/BootstrapTooltip";

interface RecruiterProfileDetailsProps {
    userProfile: UserProfileInterface;
    userID: string;
    userDetails: UserDetails;
    FollowFromFollowing: (value: string) => void;
    handleFollowClick: () => void;
    removeConnectionPopup: boolean;
    withdrawConnection: boolean;
    removeConnectionRequest: () => void;
    withdrawConnectionRequest: () => void
    followClick: boolean;
    handleInvitationPopup: (value: string) => void
    loggedId: string | null;
    handleReportOpen: () => void;
    handleWithdraw: () => void;
    ignoreFriendRequestApiCall: () => void;
    handleRemoveConnection: () => void;
    handleMessageClick: () => void

}

export default class RecruiterProfileDetails extends Component<RecruiterProfileDetailsProps> {
    constructor(props: RecruiterProfileDetailsProps) {
        super(props);
    }
    render() {
        return (
            <>
            <Grid direction="column" spacing={2} style={{ width: "100%", marginBottom: "20px" }}>
                <Grid container style={webStyle.imageBox}>
                    <Grid item style={{ width: "18%", height: "10%" }}>
                        <Avatar src={this.props.userProfile.user_profile.data.attributes.photo} style={{
                            height: "132px",
                            width: "132px",
                            borderRadius: "50%"
                        }} >
                        </Avatar>
                    </Grid>
                    <Grid item style={{ paddingLeft: "4px", width: "80%" }}>
                        <Box style={{display: "flex", justifyContent: "space-between"}}>
                            <Typography style={webStyle.nameText}>
                                {this.props.userProfile?.user_profile?.data?.attributes?.full_name}
                            </Typography>
                            <img src={recruiterIcon} />
                        </Box>


                            <BootstrapTooltip title={this.props.userProfile?.user_profile?.data?.attributes?.current_title}>
                                <Typography id="currentTitle" style={webStyle.positionText}>
                                    {TruncateWithTooltip(this.props.userProfile?.user_profile?.data?.attributes?.current_title, 25)}
                                </Typography>
                            </BootstrapTooltip>
                        <Typography style={webStyle.connectionText}>
                            {this.props.userProfile?.user_profile?.data?.attributes?.total_connections} connections
                        </Typography>
                        {
                            handleConditionFunction(
                                (`${this.props.userID}` !== `${this.props.userDetails.account_details?.id}`),
                                <Grid container spacing={1} style={{ marginTop: "8px", }}>
                                    <Grid item style={{ position: "relative" }}>
                                        {handleConditionFunction(this.props.userProfile?.user_profile.data.attributes.is_followed === false,
                                            <Box
                                                style={webStyle.followButtonBox}
                                                data-test-id="addFromFollowing"
                                                onClick={() => this.props.FollowFromFollowing(this.props.userProfile?.user_profile?.data?.attributes?.account_details?.id)}
                                            >
                                                <img src={add} style={{width: "16px"}} />
                                                <Typography style={webStyle.followText}>Follow</Typography>

                                            </Box>,
                                            <Box style={webStyle.followButtonBox}
                                                data-test-id="unFollowFromFollowing"
                                                onClick={this.props.handleFollowClick}>
                                                <img src={followingIcon} style={webStyle.iconImage}/>
                                                <Typography style={webStyle.followText}>Following</Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {handleConditionFunction(this.props.userProfile?.user_profile?.data?.attributes?.connection_request_details === null,
                                            <Box sx={webStyle.connectButton} data-test-id="addConnection"
                                                onClick={() => this.props.handleInvitationPopup(this.props.userProfile?.user_profile?.data?.attributes?.account_details?.id)} >
                                                <img src={connect} style={webStyle.iconImage} />
                                                <Typography style={webStyle.connectText}>
                                                    Connect</Typography>
                                            </Box>, ""
                                        )}
                                        {handleConditionFunction(this.props.userProfile?.user_profile?.data?.attributes?.connection_request_details?.status === "pending" &&
                                            Number(this.props.loggedId) === this.props.userProfile?.user_profile?.data?.attributes?.connection_request_details?.sender_id,
                                            <Box sx={webStyle.connectButton} data-test-id="withdrawConnectionRequest" onClick={this.props.handleWithdraw} >
                                                <img src={connect} style={webStyle.iconImage} />
                                                <Typography style={webStyle.connectText}>
                                                    Pending</Typography>
                                            </Box>, ""
                                        )}

                                        {handleConditionFunction(Number(this.props.loggedId) !== this.props.userProfile.user_profile?.data?.attributes?.connection_request_details?.sender_id && this.props.userProfile.user_profile?.data?.attributes?.connection_request_details?.status === "pending",
                                            <Box sx={webStyle.connectButton} data-test-id="ignoreConnectionRequest" onClick={this.props.ignoreFriendRequestApiCall} >
                                                <img src={connect} style={webStyle.iconImage} />
                                                <Typography style={webStyle.connectText}>
                                                    Ignore</Typography>
                                            </Box>, ""
                                        )}

                                        {handleConditionFunction(this.props.userProfile?.user_profile?.data?.attributes?.connection_request_details?.status === "accepted",
                                            <Box sx={webStyle.connectButton} data-test-id="removeConnectionRequest" onClick={this.props.handleRemoveConnection}>
                                                <img src={connect} style={webStyle.iconImage} />
                                                <Typography style={webStyle.connectText}>
                                                    Remove</Typography>
                                            </Box>, ""
                                        )}
                                    </Grid>
                                    {handleConditionFunction(this.props.userProfile?.user_profile?.data?.attributes?.connection_request_details?.status === "accepted" || this.props.userProfile?.user_profile.data.attributes.open_for_direct_approach,
                                        <Grid item>
                                            <Box style={webStyle.messageButton} data-test-id="handleMessageClick" onClick={this.props.handleMessageClick}>
                                                <img src={message} style={webStyle.iconImage} />
                                                <Typography style={webStyle.messageText}>Message</Typography>
                                            </Box>
                                        </Grid>
                                        , ""
                                    )}
                                    <Grid item>
                                        {this.props.userProfile?.user_profile.data.attributes.is_reported === false ?
                                            <Box
                                                data-test-id="reportButton"
                                                style={webStyle.reportButton}
                                                onClick={this.props.handleReportOpen}>
                                                <img src={report} style={{width: "22px"}} />
                                                <Typography style={webStyle.reportText}>
                                                    Report
                                                </Typography>
                                            </Box> :
                                            <Box style={webStyle.reportButton}>
                                                <img src={report} style={{width: "22px"}} />
                                                <Typography style={webStyle.reportText}>
                                                    Reported
                                                </Typography>
                                            </Box>
                                        }

                                    </Grid>
                                </Grid>,
                                "")
                        }
                    </Grid>
                </Grid>
            </Grid>
            <CompanyDetails companyDetails={this.props.userProfile.user_profile.data.attributes} boxBorder="16px"/>
            </>

        );
    }
}

const webStyle = {
    imageBox: {
        padding: "20px 32px",
        borderRadius: "8px",
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        background: "#FFFFFF",
        marginTop: "20px",
    },
    positionText: {
        fontSize: "14px",
        color: "#9C9C9C",
        fontFamily: "Arial",
        fontWeight: 400,
        width: "30%"
    },
    nameText: {
        fontSize: "30px",
        color: "#17365D",
        fontFamily: "Arial",
        fontWeight: 700,
    },
    followButtonBox: {
        background: "#6D97C1",
        display: "flex",
        color: "#FFFFFF",
        borderRadius: "8px",
        gap: "6px",
        padding: "10px 26px",
        height: "fit-content",
        cursor: "pointer",
    },
    connectButton: {
        borderRadius: "8px",
        display: "flex",
        padding: "10px 26px",
        border: "1px solid #6D97C1",
        cursor: "pointer",
        gap: "6px",
        height: "fit-content",
    },
    connectionText: {
        fontSize: "14px",
        color: "#6D97C1",
        fontFamily: "Arial",
        fontWeight: 400,
    },
    messageButton: {
        borderRadius: "8px",
        display: "flex",
        padding: "10px 26px",
        border: "1px solid #6D97C1",
        cursor: "pointer",
        gap: "6px",
        height: "fit-content",
    },
    followText: {
        fontSize: "14px",
        color: "#ffffff",
        fontWeight: 700,
        fontFamily: "Arial",
    },
    reportButton: {
        borderRadius: "8px",
        display: "flex",
        padding: "10px 26px",
        border: "1px solid #6D97C1",
        cursor: "pointer",
        gap: "6px",
        height: "fit-content",
        alignItems: "center",
        justifyContent: "center",
        color: "#6D97C1",
        fontSize: "14px",
    },
    connectText: {
        fontSize: "14px",
        color: "#6D97C1",
        fontFamily: "Arial",
        fontWeight: 700,
    },
    messageText: {
        fontSize: "14px",
        fontFamily: "Arial",
        color: "#6D97C1",
        fontWeight: 700,
    },
    reportText: {
        fontSize: "14px",
        fontFamily: "Arial",
        color: "#6D97C1",
        fontWeight: 700,
    },
    iconImage: {
        width: "24px",
    },
};
