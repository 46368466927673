import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Uploadmedia3Controller, {
  Props,
  configJSON,
} from "./Uploadmedia3Controller";

export default class Uploadmedia3 extends Uploadmedia3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      // <ThemeProvider theme={theme}>
        
        <Container 
          style={{ ...webStyle.container, flexDirection: 'column' }} 
          maxWidth={"sm"}
        >  
        { 
          <Box position="relative" display="inline-flex">
              <CircularProgress variant="determinate" value={this.props.progress} />
              <Box 
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                <Typography  variant="caption" component="div" color="textSecondary">
                  {this.props.progress}%
                </Typography>
              </Box>
            </Box>
          }
          { 
            <Typography style={{color:'blue'}}>{this.props.time.toFixed(2)} seconds</Typography> 
          }
        </Container>
      // </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  container : { 
    width:'fit-content', 
    display:'flex', 
    gap:"2px", 
    justifyContent:'center', 
    alignItems:'center'
  }
};
// Customizable Area End
