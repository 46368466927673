Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.aplyFiltersAPiEndPoint = "bx_block_joblisting/jobs/landing_page_job_listing";
exports.skillsListApiEndPoint = "bx_block_joblisting/jobs/skills_listing"
exports.recruiterListApiEndPoint = "bx_block_profile/profiles/get_recruiters_list"
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.excludeOutOfStockTxt = "Exclude out of stock";
exports.priceRangeTxt = "Price range";
exports.brandTxt = "Brand";
exports.categoryTxt = "Category";
exports.cancel = "Cancel";
exports.apply = "Apply";
exports.searchForBrandTxt = "Search for a Brand";
exports.searchForCategoryTxt = "Search for a Category";
exports.webFilterOptionsTxt = "Web Filter Options";
exports.emptyBlockTxt = "Empty Block";

exports.filterTxt = "Filter";
// Customizable Area End
