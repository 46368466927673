// Customizable Area Start
export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
export const imgHeaderBg = require('../assets/header_bg.png');
export const companyLogo = require('../assets/microsoft-logo.png');
export const profilepagebg = require('../assets/image_Header.jpg');
export const people1 = require('../assets/people1.png');
export const people2 = require('../assets/people2.png');
export const people3 = require('../assets/people3.png');
export const videobg = require('../assets/videobg.png');
export const profilebg = require('../assets/profile_bg.png');
export const profile = require('../assets/profile.png');
export const apple = require('../assets/apple.png');
export const loadingGif = require('../assets/loading.gif');
export const cardBackgroundImage = require('../assets/card_background.png');
export const right_arrow = require('../assets/right-arrow.png');
export const add_icon = require('../assets/add.png')
export const dots = require('../assets/Right_Button.png')
export const search = require('../assets/search.png')
export const roundplus = require('../assets/roundplus.svg');
export const topRight = require("../assets/topRight.svg");
export const more = require("../assets/more.svg");
export const plus = require("../assets/pluse.svg");
export const sigma = require("../assets/sigma.svg");
export const down = require("../assets/down.svg");
export const diamond = require("../assets/diamond.svg");
export const shakehand = require("../assets/shakehand.svg");
export const dont = require("../assets/dont.svg");
export const question = require("../assets/question.svg");
export const comment = require("../assets/comment.svg");
export const right = require("../assets/right.svg");
export const bulbicon = require("../assets/bulbicon.svg");
export const cancleicon = require("../assets/cancleicon.svg");
export const bookicon = require("../assets/bookIcon.svg");
export const suitecaseicon = require("../assets/suitecaseicon.svg");
export const rightgreenicon = require("../assets/rightgreenicon.svg");
export const brifcaseBlueicon = require("../assets/brifcaseBlue.svg");
export const dollarIcon = require("../assets/dollar.svg");
export const crossBlueIcon = require("../assets/crossBlue.svg");
export const dropIcon = require("../assets/drop.svg");
export const editBlueIcon = require("../assets/editBlueIcon.svg");
export const addRoundIcon = require("../assets/addround.svg");
export const penIcon = require("../assets/pen.svg");
export const deleteIcon = require("../assets/delete.svg");
export const circleIcon = require("../assets/circle.svg");
export const bgRightTop = require("../assets/bg_right_top.svg");
export const sigma0 = require("../assets/sigma0.svg");
export const sigma1 = require("../assets/sigma1.svg");
export const down0 = require("../assets/down0.svg");
export const down1 = require("../assets/down1.svg");
export const applied0 = require("../assets/applied0.svg");
export const applied1 = require("../assets/applied1.svg");
export const hand0 = require("../assets/hand0.svg");
export const hand1 = require("../assets/hand1.svg");
export const reject0 = require("../assets/reject0.svg");
export const reject1 = require("../assets/reject1.svg");
export const question0 = require("../assets/question0.svg");
export const question1 = require("../assets/question1.svg");
export const chat0 = require("../assets/chat0.svg");
export const chat1 = require("../assets/chat1.svg");
export const dright0 = require("../assets/dright0.svg");
export const dright1 = require("../assets/dright1.svg");
export const love0 = require("../assets/love0.svg");
export const love1 = require("../assets/love1.svg");
export const clockIcon = require("../assets/clockTable.svg");
export const downOpenIcon = require("../assets/downOpen.svg");


// icons
export const icons = {
  back: 'chevron-left',
  business: 'business',
  photo_camera: 'photo-camera',
  edit: 'edit',
  check: 'check',
  share: 'share',
  more_vert: 'more-vert',
  location_on: 'location-on',
  close: 'close',
  search: 'search',
  date_range: 'date-range',
  navigate_next: 'navigate-next',
  videocam: 'videocam',
  add_circle_outline: 'add-circle-outline',
  play_arrow: 'play-arrow',
  plus: 'plus',
  cached: 'cached',
  loop: 'loop',
  flash_off: 'flash-off',
  flash_on: 'flash-on',
  add: 'add',
  expand_more: 'expand-more',
  calendar: 'calendar'
};
// Customizable Area End
