import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { StripeElements, Stripe, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent, StripeCardCvcElementChangeEvent } from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { handleNavigation, isTwoDaysOrLessRemaining, scrollToClassName } from "../../../../packages/components/src/Utils.web";

interface Plan {
  header: string;
  title: string;
  logo: string;
  price: string;
  details: string;
  listItems: { text: string }[];
}

interface Plan1 {
  header1: string;
  title1: string;
  logo1: string;
  price1: string;
  details1: string;
  listItems1: { text: string }[];
}

export type Subscription = {
  id: string;
  type: string;
  attributes: {
      name: string;
      price: string;
      description: string;
      user_type: string;
      subscription_type: string;
      chat_limits: null;
      image_link: string;
      features: {
          id: number;
          name: string;
          description: string;
          subscription_id: number;
      }[];
      subscribed: boolean;
  };
};

type SubscriptionFeature = {
  id: number;
  name: string;
  description: string;
  subscription_id: number;
};

type SubscriptionDetails = {
  id: number;
  name: string;
  price: string;
  chat_limits: number;
  description: string;
  features: SubscriptionFeature[];
};

type UserSubscriptionAttributes = {
  start_date: string;
  end_date: string;
  status: string;
  user_type: string;
  subscription_details: SubscriptionDetails;
  previous_subscription_details: SubscriptionDetails;
};

type UserSubscriptionResponse = {
  id: string;
  type: string;
  attributes: UserSubscriptionAttributes;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: Subscription[];
  data: any;
  role:string;
  stepActive:string;
  membershipTitle:string;
  membershipDetails:string;
  membershipPrice:string;
  error: string | undefined;
  success: boolean;
  userSubscriptionId: string;
  errorsPayment: {
    card: string;
    date: string;
    cvv: string;
    cardHolder: string;
  },
  cardHolder: string;
  loading: boolean;
  isUpgrading: boolean;
  subscriptionDetails: UserSubscriptionResponse;
  newPrice: string;
  pageLoader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  subscribePlanCallId: Message |string = "";
  createPaymentDataApiId: string = "";
  getUserSubscriptionDetailsApiId: string = "";
  getCheckourPriceApiId: string = "";
  subscribePlanApiId: string = "";
  getSubscriptionByIdCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      role: "",
      stepActive: 'plans',
      membershipTitle:'',
      membershipDetails:'',
      membershipPrice:'',
      error: undefined,
      success: false,
      userSubscriptionId: "",
      errorsPayment: {
        card: "",
        cvv: "",
        date: "",
        cardHolder: ""
      },
      cardHolder: "",
      loading: false,
      isUpgrading: false,
      subscriptionDetails: {} as UserSubscriptionResponse,
      newPrice: "",
      pageLoader: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsListOne();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getSubscriptionByIdCallId:
            return this.handleSubscriptionDataResponse(responseJson);

          case this.subscribePlanApiId:
            return this.handleSubscribeResponse(responseJson);

          case this.getCheckourPriceApiId:
            this.setState({ newPrice: responseJson?.amount_to_charge ?? "" });
            return;

          case this.getUserSubscriptionDetailsApiId:
            return this.handleUserSubscriptionDetails(responseJson);

          case this.getListCallId:
            this.setState({ subscriptions: responseJson.subscriptions.data });
            return;

          case this.createPaymentDataApiId:
            this.setState({ loading: false });
            this.handlePaymentUrl(responseJson);
            return;

          default:
            return null;
        }

      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const paymentResponse = this.props.navigation.getParam("paymentResponse");
    const subscriptionId = this.props.navigation.getParam("subscriptionId");

    if(paymentResponse === "success") {
      this.setState({
        pageLoader: true
      })
      this.getSubscriptionById(subscriptionId)
      this.onClickDetailsProceed(true);
    } else if(paymentResponse === "error" || paymentResponse === "cancel") {
      this.getSubscriptionById(subscriptionId)
      this.onClickDetailsProceed(false);
      this.setState({
        pageLoader: true
      })
    }

    this.fetchSubscriptionsListOne()
    const userRole = await getStorageData("userRole");
    this.setState({ role: userRole });
    this.getUserSubscriptionDetails();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start

  handlePaymentUrl = (response: {checkout_session_id: [string, {url: string}]}) => {
    if(Number(this.state.membershipPrice) === 0) {
      this.subscribePlan()
    } else if (response.checkout_session_id && response.checkout_session_id.length > 0) {
      const url = response.checkout_session_id[1].url
      handleNavigation(url)
    }
  }

  handleUserSubscriptionDetails = (responseJson: {user_subscription: {data: UserSubscriptionResponse}}) => {
    if(responseJson?.user_subscription.data && responseJson.user_subscription.data.attributes.status !== "cancelled"){
      this.setState({
        subscriptionDetails: responseJson.user_subscription.data
      })
    }
  }

  handleSubscribeResponse = (responseJson: {message: string, error: string}) => {
    if(responseJson.message) {
      return this.onClickDetailsProceed(true);
    }
    this.onClickDetailsProceed(false);
  }

  handleSubscriptionDataResponse = (responseJson: {subscription: {data: {id: number, attributes: SubscriptionDetails}}}) => {
    if(this.state.pageLoader) {
      this.setState({ 
        pageLoader: false,
        membershipTitle: responseJson.subscription.data.attributes.name, 
        membershipDetails: responseJson.subscription.data.attributes.description, 
        membershipPrice: responseJson.subscription.data.attributes.price, 
        userSubscriptionId: responseJson.subscription.data.id.toString(), 
       });
       return;
    }
    this.navigateToHome()
  }

  getUserSubscriptionDetails = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserSubscriptionDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserSubscriptionDetailsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getPriceDetails = async (subscriptionId: string) => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCheckourPriceApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_payments/payments/get_calculate_price?new_subscription_id=${subscriptionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangeHolderName = (value: string = "") => {
    if (value === "") {
      this.handleChangeCardField("cardHolder", { error: { message: "Cardholder Name is a required field." } });
    } else {
      this.handleChangeCardField("cardHolder", { error: { message: "" } });
    }
    this.setState({
      cardHolder: value
    });
  }

  handleSubmit = async (event: React.FormEvent, stripe: Stripe | null, elements: StripeElements | null) => {
    event.preventDefault();
    this.setState({
      loading: true
    })
    this.createPayment()
  };

  navigateToHome = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(msg);
  }

  createPayment = async () => {
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: await getStorageData("authToken")
    };

    let formData = new FormData();
    formData.append("payment[subscription_id]", this.state.userSubscriptionId);
    formData.append("payment[currency]", "usd");

    if(this.state.isUpgrading && this.state.newPrice) {
      formData.append("payment[is_upgrade]", "true");
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPaymentDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_payments/payments/create_payment"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getButtonTitle = (plan: Subscription): { buttonTitle: string, isActive: boolean, tooltipTitle: string, background: string, isUpgrading: boolean } => {
    let currentSubscriptionId;
    let buttonTitle = "Subscribe now";
    let isActive = false;
    let tooltipTitle = "";
    let background = "rgb(109, 151, 193)";
    let isUpgrading = false;

    if (Object.keys(this.state.subscriptionDetails).length > 0) {
      currentSubscriptionId = this.state.subscriptionDetails.attributes.subscription_details.id;
      isUpgrading = isTwoDaysOrLessRemaining(this.state.subscriptionDetails!.attributes.end_date);
      if (currentSubscriptionId === +plan.id) {
        buttonTitle = "Subscribed";
        isActive = true;
        background = "rgb(52, 211, 153)"
      } else {
        if (currentSubscriptionId) {
          if (+plan.attributes.price <= +this.state.subscriptionDetails!.attributes.subscription_details.price) {
            buttonTitle = "Subscribe"
            tooltipTitle = "You can subscribe to the plan within 2 days before your current subscription expires."
            background = "#9c9c9c"
            isActive = true;
            if (isUpgrading) {
              isActive = false;
              buttonTitle = "Subscribe now";
              background = "rgb(109, 151, 193)";
              tooltipTitle = ""
            }
          } else {
            buttonTitle = "Upgrade now"
          }
        }
      }
    }
    return { buttonTitle, isActive, tooltipTitle, background, isUpgrading };
  }

  allPlansUnsubscribed = () => {
    return !this.state.subscriptions.some((plan) => plan.attributes.subscribed)
   }

  fetchSubscriptionsListOne = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubscriptionAPiEndPoint}${this.state.role}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangeCardField = (name: "card" | "date" | "cvv" | "cardHolder", error: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent | { error: { message: string } }) => {
    this.setState({
      errorsPayment: {
        ...this.state.errorsPayment,
        [name]: error.error?.message
      }
    })
  }

  onClickSubscribe = (membershipTitle: string, membershipDetails: string, membershipPrice: string, subscriptionId: string, isUpgrading: boolean) => {
    scrollToClassName('.bodyOfApp');
    this.setState({ membershipTitle: membershipTitle, membershipDetails: membershipDetails, membershipPrice: membershipPrice, userSubscriptionId: subscriptionId, isUpgrading });
    if(Object.keys(this.state.subscriptionDetails).length > 0 && !isTwoDaysOrLessRemaining(this.state.subscriptionDetails!.attributes.end_date)) {
      this.getPriceDetails(subscriptionId);
    }
    this.onClickConfirmationBack()
  }

  onClickDetailsProceed = (value: boolean) => {
    scrollToClassName('.bodyOfApp');
    this.setState({ stepActive: 'paymentConfirmation', success: value, loading:true, cardHolder: "" });
  }

  onClickDetailsBack = () => {
    scrollToClassName('.bodyOfApp');
    this.setState({ stepActive: 'plans' });
  }

  onClickConfirmationBack = () => {
    this.setState({ stepActive: 'paymentDetails' });
  }

  subscribePlan = async () => {
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: await getStorageData("authToken")
    };

    let formData = new FormData();
    formData.append("subscription_id", this.state.userSubscriptionId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subscribePlanApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscribeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubscriptionById = async(subscriptionId: string) => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionByIdCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subscriptionAPiEndPoint}/${subscriptionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
