import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { searchIcon } from './assets'
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const globalCss = `
          .inputDropdown:hover {
              background-color: rgba(109, 151, 193, 0.2);
              border-radius: 20px;
            }
    `;

    return (
      <ThemeProvider theme={theme}>
        <Container style={{padding:'0px'}} maxWidth="md">
          {/* Customizable Area Start */}
          <div ref={this.divRef}>
            <style>{globalCss}</style>
            <div style={{display:'flex', gap:'13px', position:'relative'}}>
              <input
                data-test-id="searchInput"
                type="text" id="search" name="fname" placeholder='Search here'
                data-text-id='inputSearchText'
                style={{
                  width:"100%", borderRadius:'40px', height:'42px',
                  border:'1px solid rgba(235, 233, 233, 1)', padding:'10px 23px', outline:'none',
                  fontFamily:'Arial', backgroundColor:'#F4F4F4', color:'#C0C0C0', 
                  fontSize:'16px', fontWeight:400, boxShadow:'0px 15px 10px -15px rgba(24, 57, 98, 0.1)', paddingLeft:'45px'
                }}
                onKeyUp={(event) => this.handleSearch(event)}
              />
              <img 
                src={searchIcon}
                alt="search"
                style={{position:'absolute', top:'14px', left:'20px'}}
              />
            </div>
            { (this.state.showSearchResults && this.state.searchText.length>0) || this.state.loading ? 
              <div
                style={{ 
                  width:'100%', maxHeight:'262px', borderRadius:'16px', zIndex:99,
                  border:'1px solid #CBD5E1',  position:'absolute',
                  backgroundColor:'#FFFFFF', overflow:'auto', cursor:'pointer', padding: '4px 0'
                }}
              > 
              { this.state.getAllSearchResults && this.state.getAllSearchResults.accounts.data.map((item:any, indexValue:number) => (
                (item.attributes.profile_details.data.attributes.full_name ||
                  item.attributes.profile_details.data.attributes.current_title
                ) && (
                    <div
                      className="inputDropdown"
                      style={{
                        height:'45px', borderBottom:'1px solid #F4F4F4',
                        display:'flex', flexDirection:'column', padding:'8px 14px', gap:'8px',
                        borderRadius:'16px', marginRight: "5px", marginLeft: "5px", justifyContent: 'center'
                      }}
                      onClick={() => this.handleNavigateToSearchResult()}
                    >
                        <div
                          style={{fontFamily:'Arial', fontWeight:400, fontSize:'16px', color:'#1F497D', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}
                        >
                          {item.attributes?.profile_details?.data.attributes?.full_name}
                        </div>
                        <div
                          style={{fontFamily:'Arial', fontWeight:400, fontSize:'14px', color:'#9C9C9C', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}
                        >
                          {item.attributes?.profile_details?.data?.attributes?.current_title}
                        </div>
                    </div>
                )))
              }
              { this.state?.getAllSearchResults?.jobs?.data.map((item:any, indexValue:number) => (
                (item.attributes?.job_title || item.attributes?.job_description) && (
                    <div
                      data-test-id="searchResultList"
                      className="inputDropdown"
                        style={{
                        width:'95%', height:'62px', borderBottom:'1px solid #F4F4F4',
                        display:'flex', flexDirection:'column', padding:'8px 14px', gap:'12px',
                        borderRadius:'20px'
                      }}
                      onClick={() => this.handleNavigateToSearchResult()}
                    >
                        <div
                          style={{fontFamily:'Arial', fontWeight:400, fontSize:'16px', color:'#1F497D', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}
                        >
                          {item.attributes?.job_title}
                        </div>
                        <div
                          style={{fontFamily:'Arial', fontWeight:400, fontSize:'14px', color:'#9C9C9C', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}
                        >
                          {item?.attributes?.job_description}
                        </div>
                    </div>
                )))
              }
              { this.state?.getAllSearchResults?.posts?.data.map((item:any, indexValue:number) => (
                (item.attributes?.account.account_name || item.attributes?.body) && (
                    <div
                        className="inputDropdown"
                        style={{
                        width:'95%', height:'62px', borderBottom:'1px solid #F4F4F4',
                        display:'flex', flexDirection:'column', padding:'8px 14px', gap:'12px',
                        borderRadius:'20px'
                      }}
                      onClick={() => this.handleNavigateToSearchResult()}
                    >
                        <div
                          style={{fontFamily:'Arial', fontWeight:400, fontSize:'16px', color:'#1F497D', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}
                        >
                          {item?.attributes?.account?.account_name}
                        </div>
                        <div
                          style={{fontFamily:'Arial', fontWeight:400, fontSize:'14px', color:'#9C9C9C', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}
                        >
                          {item?.attributes?.body}
                        </div>
                    </div>
                  )))
              }
              {this.state.loading && <div style={{margin: "auto", textAlign: "center", display: "flex", justifyContent: "center", height: "100px", alignItems: "center"}}>
                <CircularProgress style={{ color: '#1F497D', width: "30px", height: "30px" }} />
              </div>}

              </div> : <></>
          }
          {/* Customizable End Start */}
          </div>
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
// Customizable Area End
