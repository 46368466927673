import React from "react";

// Customizable Area Start
import { Helmet } from 'react-helmet';
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  ClickAwayListener,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { backgroundImg, dropdown } from "./assets";
import * as Yup from 'yup';
import { Formik } from "formik";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { ToastContainer } from "react-toastify";
import { META_TAGS } from '../../../../packages/components/src/Utils.web';

const LoginBlock = styled(Box)({
  '& .heading': {
    '@media (max-width: 960px)': {
      marginLeft: '0 !important',marginTop: '30px',textAlign: 'center'
    },
  },
  '& .bgImage': {
    '@media (max-width: 960px)': {display: 'none'}
  },
  '& .formContainer': {
    paddingLeft: '20px', 
    paddingRight: '20px', 
    flexGrow: 0, 
    maxWidth: '37%', 
    flexBasis: "37%", 
    display: "flex", 
    alignItems: "center",
    '@media (max-width: 960px)': {
      flexGrow: 0,
      maxWidth: '83.333333%',
      flexBasis: '83.333333%',
    }
  },
  "& .rememberMe" : {
    "& .MuiSvgIcon-fontSizeSmall" : {
      height: "22px",
      width: "22px"
    }
  },
  "& .roleInput": {
    "& .MuiOutlinedInput-inputAdornedEnd": {
      cursor: "pointer",
      caretColor: "transparent"
    }
  }
})

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
}
  // Customizable Area Start
  validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter correct mail id').required('Email is required'),
    role: Yup.string().required('Role is required'),
    password: Yup.string().required('Password is required')
  });
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta
            name="description"
            content={`
            Connect with Venture Capital Leaders on Our Platform 
            Infrastructure Investment Opportunities on Our Platform 
            Recruit Top Private Equity Talent: Join Our Platform 
            Hire Growth Equity Professionals: Join Our Network 
          `}
          />
          <meta name="keywords"
            content={META_TAGS}
          />
        </Helmet>
        <Container maxWidth={false} style={{ width: '100%', 
        padding: '0px' }}>
          <LoginBlock
            sx={{
              bgcolor: '#6D97C1', height: '100vh',display: { md: 'flex', lg: 'flex', xl: 'flex' },
              justifyContent: 'center',alignItems: 'center'
            }}>
            <Grid container justifyContent="center" style={{ background: '#6D97C1', 
            flexWrap: 'wrap-reverse' }}>
              <Grid item xs={10} className="formContainer">
                <Box
                  sx={{
                    bgcolor: '#EEECE1',padding: '40px',borderRadius: '20px', color: "#1F497D"
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography style={{
                        color: "#1F497D",fontSize: "24px",
                        fontStyle: "normal",fontFamily: "Arial",
                        fontWeight: 500,
                      }}>
                        {this.labelTitle}
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '10px' }}>
                      <Typography style={{
                        color: "#6D97C1",fontSize: "13px", fontStyle: "normal",fontWeight: 400, fontFamily: "Arial"
                      }}>
                        New member? <span
                          onClick={() => this.goToSignUpPage()}
                          style={{
                            color: "#1F497D",
                            fontSize: "15px", fontStyle: "normal", fontWeight: 500,
                            cursor: "pointer", fontFamily: "Arial"
                          }}>Sign Up</span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Formik
                    enableReinitialize={true}
                    data-testid='formik'
                    initialValues={{
                      fname: this.handleCondition(this.state.prefilledData.fname, this.state.prefilledData.fname, ''),
                      email: this.handleCondition(this.state.prefilledData.email, this.state.prefilledData.email, ''),
                      password: this.handleCondition(this.state.prefilledData.password, this.state.prefilledData.password, ''),
                      roleId: this.handleCondition(this.state.avilableRoles.filter((item) => item.id === this.state.prefilledData.roleId)[0]?.id, this.state.avilableRoles.filter((item) => item.id === this.state.prefilledData.roleId)[0]?.id, ""),
                      role: this.handleCondition(this.state.avilableRoles.filter((item) => item.id === this.state.prefilledData.roleId)[0]?.attributes?.name === "Candidate", 'Job Applicant', this.handleCondition(this.state.avilableRoles.filter((item) => item.id === this.state.prefilledData.roleId)[0]?.attributes?.name === "Recruiter", "Recruiter", "")),
                      checkbox: this.handleCondition(this.state.prefilledData.checkbox, this.state.prefilledData.checkbox, ''),
                    }}
                    validationSchema={this.validationSchema}
                    validateOnBlur={true}
                    validateOnChange={true}
                    onSubmit={(values, actions) => {
                      this.handleLoginSubmit(values);
                    }}
                  >
                    {({ setFieldValue, values, touched, errors, handleChange, handleSubmit, handleBlur, setTouched }) => (
                      <form onSubmit={handleSubmit}>
                        <Box sx={{ width: "100%", paddingTop: "22px" }}>
                          <Box sx={{ width: "100%", paddingTop: "20px" }}>
                            <Typography style={{
                              fontSize: "14px", fontFamily: "Arial",fontWeight: 700, color: "#1F497D", marginBottom: "4px"
                            }}>Email</Typography>
                            <TextField variant="outlined"
                              inputProps={{
                                style: {
                                  padding: '12px 10px',  background: '#FFF',fontSize: '12.5px',borderRadius: "8px"
                                }
                              }}
                              id="email-field"
                              name="email"
                              value={values.email}
                              onChange={handleChange("email")}
                              onBlur={handleBlur("email")}
                              onFocus={() => setTouched({...touched, email: true})}
                              error={this.handleCondition(touched?.email, errors.email, "")}
                              fullWidth placeholder="Enter your email here" />
                              {this.handleCondition(this.handleCondition(touched?.email, errors.email, ""), 
                              <Typography style={{ color: 'red',
                              fontSize: "0.8rem",
                               textAlign: 'right',
                                display: 'block ruby',
                                 fontWeight: 400, fontFamily: "Arial"
                                  }}>{errors.email}</Typography>, 
                              "")}
                          </Box>
                          <Box sx={{ width: "100%", paddingTop: "20px" }}>
                            <Typography style={{ fontSize: "14px",
                             fontWeight: 700, fontFamily: "Arial", marginBottom: "4px" }}>Password</Typography>
                            <FormControl variant="outlined" fullWidth size="small" style={{ 
                              background: '#FFF', borderRadius: "8px" }}>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                placeholder="*************"
                                type={this.state.showPass ? 'text' : 'password'}
                                name="password"
                                className="password-input"
                                value={values.password}
                                error={!!(touched?.password && (errors.password || this.state.passwordError))}
                                onChange={handleChange("password")}
                                onFocus={() => setTouched({...touched, password: true})}
                                inputProps={{
                                  style: {fontSize: '12.5px', }
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      data-test-id={"btnEyeVisibility"}
                                      aria-label="toggle password visibility"
                                      onClick={() => this.handleShowPass(!this.state.showPass)}
                                      edge="end"
                                    >
                                      {this.handleCondition(this.state.showPass, <VisibilityOff />, <Visibility />)}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                            {this.handleCondition(this.handleCondition(touched?.password || this.state.passwordError, errors.password || this.state.passwordError, ""), 
                              <Typography style={{ color: 'red', fontSize: "0.8rem",
                               textAlign: 'right',fontWeight: 400,
                               wordWrap: 'break-word',
                               overflowWrap: 'break-word',
                               whiteSpace: 'normal',
                               maxWidth: '100%',
                               fontFamily: "Arial"
                              }}>{errors.password || this.state.passwordError}</Typography>, 
                              "")}
                          </Box>
                          <Box sx={{ width: "100%", paddingTop: "20px" }}>
                            <Typography style={{ fontSize: "14px", 
                            fontWeight: 700, fontFamily: "Arial", marginBottom: "4px" }} >Roles</Typography>
                            <ClickAwayListener onClickAway={this.handleRoleClose}>
                            <TextField variant="outlined"
                              className="roleInput"
                              fullWidth size="small"
                              onClick={this.handleRoleClick}
                              name="role"
                              id="roleInputField"
                              onFocus={() => setTouched({...touched, role: true})}
                              value={values.role}
                              style={{ background: '#FFF', borderRadius: "8px", fontSize: "14px", fontFamily: "Arial", cursor: "pointer"}}
                              error={!!(touched?.role && errors.role)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                    <img src={dropdown} alt="dropdown" onClick={this.handleRoleClick}/>
                                </InputAdornment>
                              ),
                              style: {
                                cursor: "pointer", fontFamily: 'Arial', fontWeight: '400', fontSize: "14px", color: this.handleCondition(values.role, '#64748B', '#A6B0BF')
                              }
                            }}>
                            </TextField>
                            </ClickAwayListener>
                            <Box style={{ position: "relative" ,paddingTop:"2px",}}>
                              {this.handleCondition(this.state.rolesOpen, <Box style={{ position: "absolute", 
                                width: "100%", zIndex: 2,background: "#fff",
                                border:"1px solid #CBD5E1", borderRadius:"8px", cursor: "pointer" }}>
                                  {this.state.avilableRoles.map((item: {id: number, attributes: {name: string}}) => {
                                    return <MenuItem
                                      value={values.role}
                                      data-test-id="roless"
                                      onClick={() => {
                                        setFieldValue("roleId", item.id);
                                        setFieldValue("role", item.attributes.name === 'Candidate' 
                                        ? 'Job Applicant' : item.attributes.name);
                                        this.setState({ rolesOpen: false });
                                      }}
                                      style={{ fontSize: '16px', fontFamily: 'Arial', color: "#64748B", fontWeight: '400' }}>
                                      {item?.attributes?.name === 'Candidate' ? 'Job Applicant' : item?.attributes?.name}
                                    </MenuItem>
                                  })}
                                </Box>, "")}
                            </Box>
                            {this.handleCondition(this.handleCondition(touched?.role, errors.role, ""), 
                              <Typography style={{ color: 'red',fontSize: "0.8rem",
                               textAlign: 'right', display: 'block ruby',fontWeight: 400, fontFamily: "Arial"
                                  }}>{errors.role}</Typography>, 
                              "")}
                          </Box>
                          <Grid container justifyContent="space-between" style={{ marginTop: "20px" }}>
                            <Grid item>
                              <FormGroup className="rememberMe">
                                <FormControlLabel
                                  style={{ color: "#1F497D" }}
                                  control={<Checkbox icon={<span style={{ borderRadius: 2, height: 15, width: 16, border: '1px solid #CBD5E1', backgroundColor: 'white' }} />} checked={values.checkbox} style={{ color: "#CBD5E1", padding: '0', margin: '9px', backgroundColor: 'white', height: '16px', width: '16px' }} onChange={(event) => { setFieldValue('checkbox', event.target.checked) }} size="small" />}
                                  label={<Typography style={{ fontSize: "14px", fontFamily: "Arial" }}>Remember Me</Typography>} />
                              </FormGroup>
                            </Grid>
                            <Grid item>
                              <Typography
                                data-test-id={"btnForgotPassword"}
                                style={{
                                  cursor: "pointer", fontSize: "14px",
                                  color: "#1F497D",  marginTop: "6px",fontFamily: "Arial"
                                }}
                                onClick={() => this.goToForgotPassword()}
                              >
                                Forgot Password?
                              </Typography>
                            </Grid>
                          </Grid>

                          <Box style={{ marginTop: '33px', 
                          display: 'flex' }}>
                            <Grid item md={9}>
                              <Typography style={{ color: "#6D97C1", fontSize: '14px', 
                              marginRight: "20px", fontFamily: 'Arial' }}>
                                Create your account or login. By continuing, you agree to our <span style={{ color: "#1F497D", 
                                cursor: 'pointer' }} data-test-id="terms" onClick={() => this.goToTermsPage('terms')}>Terms of Use</span>{` and `}
                                <span style={{ color: "#1F497D", cursor: 'pointer' }} data-test-id="privacy" onClick={() => this.goToTermsPage('privacy')}>Privacy Policy.</span>
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Button
                                data-test-id={"btnEmailLogIn"}
                                type="submit"
                                variant="contained"
                                style={{
                                  background: this.handleCondition(!this.disableButton(values), "#6D97C1", "#CBD5E1"),
                                  color: "white",textTransform: 'capitalize',
                                  fontSize: "16px", fontWeight: 700, padding: "16px", fontFamily: "Arial"
                                }}
                                fullWidth
                                disabled={this.disableButton(values)}
                              >
                                Login {this.handleCondition(this.state.verifyLoader, <CircularProgress style={{ color: 'white', marginLeft: '12px', width: "16px", height: undefined }}  />, "")}
                              </Button>
                            </Grid>
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Grid>
              <Grid item xs={10} style={{ marginLeft: '5%', flexGrow: 0, maxWidth: '37%', flexBasis: "37%" }} className="heading">
                <Typography
                  style={{
                    color: '#FFFFFF', fontSize: '54px', fontWeight: 600,fontFamily: 'Arial'
                  }}
                >
                  Work Centivo
                </Typography>
                <img
                  src={backgroundImg}
                  alt="login"
                  height="530px"
                  className="bgImage"
                />
              </Grid>
            </Grid>
          </LoginBlock>
        </Container>
        <ToastContainer />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
