import { Box, Button, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { showAll, topRight } from "../../src/assets";
import { Post, UserDetails } from "../ProfilePageForRecruiterController.web";
import CustomPost from "./CustomPost.web";

interface Activity {
    posts: [],
    comments: [],
    reactions: [],
}

interface AllActivityProps {
    activityType: string;
    handleUpdateActivityType: (status: string) => void;
    activity: Activity;
    upvotePost: (postId: number | string, type: string, activityType: string) => void;
    downvotePost: (postId: number | string, type: string, activityType: string) => void;
    toggleComments: (post: Post) => void;
    postShowID: number | string;
    createComment: () => void;
    upvotePostComment: (postId: number | string, type: string) => void;
    downvotePostComment: (postId: number | string, type: string) => void;
    userDetails: UserDetails;
    handleFeedModalOpen: (post?: Post) => void;
    handleSavePostApi: (postId: number | string, isSaved?: boolean) => void;
    handleShowAllPosts: () => void;
    openConfirmDialog: (receiverId: number | string, menuType?: 'connect' | 'following' | 'hidePost' | 'reportPost', invitationId?: number | string | boolean) => void;
    getInitials: (status?: string | undefined) => string;
    updateComment: () => void;
    handleDeleteComment: (commentId: number | string) => void;
    progressComment: number,
    remainingTimeComment: number,
    loaderComment: boolean,
    expandedPosts?: string[],
    toggleReadMore?: (postId: string) => void,  
    handleUserNavigation: (accountId: number | string) => void
}

export default class AllActivity extends Component<AllActivityProps> {
    constructor(props: AllActivityProps) {
        super(props);
    }
    render() {
        return (
            <Box style={{ ...webstyle.item, position: 'relative' }} className="allActivityInnerBox">
                <img src={topRight} alt="icons" style={webstyle.topRight} />
                <Typography style={webstyle.blockHeading1}>All Activity</Typography>
                <Box>
                    <Button
                        data-test-id="postsButton"
                        style={{
                            ...webstyle.activityButton, textTransform: 'none', marginLeft: '0',
                            ...(this.props.activityType === "posts" ? {} : webstyle.inactiveButton)
                        }}
                        onClick={() => this.props.handleUpdateActivityType("posts")}
                    >
                        Posts
                    </Button>
                    <Button
                        data-test-id="commentsButton"
                        style={{
                            ...webstyle.activityButton,
                            margin: '10px 8px',
                            textTransform: 'none',
                            ...(this.props.activityType === "comments" ? {} : webstyle.inactiveButton)
                        }}
                        onClick={() => this.props.handleUpdateActivityType("comments")}
                    >
                        Comments
                    </Button>
                    <Button
                        data-test-id="reactionsButton"
                        style={{
                            ...webstyle.activityButton,
                            textTransform: 'none',
                            ...(this.props.activityType === "reactions" ? {} : webstyle.inactiveButton)
                        }}
                        onClick={() => this.props.handleUpdateActivityType("reactions")}
                    >
                        Reactions
                    </Button>
                </Box>
                {
                    (this.props.activityType === 'posts' || this.props.activityType === 'comments' || this.props.activityType === 'reactions') &&
                        (this.props.activity[this.props.activityType].length > 0) ?
                        <>
                            <CustomPost
                                data-test-id="activityList"
                                posts={this.props.activity[this.props.activityType].slice(0, 2)}
                                upvotePost={this.props.upvotePost}
                                downvotePost={this.props.downvotePost}
                                activityType={this.props.activityType}
                                toggleComments={this.props.toggleComments}
                                postShowID={this.props.postShowID}
                                createComment={this.props.createComment}
                                upvotePostComment={this.props.upvotePostComment}
                                downvotePostComment={this.props.downvotePostComment}
                                userDetails={this.props.userDetails}
                                openEditFeedModal={this.props.handleFeedModalOpen}
                                handleSavePost={this.props.handleSavePostApi}
                                getInitials={this.props.getInitials}
                                isAdvanceSearch={false}
                                openConfirmDialog={this.props.openConfirmDialog}
                                updateComment={this.props.updateComment}
                                handleDeleteComment={this.props.handleDeleteComment}
                                progressComment = {this.props.progressComment}
                                remainingTimeComment = {this.props.remainingTimeComment}
                                loaderComment = {this.props.loaderComment}
                                expandedPosts={this.props.expandedPosts}
                                toggleReadMore={this.props.toggleReadMore}
                                showReadMore
                                handleNameClick={this.props.handleUserNavigation}
                            />
                            <Box style={webstyle.showAllPostActivityBox}>
                                <Box
                                    style={webstyle.showAllActivityText1}
                                    onClick={this.props.handleShowAllPosts}
                                >
                                    <Typography
                                        style={webstyle.showAllActivityText}>
                                        Show all {this.props.activityType}
                                    </Typography>
                                    <img src={showAll} />
                                </Box>
                            </Box>
                        </>
                        : <Box>
                            <Typography style={{ ...webstyle.blockHeading1, textAlign: "center", textTransform: "capitalize", marginTop: "4px" }}>No {this.props.activityType}</Typography>
                        </Box>
                }
            </Box>
        );
    }
}

const webstyle = {
    item: {
        background: "#ffffff",
        borderRadius: '20px',
        padding: '20px',
        minHeight: '160px'
    },
    blockHeading1: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#17365D'
    },
    topRight: {
        position: "absolute" as "absolute",
        top: "0px",
        right: "0px"
    },
    activityButton: {
        height: "24px",
        fontSize: '16px',
        fontWeight: '700',
        fontFamily: 'Arial',
        color: '#17365D',
        borderRadius: '20px',
        padding: '6px 10px 6px 10px',
        backgroundColor: '#C7D9F0',
        margin: '10px'
    },
    inactiveButton: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #6D97C1',
        fontSize: '14px',
        fontWeight: '400',
        color: "#9C9C9C"
    },
    showAllPostActivityBox: {
        display: 'flex',
        justifyContent: 'end',
        paddingTop: '10px'
    },
    showAllActivityText1: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    showAllActivityText: {
        fontFamily: 'Arial',
        fontWeight: '400',
        fontSize: '14px',
        color: '#1F497D',
        paddingRight: '10px'
    },
}