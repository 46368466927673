// Customizable Area Start
Object.defineProperty(exports, '__esModule', {
    value: true
});


exports.compnaypagetitle = 'COMPANY PAGE';

exports.getcompanyApiContentType = 'application/json';
exports.getCompanyEndPoint = 'bx_block_joblisting/company_pages';
exports.getcompanyApiMethod = 'GET';
exports.ApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.searchJobApiEndPoint = "bx_block_search/search_jobs";
exports.searchPeopleApiEndPoint = "bx_block_search/search_people";
exports.searchCompanyApiEndPoint = "bx_block_search/search_companies";
exports.getDealExperienceEndPoint = "bx_block_contentflag/contents"
exports.checkForSubscriptionAPI = "bx_block_subscriptions/subscriptions/user_subscription_details"

exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'JobListing';
exports.labelBodyText = 'JobListing Body';
exports.emptyList = 'No Data Found';
exports.btnExampleTitle = 'CLICK ME';
exports.freePlanLimit = 10;
exports.maxLimit = 100000

// Manage people
exports.pageMemberLabel = 'Company page admins';
exports.pageMemberNote = 'View, Edit or Add member to you company page';

// add people
exports.addPeople = 'ADD PEOPLE';

// buttons
exports.continue_btn = 'Continue';
exports.continuetopage = 'Go to company page';
exports.addMember = 'Add New Admin';
exports.createJobApiEndPoint = "/bx_block_joblisting/jobs";
exports.getAllCreatedJobs = "/bx_block_joblisting/jobs/recruiter_job_listing";
exports.httpDeleteMethod = "DELETE";
exports.httpPutMethod = "PUT";
exports.jobStatusUpdate = "/bx_block_joblisting/applied_jobs/manage_job_applicants";
exports.skillsListingApi = "bx_block_joblisting/jobs/skills_listing";
exports.addCandidateIntoTalentPool = "/bx_block_cfrecruitersdashboard/talent_pipelines";
exports.getTalentPoolCandidates = "/bx_block_cfrecruitersdashboard/talent_pipelines?page=1&per_page=10000000000";
exports.removeCandidateFromTalentPool = "/bx_block_cfrecruitersdashboard/talent_pipelines/"
exports.subscriptionUrl="bx_block_joblisting/jobs/check_create_job_access"
exports.experienceFILTER = [
    { value: "0-2 Years", label: "0-2 Years" },
    { value: "2-4 Years", label: "2-4 Years" },
    { value: "4-6 Years", label: "4-6 Years" },
    { value: "6-8 Years", label: "6-8 Years" },
    { value: "8-10 Years", label: "8-10 Years" },
    { value: "10+ Years", label: "10+ Years" }
];
exports.globalCSS = `
.talentpool-card-user:hover {
    border: 1px solid #1F497D !important;
}
.MuiTabs-root .MuiTab-root {
    min-width: 72px !important;
}

@media (min-width: 599px) {
    .MuiTabs-root .MuiTab-root {
        
    }
}

.MuiTabs-indicator {
    background-color: #6D97C1 !important;
    height: 3px !important;
}

.MuiTabs-root {
    align-items: center;
}

.cardContainer::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  .cardContainer::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #D9D9D9;
    height: 500px
  }
   
  /* Handle */
  .cardContainer::-webkit-scrollbar-thumb {
    background: #1F497D; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .cardContainer::-webkit-scrollbar-thumb:hover {
    background: #1F497D; 
  }

@media (max-width: 599.95px){
    .MuiTabs-scrollButtonsDesktop {
        display: block !important;
    }
}
.app-tab {
    width: 100%;
}

.app-tab .MuiTabs-flexContainer {
    justify-content: space-between !important
}
.app-tab .MuiTabs-flexContainer .app-tab-main {
    min-width: 50% !important;
    flex: 1;
    width: 100%;
}

@media (max-width: 960px){
    .right-container {
        padding-right: 12px !important
    }
    .left-container {
        padding-left: 12px !important
    }
}

   
@media (max-width: 700px){
    .cardContainer {
        padding: 0px !important;
    }
}

`;
exports.globalCSS1 = `
   
@media (max-width: 700px){
    .cardContainer {
        padding: 0px !important;
    }
}

`;
// Customizable Area End
