import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldProps } from 'formik';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "8px",
            "& .MuiOutlinedInput-input": {
                "padding": "14px",
                borderRadius: "8px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#1F497D",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "&:hover": {
                    border: "0px solid #6D97C1",
                },
                "&:focus": {
                    border: "0px solid #1F497D",
                },
                "&::placeholder": {
                    "color": "#64748B !important",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "0px"
            },
            "& .MuiFormHelperText-root" : {
                fontSize: "12px",
                fontFamily: "Arial"
            }
        },

        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginBottom": "32px",
            "marginTop": "6px"
        }
    })
);

type Props = FieldProps & {
    placeHolder: string;
    title: string;
    multiline?: boolean;
    controlledValue?: string;
    onChangeControlled?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
};

const CustomTextFieldCommon: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    title,
    multiline = false,
    controlledValue,
    onChangeControlled
}) => {
    const classes = useStyles();

    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={field.name}
            >
                {title}
            </InputLabel>
            <TextField
                className={classes.root}
                fullWidth
                id={field.name}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { root: classes.root } }}
                error={!!form.errors[field.name] && !!form.touched[field.name]}
                helperText={
                    !!form.errors[field.name] &&
                    form.touched[field.name] &&
                    form.errors[field.name]
                }
                {...field}
                value={controlledValue ?? field.value}
                placeholder={placeHolder}
                multiline={multiline}
                onChange={onChangeControlled ?? field.onChange}
            />
        </>
    );
};

export default CustomTextFieldCommon;
