
import React, { CSSProperties } from "react";
// Customizable Area Start
import { Formik, Form, Field } from 'formik';
import { Grid, Avatar, IconButton, Button, Typography, Box, CircularProgress, InputAdornment, FormControl, withStyles, OutlinedInput, styled } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ICountry, Country } from 'country-state-city'
import Uploadmedia3 from '../../uploadmedia3/src/Uploadmedia3.web';
import OtpTimer from "../../../components/src/OtpTimer";
import OTPInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
// Customizable Area End

import UserProfileEdit1Controller, {
    Props,
} from "./UserProfileEdit1Controller";
import CustomTextField from "./components/CustomTextField";
import AutoCompleteInputText from "./components/AutoCompleteInputText";
import { bottomLeft, cross, imageImg, topRight, userProfileImg } from "./assets";
import { Autocomplete } from "@material-ui/lab";

export class UserProfileEdit1 extends UserProfileEdit1Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            // Customizable Area Start
            <>
                <Grid container style={webStyle.page}>
                    <Formik
                        initialValues={this.state.initialValues}
                        validationSchema={this.getValidationSchema()}
                        enableReinitialize
                        onSubmit={(values) => {
                                this.handleFormSubmit(values)
                        }}
                        validate={this.handleCurrentValues}
                    >
                        {({ values, errors, setFieldValue, setFieldError, touched, handleBlur, setFieldTouched, handleChange, setTouched }) => (
                            <Form style={{ width: "inherit" }}>
                                <Grid style={webStyle.form as CSSProperties}>
                                    <img src={topRight} alt="icons" style={webStyle.topRight as CSSProperties} />
                                    <img src={bottomLeft} alt="icons" style={webStyle.bottomLeft as CSSProperties} />
                                    <Grid container style={{ paddingLeft: "12px", paddingBottom: "20px" }}>
                                        <Grid item sm={12}>
                                            <Typography style={webStyle.cardTitle12}>
                                                {this.handleConditionRender(this.state.isFirstTimeLogin, "Update/Edit Profile", "Complete Profile")}
                                            </Typography>

                                        </Grid>
                                        <Grid item container sm={12}>
                                            <Grid item sm={12}>
                                                <IconButton
                                                    id='iconButton'
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    style={{ paddingBottom: "0px" }}
                                                >
                                                    <input
                                                        type="file"
                                                        hidden
                                                        name="photo"
                                                        accept="image/*"
                                                        id="group_image"
                                                        data-test-id="group_image"
                                                        onClick={this.handleProfileStateUpdate}
                                                        onChange={(event) => this.handleProfileImageChange(event, setFieldValue, setFieldError)}        
                                                    />
                                                    {this.handleConditionRender(values.photo instanceof File, 
                                                        <Avatar style={{ width: "90px", height: "90px" }} src={this.createUrl(values.photo) || userProfileImg}></Avatar>, 
                                                        <Avatar style={{ width: "90px", height: "90px", }} src={values.photo || userProfileImg}></Avatar>)}
                                                    {this.handleConditionRender(this.state.loader, <div style={{ position: 'absolute', top: '10px', left: '135px', bottom: '0px', right: '0px' }}>
                                                            <Uploadmedia3 progress={this.state.progress} time={this.state.remainingTime} {...this.props} />
                                                        </div>, <></>)}
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Typography style={webStyle.uploadTxt}><img src={imageImg} alt="icon" />Upload/Edit Image &nbsp;</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12}>
                                            {this.handleConditionRender(errors.photo, this.handleConditionRender(touched.photo, 
                                                <Typography style={webStyle.errorText}>
                                                {errors.photo}
                                            </Typography>, ""), "")}
                                        </Grid>
                                    </Grid>
                                    <Grid container style={webStyle.formfieldCont as CSSProperties} spacing={3} >
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="full_name"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Name"
                                                placeHolder="HJ"
                                                rootStyle={{ height: "10px !important" }}
                                                onBlur={() => {
                                                    setFieldTouched('full_name', true);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Typography
                                                style={webStyle.contactField} >Contact Number</Typography>
                                            <PhoneInputStyled
                                                containerStyle={{ height: "44px" }}
                                                dropdownStyle={{
                                                    borderTopLeftRadius: "8px",
                                                    borderBottomLeftRadius: "8px",
                                                    background: "white"
                                                }}
                                                inputStyle={{
                                                    height: "44px",
                                                    width: "100%",
                                                    color: "#6D97C1",
                                                    borderRadius: "8px"
                                                }}
                                                enableSearch
                                                autoFormat
                                                searchNotFound="Country Not Found"
                                                specialLabel="Contact Number"
                                                data-test-id="PhoneInputStyled"
                                                placeholder={"Enter your contact number here"}
                                                value={values.full_phone_number}
                                                onChange={(value, countryData) => {
                                                    setFieldValue("full_phone_number", value);
                                                    setTouched({ 
                                                        ...touched, 
                                                        'full_phone_number': true 
                                                    })
                                                    if (!this.isPhoneValid({ phone: value, country: countryData })) {
                                                        setTimeout(() => {
                                                            setFieldError("full_phone_number", "Invalid mobile number");
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.full_phone_number && touched.full_phone_number && <p style={{...webStyle.errorText, marginTop: '6px'}}>{errors.full_phone_number}</p>}
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Autocomplete
                                                data-test-id="countryField"
                                                id="combo-box-demo"
                                                options={Country.getAllCountries().map((country: ICountry) => ({
                                                    value: country.name,
                                                    label: country.name,
                                                    cId: country.isoCode
                                                }))}
                                                value={{ value: values.country_field, label: values.country_field, cId: "0" }}
                                                getOptionLabel={(option) => option.label || ''}
                                                onChange={(event, value) => { this.handleLocationChange(setFieldValue, event, value, values)}}
                                                onBlur={() => {
                                                    setFieldTouched('country_field', true);
                                                }}
                                                renderInput={(params) => <AutoCompleteInputText 
                                                    errors={!!(touched?.country_field && errors.country_field) && errors}
                                                    params={params}
                                                    touched={touched.country_field}
                                                    title="Country" name="country_field" placeHolder="Country"
                                                />}
                                            />
                                        </Grid>

                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Autocomplete
                                                data-test-id="cityField"
                                                id="combo-box-demo"
                                                options={this.state.cities}
                                                getOptionLabel={(option) => option.label}
                                                value={{ value: values.city_field, label: values.city_field }}
                                                onChange={(event) => { setFieldValue("city_field", (event.target as HTMLElement).textContent)}}
                                                onBlur={() => {
                                                    setFieldTouched('city_field', true);
                                                }}
                                                renderInput={(params) => <AutoCompleteInputText
                                                    errors={!!(touched?.city_field && errors.city_field) && errors}
                                                    title="City"
                                                    name="city_field"
                                                    placeHolder="City"
                                                    params={params}
                                                    touched={touched.city_field}
                                                />
                                                }
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Typography style={{
                                                color: '#1F497D',
                                                fontFamily: 'Arial',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 'bold',
                                                lineHeight: '22px',
                                                paddingBottom: '4px',
                                            }}>Work Email Id</Typography>
                                            <FormControl variant="outlined" fullWidth size="small"
                                                style={{ background: '#FFF', borderRadius: "8px", border: "1px solid  #CBD5E1" }}>
                                                <OutlinedInputCustom
                                                    placeholder="hjwork@email.com"
                                                    type="email"
                                                    name="work_email_id"
                                                    value={values.work_email_id}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: '14px',
                                                            paddingRight: "4px"
                                                        }
                                                    }}
                                                    endAdornment={<InputAdornment position="end">
                                                        {this.handleConditionRender(this.state.workEmailVerified, 
                                                            <Grid
                                                            style={{ color: "#059669", fontFamily: "Arial", fontSize: "14px", fontWeight: 700, padding: "2px 0" }}
                                                        >
                                                            Verified
                                                        </Grid>, <Grid
                                                            data-test-id={"btnEmailVerificationWork"}
                                                            style={{
                                                                ...webStyle.verifyButton,
                                                                background: `${this.handleConditionRender(
                                                                    this.checkForDisableButton("workEmailVerified", this.state.initialValues.work_email_id, values.work_email_id, this.state.workEmailVerified),
                                                                    "rgb(203, 213, 225)",
                                                                    "#6D97C1")}`
                                                            }}
                                                            onClick={() => this.checkForEmailValue("workEmailVerified", values.work_email_id, errors.work_email_id)}
                                                        >
                                                            Verify
                                                        </Grid>)}
                                                    </InputAdornment>}
                                                    />
                                            </FormControl>
                                            {this.handleConditionRender(errors.work_email_id, <p style={{ ...webStyle.errorText, marginTop: '6px' }}>{errors.work_email_id}</p>, "")}
                                            {this.handleConditionRender(this.state.showWorkEmailError, 
                                            this.handleConditionRender(!this.state.workEmailVerified, 
                                            <p style={{ ...webStyle.errorText, marginTop: '6px' }}>Email id is not verified</p>, ""), "")}
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Typography style={{
                                                color: '#1F497D',
                                                fontFamily: 'Arial',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 'bold',
                                                lineHeight: '22px',
                                                paddingBottom: '4px',
                                            }}>Personal Email id</Typography>
                                            <FormControl variant="outlined" fullWidth size="small"
                                                style={{ background: '#FFF', borderRadius: "8px", border: "1px solid  #CBD5E1" }}>
                                                <OutlinedInputCustom
                                                    placeholder="hj22@email.com"
                                                    type="email"
                                                    name="personal_email_id"
                                                    value={values.personal_email_id}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: '14px',
                                                        }
                                                    }}
                                                    endAdornment={<InputAdornment position="end">
                                                        {this.handleConditionRender(this.state.personalEmailVerified, <Grid
                                                            style={{ color: "#059669", fontFamily: "Arial", fontSize: "14px", fontWeight: 700 }}
                                                        >
                                                            Verified
                                                        </Grid>, <Grid
                                                            data-test-id={"btnEmailVerification"}
                                                            style={{
                                                                ...webStyle.verifyButton,
                                                                background: `${this.handleConditionRender(
                                                                    this.checkForDisableButton("personalEmailVerified", this.state.initialValues.personal_email_id, values.personal_email_id, this.state.personalEmailVerified),
                                                                    "rgb(203, 213, 225)",
                                                                    "#6D97C1")}`
                                                            }}
                                                            onClick={() => this.checkForEmailValue("personalEmailVerified", values.personal_email_id, errors.personal_email_id)}
                                                        >
                                                            Verify
                                                        </Grid>)}
                                                    </InputAdornment>}
                                                    />
                                            </FormControl>
                                            {this.handleConditionRender(errors.personal_email_id, <p style={{ ...webStyle.errorText, marginTop: '6px' }}>{errors.personal_email_id}</p>, "")}
                                            {this.handleConditionRender(this.state.showPersonalEmailError, this.handleConditionRender(!this.state.personalEmailVerified, <p style={{ ...webStyle.errorText, marginTop: '6px' }}>Email id is not verified</p>, ""), "")}
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>
                                            <Field
                                                name="bio"
                                                component={CustomTextField}
                                                type="text"
                                                variant="outlined"
                                                title="Bio"
                                                placeHolder="You can add your upcoming"
                                                rootStyle={{ height: "10px !important" }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={webStyle.btncontainerMain}>
                                        <Grid item sm={12} style={webStyle.btnContainer}>
                                            {localStorage.getItem("setProfile") === 'true' && <Button data-test-id="cancelClick" style={webStyle.noBtn as CSSProperties} onClick={this.handleCancel}>
                                                Cancel
                                                </Button>}
                                            <Button style={webStyle.yesBtn12 as CSSProperties} type="submit">
                                                Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                {this.state.showVerifiyEmail && (
                        <Box style={{
                            position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(15, 23, 42, 0.4)",
                            zIndex: 101, display: "flex", alignItems: "center", justifyContent: "center",
                        }}>
                            <Box style={{ minWidth: "500px", background: "#fff", border: "0px 0px 1px  0px", position: "absolute", borderTop: "1px solid #E2E8F0", borderRadius: "8px" }}>
                                <Box style={{ padding: "24px 40px 20px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography style={{ fontFamily: "Arial", fontWeight: 700, fontSize: "18px", color: "#17365D" }}
                                    >Check your email </Typography>
                                    <img src={cross} style={{cursor: "pointer"}}  onClick={this.handleCloseClick} />
                                </Box>
                                <Box style={{ padding: "2px 40px 40px 40px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", position: "relative", gap: "4px" }}>
                                    <Typography style={{ color: "#1F497D", fontSize: "18px", fontWeight: 700, fontFamily: "Arial" }}>
                                        We have {this.handleConditionRender(this.state.resend, 'resend', 'sent')} an OTP to {this.state.emailToDisplay}</Typography>
                                {this.handleConditionRender(this.state.otpError, 
                                <Box sx={{
                                    marginTop: "10px",
                                    marginBottom: '10px',
                                    bgcolor: '#FEE2E2',
                                    borderLeft: '4px solid #DC2626',
                                    padding: '15px 10px 15px 10px',
                                    width: "100%"
                                }}>
                                    Invalid OTP entered
                                </Box>, 
                                "")}
                                    <Box style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingBottom: "20px" }}>
                                    <OTPInput
                                        value={this.state.otpValue}
                                        data-test-id={"handleChangeOTP"}
                                        onChange={(event) => this.handleChangeOTP(event)}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                            border: `1px solid ${this.handleConditionRender(this.state.otpError, '#DC2626', '#9C9C9C')}`,
                                            borderRadius: "5px",
                                            width: "44px",
                                            height: "44px",
                                            fontSize: '0.85rem',
                                            color: "#000",
                                            gap: "8px"
                                        }}
                                        containerStyle={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            width: '100%',
                                            marginTop: '30px',
                                            paddingLeft: '10px',
                                            paddingRight: '10px'
                                        }}
                                        renderInput={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) => <Grid item xs={1}><input {...props} /></Grid>}
                                    />
                                    </Box>
                                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                        <OtpTimer 
                                            handleShowVerifyEmail={() => this.sendOtpCall(this.state.emailToDisplay, true) } 
                                            handleOTPTimer={(val: boolean) => this.handleOTPTimerCall(val)}
                                        />
                                    </Box>
                                </Box>
                                <Box style={{
                                    padding: "24px 40px", borderTop: "1px solid #F4F4F4",
                                    display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "16px"
                                }}>
                                      <Grid container justifyContent="flex-end">
                              <Grid item md={4}>
                                <Button
                                  data-test-id={"btnVerifyOTP"}
                                  variant="contained"
                                  style={{
                                    color: "white", textTransform: 'capitalize',
                                    fontSize: "16px", marginTop: '10px',fontFamily:"Arial",fontWeight:"bold",
                                    background: `${this.handleConditionRender(this.state.otpValue.length < 6 || !this.state.timer, "#CBD5E1", "#6D97C1")}`,
                                  }}
                                  fullWidth
                                  onClick={this.verifyOtpCall}
                                  disabled={this.state.otpValue.length < 6 || !this.state.timer}
                                >
                                  Verify {this.handleVerifyCondition(this.state.verifyLoader, <CircularProgress style={{ color: 'white', marginLeft: '2px' }} size="1rem" />, "")}
                                </Button>
                              </Grid>
                            </Grid> </Box> </Box> </Box>
                )}   <ToastContainer />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Star
export default UserProfileEdit1

const webStyle = {
    page: {
        padding: "50px 100px",
        maxWidth: "1280px",
        background: "#F3F4F8",
    },
    form: {
        width: "auto",
        background: "#FFFFFF",
        padding: "24px 40px 10px 40px",
        margin: "0px",
        position: "relative",
        borderRadius: "8px 8px 32px 8px",
    },
    noBtn: {
        "fontFamily": "Arial",
        "color": "#1F497D",
        "fontStyle": "normal",
        "fontSize": "16px",
        "lineHeight": "22px",
        "fontWeight": "bold",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "border": "1px solid  #1F497D",
        "borderRadius": "8px",
        "width": "120px",
        "background": "#FFF",
        "padding": "10px 16px",
        "textTransform": "none",
        "height": "56px",
    },
    yesBtn12: {
        "border": "1px solid #C7D9F0",
        "color": "#FFFFFF",
        "fontFamily": "Arial",
        "fontStyle": "normal",
        "background": "#6D97C1",
        "fontSize": "16px",
        "marginLeft": "20px",
        "lineHeight": "22px",
        "&:hover": {
            background: "#C7D9F0"
        },
        "borderRadius": "8px",
        "fontWeight": "bold",
        "width": "120px",
        "padding": "16px",
        "display": "flex",
        "alignItems": "center",
        "height": "56px",
        "justifyContent": "center",
        "textTransform": "none",
        "gap": "8px",
    },
    btnContainer: {
        justifyContent: "flex-end",
        display: "flex",
        marginBottom: "5px",
        paddingTop: "25px !important",
        alignItems: "center",
    },
    btncontainerMain: { margin: "0px", width: "auto", paddingTop: "22px" },
    btnContainer1: {
        justifyContent: "flex-end", display: "flex",
        marginBottom: "5px", paddingTop: "170px !important",
        alignItems: "center",},
    cardTitle12: {
        "color": "#17365D","fontSize": "14px",
        "lineHeight": "22px","fontStyle": "normal",
        "marginBottom": "22px","fontWeight": "bold",
        "fontFamily": "Arial",
    },
    uploadTxt: {
        "display": "flex","width": "121px",
        "alignItems": "center",
        "borderRadius": "4px", "gap": "6px",
        "justifyContent": "flex-end",
        "color": "#17365D","fontSize": "12px",
        "fontFamily": "Arial","background": "#F4F4F4",
        "fontWeight": "lighter",
        "whiteSpace": "nowrap",
        "marginLeft": "13px",
        "fontStyle": "normal",
        "lineHeight": "18px",
    },
    errorText: {
        "color": "#f44336",
        "fontFamily": "Arial",
        "lineHeight": "18px",
        "fontStyle": "normal",
        "fontSize": "12px",
        "display": "flex",
        "fontWeight": "lighter",
    },
    contactField: {
        color: '#1F497D',
        fontFamily: 'Arial',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '22px',
        paddingBottom: '4px',
    },
    bottomLeft: {
        left: "0px",
        position: "absolute",
        bottom: "0px",
    },
    topRight: {
        top: "0px",
        position: "absolute",
        right: "0px",
    },
    formfieldCont: {
        margin: "0px",
        width: "auto",
    },
    verifyButton: {
        cursor: "pointer",
        width: '67px',alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        background: '#6D97C1',
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: "14px",
        padding: "8px",
        fontFamily: "Arial",
        textAlign: "center" as "center",
    },
}

const OutlinedInputCustom = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: "8px",
        position: 'relative',
        "& .MuiOutlinedInput-input": {
            borderRadius: "8px",
            border: "0px solid #9C9C9C",
            background: "#FFF",
            color: "#6D97C1",
            "lineHeight": "22px",
            height: "24px",
            "&:hover": {
                border: "0px solid #6D97C1",
            },
            "&:focus": {
                border: "0px solid #1F497D",
            },
            "&::placeholder": {
                "color": "#64748B",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px"
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: '0px'
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: '8px !important'
            },
            "& .MuiOutlinedInput-root": {
                paddingRight: "10px",
                height: "44px",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
        }
    }
})(OutlinedInput);

const PhoneInputStyled = styled(PhoneInput)({
    '& .flag-dropdown': {
        "&:focus" : {
            border: "2px solid #9c9c9c",
            borderRight: "0px !important"
        },
        "&:hover" : {
            border: "2px solid #9c9c9c",
            borderRight: "0px !important"
        },
        background: "white",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
        borderRight: "0px !important"
    },
    "& .selected-flag": {
        background: "white",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
    },
    "& .open": {
        background: "white",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
    },
    '& .form-control': {
        "&:focus" : {
            border: "2px solid #9c9c9c"
        },
        "&:hover" :{
            border: "2px solid #9c9c9c"
        },
        "&::placeholder": {
            "color": "#64748B",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px"
        },        
    },
    '& .form-control:hover + div': {
        border: "2px solid #9c9c9c"
    },
    '& .form-control:focus + div': {
        border: "2px solid #9c9c9c"
    }
});


// Customizable Area End
