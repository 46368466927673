import React, { useState, useRef, useEffect } from "react";
import { ClickAwayListener, Grow, Popper, MenuItem, MenuList, Button, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      
      
        },
    menuitem: {
        color: '#1F497D',
        fontFamily: 'Arial',
        fontSize: '12px',
        fontWeight: 400,
        cursor: "text",
        '&:hover': {
            backgroundColor: 'transparent',
            fontWeight: "bold"
          }
    },
    menuitemNoData: {
      color: '#1F497D',
      fontFamily: 'Arial',
      fontSize: '12px',
      cursor: "text",
      fontWeight: "bold"
    },
    btn: {
            height: '24px',
            backgroundColor: '#6D97C1',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '2px 8px 2px 8px',
            cursor: 'pointer',
            fontFamily: 'Arial',
            fontSize: '12px',
            fontWeight: 400,
            textTransform:'none',
            '&:hover': {
                backgroundColor: '#6D97C1', 
              } 
    },
  })
);

type CustomPopperProps = {
  rowId: string;
  options: string[]
};

const CustomViewPopper: React.FC<CustomPopperProps> = ({ rowId, options }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button ref={anchorRef} onClick={handleToggle} className={classes.btn}>
      view...
      </Button>
      <div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal 
      placement="bottom-end"
      style={{
        zIndex:1000,
        background: "#FFF",
        borderRadius:'16px',
        maxWidth: '200px',
        padding:' 2px 15px',
        border: '1px solid #F4F4F4',
        marginTop:'10px',
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
        overflow: "scroll",
        maxHeight: "240px"
        
}}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: "center top", background: "#FFF", }} 
          >
            <ClickAwayListener onClickAway={handleClose}>
              {
                options.length > 0 ? <MenuList
                autoFocusItem={open}
                id={`menu-list-grow-${rowId}`}
                onKeyDown={handleListKeyDown}
                className={classes.popover}
              >
                  {
                    options.map((opt) => <MenuItem className={classes.menuitem}>{opt}</MenuItem>)
                  }
                </MenuList> : <div style={{padding: "10px"}}>
                  <Typography className={classes.menuitemNoData}>
                    No data found
                  </Typography>
                </div>
              }
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
      </div>
    </div>
  );
};

export default CustomViewPopper;
