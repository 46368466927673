import { Button, Fade, Menu, MenuItem, Popover, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { JobObject } from '../../blocks/cfrecruitersdashboard/src/CfrecruitersdashboardController';
//@ts-ignore
import menuIcon from './menuIcon.svg';
import { toast } from 'react-toastify';

interface MenuItemProps {
  label: string;
  onClick?: (value: any) => void;
  hideItem?: boolean;
}

interface Props {
  buttonLabel?: string;
  buttonIcon?: string;
  menuWidth?: string;
  menuFontWeight?: number,
  menuItems: MenuItemProps[];
  btnStyle?: React.CSSProperties;
  hideSelectedIcon?: boolean;
  popoverMenuItems?: JobObject[];
  handleInvitation?: (value: number) => void;
  tooltipTitle?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '8px',
      border: '1px solid #CBD5E1',
      "& .MuiMenu-paper": {
        "box-shadow": "0px 4px 8px 0px #00000008 !important",
        "border-radius": "8px"
      },

      "& .MuiMenu-list": {
        width: (props: Props) => props.menuWidth || (props.buttonIcon ? '169px' : '192px'),
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        padding: '4px',
        background: '#FFFFFF',
      },
      "& .MuiMenuItem-root": {
        color: '#1F497D',
        fontSize: '12px',
        fontFamily: 'Arial',
        borderRadius: '8px',
        height: "36px",
        '&.Mui-selected': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          fontWeight: 700,
        },
      },
    },
    btn: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '12px',
      color: '#1F497D',
      fontFamily: "Arial"
    },
    iconStyle: {
      cursor: 'pointer',
    }
  }
  ))

const CustomMenu: React.FC<Props> = ({ buttonLabel, menuItems, btnStyle, menuWidth, menuFontWeight, buttonIcon, hideSelectedIcon, popoverMenuItems, handleInvitation, tooltipTitle }) => {
  const classes = useStyles({
    buttonIcon,
    menuItems: [],
    menuWidth,
    menuFontWeight
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorPopoverEl, setAnchorPopoverEl] = useState<HTMLElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const renderErrorMessage = () => [
    toast.error("You don't have any active jobs.")
  ]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (menuItems && menuItems.length > 0) {
      setAnchorEl(event.currentTarget)
    } else {
      renderErrorMessage()    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: MenuItemProps, index: number, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if(popoverMenuItems &&  popoverMenuItems.length <= 0) {
      renderErrorMessage()
      setAnchorEl(null)
    }
    if (item.onClick) {
      setSelectedIndex(index);
      item.onClick(item.label)
      handleClose()
    } else {
      setShowMenu(true)
      setAnchorPopoverEl(event.currentTarget)
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null)
    setShowMenu(false)
    setAnchorPopoverEl(null)
  }

  const handleSelectPopoverItem = (event: React.MouseEvent<HTMLElement>, item: JobObject) => {
    event.stopPropagation()
    handleClosePopover();
    handleInvitation && handleInvitation(item.id);
  }

  const open = Boolean(anchorEl);

  return (
    <div>
      <Tooltip title={tooltipTitle || ''}>
        {buttonIcon ?
          <img
            src={buttonIcon}
            onClick={handleClick}
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className={classes.iconStyle}
          />
          : <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={classes.btn}
            style={btnStyle}
            endIcon={<img src={menuIcon} />}
          >
            {buttonLabel}
          </Button>
        }
      </Tooltip>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        {menuItems.map((item, index) => {
          return (!item?.hideItem && <MenuItem
            style={{fontWeight: menuFontWeight || 400}}
            key={item.label}
            onClick={(event) => handleMenuItemClick(item, index, event)}>
            {item.label}
          </MenuItem>)
        }
        )}
      </Menu>

      {popoverMenuItems && popoverMenuItems.length > 0 &&
        <Popover
          open={showMenu}
          onClose={() => {
            setAnchorEl(null)
            setShowMenu(false)
            setAnchorPopoverEl(null)
          }
          }
        >
          <Menu
            id="fade-menu"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: -10,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorPopoverEl}
            open={showMenu}
            onClose={handleClosePopover}
            TransitionComponent={Fade}
            className={classes.root}
          >
            {
              popoverMenuItems && popoverMenuItems.map((item) => {
                return <MenuItem
                  onClick={(event) => handleSelectPopoverItem(event, item)}
                >
                  {item.attributes.job_title}
                </MenuItem>
              })
            }
          </Menu>
        </Popover >}

    </div >
  );
};

export default CustomMenu;
