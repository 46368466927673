import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomBottonComponent from './CustomBottonComponent.web';
//@ts-ignore
import iconClose from "./closeBlue.svg"
import { Divider, IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';

type Props = {
    open: boolean,
    handleClose: () => void,
    onCancleText?: string,
    onDeleteText?: string,
    questionOne: string,
    questionTwo?: string,
    description1?: string,
    description2?: string,
    hadndleDelete: any,
    type?: "outlined" | "normal" | "secondary" | "rounded" | "cancel" | "confirm"
}
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        "& .MuiDialog-paperScrollPaper": {
            borderRadius: "8px 8px 0 0",
        }
    }
}));

export default function ConfirmDialog({ open, handleClose, onCancleText = "Cancel", onDeleteText = "Delete", questionOne, questionTwo = "", hadndleDelete, type = "cancel", description1 = "", description2 = "" }: Props) {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            style={{
                padding: "5px 20px"
            }}
            className={classes.root}
        >
            <IconButton style={{ alignSelf: "end", padding: '16px' }} onClick={handleClose}>
                <img src={iconClose} alt="icon" />
            </IconButton>
            <DialogTitle style={{
                minWidth: "450px",
                padding: '16px 40px'
            }}>
                <span style={{
                    color: "#17365D",
                    fontSize: "24px",
                    fontFamily: "Arial",
                    minWidth: "450px",
                    fontWeight: 700
                }}>
                    {questionOne}
                    <br />
                     {questionTwo}
                </span>
                <br />
                {(description1 !== "" || description2 !== "") && <div>
                <span style={{ color: "#9C9C9C", 
                fontSize: "18px", fontWeight: 400, 
                fontFamily: "Arial"}}>
                    {description1}<br />{description2}
                </span></div>}
            </DialogTitle>
            <Divider style={{ height: "1px", width: "100%", marginBottom: "10px" }}/>
            <DialogActions style={{padding: '16px 24px'}}>
                <CustomBottonComponent title={onCancleText} type="secondary" onClick={handleClose} style={{ fontSize: "16px", padding: "0px 30px" }} />
                <CustomBottonComponent title={onDeleteText} type={type} onClick={hadndleDelete} style={{ fontSize: "16px", padding: "0px 30px" }} />
            </DialogActions>
        </Dialog>
    );
}
