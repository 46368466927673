import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import BootstrapTooltip from './BootstrapTooltip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        decline: {
            justifyContent: 'center',
            background: "#EEECE1",
            borderRadius: "4px",
            display: "flex",
            height: "24px",
            padding: "6px 8px",
            alignItems: "center",
            "& .MuiChip-label" : {
                wordBreak: "break-all",
                maxWidth: "300px"
            }
        },
        approve: {
            "display": "flex",
            "height": "28px",
            "padding": "6px 4px",
            "alignItems": "center",
            "borderRadius": "8px",
            "background": "#E6FAEB",
            "& .MuiChip-label" : {
                wordBreak: "break-all",
                maxWidth: "300px"
            }
        },

    }),
);

type Props = {
    title: string,
    icon: string,
    type: "approve" | "decline"
}

const CustomChip = (props: Props) => {
    const classes = useStyles();
    const { title, icon, type } = props;
    return (
        <BootstrapTooltip title={title.length >= 45 ? title : ""}>
            <Chip
            className={type === "approve" ? classes.approve : classes.decline}
            size="small"
            label={title}
            deleteIcon={<img src={icon} alt="icon" />}
            onDelete={() => { }}
        />
        </BootstrapTooltip>
    )
}

export default CustomChip