import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { debounce } from "lodash";
import { DataListItemForConnection } from "../../../../packages/blocks/followers/src/FollowersController";

interface PushNotificationAttributes {
  push_notificable_id: number;
  push_notificable_type: string;
  remarks: string;
  is_read: boolean;
  notify_type: string;
  data: {
    job_id: number,
    post_id: number,
    connection_request_id?: number
  };
  created_at: string;
  updated_at: string;
  account: AccountAttributes;
}

interface AccountAttributes {
  full_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string | null;
  email: string;
  activated: boolean;
  current_title: string;
  profile_photo: string;
  role: string;
  account_id: number
}

interface PushNotification {
  id: string;
  type: string;
  attributes: PushNotificationAttributes;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  openConfirm: boolean;
  notifications: {title: string, description: string, id: number, read: boolean, time: string}[];
  notificationsData: PushNotification[];
  currentNotification: PushNotification;
  isInfiniteLoading: boolean;
  currentPage: number;
  totalPage: number;
  unReadCount: number
  connectionList: DataListItemForConnection[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  markAllAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationApiId: string = "";
  deleteNotificationApiId: string = "";
  readNotificationApiId: string = "";
  observer: IntersectionObserver | null = null;
  connectionListId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleScroll = debounce(this.handleScroll.bind(this), 1000);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      openConfirm: false,
      notifications: [
        { "title": "System Update", "description": "A new system update is available for your device.", "id": 1, "read": false, "time": "1h" },
        { "title": "New Message", "description": "You have a new message from John Doe.", "id": 2, "read": false, "time": "2h" },
        { "title": "Security Alert", "description": "Suspicious login attempt detected on your account.", "id": 3, "read": false, "time": "3h" },
        { "title": "Appointment Reminder", "description": "Reminder: Your appointment is scheduled for tomorrow at 10 AM.", "id": 4, "read": false, "time": "4h" },
        { "title": "Newsletter Subscription", "description": "You've successfully subscribed to our monthly newsletter.", "id": 5, "read": false, "time": "5h" },
        { "title": "Payment Received", "description": "Your payment of Rs 1,500 has been successfully processed.", "id": 6, "read": false, "time": "6h" },
        { "title": "New Comment", "description": "Your post has received a new comment.", "id": 7, "read": false, "time": "7h" },
        { "title": "Friend Request", "description": "You have a new friend request from Jane Smith.", "id": 8, "read": true, "time": "8h" },
        { "title": "Software Error", "description": "An error occurred while updating your software.", "id": 9, "read": true, "time": "9h" },
        { "title": "Offer Alert", "description": "Special offer: 20% off on your next purchase.", "id": 10, "read": true, "time": "10h" },
        { "title": "Event Invitation", "description": "You've been invited to a new event: Tech Conference 2024.", "id": 11, "read": true, "time": "1h" },
        { "title": "Password Change", "description": "Your password has been successfully changed.", "id": 12, "read": true, "time": "2h" },
        { "title": "Subscription Expiry", "description": "Your subscription will expire in 3 days.", "id": 13, "read": false, "time": "3h" },
        { "title": "Order Shipped", "description": "Your order #12345 has been shipped and is on its way.", "id": 14, "read": false, "time": "4h" },
        { "title": "Login Alert", "description": "New login detected from an unknown device.", "id": 15, "read": false, "time": "5h" }
      ],
      notificationsData: [],
      currentNotification: {} as PushNotification,
      isInfiniteLoading: false,
      currentPage: 1,
      totalPage: 0,
      unReadCount: 0,
      connectionList: [] as DataListItemForConnection[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const authToken = await getStorageData("authToken")
    this.setState({
      token: authToken
    })
    this.getConnectionList()

    this.getNotificationApiCall()
    this.scrollToTop()
    this.createObserver();
    window.addEventListener("GET_NOTIFICATION_DATA_REAL_TIME", this.handleRealTimeNotification as unknown as EventListener);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch(apiRequestCallId) {
      case (this.getNotificationApiId) :
        return this.handleGetNotificationResponse(responseJson);
      case (this.deleteNotificationApiId): 
        return this.handleDeleteNotificationResponse(responseJson);
      case (this.markAllAsReadCallId) :
        return this.handleMarkReadResponse(responseJson);
      case this.connectionListId : 
        return this.handleConnectionList(responseJson)
    }

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  scrollToTop = () => {
    const containerDiv = document.getElementById("mark-all-read-btn")
    if (containerDiv) {
        containerDiv.scrollIntoView();
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.currentPage !== this.state.currentPage && this.state.currentPage <= this.state.totalPage) {
      this.getNotificationApiCall(this.state.currentPage)
    }
    
    if (this.observer) {
      const elements = document.querySelectorAll('.notification-item');

      if (elements.length > 0) {
        const lastElement = elements[elements.length - 1];
        this.observer.observe(lastElement);
      }
    }

  }

  createObserver() {
    this.observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.handleScroll();
      }
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });
  }

  handleRealTimeNotification = () => {
    this.setState({
      currentPage: 1,
      totalPage: 0
    })
    this.getNotificationApiCall()
  }

  handleScroll() {
      this.setState({
        currentPage: this.state.currentPage + 1
      })
  }

  handleGetNotificationResponse = (responseJson: {notifications: {data: PushNotification[]}, total_page: number, unread_notifications_count: number}) => {
    this.setState({
      isInfiniteLoading: false
    })

    if(responseJson.notifications.data) {
      this.setState({
        notificationsData: this.state.currentPage <= 1 ? responseJson.notifications.data : [...this.state.notificationsData, ...responseJson.notifications.data],
        totalPage: responseJson.total_page,
        unReadCount: responseJson.unread_notifications_count
      })
    }
  }

  handleDeleteNotificationResponse = (responseJson: {data: {id: string, attributes: {is_read: boolean} }, message: string}) => {
    if(responseJson.message) {
      window.dispatchEvent(new CustomEvent("GET_NOTIFICATION_DATA", {detail: {unReadCount: responseJson.data.attributes.is_read ? this.state.unReadCount : Math.max(this.state.unReadCount - 1, 0)}}))
      this.setState({
        notificationsData: this.state.notificationsData.filter((notification) => notification.id !== responseJson.data.id)
      })
    }
  }

  handleMarkReadResponse = (responseJson: { message: string } ) => {
    if(responseJson.message) {
      window.dispatchEvent(new CustomEvent("GET_NOTIFICATION_DATA", {detail: {unReadCount: 0}}))

      this.setState({
        notificationsData: this.state.notificationsData.map((notification) => {
          return {
            ...notification,
            attributes: {
              ...notification.attributes,
              is_read: true
            }
        }}),
        unReadCount: 0
      })
    }
  }

  handleConnectionList = (responsejson : { error: string} | { data: DataListItemForConnection[]}) => {
    if("data" in responsejson) {
      this.setState({
        connectionList: responsejson.data
      })
    }
  }

  trimText = (text: string) => {
    if (text.length > 120) {
      return text.slice(0, 120) + '...';
    }
    return text;
  }

  getCurrentTitle = (account: AccountAttributes) => {
    if(account.role === "recruiter") {
      return "Recruiter"
    } else {
      return account.current_title
    }
  }

  handleMarlAllRead = () => {
    if(!this.state.notificationsData.some(item => !item.attributes.is_read)){
      return
    }
    this.markAllAsRead()
  }
  
  handleConfirm = () => {
    this.setState({
      openConfirm: !this.state.openConfirm
    })
  }

  handleDeleteNotification = () => {
    this.handleConfirm()
    this.deleteNotificationApiCall()
  }

  handleOpenDeleteDialog = (notification: PushNotification) => {
    this.setState({
      openConfirm: true,
      currentNotification: notification
    })
  }

  handleNavigation = (notification: PushNotification) => {
    this.markAsRead(notification.id)
    window.dispatchEvent(new CustomEvent("GET_NOTIFICATION_DATA", {detail: {unReadCount: notification.attributes.is_read ? this.state.unReadCount : Math.max(this.state.unReadCount - 1, 0)}}))    

    switch (notification.attributes.notify_type) {
      case "job_interviewing":
      case "job_rejected":
      case "job_invitation":
      case "job_review":
      case "job_messaged":
      case "job_matching":
      case "new_job":
        return this.navigateToJob(notification.attributes.data.job_id)
        
        case "profile_update":
        case "view profile":
        case "connection_request_accepted":
        return this.navigateToProfile(notification.attributes.account.account_id)

      case "follow":
      case "connection_request_received":
        if(this.state.connectionList.some((user: DataListItemForConnection) => user.attributes.id === notification.attributes.data?.connection_request_id)) {
          return this.navigateToPage("Connections", 1)
        } else {
          return this.navigateToPage("Followers")
        }

      case "subscription_activation":
      case "subscription_upgraded":
      case "subscription_downgraded":
      case "subscription_expiry":
      case "subscription_renewal":
        return this.navigateToPage("Customisableusersubscriptions")

      case "comment_reaction_notification":
      case "upvote":
      case "downvote":
      case "new_post":
      case "comment":
        return this.navigateToPost(notification.attributes.data.post_id)

      case "chat":
        return this.goToMessage(notification.attributes.account)
  
      default: 
        return;
    }

  }

  navigateToJob = (jobId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `Catalogue`);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const payloadMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
    payloadMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { query: jobId })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMessage);
    this.send(message);
  }

  navigateToPost = (postId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'LandingPagePost');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), postId);
    this.send(message);
  }

  navigateToProfile = (accountId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), accountId);
    this.send(message);
  }

  navigateToPage = (pageUrl: string, anyID?: string | number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pageUrl);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), anyID);
    this.send(message);
  }

  goToMessage = (data: AccountAttributes | null) => {
    const profileDetails = {
      data: {
        attributes: {
          photo: data?.profile_photo, 
          full_name: data?.full_name, 
          current_title: data?.current_title, 
          account_details: {
            id: data?.account_id
          }
        }
      }
    }

    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { from: "Profile", accountId: profileDetails })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  iconBellProps = {
    source: imgBell,
  };

  getConnectionList = async () => {
    const token = this.state.token;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const connectionList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    connectionList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.connectionListId = connectionList.messageId;
    connectionList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_friends/connections_list"
    );
    connectionList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(connectionList.id, connectionList);
  };

  getNotificationApiCall = async (page?: number) => {
    this.setState({
      isInfiniteLoading: true
    })
    const authToken = await getStorageData("authToken")
    let url = ""

    if(page) {
      url = `page=${page}&per_page=10}`
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationApi}?${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteNotificationApiCall = async () => {
    const authToken = await getStorageData("authToken")

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNotificationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationApi}/${this.state.currentNotification.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  readNotificationApiCall = async (notificationId: number) => {
    const authToken = await getStorageData("authToken")

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.readNotificationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationApi}?id=${notificationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  async markAsRead(notificationId: string | number) {
    const authToken = await getStorageData("authToken")

    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const requestBody = {
      "data": {
        "attributes": {
          "is_read": true
        }
      }
    }

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationApi}/${notificationId}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: authToken,
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  async markAllAsRead() {
    
    const authToken = await getStorageData('authToken');

    const markAsReadMsg = new Message( getName(MessageEnum.RestAPIRequestMessage));
    this.markAllAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.notificationApi}/mark_all_as_read`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': configJSON.apiContentType,
        token: authToken,
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }
  // Customizable Area End
}
