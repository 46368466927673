import React, { ChangeEvent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: "100%",
            borderRadius: '20px',
            border: '1px solid #CBD5E1',
            background: '#FFF',
            padding: '0px',
            fontFamily: 'Arial',
            boxShadow: '0px 4px 4px 0px rgba(24, 57, 98, 0.10)',
            height: '41px'
        },
        input: {
            flex: 1,
            '&::placeholder': {
                color: '#D2D2D2',
            },
            '& .MuiInputBase-input':{
                padding:'0px',
            },
            fontSize: '16px',
            color: '#6D97C1',
            fontWeight: "bold"
        },
        iconButton: {
            color: "#1F497D",
            fontFamily: "Arial",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "initial",
            paddingRight: "25px"
        },
    }),
);
type Props = {
    placeHolder: string,
    field: string,
    icon: string,
    onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void,
    name?: string,
}
export default function SearchField(props: Props) {
    const classes = useStyles();
    const { placeHolder, field, icon, onChangeHandler, name } = props;
    return (
        <Box className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="menu">
                {field}
            </IconButton>
            <InputBase
                onChange={onChangeHandler}
                className={classes.input}
                placeholder={placeHolder}
                name={name}
                inputProps={{ style: { color: '#6D97C1', fontWeight: "lighter",} }}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <img src={icon} alt="icon" />
            </IconButton>
        </Box>
    );
}
