import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import Filteritems from "./Filteritems.web";
import { toast } from "react-toastify";

export interface Root {
  recruiter_job_listing: RecruiterJobListing
  total_page: number
  current_page: number
}

export interface RecruiterJobListing {
  data: Daum[]
}

export interface Daum {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  job_title: string
  company_name: string
  city: string
  country: string
  disclose: boolean
  status: string
  created_at: string
}

export interface FilterData {
  recruiter_job_listing: {
    data: never[]
  },
  total_page: number,
  current_page: number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  filteredData?: (filterdata: Root[] | FilterData) => void
  country?: (selectedCountry: string) => void;
  city?: (selectedCity: string) => void;
  date?: (selectedDate: string) => void;
  skills?: (selectedSkill: string[]) => void;
  postBy?: (selectedPostedBy: string) => void;
  experience?: (selectedExperiance: string[]) => void;
  disableButton?: (disableButton: boolean) => void;
  openFilter: (disableButton: boolean) => void;
  experienceSelect?: (selectedExperianceCheck:string[]) => void
  currentPage?: (currentPage: number) => void
  totalPage?: (totalPage: number) => void
  isRecruiterPage?: boolean
  handleFilter?: (type: "reset" | "apply") => void
  // Customizable Area End
}

// Customizable Area Start
export interface AllBrands {
  id: string;
  name?: string;
  type?: string;
  attributes: Brand;
  checked?: boolean;
}

export interface Brand {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  currency?: string;
  checked?: boolean;
}

export interface SubCategory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string | number | null;
  rank: string | number | null;
}

export interface Reviews {
  id: number;
  catalogue_id: number;
  comment: string;
  rating: number;
  created_at: string;
  updated_at: string;
}

export interface CatalogueVariants {
  id: string;
  type: string | null;
  attributes: {
    id: number;
    catalogue_id: number | null;
    catalogue_variant_color_id: number | null;
    catalogue_variant_size_id: number | null;
    price: string;
    stock_qty: string | number | null;
    on_sale: boolean | null;
    sale_price: string | number | null;
    discount_price: string | number | null;
    length: string | number | null;
    breadth: string | number | null;
    height: string | number | null;
    created_at: string;
    updated_at: string;
    images: string[] | null;
  };
}

export interface AllCategory {
  id: string;
  type: string | null;
  checked?: boolean;
  attributes: {
    sub_category?: SubCategory | SubCategory[] | null;
    brand?: Brand | Brand[] | null;
    tags?: string[] | null;
    reviews?: Reviews[] | null;
    name: string | null;
    sku?: string | null;
    description?: string | null;
    manufacture_date?: string | null;
    length?: number | null;
    breadth?: number | null;
    height?: number | null;
    stock_qty?: number | null;
    availability?: string | null;
    weight?: string | null;
    price?: number | number[] | null;
    images?:
      | {
          url: string;
        }[]
      | null;
    recommended?: boolean | null;
    on_sale?: boolean | null;
    sale_price?: string | number | null;
    discount?: string | number | null;
    category: Category;
    average_rating?: number | null;
    catalogue_variants?: CatalogueVariants[] | null;
  };
}

export interface Category {
  id: string;
  type: string | null;
  name?: string | null;
  attributes: {
    id?: number;
    name: string | null;
    dark_icon?: string | null;
    dark_icon_active?: string | null;
    dark_icon_inactive?: string | null;
    light_icon?: string | null;
    light_icon_active?: string | null;
    light_icon_inactive?: string | null;
    rank?: number | string | null;
    created_at?: string | null;
    updated_at?: string | null;
    selected_sub_categories?: [] | string | null;
  };
}
// Customizable Area End

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  outOfStock: boolean;
  pricerange: boolean;
  brand: boolean;
  category: boolean;
  tag: boolean;
  checkedStock: boolean;
  checkedDiscounted: boolean;
  rangeLow: string | null;
  rangeHigh: string | null;
  value: number;
  token: string;
  data: AllCategory[];
  checkedCategory: boolean;
  checkedTag: boolean;
  GetAllBrand: AllBrands[];
  BrandList: AllBrands[];
  selectedItems: AllBrands[];
  selectedCategory: AllCategory[];
  scrollEnabled: boolean;
  minValue: number;
  maxValue: number;
  priceMin: number;
  priceMax: number;
  arrayHolder: AllCategory[];
  categoryArray: AllCategory[];
  open: boolean
  selectedCountry: string
  selectedCity: string
  selectedDate: string
  selectedSkill: string[]
  selectedPostedBy: string
  selectedExperiance: string[]
  selectedExperianceCheck: string[]
  disableButton: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteroptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: string = "";
  getBrandApiCallId: string = "";
  applyAllApiCallId: string = "";
  applyFilterApiCallId: string = '';
  childRef: React.RefObject<Filteritems> = React.createRef()

  minimumValue = 0;
  maximumValue = 100;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      outOfStock: false,
      pricerange: true,
      brand: false,
      category: false,
      tag: false,
      checkedStock: false,
      checkedDiscounted: false,
      rangeLow: "",
      rangeHigh: "",
      value: 10,
      token: "",
      data: [],
      checkedCategory: false,
      checkedTag: false,
      GetAllBrand: [],
      BrandList: [],
      selectedItems: [],
      selectedCategory: [],
      scrollEnabled: false,
      minValue: 10,
      maxValue: 200,
      priceMin: 0,
      priceMax: 0,
      arrayHolder: [],
      categoryArray: [],
      open: false,
      selectedCountry: '',
      selectedDate: '',
      selectedExperiance: [],
      selectedExperianceCheck: [],
      selectedPostedBy: '',
      selectedSkill: [],
      selectedCity: '',
      disableButton: true
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  openFilter(value: string) {
    if (value === "stock") {
      this.setState({
        tag: false,
        outOfStock: true,
        pricerange: false,
        brand: false,
        category: false
      });
    }
    if (value === "pricerange") {
      this.setState({
        tag: false,
        outOfStock: false,
        pricerange: true,
        brand: false,
        category: false
      });
    }
    if (value === "brand") {
      this.setState({
        tag: false,
        outOfStock: false,
        pricerange: false,
        brand: true,
        category: false
      });
    }
    if (value === "category") {
      this.setState({
        tag: false,
        outOfStock: false,
        pricerange: false,
        brand: false,
        category: true
      });
    }
    if (value === "tags") {
      this.setState({
        tag: true,
        outOfStock: false,
        pricerange: false,
        brand: false,
        category: false
      });
    }
  }

  async componentDidMount() {
    // Customizable Area Start
    this.constructURL(1)
    this.constructURL()
    // Customizable Area End
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBrandList = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  applyAllfilters = () => {
    let urlValue = configJSON.cataloguesAPiEndPoint;
    const priceMin = this.state.priceMin;
    const priceMax = this.state.priceMax;
    let brandSingle = "q[brand_id][]=";
    let brandMulti = "&q[brand_id][]=";
    let priceValue =
      "q[price][from]=" + `${priceMin}` + "&q[price][to]=" + `${priceMax}`;
    let categorySingle = "q[category_id][]=";
    let categoryMulti = "&q[category_id][]=";
    let brands: AllBrands[] = [];
    let price = priceMin !== 0 && priceMax !== 0;
    if (this.state.selectedItems.length > 0) {
      let selectedItems = this.state.selectedItems;
      brands = selectedItems;
    }
    let CategoryValue: AllCategory[] = [];
    if (this.state.selectedCategory.length > 0) {
      let selectedCategory = this.state.selectedCategory;
      CategoryValue = selectedCategory;
    }
    if (brands && !CategoryValue.length && !price) {
      for (let brandIndex = 0; brandIndex < brands.length; brandIndex++) {
        if (brandIndex === 0) {
          urlValue = urlValue + brandSingle + brands[brandIndex].id;
        } else {
          urlValue = urlValue + brandMulti + brands[brandIndex].id;
        }
      }
    } else if (price && !brands.length && !CategoryValue) {
      urlValue = urlValue + priceValue;
    } else if (CategoryValue && !brands.length && !price) {
      for (
        let categoryIndex = 0;
        categoryIndex < CategoryValue.length;
        categoryIndex++
      ) {
        if (categoryIndex === 0) {
          urlValue =
            urlValue + categorySingle + CategoryValue[categoryIndex].id;
        } else {
          urlValue = urlValue + categoryMulti + CategoryValue[categoryIndex].id;
        }
      }
    } else if (brands && price && !CategoryValue.length) {
      let brandIndex = 0;
      let brandSelect = "";
      for (brandIndex = 0; brandIndex < brands.length; brandIndex++) {
        if (brands.length === 1) {
          brandSelect = brandSingle + brands[brandIndex].id;
        } else {
          brandSelect = brandMulti + brands[brandIndex].id;
        }
        urlValue = urlValue + brandSelect;
      }
      urlValue = urlValue + "&" + priceValue;
    } else if (CategoryValue && price && !brands.length) {
      let categoryIndex = 0;
      let catSelect = "";
      for (
        categoryIndex = 0;
        categoryIndex < CategoryValue.length;
        categoryIndex++
      ) {
        if (CategoryValue.length === 1) {
          catSelect = categorySingle + CategoryValue[categoryIndex].id;
        } else {
          catSelect = categoryMulti + CategoryValue[categoryIndex].id;
        }
        urlValue = urlValue + catSelect;
      }
      urlValue = urlValue + "&" + priceValue;
    } else if (CategoryValue && brands && !price) {
      let brandIndex = 0;
      let categoryIndex = 0;
      let brandSelect = "";
      let catSelect = "";
      for (brandIndex = 0; brandIndex < brands.length; brandIndex++) {
        if (brands.length === 1) {
          brandSelect = brandSingle + brands[brandIndex].id;
        } else {
          brandSelect = brandMulti + brands[brandIndex].id;
        }
        urlValue = urlValue + brandSelect;
      }
      for (
        categoryIndex = 0;
        categoryIndex < CategoryValue.length;
        categoryIndex++
      ) {
        if (CategoryValue.length === 1) {
          catSelect = categorySingle + CategoryValue[categoryIndex].id;
        } else {
          catSelect = categoryMulti + CategoryValue[categoryIndex].id;
        }
        urlValue = urlValue + catSelect;
      }
    } else if (CategoryValue && brands && price) {
      let brandIndex = 0;
      let categoryIndex = 0;
      let brandSelect = "";
      let catSelect = "";
      for (brandIndex = 0; brandIndex < brands.length; brandIndex++) {
        if (brands.length === 1) {
          brandSelect = brandSingle + brands[brandIndex].id + "&";
        } else {
          brandSelect = brandMulti + brands[brandIndex].id;
        }
        urlValue = urlValue + brandSelect;
      }
      for (categoryIndex = 0; categoryIndex < CategoryValue.length; categoryIndex++) {
        if (CategoryValue.length === 1) {
          catSelect = categorySingle + CategoryValue[categoryIndex].id;
        } else {
          catSelect = categoryMulti + CategoryValue[categoryIndex].id;
        }
        urlValue = urlValue + catSelect;
      }
      urlValue = urlValue + "&" + priceValue;
    } else {
      this.props.navigation.goBack();
    }
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.applyAllApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlValue
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId == this.applyFilterApiCallId) {
        if(responseJson.recruiter_job_listing) {
          this.props.filteredData !== undefined && this.props.filteredData(responseJson);
          if (!this.isNullData()) {
            this.setState({
              open: false
            }, () => this.props.openFilter(this.state.open));
          }
        } else {
          const nullData = {
            recruiter_job_listing: {
              data: []
            },
            total_page: 0,
            current_page: 1
          }
          this.props.filteredData !== undefined && this.props.filteredData(nullData);
          toast.error(responseJson.message)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onCheckBrand = (item: AllBrands, itemIndex: number) => {
    let items: AllBrands[] = [];
    items = this.state.GetAllBrand;
    items[itemIndex].checked = items[itemIndex].checked ? !items[itemIndex].checked : true;
    if (items) {
      items.forEach(itemI => {
        if (item.id === itemI.id) {
          if (itemI.checked) {
            this.state.selectedItems.push(itemI);
          } else {
            this.setState({ selectedItems: [] });
          }
        }
      });
    }

    this.setState({ GetAllBrand: items });
  };

  onCheckCategory = (item: AllCategory, itemIndex: number) => {
    let items = [];
    items = this.state.arrayHolder;
    items[itemIndex].checked = items[itemIndex].checked ? !items[itemIndex].checked : true;
    if (items) {
      items.forEach(itemI => {
        if (item.id === itemI.id) {
          if (itemI.checked) {
            this.state.selectedCategory.push(itemI);
          } else {
            this.setState({ selectedCategory: [] });
          }
        }
      });
    }

    this.setState({ data: items });
  };

  isNullData= ()=> 
  {
    return (
      this.state.selectedCountry === "" && 
      this.state.selectedCity === "" && 
      this.state.selectedDate === "" && 
      this.state.selectedExperiance.length === 0 && 
      this.state.selectedExperianceCheck.length === 0 &&
      this.state.selectedPostedBy === "" && 
      this.state.selectedSkill.length === 0 && 
      this.state.disableButton === true
  )}

  searchFilterFunction = (text: string) => {
    if (this.state.brand) {
      if (text === "") {
        this.setState({ GetAllBrand: this.state.BrandList });
      } else {
        let Name = this.state.BrandList;
        const textData = Name.filter(item =>
          item.attributes.name
            .toLocaleLowerCase()
            .startsWith(text.toLowerCase())
        );
        this.setState({ GetAllBrand: textData });
      }
    }
  };

  numberValuesChange = (value: number[]) => {
  };

  valueChangeFinish = () => {
  };
goBack = () => {
    this.props.navigation.goBack();
  };

  handleToggle = (val:boolean) => {
    this.setState({
      open: val
    },()=>this.props.openFilter(this.state.open));
  };

  setCountryvalue = (val: string) => {
    this.setState({ selectedCountry: val }, () => { this.props.country?.(this.state.selectedCountry) })
  }

  setCityValue = (val: string) => {
    this.setState({ selectedCity: val }, () => { this.props.city?.(this.state.selectedCity) })
  }

  setDatevalue = (val: string) => {
    this.setState({ selectedDate: val }, () => { this.props.date?.(this.state.selectedDate) })
  }

  setPostedByValue = (val: string) => {
    this.setState({ selectedPostedBy: val }, () => { this.props.postBy?.(this.state.selectedPostedBy) })
  }

  setSkillValue = (val: string[]) => {
    this.setState({ selectedSkill: val }, () => { this.props.skills?.(this.state.selectedSkill) })
  }

  setExperienceValue = (val: string[]) => {
    this.setState({ selectedExperiance: val }, () => { this.props.experience?.(this.state.selectedExperiance) })
  }

  setButtonState = (val: boolean) => {
    this.setState({ disableButton: val }, () => { this.props.disableButton?.(this.state.disableButton) })
  }

  setExperienceCheckValue = (val: string[]) => {
    this.setState({ selectedExperianceCheck: val }, () => { this.props.experienceSelect?.(this.state.selectedExperianceCheck) })
  }

  constructURL = (page?: number, searchText?:string): string => {
    let URL: string = `${configJSON.aplyFiltersAPiEndPoint}?per_page=${page ? 10 * page : 10}`;
  
    let appendArrayParams = (paramName: string, array: string[]): void => {
      if (array.length > 0) {
        for (let item of array) { URL += `&${paramName}[]=${item}` }
      }
    }

    if ((this.state.selectedCountry.trim() !== "")) { URL += `&country=${this.state.selectedCountry}` }
    if ((this.state.selectedCity.trim() !== "")) { URL += `&city=${this.state.selectedCity}` }
    if ((this.state.selectedDate.trim() !== "")) { URL += `&date_posted[]=${this.state.selectedDate}` }
    if ((this.state.selectedPostedBy.trim() !== "")) { URL += `&posted_by=${this.state.selectedPostedBy}` }
    if(searchText) { URL += `&search_key=${searchText}` }

    appendArrayParams("skills", this.state.selectedSkill);
    appendArrayParams("experience", this.state.selectedExperiance);
  
    return URL;
  }

  applyfilters = (page?: number, searchText?: string) => {
    if (this.props.isRecruiterPage) { 
      this.props.handleFilter!("apply") 
      this.handleToggle(false)
    }
    else {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      let params = `${this.constructURL(page, searchText)}`

      this.applyFilterApiCallId = getValidationsMsg.messageId;
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        decodeURIComponent(params)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }

  resetState = () => {
    if (this.childRef.current) {
      this.childRef.current.clearState();
    }
    this.setState({
      selectedCountry: '',
      selectedCity: '',
      selectedDate: '',
      selectedExperiance: [],
      selectedExperianceCheck: [],
      selectedPostedBy: '',
      selectedSkill: [],
      disableButton: true
    }, () => {
      this.props.country?.(this.state.selectedCountry)
      this.props.city?.(this.state.selectedCity)
      this.props.date?.(this.state.selectedDate)
      this.props.postBy?.(this.state.selectedPostedBy)
      this.props.skills?.(this.state.selectedSkill)
      this.props.experience?.(this.state.selectedExperiance)
      this.props.disableButton?.(this.state.disableButton)
      this.getPropsDataFromLandingPage(this.state.selectedCountry, this.state.selectedCity, this.state.selectedDate, this.state.selectedExperiance, this.state.selectedPostedBy, this.state.selectedSkill, this.state.disableButton, this.state.selectedExperianceCheck)
      this.applyfilters();
      this.props.openFilter(this.state.open)
    })
    if(this.props.isRecruiterPage){
      this.props.handleFilter!("reset")
      this.handleToggle(false)
    }
  }

  getPropsDataFromLandingPage = (selectedCountry: string, selectedCity: string, selectedDate: string, selectedExperiance: string[], selectedPostedBy: string, selectedSkill: string[], disableButton: boolean, selectedExperianceCheck: string[]) => {
    this.setState({
      selectedCountry,
      selectedCity,
      selectedDate,
      selectedExperiance,
      selectedPostedBy,
      selectedSkill,
      disableButton,
      selectedExperianceCheck
    }, () => {
      this.passPropsDataFilterItem(this.state.selectedCountry, this.state.selectedCity, this.state.selectedDate, this.state.selectedExperiance, this.state.selectedPostedBy, this.state.selectedSkill, this.state.disableButton, this.state.selectedExperianceCheck)
    })
  }

  passPropsDataFilterItem = (selectedCountry: string, selectedCity: string, selectedDate: string, selectedExperiance: string[], selectedPostedBy: string, selectedSkill: string[], disableButton: boolean, selectedExperianceCheck: string[]) => {
    if (this.childRef.current) {
      this.childRef.current.setStateFromProps(selectedCountry, selectedCity, selectedDate, selectedExperiance, selectedPostedBy, selectedSkill, disableButton, selectedExperianceCheck);
    }
  }
  // Customizable Area End
}
