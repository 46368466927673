import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { FieldProps } from 'formik';
import { TextareaAutosize } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: "8px",
            position: 'relative',
            "& .MuiOutlinedInput-input": {
                borderRadius: "8px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "rgba(31, 73, 125, 1)",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px",
                "&:hover": {
                    border: "0px solid #6D97C1",
                },
                "&:focus": {
                    border: "0px solid #1F497D",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },

            },
            "& .MuiFormHelperText-contained": {
                marginLeft: '0px'
            },
            "& .MuiOutlinedInput-root": {
                height: (props: { rootStyle: { height: string }, isMultiline: boolean }) => props.isMultiline ? "auto" : "44px",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
            "& .MuiInputBase-input": {
                height: (props: { rootStyle: { height: string } }) => props?.rootStyle?.height
            },
        },


        inputLabel: {
            color: 'rgba(23, 54, 93, 1)',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginBottom": "32px",
            "marginTop": "6px"
        },
        verifyButton: {
            width: '67px',
            height: '28px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            position: 'absolute',
            right: '13px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: '#6D97C1'
        }
    })
);

type Props = FieldProps & {
    placeHolder: string;
    title: string;
    type: string;
    openDialog: any;
    rootStyle?: {};
    value?:string;
    setFieldValue?: any;
    isMultiline?: boolean;
};

const CustomTextField: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    title,
    type,
    openDialog,
    rootStyle = {},
    value,
    setFieldValue,
    isMultiline = false,
}) => {
    const classes = useStyles({ rootStyle, isMultiline } as { rootStyle: { height: string }, isMultiline: boolean });


    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={field.name}
            >
                {title}
            </InputLabel>
            <div className={classes.root}>
                <TextField
                    fullWidth
                    id={field.name}
                    variant="outlined"
                    multiline={isMultiline}
                    InputLabelProps={{ shrink: true }}
                    InputProps={isMultiline ? { classes: { root: classes.root }, inputComponent: TextareaAutosize} : { classes: { root: classes.root } }}
                    error={!!form.errors[field.name] && !!form.touched[field.name]}
                    helperText={
                        !!form.errors[field.name] &&
                        form.touched[field.name] &&
                        form.errors[field.name]
                    }
                    {...field}
                    value={value ?? field.value}
                    placeholder={placeHolder}
                    onChange={(event) => {
                        setFieldValue ? setFieldValue(field.name, event.target.value.replace(/,/g, '')) : field.onChange(event)
                    }}
                />
                {type === "emailp" && (
                    <Button className={classes.verifyButton} variant="contained" color="primary" onClick={() => openDialog()}>
                        Verify
                    </Button>
                )}
            </div>
        </>
    );
};


export default CustomTextField;
