import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomBottonComponent from './CustomBottonComponent.web';
//@ts-ignore
import iconClose from "./closeBlue.svg"
import { Button, DialogContent, Divider, IconButton, TextareaAutosize, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';

type Props = {
    open: boolean,
    handleClose: () => void,
    onCancleText?: string,
    onDeleteText?: string,
    questionOne: string,
    questionTwo?: string,
    handleSendInvitation: (note: string) => void
}
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        "& .MuiDialog-paperScrollPaper": {
            borderRadius: "8px 8px 0 0",
            width: "600px"
        },
        "& .MuiIconButton-root" : {
            '&:hover': {
                backgroundColor: '#FFFFFF',
            },
        }
    },
    textArea: {
        "&::placeholder": {
            color: "#94A3B8"
        }
    },
    sendBtn: {
        "display": "flex",
        "height": "48px",
        "padding": "10px 16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "borderRadius": "8px",
        "background": "#DC2626",
        "minWidth": "0px !important",
        "textTransform": "none",
        '&:hover': {
            backgroundColor: '#DC2626',
        },
        "color": "#FFFFFF",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        '&:disabled' : {
            backgroundColor: '#CBD5E1 !important',
            color: '#EEECE1'
        }
    },
}));

export default function InvitationDialog({ open, handleClose, onCancleText = "Add a note", onDeleteText = "Send without a note ", questionOne, questionTwo = "", handleSendInvitation }: Props) {
    const classes = useStyles();
    const [addNote, setAddNote] = useState(false)
    const [noteValue, setNoteValue] = useState("")

    const handleCloseDialog = () => {
        handleClose()
        setAddNote(false)
        setNoteValue("")
    }

    const handleSubmitDialog = () => {
        handleSendInvitation(noteValue)
        handleCloseDialog()
    }

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
            style={{
                padding: "5px 20px"
            }}
            className={classes.root}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '24px 40px'
            }}>
                <Typography style={{ color: "#17365D", fontSize: "24px", fontWeight: 700, fontFamily: "Arial" }}>
                    {addNote ? 'Add a note to your invitation' : questionOne}
                </Typography>
                <IconButton style={{ alignSelf: "end", paddingRight: '0px' }} onClick={handleCloseDialog}>
                    <img src={iconClose} alt="icon" />
                </IconButton>
            </div>
            {addNote ?
                <>
                    <DialogTitle style={{
                        minWidth: "450px",
                        padding: '16px 40px'
                    }}>
                        <TextareaAutosize
                            className={classes.textArea}
                            value={noteValue}
                            onChange={(event) => setNoteValue(event.target.value)}
                            placeholder='Write a note here.'
                            style={{
                                fontSize: "16px",
                                fontFamily: "Arial",
                                fontWeight: 400,
                                borderRadius: "8px",
                                width: "100%",
                                border: "1px solid #9C9C9C",
                                padding: "6px",
                                color: "#17365D",
                                height: "97px"
                            }}
                            minRows={4}
                        />
                    </DialogTitle>
                    <Divider style={{ height: "1px", width: "100%", marginBottom: "10px" }} />
                    <DialogActions style={{ padding: '24px 40px' }}>
                        <Button disabled={!noteValue.trim()} onClick={handleSubmitDialog} className={classes.sendBtn} style={{ fontSize: "16px", padding: "16px", backgroundColor: "rgba(31, 73, 125, 1)", marginLeft: "34px", height: "56px", width: "120px", minWidth: "120px !important" }}>
                            Send
                        </Button>
                    </DialogActions>
                </> :
                <>
                    <DialogTitle style={{
                        minWidth: "450px",
                        padding: '24px 40px'
                    }}>
                        <span style={{
                            color: "#9C9C9C",
                            fontSize: "18px",
                            fontFamily: "Arial",
                            fontWeight: 400
                        }}>
                            {questionTwo}
                            <br />
                        </span>
                    </DialogTitle>
                    <Divider style={{ height: "1px", width: "100%", marginBottom: "10px" }} />
                    <DialogActions style={{ padding: '24px 40px' }}>
                        <CustomBottonComponent title={onCancleText} onClick={() => setAddNote(true)} style={{ fontSize: "16px", padding: "16px", weight: "120px", height: "56px", backgroundColor: "#F4F4F4", color: "#9C9C9C" }} type="secondary" />
                        <CustomBottonComponent title={onDeleteText} onClick={handleSubmitDialog} style={{ fontSize: "16px", padding: "16px", backgroundColor: "rgba(31, 73, 125, 1)", marginLeft: "34px", height: "56px" }} type="cancel" />
                    </DialogActions>
                </>
            }
        </Dialog>
    );
}
