export const insertPhoto = require("../assets/insert_photo.png");
export const appleyyyy = require("../assets/imageppp.png")
export const pencilIcon = require("../assets/pencil.svg");
export const threeDotsIcon = require("../assets/threeDots.svg");
export const profileImage = require("../assets/profileImage.svg");
export const cancelIcon = require("../assets/cancel.svg");
export const deleteIcon = require("../assets/delete.svg");
export const imageUpload = require("../assets/imageUpload.png");
export const search = require("../assets/search.png");
export const noMessage = require("../assets/noMessage.png");
export const pendingMessage = require("../assets/pendingMessage.png")
export const leftArrowIcon = require("../assets/leftArrow.svg");

