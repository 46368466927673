import { Box, Button, Popover } from "@material-ui/core"
import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {successTick} from  '../assets'

type Props = {
open: boolean,
closeModal: ()=>void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContainer: {
            width: '410px',
            height: '42px',
            borderRadius: '8px',
            background: '#FFF',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
      },
      modalText: {
        color: "#1F497D",
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Arial'
      },
      buttonStyles: {
        borderRadius: "8px",
        backgroundColor: "#1F497D",
        color: "#F8FAFC",
        fontFamily: 'Arial',
        fontSize: '14px',
        fontWeight: 700
      },
      popOverContainer: {

      },
      "MuiPopover-paper": {
        top: "65px"
      }
    }),
)
export default function CreateJobSuccessModal(Props: Props) {
    const classes = useStyles();
    const {open, closeModal} = Props

    return (
        <Box className={classes.popOverContainer}>
        <Popover
        open={open}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  onClose={closeModal}
>
<Box className={classes.modalContainer}>
    <img src={successTick}/>
           <Box className={classes.modalText}>
               Your application has been submitted successfully.
            </Box>
        </Box>
</Popover>
</Box>
    )
}