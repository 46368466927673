import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "display": "flex",
            "height": "48px",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#6D97C1",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#6D97C1',
            },
            "color": "#FFF",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "22px"
        },
        outLined: {
            "display": "flex",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "borderRadius": "8px",
            "border": "1px solid #1F497D",
            "background": "#FFF",
            "color": "#1F497",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#FFF',
            },
        },
        applied: {
            "display": "flex",
            "height": "48px",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#F1F5F9",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#F1F5F9',
            },
            "color": "#1F497D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "22px"
        },
        cancel: {
            "display": "flex",
            "height": "48px",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#DC2626",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#DC2626',
            },
            "color": "#FFFFFF",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "22px"
        },
        roundedBtn: {
            "display": "flex",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "16px",
            "background": "#F1F5F9",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#F1F5F9',
            },
            "color": "#1F497D",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "height": "32px"
        },
        cnfrmBtn: {
            "height": "48px",
            "display": "flex",
            "justifyContent": "center",
            "padding": "10px 16px",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#1F497D",
            "minWidth": "0px !important",
            "textTransform": "none",
            '&:hover': {
                backgroundColor: '#1F497D',
            },
            "color": "#FFFFFF",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "22px"
        },
        cnfrmBtnDisabled: {
            "height": "48px",
            "display": "flex",
            "justifyContent": "center",
            "padding": "10px 16px",
            "gap": "8px",
            "alignItems": "center",
            "background": "#CBD5E1",
            "borderRadius": "8px",
            "textTransform": "none",
            "minWidth": "0px !important",
            '&:hover': {
                backgroundColor: '#1F497D',
            },
            "fontFamily": "Arial",
            "color": "#FFFFFF",
            "fontStyle": "normal",
            "fontSize": "14px",
            "lineHeight": "22px",
            "fontWeight": "bold"
        }
    }),
);
type Props = {
    title: string | React.ReactNode,
    type: "outlined" | "normal" | "secondary" | "rounded" | "cancel" | "confirm" | "confirmDisabled",
    style?: any,
    onClick: () => void
}

export default function CustomBottonComponent(props: Props) {
    const classes = useStyles();
    const { title, type, style, onClick } = props;
    const getClassName = () => {
        switch (type) {
            case "normal":
                return classes.root;
            case "outlined":
                return classes.outLined;
            case "secondary":
                return classes.applied;
            case "rounded":
                return classes.roundedBtn;
            case "cancel":
                return classes.cancel;
            case "confirm":
                return classes.cnfrmBtn;
            case "confirmDisabled":
                return classes.cnfrmBtnDisabled
        }
    }
    return (<Button variant="contained" disableElevation className={getClassName()} style={style} onClick={onClick}>
        {title}
    </Button>
    );
}