import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { FieldProps } from 'formik';
//@ts-ignore
import openicondown from '../../assets/openicondown.svg';
import { Input, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "8px",
            border: "1px solid #9C9C9C",
            background: "#FFF",
            color: "#6D97C1",
            fontFamily: "Arial",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "lighter",
            lineHeight: "22px",

            '&:hover': {
                border: "1px solid #9C9C9C",
            },
            '&:focus': {
                border: "1px solid #9C9C9C",
            },
            width: "100%",
            "& .MuiSelect-select": {
                "width": "100%",
                "height": "42.6333px",
                "borderRadius": "8px",
                "padding": "0px 12px",
                "background": "white",
                "border": "0px",
                "display": "flex",
                "justifyContent": "flex-start",
                "alignItems": "center",
            },
            "& .MuiInput-input": {
                "border": "0px"
            },
            "& .MuiInput-input:focus": {
                "border": "0px solid #9C9C9C"
            },
            "& .MuiInput-root": {
                border: "1px solid #9C9C9C",
            }
        },
        rootError: {
            borderRadius: "8px",
            border: "1px solid #DC2626",
            background: "#FFF",
            color: "#6D97C1",
            fontFamily: "Arial",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "lighter",
            lineHeight: "22px",

            '&:hover': {
                border: "1px solid #9C9C9C",
            },
            '&:focus': {
                border: "1px solid #9C9C9C",
            },
            width: "100%",
            "& .MuiSelect-select": {
                "width": "100%",
                "height": "42.6333px",
                "borderRadius": "8px",
                "padding": "0px 12px",
                "background": "white",
                "border": "0px",
                "display": "flex",
                "justifyContent": "flex-start",
                "alignItems": "center",
            },
            "& .MuiInput-input": {
                "border": "0px"
            },
            "& .MuiInput-input:focus": {
                "border": "0px solid #9C9C9C"
            },
            "& .MuiInput-root": {
                border: "1px solid #9C9C9C",
            }
        },
        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        selectEmpty: {
            border: "1px solid #EEECE1",
            borderRadius: "8px",
            margin: "0px !important",
            padding: "2px 5px",
            background: "white",
            "& .MuiInput-input": {
                background: "white",
            },
            "& .MuiInput-input:focus": {
                backgroundColor: "white",
            },
            "& .MuiInput-underline": {
                display: "none"
            },
            "& .MuiInput-formControl": {
                marginTop: "2px"
            }
        },
        radioTitle: {},
        errorText: {
            "color": "#DC2626 !important",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
        },
        selectMenu: {
            borderRadius: "8px",
            background: "red"
        },
        menuItem: {
            "display": "flex",
            "height": "36px",
            "padding": "9px 20px 9px 16px",
            "alignItems": "center",
            "alignSelf": "stretch",
            "background": "white",
            "color": "#17365D",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "&:hover": {
                "background": "#F3F4F8 !important",
                borderRadius: "8px",
            }
        },
        menuLabel: {

        },
        menuDropdown: {
            "borderRadius": "16px",
            "border": "1px solid  #CBD5E1",
            "background": "#FFF",
            "boxShadow": "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
            "marginTop": "5px"
        },
        placeholder: {
            "color": "#64748B !important",
            "fontFamily": "Arial",
            "fontSize": "16px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px"
        },
        selected: {
            color: "#17365D !important",
            fontSize: "16px",
            fontFamily: "Arial",
            fontWeight: "lighter"
        }
    })
);

const CustomDropdownIcon = (props: any) => {
    return <img src={openicondown} alt="open" {...props} style={{ paddingRight: "4px" }} />;
};

type Props = FieldProps & {
    placeHolder: string;
    title: string;
    options: { value: string; label: string }[];
    controlledValue?: string;
    onChangeControlled?: (e: React.SyntheticEvent<HTMLDivElement, Event> | React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => void;
    required?: boolean;
};

const CustomSelectDropdownComponent: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    title,
    options,
    controlledValue,
    onChangeControlled,
    required=false
}) => {
    const classes = useStyles();

    return (
        <div>
            <InputLabel
                className={classes.inputLabel}
            >
                {title} {required && <span style={{ color: '#f44336' }}>*</span>}
            </InputLabel>
            <FormControl error style={{ width: "100%" }} fullWidth>
                <Select
                    displayEmpty
                    input={<Input />}
                    renderValue={(selected: any) => {
                        if (!selected) {
                            return <Typography className={classes.placeholder}>{placeHolder}</Typography>;
                        }
                        return <Typography className={classes.selected}>{selected}</Typography>
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            className: classes.menuDropdown
                        }
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    className={!!form.errors[field.name] && !!form.touched[field.name] ? classes.rootError : classes.root}
                    error={!!form.errors[field.name] && !!form.touched[field.name]}
                    IconComponent={CustomDropdownIcon}
                    disableUnderline
                    {...field}
                    value={controlledValue ?? field.value}
                    onSelect={onChangeControlled ?? form.handleChange}
                    onChange={onChangeControlled ?? form.handleChange}
                    style={{ height: "20px !important" }}
                >
                    {options.map((name) => (
                        <MenuItem className={classes.menuItem} key={name.label} value={name.value} style={{ background: "white" }}>
                            <Typography className={classes.menuLabel}>{name.label}</Typography>
                        </MenuItem>
                    ))}
                </Select>
                {
                    !!form.errors[field.name] && !!form.touched[field.name] && <FormHelperText className={classes.errorText} >{form.errors[field.name]}</FormHelperText>
                }
            </FormControl>
        </div>
    );
};

export default CustomSelectDropdownComponent;
