import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormHelperText, InputAdornment, InputLabel, TextField } from '@material-ui/core';
//@ts-ignore
import { openMenuicon } from './openMenuWhite.svg';
//@ts-ignore
import SearchIcon from './search.svg';
//@ts-ignore
import EndSearchIcon from "./endsearch.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: "8px",
            position: 'relative',
            minWidth: "200px",
            background: "#F4F4F4",
            "& .MuiFormHelperText-contained": {
                marginLeft: '0px'
            },
            "& .MuiTextField-root": {
                "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "0px solid #9C9C9C"
                }
            },
            "& .MuiSvgIcon-root": {
                display: 'none'
            },
            "& .MuiAutocomplete-input": {
                padding: "5.5px 4px !important"
            },
            "& .custom-dropdown-icon": {
                width: '24px',
                height: '24px',
                backgroundImage: `url(${openMenuicon})`,
                backgroundSize: 'cover',
                cursor: 'pointer',
                right: 0,
                position: 'absolute',
                pointerEvents: 'none',
                display: 'none'
            },
            "& .MuiOutlinedInput-input": {
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "transparent",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#6D97C1",
                padding : '5px',
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px",
                "&:hover": {
                    border: "0px solid #6D97C1",
                },
                "&:focus": {
                    border: "0px solid #1F497D",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
                paddingLeft: "20px !important"
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                },
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent !important"
            },
            "& .MuiInputAdornment-positionEnd": {
                position: "absolute",
                right: "10px"
            },
            "& .MuiInputAdornment-positionStart":{
                position: "absolute",
                padding: "2px"
            }
        },

        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginTop": "6px"
        },
        verifyButton: {
            width: '67px',
            height: '28px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            position: 'absolute',
            right: '13px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: '#6D97C1'
        }
    })
);

type Props = {
    placeHolder?: string;
    title?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    params: any;
    name: string;
    errors?: any;
    height?: string
};

const AutoCompleteSearch: React.FC<Props> = ({
    placeHolder,
    title,
    onChange = () => {},
    params,
    name,
    height,
    errors = ""
}) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <TextField {...params}
                    name={name}
                    placeholder={placeHolder}
                    variant="outlined" onChange={onChange} onFocus={onChange}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors[name || '']}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        classes: { root: classes.root },
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt="icon" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <img src={EndSearchIcon} alt="icon" />
                            </InputAdornment>
                        )
                    }}

                />
            </div>
            {
                !!errors[name] && <FormHelperText className={classes.errorText}>{errors[name]}</FormHelperText>
            }
        </>
    );
};

export default AutoCompleteSearch;
