export const searchIcon = require("../assets/image_.svg");
export const cloudUpload = require("../assets/cloud_upload.svg");
export const groupAdd = require("../assets/image_group_add.svg");
export const workOutline = require("../assets/image_work_outline.svg");
export const horiAdd = require("../assets/image_more_horiz.svg");
export const bookmarkImg = require("../assets/image_bookmark.svg");
export const rectangeImg = require("../assets/view_rectangle.png");
export const chatBubble = require("../assets/chat_bubble.svg");
export const bookmarkBorder = require("../assets/bookmark_border.svg");
export const upArrow = require("../assets/up.svg");
export const downArrow = require("../assets/down.svg");
export const charBubbleJobs = require("../assets/chat_bubble1.png");
export const bookmarkBorderJobs = require("../assets/bookmark_border1.svg");
export const bookmark_saved = require("../assets/bookmark_saved.svg");
export const cardTravel = require("../assets/card_travel.svg");
export const location = require("../assets/location.svg");
export const like = require("../assets/like.svg");
export const disliked = require("../assets/disliked.svg");
export const backBtn = require("../assets/button_back.svg");
export const backBtnBlue = require("../assets/buttonBackBlue.svg");
export const groupCharacter = require("../assets/group_Character.svg");
export const showAllIcon = require("../assets/showAll.svg");