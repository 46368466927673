Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.exampleApiContentType = "application/json";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Leaderboard";
exports.labelBodyText = "Leaderboard Body";

exports.btnExampleTitle = "CLICK ME";

exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";

exports.leaderboardURLEndPoint = "bx_block_leader_board/leader_boards";
exports.dateFormat = "MM/DD/YYYY";

exports.loginURLEndPoint = "bx_block_login/logins";
exports.loginEmail = "buraktest@gmail.com";
exports.loginPassword = "123456";
exports.skillsOptions = 'bx_block_joblisting/jobs/skills_listing'
exports.experinceOptions='bx_block_joblisting/jobs/get_experience_required'
exports.createConnectionEndpoint = "bx_block_friends/create_connection_request";
// Customizable Area End
