import { Stripe, StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent, StripeElements, loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { makeStyles, styled } from "@material-ui/core/styles";
import { Button, Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { bottomLeft, infoIcon, topRight, } from '../../../packages/blocks/customisableusersubscriptions/src/assets';
import { StepActiveIcon, StepDisabledIcon, StepDoneIcon } from '../../../packages/blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import React from 'react';

const PaymentDetailsStyles = makeStyles((theme) => ({
    inputElement: {
        border: "1px solid #9C9C9C",
        marginTop: "5px",
        color: "#17365D",
        padding: "10px 8px",
        borderRadius: "8px",
        width: "100%",
        "&:focus": {
            border: "1px solid #9C9C9C",
        }
    },
    inputElementError: {
        border: "1px solid #DC2626",
        marginTop: "5px",
        color: "#17365D",
        padding: "10px 8px",
        borderRadius: "8px",
        width: "100%",
        "&:focus": {
            border: "1px solid #9C9C9C",
        }
    },
    errortext: {
        color: "#DC2626",
        fontFamily: "Arial",
        fontWeight: "lighter",
        fontSize: "12px"
    },
    inputElementOne: {
        border: "1px solid #9C9C9C",
        marginTop: "5px",
        color: "#17365D",
        borderRadius: "8px",
        padding: "12.5px 8px",
        width: "105%",
        '&::placeholder': {
            color: 'grey',
            fontSize: '15px',
            fontFamily: "sans-serif",
            fontWeight: "100",
            lineHeight: "22px"
        },
        "&:focus": {
            border: "1px solid #9C9C9C",
            outline: "0px"
        },
        "@media (min-width: 960px) and (max-width: 1060px)": {
            width: "105%",
        },
        "@media (min-width: 1063px)": {
            width: "103.5%"
        },
        "@media (max-width: 960px)": {
            width: "102.5%"
        }
    },
    inputElementOneError: {
        border: "1px solid #DC2626",
        marginTop: "5px",
        color: "#17365D",
        borderRadius: "8px",
        padding: "12.5px 8px",
        width: "105%",
        '&::placeholder': {
            color: '#9C9C9C',
            fontSize: '18px',
            fontFamily: "Arial",
            fontWeight: "lighter",
            lineHeight: "22px"
        },
        "&:focus": {
            border: "1px solid #9C9C9C",
            outline: "0px"
        },
        "@media (min-width: 960px) and (max-width: 1060px)": {
            width: "105%",
        },
        "@media (min-width: 1063px)": {
            width: "103.5%"
        },
        "@media (max-width: 960px)": {
            width: "102.5%"
        }
    },
    labelText: {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#1F497D"
    },
    page: {
        background: "#F3F4F8",
        padding: "50px 100px",
        maxWidth: "1280px"
    },
    form: {
        background: "#FFFFFF",
        width: "auto",
        margin: "0px",
        padding: "24px 40px 10px 40px",
        borderRadius: "16px",
        position: "relative" as "relative"
    },
    cardTitle: {
        "color": "#1F497D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": 700,
        "lineHeight": "24px",
        "marginBottom": "20px"
    },
    membershipTitle: {
        "fontFamily": "Arial",
        "fontSize": "18px",
        "fontWeight": 700,
        "lineHeight": "26px",
        "textAlign": "left" as "left",
        "color": "#1F497D"
    },
    membershipDetailsDiv: {
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        display: "flex",
        "boxShadow": "0px 2px 8px 0px #00000014",
        gap: '20px',
    },
    membershipDetailsDivOne: {
        borderRadius: "8px",
        "boxShadow": "0px 2px 8px 0px #00000014",
        padding: "12px 16px 12px 16px",
        display: "flex",
        gap: '20px',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    membershipInfo: {
        "fontFamily": "Arial",
        'fontSize': '14px',
        'fontWeight': 400,
        'lineHeight': '22px',
        'textAlign': 'left',
        'color': '#9C9C9C',
        'marginTop': '10px'
    },
    membershipInfoOne: {
        'fontSize': '14px',
        "fontFamily": "Arial",
        'lineHeight': '22px',
        'textAlign': 'left',
        'fontWeight': 400,
        'color': '#9C9C9C',
    },
    membershipPriceDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    membershipPrice: {
        fontFamily: 'Arial',
        fontSize: '36px',
        fontWeight: 'bold',
        lineHeight: '44px',
        letterSpacing: '-0.01em',
        textAlign: 'right',
        color: '#1F497D',
        display:"flex",
        alignItems: "center",
        fontStyle:"cancle"
    },
    formfieldCont: {
        width: "100%",
        fontFamily: "Arial",
        padding: "20px 0px 20px 0px",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#D9D9D9",
            width: "6px",
            borderRadius: 16,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 16,
            backgroundColor: "#6C96C1",
            border: "0px solid #6C96C1",
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#6C96C1",

        },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            borderRadius: 16,
        },
    },
    bottomLeft: {
        position: "absolute",
        bottom: "0px",
        left: "0px"
    },
    topRight: {
        position: "absolute",
        top: "0px",
        right: "0px"
    },
    heading: {
        fontFamily: 'Arial',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
        textAlign: 'left',
        color: '#1F497D',
        marginTop: '10px'
    },
    info: {
        fontFamily: 'Arial',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1F497D',
    },
    noBtn: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "1px solid  #1F497D",
        "background": "#FFF",
        "width": "120px",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none"
    },
    yesBtn: {
        "border": "1px solid #C7D9F0",
        "color": "#FFFFFF",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "#C7D9F0"
        },
        "borderRadius": "8px",
        "background": "#6D97C1",
        "display": "flex",
        "width": "120px",
        "height": "56px",
        "padding": "16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "textTransform": "none",
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        paddingTop: "25px !important",
        borderTop: "1px solid #F4F4F4"
    },
    listDescription: {
        '& .MuiTypography-displayBlock': {
            fontFamily: "Arial",
            fontWeight: "400",
            fontSize: "16px",
        }
    },
    cancleText: {
        "textDecoration": "line-through",
        textDecorationColor: "#DC2626"
    }
}));

interface PaymentDetailsProps {
    title: string;
    details: string;
    price: string;
    onClickProceed: any;
    onClickBack: any;
    handleSubmit: (event: React.FormEvent, stripe: Stripe | null, elements: StripeElements | null) => void;
    handleChangeCardField: (name: "card" | "date" | "cvv" | "cardHolder", error: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent) => void;
    errorsPayment: {
        card: string;
        date: string;
        cvv: string;
        cardHolder: string;
    },
    handleChangeHolderName: (value: string) => void;
    cardHolder: string;
    newPrice: string;
}

const stripePromise = loadStripe('pk_test_51OAti8BAzrmLKwqhpZr1bhQNFV93J84pjDpbgYpVSXuuF5X9MvLFp4lx8A1REYYovth2yFxBEdf48C8Ubf3v8Dvy00CEUJmrn5');

interface PaymentStepsProps {
    activeStep: number;
}

let optionOfStripeField = {
    style: {
        base: {
            fontFamily: 'sans-serif',
            lineHeight: '22px',
            color: "#17365D",
            fontSize: '14px',
            '::placeholder': {
                color: '#bdbdbd',
                fontFamily: 'sans-serif',
            },
            ':-webkit-autofill': {
                color: '#17365D',
                fontFamily: 'sans-serif',
            },
        },
        invalid: {
            fontFamily: 'sans-serif',
            lineHeight: '22px',
            color: '#DC2626',
        },
        empty: {
            fontFamily: 'sans-serif',
            lineHeight: '22px',
            color: "#17365D",
            fontSize: '14px',
            '::placeholder': {
                color: '#bdbdbd',
                fontFamily: 'sans-serif',
            },
            ':-webkit-autofill': {
                color: '#17365D',
                fontFamily: 'sans-serif',
            },
        }
    }
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ title, price, details, onClickBack, handleSubmit, handleChangeCardField, errorsPayment, cardHolder, handleChangeHolderName, newPrice }) => {
    const classes = PaymentDetailsStyles();

    return (
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
                {({ stripe, elements }) => (
                    <form onSubmit={(event) => handleSubmit(event, stripe, elements)}>
                        <Grid container className={classes.page}>
                            <Grid container className={classes.form}>
                                <img src={topRight} alt="icons" className={classes.topRight} />
                                <img src={bottomLeft} alt="icons" className={classes.bottomLeft} />
                                <Grid item xs={12}>
                                    <Typography className={classes.heading}>Payment Details</Typography>
                                </Grid>
                                <Grid style={{ overflow: 'hidden' }} item xs={12}>
                                    <StyledStepper alternativeLabel activeStep={1}>
                                        <Step>
                                            <StepLabel StepIconComponent={StepDoneIcon}>Plan Selection</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel StepIconComponent={StepActiveIcon}>Payment</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel StepIconComponent={StepDisabledIcon}>Confirmation</StepLabel>
                                        </Step>
                                    </StyledStepper>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.membershipDetailsDiv}>
                                        <div >
                                            <Typography className={classes.membershipTitle}>{title}</Typography>
                                            <Typography className={classes.membershipInfo}>{details}</Typography>
                                        </div>
                                        <div className={classes.membershipPriceDiv}>
                                            <Typography className={classes.membershipPrice}><span className={newPrice !== "" ? classes.cancleText : ""}>{"$" + Math.floor(+price)}</span><span style={{ fontSize: '18px', fontWeight: 400, lineHeight: '26px' }}>/month</span></Typography>
                                        </div>
                                    </div>
                                </Grid>
                                {
                                    newPrice !== "" && <Grid item xs={12}>
                                        <div className={classes.membershipDetailsDivOne}>
                                            <div className={classes.membershipPrice}>
                                                <Typography className={classes.membershipTitle}>
                                                    Adjusted Amount
                                                </Typography>
                                                <Typography className={classes.membershipInfoOne}>&nbsp;(Amount to be pay)</Typography>
                                            </div>
                                            <Typography className={classes.membershipPrice}><span >{"$" + Math.floor(+newPrice)}</span><span style={{ fontSize: '18px', fontWeight: 400, lineHeight: '26px' }}>/month</span></Typography>
                                        </div>
                                    </Grid>
                                }
                                <Grid item sm={12} className={classes.btnContainer}>
                                    <Button id='detailsBackButton' onClick={onClickBack} className={classes.noBtn}>Back</Button>
                                    <Button id='detailsProceedButton' className={classes.yesBtn} disabled={!stripe} type="submit">Proceed</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </ElementsConsumer>
        </Elements >
    );
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        margin: '0 0 0 0'
    },
    '& .MuiStepConnector-alternativeLabel': {
        right: 'calc(50% + 7px)',
        top: 11,
        left: 'calc(-50% + 7px)',
    },
    margin: '0 -185px 0 -185px',
    '& .MuiStepConnector-line': {
        borderTopWidth: '4px',
        borderColor: '#D9D9D9',
    },
    '& .MuiStepConnector-active': {
        '& .MuiStepConnector-line': {
            borderColor: '#1F497D',
        },
    },
    '& .MuiStepConnector-completed': {
        '& .MuiStepConnector-line': {
            borderColor: '#1F497D',
        },
    },
    '& .MuiStepLabel-label': {
        fontWeight: 400,
        fontFamily: 'Arial',
        fontSize: '18px',
        lineHeight: '26px',
        textAlign: 'center'
    },
    '& .MuiStepLabel-iconContainer': {
        marginTop: '4px'
    },
    '& .MuiStepLabel-completed': {
        fontFamily: 'Arial',
        fontSize: '18px',
        color: '#17365D',
        fontWeight: 400,
        lineHeight: '26px',
        textAlign: 'center'
    },
    '& .MuiStepLabel-active': {
        fontFamily: 'Arial',
        lineHeight: '26px',
        color: '#17365D',
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'center'
    },
}));
export default PaymentDetails;

