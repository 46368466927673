Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.deleteApiMethod = 'DELETE';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';
exports.resonEndPointApi ="bx_block_contentflag/contents/get_flag_reasons"
exports.addReasonsApiEndPoint ="bx_block_contentflag/contents"

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.limitError = "You have reached the limit of chats for your subscription plan"


exports.chatList=[
  {
    image:"",
    receptientName:"Mark Antony",
    companyName:"Recruiter at X Company",
    Message:"Mark: We would like to talk to you regarding ....",
    time:"2 mins ago"
  },
  {
    image:"",
    receptientName:"Marie S",
    companyName:"Recruiter at X Company",
    Message:"Mark: We would like to talk to you regarding ....",
    time:"12 mins ago"

  },
  {
    image:"",
    receptientName:"Monica",
    companyName:"Recruiter at X Company",
    Message:"Mark: We would like to talk to you regarding ....",
    time:"Oct 12"

  },
  {
    image:"",
    receptientName:"Mark Antony",
    companyName:"Recruiter at X Company",
    Message:"Mark: We would like to talk to you regarding ....",
    time:"2 mins ago"

  },
  {
    image:"",
    receptientName:"David",
    companyName:"Recruiter at X Company",
    Message:"Mark: We would like to talk to you regarding ....",
    time:"Sept 12"
  }
]
exports.focused="Focused";
exports.singleMessageDeleteConfirmation="Are you sure you want to delete this conversation?";
exports.multipleMessageDeleteConfirmation="Are you sure you want to delete these conversation?";

exports.globalCSS = `
    ::-webkit-scrollbar {
      display: none;
    }

    * {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    `

exports.maxChatLimit = 10000
exports.getAllNewUsers="account_block/accounts"
exports.createChat="bx_block_chat/chats"
exports.sendChatMessage="bx_block_chat/messages?chat_id="
exports.checkForSubscriptionAPI = "bx_block_subscriptions/subscriptions/user_subscription_details"
exports.sendMessageOptionSetApi ="bx_block_profile/profiles/set_send_message_option"
exports.baseURL = require('../../../framework/src/config')
exports.getProfile = 'bx_block_profile/profiles'
  // Customizable Area End