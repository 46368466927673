import React, { Component, ChangeEvent } from 'react';
import { Grid, Typography, IconButton, Avatar, Button, Box } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { crossIcon } from '../assets';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { StringSchema } from 'yup';

// Define styles
const styles = createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    field: {
        display: "flex",
        alignItems: "center",
        cursor: "text",
        flexDirection: "column",
        flex: 1
    },
    placeholder: {
        fontFamily: "Arial",
        fontSize: "16px",
        color: "#9C9C9C",
        alignSelf: "center"
    },
    avatar: {
        height: "32px",
        width: "32px",
        alignSelf: "center"
    },
    gallary: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    crossIcon: {
      
    },
    input: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    fieldContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    gallaryImage: {
        height: "252px",
        width: "100%",
        padding: "10px 10px 10px 0px",
        borderRadius: "20px"
    },
    imageContainer: {
        position: "relative",
        width: '100%'
    },
    removeIcon: {
        top: "17px",
        right: "16px",
        position: "absolute",
        cursor: "pointer",
        background: '#17365D33',
        padding: '10px',
        borderRadius: '100%'
    },
    header: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%"
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
    },
    postBtn: {
        color: "#64748B",
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: "bold",
        background: "#F1F5F9",
        borderRadius: "8px",
        padding: "8px 18px",
        margin: "0px 10px 10px"
    },
    postBtnActive: {
        color: "#FFFFFF",
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: "bold",
        background: "#1F497D",
        borderRadius: "8px",
        padding: "8px 18px",
        margin: "0px 10px 10px"
    },
    textArea: {
        width: "100%",
        border: "0px",
        fontFamily: "Arial",
        fontSize: "16px",
        color: "#0F172A",
        resize: "none",
        "&:focus": {
            border: "0px",
            outline: "none"
        },
        "&::placeholder": {
            fontFamily: "Arial",
            fontSize: "14px",
            color: "#94A3B8",
        }
    }
});

// Define props interface
interface Props extends WithStyles<typeof styles> {
  images: any[],
  handleDeleteImage: (index: number)=>void,
  postContentHandler: (e: any) => void,
  postDescription: string
}

// Define component
class RichTextEditor extends Component<Props> {
    state = {
        open: false,
        comment: "",
    };


    render() {
        const { classes, images, handleDeleteImage, postContentHandler, postDescription } = this.props;
        const { open } = this.state;

        return (
            <Grid container>
                <Grid className={classes.input}>
                    <Grid className={classes.fieldContainer}>
                        <Grid id="open_field" className={classes.field} onClick={() => this.setState({ open: true })}>
                            <Grid className={classes.header}>
                            {/* <textarea placeholder='What do you want to talk about ?..' name="Text1" value={postDescription}  onChange={(event: any) => postContentHandler(event)} rows={3} className={classes.textArea}></textarea> */}
                            <TextareaAutosize aria-label="empty textarea" placeholder="What do you want to talk about ?.." name="Text1" value={postDescription}  onChange={(event: any) => postContentHandler(event)} rows={3} className={classes.textArea}/>
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    style={{ padding: "10px" }}
                                >
                                  
                                </IconButton>
                            </Grid>
                            <Grid className={classes.gallary}>
                                {
                                    images.slice().reverse().map((item: any, index: number) => (
                                        <Grid className={classes.imageContainer} item sm={(index === 0 && images.length % 2 !== 0) ? 12 : 6} key={index}>
                                            <img src={item.url ?? URL.createObjectURL(item)} style={{ height: "100%", width: "100%" }} alt={`image-${index}`} />
                                            <Box className={classes.crossIcon}>
                                                <img src={crossIcon} alt="remove" className={classes.removeIcon} onClick={() => handleDeleteImage(images.length - index - 1)} />
                                            </Box>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(RichTextEditor);
