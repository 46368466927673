import React, { Component, ChangeEvent, RefObject } from 'react';
import { Grid, Typography, IconButton, Avatar } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { gallary, remove } from '../assets';
import { Comment, Post, State, UserDetails } from '../CommentsController';
import { isLoggedIn } from '../../../../../packages/components/src/Utils.web';
import Uploadmedia3 from '../../../uploadmedia3/src/Uploadmedia3.web';

interface PostButtonProps {
    notActive: boolean;
}

const PostButton = styled('button')(({ notActive }: PostButtonProps) => ({
    color: notActive ? '#64748B': '#FFFFFF',
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "bold",
    background: notActive ? "#F1F5F9":'#1F497D',
    borderRadius: "8px",
    padding: "8px 18px",
    margin: "0px 10px 10px",
    textTransform: "none",
    height: "32px",
    display: "flex",
    alignItems: "center",
    border:'none',
    cursor:notActive ? 'auto' : 'pointer'
}));

const StyledTextArea = styled('textarea')(() => ({
    width: "100%",
    border: "0px",
    paddingTop: "10px",
    fontFamily: "Arial",
    fontSize: "14px",
    color: "#1F497D",
    resize: "none",
    "&:focus": {
        border: "0px",
        outline: "none"
    },
    "&::placeholder": {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#94A3B8",
        paddingTop: "0px"
    }
}));

// Define props interface
interface Props {
    createComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string, callback: () =>void) => void;
    state: State;
    post: Post;
    cmt: Comment;
    isNested: boolean;
    openNestedCommetns: () => void;
    goToLoginPage: () => void;
    isEditMode?: boolean;
    updateComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string, callback: () =>void) => void;
    handleEditMode?: (value: boolean) => void;
    loader:boolean;
    progress:number;
    remainingTime:number;
    userDetails: UserDetails;
    setShowTextField: (value: boolean) => void;
}
type ImageItem = File | { url: string };
type StateX = {
    open: boolean;
    images: ImageItem[];
    comment: string;
    isCommentImageUploaded: boolean
}
// Define component
class PostCommentTextField extends Component<Props, StateX> {
    state = {
        open: false,
        images: [],
        comment: "",
        isCommentImageUploaded: false
    };
    
    ref: RefObject<HTMLTextAreaElement> = React.createRef();

    handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = [...Array.from(e.target.files)];
            this.setState({ images: files });
        }
        this.setState({ isCommentImageUploaded: true });
    };

    handleCommentImageLoader = () => {
        this.setState({ isCommentImageUploaded : false });
    }

    handleDeleteImage = (index: number) => {
        const updatedImages = this.state.images.filter((_, i) => i !== index);
        this.setState({ images: updatedImages });
    };

    handleLoginUser = () => {
        if (!isLoggedIn()) {
            this.props.goToLoginPage();
        }
    }

    componentDidMount(): void {
        if (this.props.isEditMode) {
            this.setState({
                open: true,
                images: this.props.cmt.comment_images,
                comment: this.props.cmt.comment || ""
            })
        }
    }

    onClickButton = () => {
        const { createComment, post, cmt, isNested, openNestedCommetns, isEditMode = false, updateComment, handleEditMode = () => { } } = this.props;
        const { images, comment } = this.state;
        if (isEditMode) {
            updateComment(comment, images, isNested, isNested ? cmt.id : post.id, post.id,  () => this.props.setShowTextField(false));
            this.setState({
                comment: "",
                images: [],
            });
            handleEditMode(false)
        } else {
            createComment(comment, images, isNested, isNested ? cmt.id : post.id, post.id, () => this.props.setShowTextField(false))
            openNestedCommetns()
            this.setState({
                comment: "",
                images: []
            })
        }
    }

    render() {
        const { isEditMode = false, loader, progress, remainingTime} = this.props;
        const { open, images, comment } = this.state;

        return (
            <Grid container style={{ padding: "5px 20px 0px 20px",display: "flex",alignItems: "center",justifyContent: "space-between"}}>
                <Grid style={{display: "flex",flexDirection: "column",width: "100%"}} >
                    <Grid style={{display: "flex",alignItems: "center",justifyContent: "space-between",}} >
                        <Grid style={{display: "flex",alignItems: "center",justifyContent: "space-between"}} >
                            <Avatar style={{height: "32px",width: "32px", border: "2px solid #1F497D"}} src={this.props.userDetails.attributes.photo || ''} ></Avatar>
                        </Grid>
                        &nbsp;
                        &nbsp;
                        <Grid id="open_field" style={{ position: 'relative',
                            border: "1px solid #9C9C9C", borderRadius: "16px", padding: "0px 0px 0px 10px", display: "flex", alignItems: "center", cursor: "text", flexDirection: "column", flex: 1,
                        }} onClick={() => this.setState({ open: true }, () => this.ref.current?.focus())}>
                                {loader ?
                                    <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)', }}>
                                        <Uploadmedia3 navigation="navigation" id="id" progress={progress} time={remainingTime} />
                                    </div> : null
                                }
                            <Grid style={{display: "flex",alignItems: "flex-start",justifyContent: "space-between",width: "100%"}}  onClick={this.handleLoginUser}>
                                {!open ? <Typography style={{fontFamily: "Arial",fontSize: "14px",color: "#94A3B8",alignSelf: "center",}} >Add a comment</Typography> : <StyledTextArea id="text-area" ref={this.ref} style={{zIndex:99,}} placeholder='Add a comment' name="Text1" value={comment} onChange={(event) => this.setState({ comment: event.target.value })} rows={2}></StyledTextArea>}
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    style={{ padding: "6px", zIndex:99 }}
                                >
                                    <img src={gallary} alt="gallery" />
                                    <input
                                        type="file"
                                        hidden
                                        name="photo"
                                        accept="image/*"
                                        id="group_image"
                                        multiple={false}
                                        onClick={() => this.handleCommentImageLoader()}
                                        onChange={(event) => this.handleImageChange(event)}
                                    />
                                </IconButton>
                            </Grid>
                            {open && <Grid style={{display: "flex",flexWrap: "wrap",width: "100%"}} >
                                {images.map((item, index) => (
                                    <Grid style={{position: "relative",}} key={index}>
                                        <img src={(isEditMode && 'url' in item) ? (item as { url: string }).url : URL.createObjectURL(item)} alt={`Preview ${index}`} style={{height: "90px",width: "90px",padding: "10px 10px 10px 0px",borderRadius: "20px"}}  />
                                        <img src={remove} alt="remove" style={{top: "17px",right: "16px",position: "absolute",cursor: "pointer"}}  onClick={() => this.handleDeleteImage(index)} />
                                    </Grid>
                                ))}
                            </Grid>}
                            {open && <Grid style={{display: "flex",alignItems: "center",justifyContent: "flex-end",width: "100%",}} >
                                <PostButton
                                    id="postButton"
                                    notActive={(this.state.comment.trim() === "") && this.state.images.length === 0}
                                    onClick={this.state.comment.trim() === '' && this.state.images.length === 0 ? () => { } : this.onClickButton}
                                >
                                    {this.props.isEditMode ? 'Save' : 'Post'}
                                </PostButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default PostCommentTextField;
