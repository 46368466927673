import React, { useState, useRef, useEffect } from "react";
import { ClickAwayListener, Grow, Popper, MenuItem, MenuList, Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CandidateLeaderboard, Score } from '../../../packages/blocks/leaderboard/src/LeaderboardController.web';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      
      
        },
   
    btn: {
      minWidth: "auto",
      padding: 0,
    },
    menuitem: {
      fontSize: '12px',
      fontWeight: 400,

      color: '#1F497D',
      fontFamily: 'Arial',

      '&:hover': {
        fontWeight: "bold",
          backgroundColor: 'transparent', 
        }    
  },
  })
);

type CustomPopperProps = {
  rowId: string;
  onSendInvite: () => void;
  onSendMessage: () => void;
  testId?: string
  rowData: CandidateLeaderboard
};

const CustomPopper: React.FC<CustomPopperProps> = ({ rowId, onSendMessage, onSendInvite, testId, rowData }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

 

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }


  return (
    <div data-test-id={testId}>
      <Button ref={anchorRef} onClick={handleToggle} className={classes.btn}>
        <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14Z" fill="#64748B" />
        </svg>
      </Button>
      <div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal 
      style={{
        zIndex:10,
        background: "#FFF",
        border: '1px solid #F4F4F4',
        marginTop:'10px',
        borderRadius:'16px',
        width: '207px',
        padding:' 2px 15px',
        
}}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: "center top", background: "#FFF", }} 
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id={`menu-list-grow-${rowId}`}
                onKeyDown={handleListKeyDown}
                className={classes.popover}
              >
                  <MenuItem className={classes.menuitem} onClick={onSendMessage}>Send message</MenuItem>
                  {
                    !rowData.attributes.connection_request_details && <MenuItem className={classes.menuitem} onClick={onSendInvite}>Send invite</MenuItem>
                  }
                  {
                    rowData.attributes.connection_request_details
                    && rowData.attributes.connection_request_details.status === "pending"
                    && <MenuItem className={classes.menuitem} style={{cursor: "text"}}>Invited</MenuItem>
                  }
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
      </div>
    </div>
  );
};

export default CustomPopper;
