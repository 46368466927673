import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { FormControlLabel, Typography } from '@material-ui/core';


interface StyleProps {
    checkedColorBg: string;
    isPrimary: boolean;
    borderRadius: string;
    fontSize: string;
    heightWidth: string;
}

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: (props: StyleProps) => ({
        borderRadius: 5,
        width: props.heightWidth,
        height: props.heightWidth,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    }),
    checkedIcon: (props: StyleProps) => ({
        backgroundColor: props.checkedColorBg,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        borderRadius: props.borderRadius,
        boxShadow: "none",
        '&:before': {
            display: 'block',
            height: props.heightWidth,
            width: props.heightWidth,
            backgroundSize: "cover",
            backgroundImage: props.isPrimary ?
                "url(\"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjYiIGZpbGw9IndoaXRlIi8+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjU1NTggNC44ODAyNEMxMy45NjcyIDUuMTU5MTYgMTQuMDc0NiA1LjcxODc5IDEzLjc5NTcgNi4xMzAyMkw5LjIxOTYyIDEyLjg4MDJDOS4wNzAxOCAxMy4xMDA3IDguODMwNzggMTMuMjQzNiA4LjU2NTgyIDEzLjI3MDZDOC4zMDA4NyAxMy4yOTc1IDguMDM3NTggMTMuMjA1OCA3Ljg0Njc4IDEzLjAyTDQuMzIyODggOS41ODgzNkMzLjk2Njc4IDkuMjQxNTggMy45NTkyMiA4LjY3MTc4IDQuMzA2IDguMzE1NjhDNC42NTI3OCA3Ljk1OTU3IDUuMjIyNTggNy45NTIwMSA1LjU3ODY4IDguMjk4NzlMOC4zMzI3MiAxMC45ODA3TDEyLjMwNTggNS4xMjAxNkMxMi41ODQ4IDQuNzA4NzMgMTMuMTQ0NCA0LjYwMTMyIDEzLjU1NTggNC44ODAyNFoiIGZpbGw9IiM2RDk3QzEiLz4NCjwvc3ZnPg0K\")" : "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: props.checkedColorBg,
        },
    }),
    description: (props: StyleProps) => ({
        "color": props.isPrimary ? "#9C9C9C" : "rgba(23, 54, 93, 1)",
        "fontFamily": "Arial",
        "fontSize": props.fontSize,
        "fontStyle": "normal",
        "fontWeight": "lighter",
    }),
    checkdescription: (props: StyleProps) => ({
        "color": props.isPrimary ? "#FFFFFF" : "rgba(23, 54, 93, 1)",
        "fontFamily": "Arial",
        "fontSize": props.fontSize,
        "fontStyle": "normal",
        "fontWeight": "lighter",
    })
});


function CustomCheckBoxComponent(props: CheckboxProps & { title: string, checkedColorBg?: string, isPrimary?: boolean, borderRadius?: string, fontSize?: string, heightWidth?: string, onClick?: () => void }) {
    let { isPrimary = false, title = "", checked, checkedColorBg = "#6D97C1", borderRadius = '', fontSize = '14px', heightWidth = '16px', onClick } = props;
    const classes = useStyles({ checkedColorBg, isPrimary, borderRadius, fontSize, heightWidth } as StyleProps);

    return (
        <FormControlLabel
            control={<Checkbox
                className={classes.root}
                onClick={onclick ? onClick : (event) => {
                    event.stopPropagation()
                }}
                disableRipple
                color="default"
                size="medium"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                {...props}
            />}
            label={title.trim() !== "" ? <Typography className={!checked ? classes.description : classes.checkdescription}>{title}</Typography> : ""}
        />

    );
}

export default CustomCheckBoxComponent