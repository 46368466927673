import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { LeaderboardItem, LeaderboardItemResponse } from "./types";
import { createRequestMessage } from "./Helpers/create-request-message";
import { handleResponseMessage } from "./Helpers/handle-response-message";
import * as React from "react";

// Customizable Area Start
export  interface RowType {
  id:string
  rank: string;
  name: string;
  image: string;
  percentileScore: string;
  potentialScore: string;
  jobTitle: string;
  city: string;
  skills?: string[];
  badges: string;
  personalCredentials?: number;
  dealExperience?: number;
  marketKnowledge?: number;
  networking?: number;
  professionalDevelopment?: number;
  communityEngagement?: number;
  date?:string
}
import { getStorageData } from "framework/src/Utilities";
import { debounce } from "../../../../packages/components/src/Utils.web";
import { toast } from "react-toastify";

export interface CandidateLeaderboard {
  id: string;
  type: "candidates_leader_board";
  attributes: {
    account_id: number;
    total_potential_score: number,
    full_name: string;
    current_title: string;
    experience: string;
    salary: string;
    current_company: string;
    city: string;
    country: string;
    skills: string[];
    open_for_direct_approach: boolean;
    overall_rankov: number;
    percentile_score: number;
    current_user_id: number;
    photo: string;
    added_to_pipeline: boolean;
    connection_request_details: ConnectionRequestDetails | null;
    progress_details: ProgressDetails;
    overall_rank: number;
    total_progress_percentage: string;
  };
}

interface ConnectionRequestDetails {
  id: number;
  sender_id: number;
  receiver_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  note: string;
}

export interface ProgressDetails {
  data: Score[];
}

export interface Score {
  id: string;
  type: "score";
  attributes: ScoreAttributes;
}

interface ScoreAttributes {
  id: number;
  name: string;
  education_points: number;
  personal_details_points: number;
  skill_details_points: number;
  work_experience_points: number;
  deal_contribution_points: number;
  deal_size_complexity_points: number;
  market_knowledge_transaction_points: number;
  connections_points: number;
  feedposts_points: number;
  comments_points: number;
  upvotes_points: number;
  total_points: number;
  absolute_score: number;
  percentile_score: number;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hasSubscription: boolean,
  visibleRows: number,
  data: RowType[],
  remainingData:RowType[],
  anchorEl: HTMLElement | null;
  isLoadingWeb: boolean;
  leaderboardWeb: LeaderboardItem[];
  tokenWeb: string;
  totalCountWeb: number;
  totalPageWeb: number;
  pageIndexWeb: number;
  moreLoadingWeb: boolean;
  showRewards:boolean;
  userRole:string;
  currentPage: number;
  totalPages: number;
  isInfiniteLoading: boolean;
  userslist: CandidateLeaderboard[];
  currentUser: CandidateLeaderboard;
  experienceList: { id: number | string, title: string }[],
  skillsList: string[],
  selectedCountryRadio: string,
  selectedCityRadio: string,
  selectedSkills: string[],
  selectedExperiences: string[],
  isoCode: string,
  searchUser: string,
  highest_potential_score: number,
  average_potential_score: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeaderboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginLeaderboardWebApi: string;
  getLeaderboardWebApi: string;
  getMoreLeaderboardWebApi: string;
  pageSizeWeb: number;
  getUserslistApiId: string = "";
  getCurrentUserApiId: string = "";
  jobExperienceApiId: Message | string = ""
  jobSkillsApiId: Message | string = ""
  createConnectionApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      highest_potential_score: 0,
      average_potential_score: 0,
      hasSubscription: true,
      showRewards:true,
      visibleRows: 10,
      isLoadingWeb: false,
      anchorEl: null,
      leaderboardWeb: [],
      tokenWeb: "",
      pageIndexWeb: 1,
      totalCountWeb: 1,
      totalPageWeb: 1,
      moreLoadingWeb: false,
      remainingData:[ 
    ],
      data: [
        { id:'1',rank: '1st',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/men/1.jpg', percentileScore: '99%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge1', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'2',rank: '2nd',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/2.jpg', percentileScore: '97%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge2', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'3',rank: '3rd',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '95%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge3', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'4',rank: '4th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/men/1.jpg', percentileScore: '93%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge4', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'5',rank: '5th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '91%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge5', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'6',rank: '6th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'7',rank: '7th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'8',rank: '8th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'9',rank: '9th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'10',rank: '10th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'11',rank: '11th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'12',rank: '12th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'13',rank: '13th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'14',rank: '14th',date:'29 Nov', name: 'YOU', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
  
      
      ],

      userRole:'',
      currentPage: 1,
      totalPages: 1,
      isInfiniteLoading: false,
      userslist: [] as CandidateLeaderboard[],
      currentUser: {} as CandidateLeaderboard,
      experienceList: [],
      skillsList: [],
      selectedCountryRadio: "",
      selectedCityRadio: "",
      selectedSkills: [],
      selectedExperiences: [],
      isoCode: "",
      searchUser: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGetLeaderboardWeb = this.handleGetLeaderboardWeb.bind(this);
    this.handleGoToPage = this.handleGoToPage.bind(this);
    this.loginLeaderboardWebApi = "";
    this.getLeaderboardWebApi = "";
    this.getMoreLeaderboardWebApi = "";
    this.pageSizeWeb = 10;
    // Customizable Area End
  }

  async receive(from: string, receiveMessage: Message) {
    // Customizable Area Start
    if (receiveMessage.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallDataId = receiveMessage.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseDataJson = receiveMessage.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorDataJson = receiveMessage.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.setState({
        moreLoadingWeb: false,
      });

      switch (apiRequestCallDataId) {
        case this.getLeaderboardWebApi: {
          this.setState({
            isLoadingWeb: false,
          });
          handleResponseMessage({
            responseJson: responseDataJson,
            errorJson: errorDataJson,
            onSuccess: () => {
              const { leader_boards, message } = responseDataJson;
              if (leader_boards) {
                const { data, meta } = leader_boards;
                this.handleUpdatePaginationWeb(meta);
                this.handleUpdatePageData(data, false);
              } else {
                this.showAlert("", message);
              }
            },
            onFail: () => {
              this.showAlert("Error", "Get Leaderboard Failed! Please retry");
            },
          });
          break;
        }

        case this.loginLeaderboardWebApi: {
          handleResponseMessage({
            responseJson: responseDataJson,
            errorJson: errorDataJson,
            onSuccess: () => {
              this.setState(
                {
                  tokenWeb: responseDataJson.meta.token,
                },
                () => {
                  this.handleGetLeaderboardWeb();
                }
              );
            },
            onFail: () => {
              this.showAlert("Error", "Get Token Failed. Please retry!");
            },
          });
          break;
        }

        case this.getMoreLeaderboardWebApi: {
          handleResponseMessage({
            responseJson: responseDataJson,
            errorJson: errorDataJson,
            onSuccess: () => {
              const { leader_boards } = responseDataJson;
              if (leader_boards) {
                const { data, meta } = leader_boards;
                this.handleUpdatePaginationWeb(meta);
                this.handleUpdatePageData(data, true);
              }
            },
            onFail: () => {
              this.showAlert("Error", "Get Leaderboard Failed! Please retry");
            },
          });
          break;
        }

        case this.getUserslistApiId: {
          this.handleResponseUserList(responseDataJson);
          break;
        }

        case this.getCurrentUserApiId : {
          this.handleResponseCurrentUser(responseDataJson)
          break;
        }

        case this.jobExperienceApiId:
          this.handleJobExperienceResponseOne(responseDataJson);
          break;

        case this.jobSkillsApiId:
          this.handleJobSkillsResponseOne(responseDataJson);
          break;
        
        case this.createConnectionApiId:
          this.handleResponseSendInvite(responseDataJson)
          break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  navigateToSubscriptionsPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(msg);
  };

  handleRecruiterNavigation = (recruiterId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), recruiterId);
    this.send(message);
}


  handleOpenChat = (user: CandidateLeaderboard) => {
    if(user.attributes.connection_request_details?.status === "accepted" || user.attributes.open_for_direct_approach) {
      const reqMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
      reqMessage.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
      reqMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData),
        {
          from: "Profile", accountId: {
            data: {
              attributes: {
                photo: user.attributes.photo,
                full_name: user.attributes.full_name,
                current_title: user.attributes.current_title,
                current_company: user.attributes.current_company,
                account_details: {
                  id: user.attributes.account_id
                }
              }
            }
          }
        }
      )
      reqMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(reqMessage);
    } else {
      toast.error("User is not open for direct approach")
    }
  }

  handleSendConnectRequest = async (user: CandidateLeaderboard) => {
    const authToken = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: authToken,
    };
    let invitationBody = {
      "connection_request": {
        "receiver_id": user.attributes.account_id,
        "note": "Hello, i want to connect with you"
      }
    }
    const createConnectionRequestApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createConnectionApiId = createConnectionRequestApi.messageId;
    createConnectionRequestApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    createConnectionRequestApi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(invitationBody)
    );
    createConnectionRequestApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createConnectionEndpoint
    );
    createConnectionRequestApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(createConnectionRequestApi.id, createConnectionRequestApi);
  }

  setSearchUser = (value: string) => {
    this.setState({
      searchUser: value
    })
  }
  clearFilter = () => {
    this.setState({
      selectedCityRadio: "",
      selectedCountryRadio: "",
      selectedSkills: [],
      selectedExperiences: []
    })
  }

  setISOCode = (value: string) => {
    this.setState({
      isoCode: value
    })
  }

  skillsByHandleClickOne = (opt: { id: number | string, title: string }) => {
    this.setState({ selectedSkills: this.state.selectedSkills.includes(opt.title) ? this.state.selectedSkills.filter(item => item !== opt.title) : [...this.state.selectedSkills, opt.title] })
  }

  experienceHandleClickOne = (opt: { id: number | string, title: string }) => {
    this.setState({ selectedExperiences: this.state.selectedExperiences.includes(opt.title) ? this.state.selectedExperiences.filter(item => item !== opt.title) : [...this.state.selectedExperiences, opt.title] })
  }

  handleCountryChange = (value: string) => {
    this.setState({
      selectedCountryRadio: value
    })
  }

  handleCityChange = (value: string) => {
    this.setState({
      selectedCityRadio: value
    })
  }

  handleJobExperienceResponseOne = (responseJson: { experience_required: [] }) => {
    const options = responseJson.experience_required.map((item, index) => ({
      title: item,
      id: index,
    }));
    this.setState({ experienceList: options });
  }

  handleJobSkillsResponseOne = (responseJson: { skills: [] }) => {
    this.setState({ skillsList: responseJson.skills });
  }

  handleResponseUserList = (responseJason: {error: string} | {current_page: number, total_page: number, total_candidates: number, average_potential_score?:number, highest_potential_score?: number, candidates_leaderboard: { data: CandidateLeaderboard[]}}) => {
    this.setState({
      isInfiniteLoading: false,
    })

    if ('error' in responseJason) {
      this.setState({
        hasSubscription: false
      })
      return;
    }

    let { current_page, total_page, candidates_leaderboard : { data }} = responseJason;
    this.setState({
      userslist : current_page === 1 ? data : [...this.state.userslist, ...data],
      totalPages: total_page,
      highest_potential_score: responseJason.highest_potential_score ?? 0,
      average_potential_score: responseJason.average_potential_score ?? 0
    })
  }

  handleResponseCurrentUser =  (responseJason: { candidate_leaderboard_details: { data: CandidateLeaderboard } }) => {
    let { candidate_leaderboard_details : { data }} = responseJason;
    this.setState({
      currentUser: data
    })
  }

  handleResponseSendInvite = (responseJason: {message?: string, error: string, connection_request: { data: {attributes: {receiver_details: { data: {id: string}}}} }}) => {
    if(responseJason.message) {
      let tempId = responseJason.connection_request.data.attributes.receiver_details.data.id;
      this.setState({
        userslist: this.state.userslist.map(user => user.id === tempId ? ({
          ...user, attributes: {
            ...user.attributes,
            connection_request_details: {
              status: "pending",
              sender_id: +this.state.currentUser.id,
              receiver_id: +tempId,
              created_at: "",
              updated_at: "",
              note: "",
              id: 0
            }
          }
        }) : user)
      })
      toast.success("Invite send successfully")
    } else {
      toast.error(responseJason.error)
    }
  }

  handleScroll = () => {
    if(this.state.currentPage > this.state.totalPageWeb) {
      return
    }
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1
    }))
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.currentPage !== this.state.currentPage && this.state.currentPage <= this.state.totalPages) {
      this.getAllUsers(this.state.currentPage);
    }

    const { selectedCityRadio, selectedCountryRadio, selectedExperiences, selectedSkills, searchUser } = this.state;

    if (
      prevState.selectedCityRadio !== selectedCityRadio ||
      prevState.selectedCountryRadio !== selectedCountryRadio ||
      prevState.selectedExperiences !== selectedExperiences ||
      prevState.selectedSkills !== selectedSkills
    ) {
      this.getAllUsers(1);
    }
    if (prevState.searchUser !== searchUser) {
      this.debouncedGetUsers(1);
    }
  }

  getURL = (page?: number): string => {
    let URL = `/bx_block_leader_board/leader_boards/candidates_leaderboard?page=${page ?? this.state.currentPage}&per_page=20`

    if(this.state.selectedCityRadio) {
      URL += `&city=${this.state.selectedCityRadio}`
    }

    if(this.state.searchUser) {
      URL += `&search_key=${this.state.searchUser}`
    }

    if(this.state.selectedCountryRadio) {
      URL += `&country=${this.state.selectedCountryRadio}`
    }

    if(this.state.selectedExperiences.length > 0) {
      for(let experience of this.state.selectedExperiences)
      URL += `&experience[]=${experience}`
    }

    if(this.state.selectedSkills.length > 0) {
      for(let skill of this.state.selectedSkills)
      URL += `&skills[]=${skill}`
    }

    return URL
  }

  getAllUsers = async (page?: number) => {
    let token = await getStorageData("authToken");

    if (page) {
      this.setState({
        isInfiniteLoading: true
      })
    }

    let URL = this.getURL(page);

    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      token: token
    };
  
    const getAllUsers = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserslistApiId = getAllUsers.messageId;
  
    getAllUsers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );
  
    getAllUsers.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    getAllUsers.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
  
    runEngine.sendMessage(getAllUsers.id, getAllUsers);
  }

  debouncedGetUsers = debounce(this.getAllUsers, 300);

  getCurrentUser = async () => {
    let token = await getStorageData("authToken");

    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      token: token
    };

    const requestCurrentUser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrentUserApiId = requestCurrentUser.messageId;

    requestCurrentUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_leader_board/leader_boards/candidate_leaderboard_details`
    );

    requestCurrentUser.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestCurrentUser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestCurrentUser.id, requestCurrentUser);
  }


  jobExperience = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token":  await getStorageData("authToken")
    };

    const getJobExperienceOne = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobExperienceApiId = getJobExperienceOne.messageId;

    getJobExperienceOne.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.experinceOptions}`
    );

    getJobExperienceOne.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getJobExperienceOne.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(getJobExperienceOne.id, getJobExperienceOne);
  }

  jobSkills = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token":  await getStorageData("authToken")
    };

    const getJobSkillsOne = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobSkillsApiId = getJobSkillsOne.messageId;

    getJobSkillsOne.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.skillsOptions}`
    );

    getJobSkillsOne.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getJobSkillsOne.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(getJobSkillsOne.id, getJobSkillsOne);
  }

 async componentDidMount() {
  const userRole = await getStorageData("userRole")
  this.setState({
    userRole
  });
  if(userRole === "candidate"){
    this.getCurrentUser();
  }
  this.getAllUsers();
  this.jobExperience();
  this.jobSkills();
}

  handleConvertDataWeb(data: LeaderboardItemResponse[]): LeaderboardItem[] {
    return data.map((item: LeaderboardItemResponse, index) => ({
      ...item.attributes,
      position: (this.state.pageIndexWeb - 1) * 10 + index + 1,
    }));
  }
  handleUpdatePaginationWeb(meta: {
    pagination: {
      current_page: number;
      next_page: number | null;
      prev_page: number | null;
      total_pages: number;
      total_count: number;
      current_count: number;
      per_page: number;
    };
  }) {
    if (meta && meta.pagination) {
      const { total_pages, total_count, current_page } = meta.pagination;

      this.setState({
        totalPageWeb: total_pages,
        totalCountWeb: total_count,
        pageIndexWeb: current_page,
      });
    }
  }
  handleUpdatePageData(data: LeaderboardItemResponse[], loadMore: boolean) {
    if (data) {
      const mapping: LeaderboardItem[] = this.handleConvertDataWeb(data);

      if (loadMore) {
        this.setState(() => ({
          moreLoadingWeb: false,
          leaderboardWeb: [...mapping],
        }));
      } else {
        this.setState({
          leaderboardWeb: mapping,
        });
      }
    }
  }
  
  handleGetLeaderboardWeb() {
    this.setState({
      isLoadingWeb: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLeaderboardWebApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.leaderboardURLEndPoint}?per_page=${
        this.pageSizeWeb
      }&page=${1}`,
      method: configJSON.getApiMethodType,
      token: this.state.tokenWeb,
    });
  }

  handleGoToPage(event?: React.ChangeEvent<unknown>, page?: number) {
    if (page) {
      this.setState({
        pageIndexWeb: page,
        moreLoadingWeb: true,
      });

      const requestMoreMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getMoreLeaderboardWebApi = requestMoreMessage.messageId;

      createRequestMessage({
        requestMessage: requestMoreMessage,
        endPoint: `${configJSON.leaderboardURLEndPoint}?per_page=${this.pageSizeWeb}&page=${page}`,
        method: configJSON.getApiMethodType,
        token: this.state.tokenWeb,
      });
      
    }
    
  }

  handleCondition = (condition: boolean, truePart: JSX.Element, falsePart: JSX.Element): JSX.Element  => {
    return condition ? truePart : falsePart
  }

  handleRewards=()=>{
    this.setState({showRewards:false})
  }
  handlePrevious=()=>{
    this.setState({showRewards:true})
  }
  handleFuntion =()=>{}
  // Customizable Area End
}
