import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { backIcon, bottomLeft, topRight } from "./assets";
import { Field, Form, Formik, FormikErrors, FormikTouched } from "formik";
import CustomSelectField from "../../../components/src/CustomSelectField";
import CustomTextField from "../../../components/src/CustomTextField";
import * as Yup from 'yup';
import { ToastContainer } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";
import {
  DealExperienceType,
  SetFieldsType,
  SetFieldTouchedType,
} from "./EducationalUserProfileController";
import AutoCompleteEducational from "../../../components/src/AutoCompleteEducational.web";
import { getCommaSeparatedValue } from "../../../../packages/components/src/Utils.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  imageStyle: {
    width: 100,
  },
  qualificationBlock: {
    marginTop: 40,
  },
};
// Customizable Area End

import EducationalUserProfileController, {
  Props,
  Tab as PageTab,
  configJSON,
} from "./EducationalUserProfileController";

export default class EducationalUserProfile extends EducationalUserProfileController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const validationSchema = Yup.object().shape({
      deal_type: Yup.string().required('Required'),
      involvement_type: Yup.string().required('Required'),
      main_contributions: Yup.string().required('Required'),
      transaction_merits: Yup.string().required('Required'),
      potential_risks: Yup.string().required('Required'),
      transaction_side: Yup.string().required('Required'),

      target_name: Yup.string().when('deal_type', { is: 'equity', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(),}),
      buyer_investor_name: Yup.string().nullable().when('deal_type', { is: 'equity', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
      transaction_summary: Yup.string().nullable().when('deal_type', { is: 'equity', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
      enterprise_value: Yup.string().nullable().matches(/^\d+(\.\d+)?$/, 'Invalid value').when('deal_type', { is: 'equity', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(),}),
      percentage_stake: Yup.string()
      .nullable()
      .matches(/^\d+(\.\d+)?%?$/, 'Invalid value')
      .test('is-valid-percentage', 'Percentage must be between 0 and 100', (value) => {
          if (!value) return true;
          const numericValue = parseFloat(value.replace('%', ''));
          return numericValue >= 0 && numericValue <= 100;
      })
      .when('deal_type', {
          is: 'equity',
          then: Yup.string().required('Required'),
          otherwise: Yup.string().notRequired(),
      }),
      valuation_method: Yup.string().nullable().when('deal_type', { is: 'equity', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(),}),
      valuation_multiple: Yup.string().matches(/^\d+(\.\d+)?x?$/, 'Invalid value').nullable().when('deal_type', { is: 'equity', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(),}),

      debt_type: Yup.string().nullable().when('deal_type', { is: 'debt', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
      borrower_name: Yup.string().nullable().when('deal_type', { is: 'debt', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
      lender_name: Yup.string().nullable().when('deal_type', { is: 'debt', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
      debt_size: Yup.string().nullable().matches(/^\d+(\.\d+)?$/, 'Invalid value').when('deal_type', { is: 'debt', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
      leverage_level: Yup.string().nullable().matches(/^\d+(\.\d+)?x?$/, 'Invalid value').when('deal_type', { is: 'debt', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
      interest_rate: Yup.string().nullable().matches(/^\d+(\.\d+)?%?$/, 'Invalid value').when('deal_type', { is: 'debt', then: Yup.string().required('Required'), otherwise: Yup.string().notRequired(), }),
    })

    return (
      <ThemeProvider theme={theme}>
        <Grid style={this.webStyles.container} id="pageContainer">
          <img src={topRight} alt="icons" style={this.webStyles.topRight} />
          <img src={bottomLeft} alt="icons" style={this.webStyles.bottomLeft} />
          <Box style={this.webStyles.backWrapper}>
            <img src={backIcon} style={{ cursor: "pointer", padding: "6px 8px" }} onClick={() => this.handleBackClick()} />
            <Box>
              <Typography style={this.webStyles.headingText}>
                Edit Deal Experience
              </Typography>
            </Box>
          </Box>
          <Box data-test-id="dealExperiencePage" style={{ paddingRight: "40px", paddingLeft: "40px" }}>
            <Formik
              initialValues={this.state.initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.updateDealExperience(values)
              }}
              enableReinitialize
              validateOnBlur={true}
            >
              {({ values, setFieldValue, setFieldTouched, touched, errors }) => (
                <Form translate="no">
                  <Grid item style={{ marginTop: "32px" }}>
                    <Field
                      name="deal_type"
                      component={CustomSelectField}
                      placeHolder="Select"
                      type="string"
                      variant="outlined"
                      title="Were you involved on the Equity side (M&A, IPO, private placement, etc.) or the Debt side?"
                      options={[
                        { value: "equity", label: "Equity" },
                        { value: "debt", label: "Debt" },
                      ]}
                      rootStyle={{ fontWeight: "400 !important" }}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: "32px" }}>
                    <Field
                      name="involvement_type"
                      component={CustomSelectField}
                      placeHolder="Select"
                      type="string"
                      variant="outlined"
                      title={
                        <span>
                          Were you <span style={this.webStyles.underLineText}>directly involved</span> in this transaction, or <span style={this.webStyles.underLineText}>highly familiar from an outside-in</span> perspective?
                        </span>
                      }
                      options={[
                        { value: "Directly involved", label: "Directly involved" },
                        { value: "Highly familiar from an outside perspective", label: "Highly familiar from an outside perspective" },
                      ]}
                    />
                  </Grid>

                  {this.renderOnDealType(values, setFieldValue, setFieldTouched, touched, errors)}

                  <Grid item style={{ marginTop: "32px" }}>
                    <Field
                      name="main_contributions"
                      isMultiple
                      component={CustomSelectField}
                      placeHolder="Select"
                      type="string"
                      variant="outlined"
                      title="Please select your main contribution(s) in this transaction. You can list multiple roles."
                      options={[
                        { value: "Financial modelling", label: "Financial modelling" },
                        { value: "Ratings/creditworthiness analysis", label: "Ratings/creditworthiness analysis" },
                        { value: "Commercial due diligences", label: "Commercial due diligences" },
                        { value: "Financial due diligence", label: "Financial due diligence" },
                        { value: "Tax due diligence", label: "Tax due diligence" },
                        { value: "Legal due diligence", label: "Legal due diligence" },
                        { value: "Transaction structuring", label: "Transaction structuring" },
                        { value: "Preparation of memorandum for investment committee approval", label: "Preparation of memorandum for investment committee approval" },
                        { value: "Roadshow", label: "Roadshow" },
                        { value: "Other", label: "Other" },
                      ]}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: "32px" }}>
                    <Field
                      name="transaction_merits"
                      component={CustomTextField}
                      isMultiline={true}
                      placeHolder="Type Message Here"
                      type="string"
                      variant="outlined"
                      title="What, in your perspective, are the merits/rationale of the transaction?"
                    />
                  </Grid>
                  <Grid item style={{ marginTop: "32px" }}>
                    <Field
                      name="potential_risks"
                      component={CustomTextField}
                      isMultiline={true}
                      placeHolder="Type Message Here"
                      type="string"
                      variant="outlined"
                      title="What are the potential risks of the transaction from your perspective?"
                    />
                  </Grid>
                  <Grid>
                    <Typography style={this.webStyles.thankYouText}>
                      Thank you for sharing your transaction experience. Your insights are invaluable and contribute to a comprehensive understanding of transaction dynamics. If you wish to add another transaction experience or have any other questions, you can still edit the submitted responses in your profile page!
                    </Typography>
                  </Grid>
                  <Grid item sm={12} style={this.webStyles.btnContainer}>
                    <Button data-test-id="cancelBtn" style={{ ...this.webStyles.noBtn, "textTransform": "none" }} onClick={() => this.handleNavigateOnCancel()}>
                      Cancel
                    </Button>
                    <Button style={{ ...this.webStyles.yesBtn, "textTransform": "none" }} type="submit">
                      Save
                    </Button>
                  </Grid>

                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <ToastContainer />
      </ThemeProvider>
    );
    // Customizable Area End
  }
  // Customizable Area Start

  renderOnDealType = (values: DealExperienceType , setFieldValue: SetFieldsType, setFieldTouched: SetFieldTouchedType, touched: FormikTouched<DealExperienceType> , errors: FormikErrors<DealExperienceType>) => {
    if (values.deal_type === 'equity') {
      return (
        <>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="transaction_side"
              component={CustomSelectField}
              placeHolder="Select"
              type="string"
              variant="outlined"
              title={
                <span>
                  Were you on the <span style={this.webStyles.boldText}>Sell-side</span> (Target company and its advisors) or the <span style={this.webStyles.boldText}>Buy-side</span> (Buyer/Investor/Bidder and its advisors)?
                </span>
              }
              options={[
                { value: "Sell-side, working at the Target", label: "Sell-side, working at the Target" },
                { value: "Sell-side, with a firm advising the Target", label: "Sell-side, with a firm advising the Target" },
                { value: "Buy-side, working at the Buyer", label: "Buy-side, working at the Buyer" },
                { value: "Buy-side, with a firm advising the Buyer", label: "Buy-side, with a firm advising the Buyer" },
              ]}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Autocomplete
              id="target_name"
              data-test-id="target_name"
              options={this.state.companyList}
              getOptionLabel={(option) => option.label || ''}
              value={{ value: values.target_name, label: values.target_name }}
              onChange={(event) => this.handleChangeField(event, setFieldValue, "target_name")}
              onBlur={() => {
                setFieldTouched('target_name', true);
              }}
              renderInput={(params) =>
                <AutoCompleteEducational
                  name="target_name"
                  data-test-id="target_name1"
                  errors={!!(touched?.target_name && errors?.target_name) && errors}
                  params={params}
                  title={
                    <span>
                      Please provide the <span style={this.webStyles.boldText}>Target name</span> in this transaction.
                    </span>
                  }
                  placeHolder="Company"
                  onChange={(event) => {
                    setFieldValue("target_name", event.target.value);
                    this.companyListHandler(event);
                  }}
                />}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Autocomplete
              id="combo-box-demo"
              data-test-id="buyer_investor_name"
              options={this.state.companyList}
              getOptionLabel={(option) => option.label || ''}
              value={{ value: values.buyer_investor_name, label: values.buyer_investor_name }}
              onChange={(event) => this.handleChangeField(event, setFieldValue, "buyer_investor_name")}
              onBlur={() => {
                setFieldTouched('buyer_investor_name', true);
              }}
              renderInput={(params) =>
                <AutoCompleteEducational
                  name="buyer_investor_name"
                  errors={!!(touched?.buyer_investor_name && errors?.buyer_investor_name) && errors}
                  params={params}
                  title={
                    <span>
                      Please provide the <span style={this.webStyles.boldText}>Buyer / Investor name</span> in this transaction.
                    </span>
                  }
                  placeHolder="Company"
                  onChange={(event) => {
                    setFieldValue("buyer_investor_name", event.target.value);
                    this.companyListHandler(event);
                  }}
                />}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="transaction_summary"
              component={CustomTextField}
              isMultiline={true}
              placeHolder="Type Message Here"
              type="string"
              variant="outlined"
              title="Please provide a brief summary based on your understanding of the transaction."
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="enterprise_value"
              component={CustomTextField}
              id="enterprise_value_field"
              placeHolder="USD 5000 Thousand"
              type="string"
              value={getCommaSeparatedValue(values.enterprise_value)}
              variant="outlined"
              title="What was the Enterprise Value/headline number of the Target in the transaction (in Thousand)?"
              setFieldValue={setFieldValue}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="percentage_stake"
              component={CustomTextField}
              id="percentage_stake_field"
              setFieldValue={setFieldValue}
              value={getCommaSeparatedValue(values.percentage_stake)}
              placeHolder="%"
              type="string"
              variant="outlined"
              title="What was the percentage stake of the Target that was transacted (in %)?"
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="valuation_method"
              component={CustomSelectField}
              placeHolder="Select"
              type="string"
              variant="outlined"
              title="What was the valuation method used in the transaction?"
              options={[
                { value: "EV / Revenue", label: "EV / Revenue" },
                { value: "EV / EBITDA", label: "EV / EBITDA" },
                { value: "P / B", label: "P / B" },
                { value: "P / E", label: "P / E" },
                { value: "I don't know", label: "I don't know" },
              ]}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="valuation_multiple"
              component={CustomTextField}
              placeHolder="Number & X"
              type="string"
              variant="outlined"
              title="Kindly share the valuation multiple."
            />
          </Grid>
        </>
      )
    }
    else if (values.deal_type === 'debt') {
      return (
        <>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="debt_type"
              component={CustomSelectField}
              placeHolder="Select"
              type="string"
              variant="outlined"
              title='Was it a new debt/borrowing or a refinancing?'
              options={[
                { value: "New debt", label: "New debt" },
                { value: "Refinancing", label: "Refinancing" },
              ]}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="transaction_side"
              component={CustomSelectField}
              placeHolder="Select"
              variant="outlined"
              type="string"
              title={<span> Were you on the <span style={this.webStyles.boldText}>Borrowing-side</span> (Borrower/Target company and its advisors) or the <span style={this.webStyles.boldText}>Lending-side</span> (Banks/Credit Funds/Special Situation Fund/other creditors and its advisors)? </span>}
              options={
                [
                  { value: "Borrowing-side, I was working at the Target directly", label: "Borrowing-side, I was working at the Target directly" },
                  { value: "Borrowing-side, I was with a firm that advised/supported the Target (e.g. financial advisor, vendor due diligence, other advisors, etc.)", label: "Borrowing-side, I was with a firm that advised/supported the Target (e.g. financial advisor, vendor due diligence, other advisors, etc.)" },
                  { value: "Lending-side, I was working at the Banks/Credit Funds/Special Situation Fund /other creditors", label: "Lending-side, I was working at the Banks/Credit Funds/Special Situation Fund /other creditors" },
                  { value: "Lending-side, I was with a firm that advised/supported the Banks/Credit Funds/Special Situation Fund/other creditors (e.g. financial advisor, due diligence, other advisors, etc.)", label: "Lending-side, I was with a firm that advised/supported the Banks/Credit Funds/Special Situation Fund/other creditors (e.g. financial advisor, due diligence, other advisors, etc.)" },
                ]
              }
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Autocomplete
              id="combo-box-demo"
              data-test-id="borrower_name"
              options={this.state.companyList}
              getOptionLabel={(option) => option.label || ''}
              value={{ value: values.borrower_name, label: values.borrower_name }}
              onChange={(event) => this.handleChangeField(event, setFieldValue, "borrower_name")}
              onBlur={() => {
                setFieldTouched('borrower_name', true);
              }}
              renderInput={(params) =>
                <AutoCompleteEducational
                  name="borrower_name"
                  errors={!!(touched?.borrower_name && errors?.borrower_name) && errors}
                  params={params}
                  title={
                    <span>
                      Please provide the <span style={this.webStyles.boldText}>Borrower / Target name</span> in this transaction.
                    </span>
                  }
                  placeHolder="Company"
                  onChange={(event) => {
                    setFieldValue("borrower_name", event.target.value);
                    this.companyListHandler(event);
                  }}
                />}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Autocomplete
              id="combo-box-demo"
              data-test-id="lender_name"
              options={this.state.companyList}
              getOptionLabel={(option) => option.label || ''}
              value={{ value: values.lender_name, label: values.lender_name }}
              onChange={(event) => this.handleChangeField(event, setFieldValue, "lender_name")}
              onBlur={() => {
                setFieldTouched('lender_name', true);
              }}
              renderInput={(params) =>
                <AutoCompleteEducational
                  name="lender_name"
                  errors={!!(touched?.lender_name && errors?.lender_name) && errors}
                  params={params}
                  title={
                    <span>
                      Please provide the <span style={this.webStyles.boldText}>Lender name</span> in this transaction.
                    </span>
                  }
                  placeHolder="Company"
                  onChange={(event) => {
                    setFieldValue("lender_name", event.target.value);
                    this.companyListHandler(event);
                  }}
                />}
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="transaction_summary"
              component={CustomTextField}
              placeHolder="Type Message Here"
              type="string"
              variant="outlined"
              title="Could you please provide your transaction summary?"
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="debt_size"
              component={CustomTextField}
              placeHolder="USD 5000 Thousand"
              type="string"
              value={getCommaSeparatedValue(values.debt_size as "")}
              setFieldValue={setFieldValue}
              variant="outlined"
              id="debt_size_field"
              title="What was the debt size/headline number in the transaction (in Thousand)?"
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="leverage_level"
              component={CustomTextField}
              value={getCommaSeparatedValue(values.leverage_level as "")}
              setFieldValue={setFieldValue}
              placeHolder="Number & X"
              type="string"
              variant="outlined"
              id="leverage_level_field"
              title="What was the leverage level in terms of Gross Debt/EBITDA (in x)?"
            />
          </Grid>
          <Grid item style={{ marginTop: "32px" }}>
            <Field
              name="interest_rate"
              component={CustomTextField}
              placeHolder="%"
              type="string"
              variant="outlined"
              title="What was the all-in interest rate in the transaction?"
            />
          </Grid>
        </>
      )
    }
  }

  webStyles = {
    container: {
      width: "70%", 
      backgroundColor: "rgba(255, 255, 255, 1)",
      marginTop: "30px",
      marginBottom: "100px",
      borderRadius: "8px",
      position: "relative" as "relative"
    },
    topRight: {
      position: "absolute" as "absolute",
      top: "0px",
      right: "0px"
    },
    bottomLeft: {
      position: "absolute" as "absolute",
      bottom: "0px",
      left: "0px"
    },
    backWrapper: {
      display:"flex",
      marginLeft: "40px",
      marginTop: "24px",
      alignItems: "center"
    },
    headingText: {
      fontFamily: "Arial",
      fontWeight: 700,
      fontSize: "14px",
      color: "rgba(23, 54, 93, 1)",
      marginLeft: "11px"
    },
    thankYouText: {
      fontFamily: 'Arial',
      fontWeight: 400,
      fontSize: '14px',
      color: "rgba(23, 54, 93, 1)",
      marginTop: "32px"
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "24px 0"
    },
    yesBtn:{
      "&:hover": {
          background: "#C7D9F0"
      },
      "border": "1px solid #C7D9F0",
      "color": "rgba(255, 255, 255, 1)",
      "fontFamily": "Arial",
      "fontStyle": "normal",
      "fontSize": "16px",
      "lineHeight": "22px",
      "fontWeight": 700,
      "borderRadius": "8px",
      "background": "#6D97C1",
      "marginLeft": "20px",
      "display": "flex",
      "height": "56px",
      "width": "120px",
      "justifyContent": "center",
      "padding": "16px",
      "gap": "8px",
      "alignItems": "center",
    },
    noBtn: {
      "fontFamily": "Arial",
      "color": "#17365D",
      "fontStyle": "normal",
      "fontSize": "16px",
      "lineHeight": "22px",
      "marginLeft": "20px",
      "fontWeight": 700,
      "borderRadius": "8px",
      "border": "1px solid  #1F497D",
      "background": "#FFF",
      "height": "56px",
      "width": "120px",
      "padding": "10px 16px",
      "textTransform": "none",
      "&:hover": {
        background: "white"
      },
    },
    underLineText: {
      textDecoration: "underline",
    },
    boldText: {
      fontWeight: 700
    }
  }

  // Customizable Area End
}
