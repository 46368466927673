import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { City, Country } from "country-state-city";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { getLocation, handleConditionFunction, debounce } from "../../../components/src/Utils.web";
import { toast } from "react-toastify";

export interface ProfileDetails {
  attributes: {
    photo: string,
    full_name: string,
    current_title: string,
    open_for_direct_approach: boolean,
    connection_request_details: {
      id?:number,
      sender_id?:number,
      receiver_id?:number,
      status?: string | "accepted" | "pending",
    } | null,
    account_details: {
      id: number
    }
  }
}

export interface DealExperienceResponse {
  id: string;
  type: string;
  attributes: DealExperienceAttributes;
}

export interface DealExperienceAttributes {
  deal_type: string;
  involvement_type: string;
  transaction_side: string;
  target_name: string;
  buyer_investor_name: string;
  transaction_summary: string;
  enterprise_value: string;
  percentage_stake: string;
  valuation_method: string;
  valuation_multiple: string;
  debt_type: string;
  borrower_name: string;
  lender_name: string;
  debt_size: string;
  leverage_level: string;
  interest_rate: string;
  main_contributions: string[];
  transaction_merits: string;
  potential_risks: string;
  created_at: string;
}

export interface WorkExperience {
  id: number,
  type: string,
  attributes: {
    company_icon_url: string,
    position: string,
    specialization: string,
    started_time: string,
    ended_time: string,
    company_name: string,
    currently_working: boolean,
    end_current_position: boolean,
    created_at: string,
  }
}

export interface CandidateDetails {
  pipelineId: number | string,
  id: number,
  attributes:{
    total_potential_score: number,
    resume:{
      name:string,
      url:string
    },
    cover_letter: {
      name:string,
      url:string
    }
    id:number , 
    photo:string , 
    full_name:string , 
    current_title:string , 
    open_for_direct_approach: boolean,
    connection_request_details: {
      id?:number,
      sender_id?:number,
      receiver_id?:number,
      status?: string | "accepted" | "pending",
    } | null,
    city:string , 
    country:string, 
    potential_score: string, 
    overall_rank: string,
    skills: string[],
    is_hidden: boolean,
    work_experiences: {
      data: WorkExperience[]
    },
    deal_experiences: {data: DealExperienceResponse[]},
    account_details: {
      id: number
    },
    applied_job_details: {
      job_title : string;
      applied_time_ago : string;
    }
  }
}

export interface TalentCandidate {
    pipelineId?: string | number;
    id: string;
    type: string;
    attributes: {
      id: number;
      full_name: string;
      location: string;
      current_title: string;
      open_for_direct_approach: boolean,
      connection_request_details: {
        id?:number,
        sender_id?:number,
        receiver_id?:number,
        status?: string | "accepted" | "pending",
      } | null,
      full_phone_number: string;
      email: string;
      experience: string;
      salary: number;
      future_self: any;
      current_company: string;
      company_indusry: string;
      bio: any;
      work_email_id: string;
      personal_email_id: any;
      potential_score: string;
      total_potential_score: number;
      overall_rank: string;
      city: string;
      country: string;
      skills: string[];
      photo: string;
      is_hidden: boolean;
      account_details: {
        id: number;
        data: {
          id: string;
          type: string;
          attributes: {
            activated: boolean;
            country_code: string;
            email: string;
            full_name: string;
            full_phone_number: string;
            phone_number: string;
            type: string;
            created_at: string;
            updated_at: string;
            device_id: any;
            unique_auth_id: string;
            role: string;
          };
        };
      };
      work_experiences: {
        data: WorkExperience[];
      };
      deal_experiences: {data: DealExperienceResponse[]},
      resume_url: any;
      cover_letter_url: any;
      resume:{
        name:string,
        url:string
      },
      cover_letter: {
        name:string,
        url:string
      },
      applied_job_details: {
        job_title : string;
        applied_time_ago : string;
      }
    };
}

export interface TalentPipelineCandidateDetails {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_at: string;
    candidate_detail: {
      data: TalentCandidate;
    };
  };
}

export interface JobObject {
  id: number,
  type: string,
  attributes: {
    job_title: string,
    job_description: string,
    status: string,
    draft_page: string,
  }
}

export interface Experiences {
  "2years": {checked: boolean, key : string}
  "4years": {checked: boolean, key : string}
  "6years": {checked: boolean, key : string}
  "8years": {checked: boolean, key : string}
  "10years": {checked: boolean, key : string}
  "10+years": {checked: boolean, key : string}
}

export type ExperienceName = "2years" | "4years" | "6years" | "8years" | "10years" | "10+years"

export interface InputValueType { value: string, label: string }

export const initialExperienceState = {
  "2years": { checked: false, key: "0-2 Years" },
  "4years": { checked: false, key: "2-4 Years" },
  "6years": { checked: false, key: "4-6 Years" },
  "8years": { checked: false, key: "6-8 Years" },
  "10years": { checked: false, key: "8-10 Years" },
  "10+years": { checked: false, key: "10+ Years" },
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  rankedValue:number[];
  potentialScore: number[];
  rangeValue:number[];
  tabsValue:number;
  skills: string[];
  selectedSkillToRemove: string
  selectedSkills: string[],
  candidateProfileShow:boolean;
  candidateDetails: CandidateDetails[];
  maxSliderValue: number;
  totalCandidates: number;
  totalCandidatesPipeline: number;
  talentPipelineCandidateDetails: TalentPipelineCandidateDetails[];
  candidateProfileDashboard: CandidateDetails | TalentCandidate | null;
  countriesList: { value: string; label: string }[];
  selectedCountryToRemove: string
  selectedCountries: { value: string; label: string } | null
  citiesList: { value: string; label: string }[];
  selectedCityToRemove: string
  selectedCities: { value: string; label: string } |  null
  showFilters: boolean
  experiences: Experiences
  directApproach: string
  allJobs: JobObject[]
  isInfiniteLoading: boolean
  currentPage: number
  currentPagePipeline: number
  searchValue: string
  searchValuePipeline: string
  searchParams: string
  cardSelected: {id: number, selected: boolean}
  coords : {
    latitude: number,
    longitude: number,
  },
  isSubscribedElite: boolean,
  subscriptionPlan: {status:string,subscription_details:{chat_limits: string, name: string}} | null,
  canAccessPipeline: boolean | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  rankedValue:number[]
  rangeValue:number[]
  tabsValue:number
  // Customizable Area End
}

export default class CfrecruitersdashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  candidatesProfileGetApiCallId: string = ""
  candidatesProfileNewPageGetApiCallId: string = ""
  talentPipelineCandidatesGetApiCallId: string = ""
  talentPipelineCandidatesNewPageGetApiCallId: string = ""
  skillsListApiId: string = ""
  hideCandidateApiCallId: string = ""
  unHideCandidateApiCallId: string = ""
  hidePipelineCandidateApiCallId: string = ""
  addCandidateToPipelineApiCallId: string = ""
  inviteCandidateToApplyApiCallId: string = ""
  getAllCreatedJobsApiCallId: string = ""
  getAppliedJobsApiCallId: string = ""
  observer: IntersectionObserver | null = null;
  getSubscriptionApiCallId: string = ""
  getUserProfileCallId: string =""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleScroll = debounce(this.handleScroll.bind(this), 1000);
    this.getFilteredCandidates = debounce(this.getFilteredCandidates.bind(this), 1000)

    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      rankedValue:[0, 10000],
      potentialScore: [0, 10],
      rangeValue:[5,10],
      tabsValue:0,
      skills: ["Finance", "Economics"],
      selectedSkillToRemove: "",
      selectedSkills: [],
      candidateProfileShow:false,
      candidateDetails:[],
      maxSliderValue: 10000,
      totalCandidates: 0,
      totalCandidatesPipeline: 0,
      talentPipelineCandidateDetails: [],
      candidateProfileDashboard:null,
      countriesList: [],
      selectedCountryToRemove: '',
      selectedCountries: null,
      citiesList: [],
      selectedCityToRemove: '',
      selectedCities: null,
      showFilters: true,
      experiences:  initialExperienceState,
      directApproach: "",
      allJobs: [],
      isInfiniteLoading: false,
      currentPage: 1,
      currentPagePipeline: 1,
      searchValue: "",
      searchValuePipeline: "",
      searchParams: "",
      cardSelected: {id: 0, selected: false},
      coords : {
        latitude: 0,
        longitude: 0,
      },
      isSubscribedElite: false,
      subscriptionPlan: null,
      canAccessPipeline: false

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });
    // }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
  );
  let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  if (apiRequestCallId && responseJson) {
    switch (apiRequestCallId) {
      case this.candidatesProfileGetApiCallId:
        return this.handelCandidateProfileData(responseJson);
      case this.candidatesProfileNewPageGetApiCallId:
        return this.handelCandidateProfileData(responseJson, true);
      case this.talentPipelineCandidatesGetApiCallId:
        return this.handelTalentPipelineCandidateProfileData(responseJson);
      case this.talentPipelineCandidatesNewPageGetApiCallId:
        return this.handelTalentPipelineCandidateProfileData(responseJson, true);
      case this.skillsListApiId:
        return this.handleSkillsResponse(responseJson);
      case this.hideCandidateApiCallId:
        return this.handleHideCandidate(responseJson);
      case this.unHideCandidateApiCallId:
        return this.handleHideCandidate(responseJson);
      case this.hidePipelineCandidateApiCallId:
        return this.handleHideCandidate(responseJson);
      case this.addCandidateToPipelineApiCallId:
        return this.handleAddCandidateToPipeline(responseJson);
      case this.inviteCandidateToApplyApiCallId:
        return this.handleInvitationResponse(responseJson);
      case this.getAllCreatedJobsApiCallId: 
        return this.handleGetJobsList(responseJson);
      case this.getSubscriptionApiCallId: 
        return this.handleSubscriptionResponse(responseJson);
      case this.getUserProfileCallId:
        return null
      default:
        return null
    }
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  componentDidMount = async () => {
    this.getCurrentLocation().finally(() => {
      this.getCandidatesProfiles();
    })
    this.checkForSubscription()
    this.getTalentPipelineCandidatesProfiles()
    this.handleInputChangeDebounced()
    this.getAllJobs()
    this.createObserver();
    const containerDiv = document.getElementById('pageContainer')
      if (containerDiv) {
        containerDiv.scrollIntoView();
    }
    if(window.innerWidth < 1024) {
      this.setState({
        showFilters: false
      })
    }
  }

  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  getCurrentLocation = async (): Promise<void> => {
    return getLocation()
    .then((position) => {
      this.setState(
        {coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }}
        );
    }).catch(() => {
      this.setState({
        coords: { latitude: 0, longitude: 0 },
      });
    })
  };

  handleMessageClick = (event: React.MouseEvent<HTMLElement>, userDetails: ProfileDetails ) => {
    event.stopPropagation()
    if(this.state.subscriptionPlan?.status === "active") {
      this.goToMessage(userDetails)
    } else {
      toast.error("You must have an active subscription to access chat section")
    }
  }

  goToMessage = (data: ProfileDetails) => {
    if (data.attributes.open_for_direct_approach || (data.attributes.connection_request_details && data.attributes.connection_request_details.status === "accepted")) {
      const reqMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
      reqMessage.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
      reqMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData),
        { from: "Profile", accountId: { data: data } }
      )
      reqMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(reqMessage);
    } else {
      toast.error("You can't send message outside of your network")
    }
  }


  handleSubscribeClick = (event: React.MouseEvent<HTMLElement> ) => {
    event.stopPropagation()
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage), 'Customisableusersubscriptions'
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props
    );

    this.send(message);
  }


  selectCardContainer = (id: number) => {
    this.setState({
      cardSelected: {
        id: this.state.cardSelected.id === id ? 0 : id,
        selected: !this.state.cardSelected.selected,
      }
    })
  }

  handleScroll() {
    this.state.tabsValue === 0 ?
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1
      }))
      :
      this.setState((prevState) => ({
        currentPagePipeline: prevState.currentPagePipeline + 1
      }))
  }

  handleSkillsResponse = (responseJson: any) => {
    if (!responseJson.errors) {
        this.setState((prevState) => ({
          skills: responseJson?.skills,
        }));
    }
  }

  renderBtnLabel = () => {
    return handleConditionFunction((this.state.subscriptionPlan?.status === "active" && !this.state.isSubscribedElite), 'Upgrade now', 'Subscribe now')
  }

  getFilteredCandidates () {
    const checkedExperiences = Object.values(this.state.experiences).filter((experience) => experience.checked).map((experience) => experience.key)

    let params = `country=${this.state.selectedCountries?.value ?? ""}&city=${this.state.selectedCities?.value ?? ""}&direct_approach=${this.state.directApproach}&rank_from=${Math.max(this.state.maxSliderValue - this.state.rankedValue[1], 1)}&rank_to=${this.renderMinValue(this.state.maxSliderValue - this.state.rankedValue[0])}`

    let appendArrayParams = (paramName: string, array: string[]): void => {
      if (array.length > 0) {
        for (let item of array) {
          params += `&${paramName}[]=${item}`
        }
      }
    }

    appendArrayParams("skills", this.state.selectedSkills)
    appendArrayParams("experience", checkedExperiences)

    this.setState({
      searchParams: params
    })
    this.getCandidatesProfiles(`${params}&search_key=${this.state.searchValue}`)
    this.getTalentPipelineCandidatesProfiles(`${params}&search_key=${this.state.searchValuePipeline}`)
  }

  handleHideCandidate = (responseJson: any) => {
    if(!responseJson.errors) {
      toast.success(responseJson.message)
      this.getFilteredCandidates()
      this.handleCandidates(null, false)
    } else {
      toast.error(responseJson.errors && responseJson.errors[0].message)
    }
  }

  handleHideCondition = (event: React.MouseEvent<HTMLElement>, candidateProfileDashboard: CandidateDetails | TalentCandidate) => {
    return candidateProfileDashboard.attributes.is_hidden ? this.unHideCandidate(event, candidateProfileDashboard.attributes.id) : this.hideCandidate(event, candidateProfileDashboard.attributes.id)
  }

  handleAddCandidateToPipeline = (responseJson: any) => {
    if(!responseJson.errors && !responseJson.error) {
      toast.success(responseJson.message)
      this.getTalentPipelineCandidatesProfiles();
    } else {
      toast.error(responseJson.error || (responseJson.errors && responseJson.errors[0].message))
    }
  }

  handleInvitationResponse = (responseJson: any) => {
    if(!responseJson.errors && !responseJson.error) {
      toast.success(responseJson.message)
    } else {
      toast.error(responseJson.error || (responseJson.errors && responseJson.errors[0].message))
    }    
  }

  handleGetJobsList = (responseJson: {errors: string, recruiter_job_listing: {data: JobObject[]}}) => {
    if(!responseJson.errors) {
      let jobs = responseJson.recruiter_job_listing.data.filter((job) => job.attributes.status === "open"); 
      this.setState({
        allJobs: jobs,
      })
    }    
  }

  handleSubscriptionResponse=(responseJson:{user_subscription:{data:{attributes:{status:string,subscription_details:{chat_limits: string, name: string}}}}})=>{
    if (responseJson.user_subscription) {  
      this.setState({
        isSubscribedElite: responseJson.user_subscription.data.attributes.status === "active" && responseJson.user_subscription.data.attributes.subscription_details.name.toLowerCase().includes("elite"),
        subscriptionPlan: responseJson.user_subscription.data.attributes,
        canAccessPipeline: responseJson.user_subscription.data.attributes && responseJson.user_subscription.data.attributes.status === "active" && !responseJson.user_subscription.data.attributes.subscription_details.name.toLowerCase().includes("free")
      })
    } else{
      this.setState({
        isSubscribedElite: false,
      })
    }
  }

  handleSetExperience = (event: { target: { name: string; checked: any; }; }) => {
    const targetName : ExperienceName  = event.target.name as ExperienceName
    this.setState({
      experiences: {
        ...this.state.experiences,
        [targetName] : {...this.state.experiences[targetName] ,checked: event.target.checked}
      }
    })
  }

  handleFiltersExpand = () => {
    this.setState({
      showFilters: !this.state.showFilters
    })
  }

  handleCountryChange = (event: React.ChangeEvent<HTMLInputElement> | null, inputValue: InputValueType) => {
    if(event) {
      this.countryListHandler(event)
    }
    if (inputValue && this.state.selectedCountries?.label !== inputValue?.label) {
      this.setState({
        selectedCountries: inputValue,
        selectedCountryToRemove: ''
      })
    }
  }

  handleCountryRemove = (value: string) => {
    this.setState({
      selectedCountryToRemove: value
    })
  }

  handleCityChange = (event: React.ChangeEvent<HTMLInputElement>, inputValue: InputValueType) => {
    this.cityListHandler(event)
    if (inputValue && this.state.selectedCities?.label !== inputValue?.label) {
      this.setState({
        selectedCities: inputValue,
        selectedCityToRemove: ''
      })
    }
  }

  handleCityRemove = (value: string) => {
    this.setState({
      selectedCityToRemove: value
    })
  }

  handleSkillChange = (newValue: string | unknown) => {
    if (newValue && !this.state.selectedSkills.includes(newValue as string)) {
      const newSkill = newValue as string
      this.setState({
        selectedSkills: [...this.state.selectedSkills, newSkill],
        selectedSkillToRemove: ''
      })
    }
  }

  handleSkillRemove = (value: string) => {
    this.setState({
      selectedSkillToRemove: value
    })
  }

  handleDirectApproach = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(this.state.isSubscribedElite)
    this.setState({
      directApproach: event.target.name
    })
  }

  cityListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      this.handleCityInputChangeDebounced(value);
    }
  }

  countryListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      this.handleCountryInputChangeDebounced();
    }
  };

  handleCountryInputChangeDebounced = () => {
    const countriesList = Country.getAllCountries().map((country) => ({
        value: country.name,
        label: country.name,
    }));
    this.setState({ countriesList });
  }

  handleCityInputChangeDebounced = (value: string) => {

    let citiesList: any[] = [];

    const cities = City?.getAllCities().map(city => ({
        value: city.name,
        label: city.name
    }));

    if (value) {
        citiesList = cities?.filter(city => city.label.toLowerCase().includes(value.toLowerCase()))
            .slice(0, 10);
    } else {
        citiesList = cities?.slice(0, 10);
    }

    this.setState({ citiesList });
  }

  handleClear = (filterType : "country" | "city" | "experiences" | "skills" | "directApproach") => {
    if(filterType === "country") {
      this.setState({
        selectedCountries: {label: '', value: ''},
      })
    } else if (filterType === "city") {
      this.setState({
        selectedCities: {label: '', value: ''},
      })
    } else if (filterType === "experiences") {
      this.setState({
        experiences: initialExperienceState
      })
    } else if (filterType === "skills") {
      this.setState({
        selectedSkills: []
      })
    } else if (filterType === "directApproach") {
      this.setState({
        directApproach: ""
      })
    }
  }

  handleChange = (event:  React.ChangeEvent<{}>, newValue: number | number[]) => {
    this.setState({rankedValue: newValue as number[]});
  };

  handleChangeRankedValue = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = [...this.state.rankedValue]; 
    newValue[index] = this.state.maxSliderValue - Number(event.target.value);
    if((newValue[0] >= 10000) || (newValue[0] < 0)) {
      return;
    }
    this.setState({
      rankedValue: newValue
    });
  }

  renderMinValue = (number: number) => {
    return (number < 0) ? this.state.maxSliderValue : number
  }

  handleChangePotentialScoreValue = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let newValue = this.state.potentialScore;
    newValue[index] = event.target.value as unknown as number;

    this.setState({
      potentialScore: newValue
    });
  }

  handleChangePotentialScore = (event:  React.ChangeEvent<{}>, newValue: number | number[]) => {
    this.setState({potentialScore: newValue as number[]});
  };

  getRangeValue(value: number) {
    return value.toString()
  }

  handleTabChange = (event: React.ChangeEvent<{}>, tabValue: number) => { 
    this.setState({tabsValue: tabValue as number});
  };

  handleCondition = (condition: boolean, firstPart: JSX.Element | string, secondPart: JSX.Element | string) => {
    return condition ? firstPart : secondPart
  }

  handleTwoCondition = (firstCondition: string, secondCondition: string) => {
    return firstCondition || secondCondition
  }

  handleFunctionCondition = (firstCondition: boolean, trueFunction: () => void, falseFunction: () => void) => {
    return () => { firstCondition ? trueFunction() : falseFunction() }
  };

  handleAndCondition = (firstPart: boolean, secondPart: JSX.Element) => {
    return firstPart && secondPart
  }

  renderBorderColor = (item: TalentPipelineCandidateDetails | CandidateDetails) => {
    return this.state.cardSelected.id === item.attributes.id ? "1px solid var(--Primary-color, #1F497D)" : 'none'
  }

  handleBackgroundColor = (tabValue: boolean) => {
    return tabValue ? "#F2F6F9" : "inherit"
  }

  handleBlurEffect = () => {
    return this.state.isSubscribedElite ? "none" : "blur(2px)"
  }

  handleCandidates = (event: React.MouseEvent<HTMLElement> | null, show: boolean, candidateId?: number) => {
    event?.stopPropagation()
    if (show) {
      const containerDiv = document.getElementById('pageContainer')
      if (containerDiv) {
        containerDiv.scrollIntoView();
      }


      const candidateData = this.state.candidateDetails.filter((item, index) => {
        return item.attributes.id === candidateId
      })

      const pipelineCandidateData = this.state.talentPipelineCandidateDetails.filter((item, index) => {
        return parseInt(item.attributes.candidate_detail.data.id) === candidateId
      })


      this.setState({ candidateProfileDashboard: this.state.tabsValue === 0 ? candidateData[0] : {...pipelineCandidateData[0].attributes.candidate_detail.data, pipelineId: pipelineCandidateData[0].id} });

    } else {
      this.setState({ candidateProfileDashboard: null });
    }
    this.setState({ candidateProfileShow: show as boolean });
  };

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }


  handleDownloadResume = (resume: { url: string, name: string}) => (event: React.MouseEvent<HTMLImageElement>) => {
    if (resume.url) {
      const url = window.URL.createObjectURL(new Blob([resume.url]));
      const link = document.createElement('a');
      link.href = url;
      if (resume.name) {
          link.setAttribute('download', resume.name);
      }
      document.body.appendChild(link);
      link.click();
  } else {
      toast.error("File url not found")
  }

  }


  handleRemoveSkill = (skill: string) => {
    if(skill && this.state.selectedSkills.includes(skill)) {
      let updatedSkills = this.state.selectedSkills.filter((prev) => prev !== skill);
      // updatedSkills.splice(0, updatedSkills.indexOf(skill))
      this.setState({
        selectedSkills: updatedSkills
      })
    }
  }

  createObserver() {
    this.observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.handleScroll();
      }
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    const checkedExperiences = Object.values(this.state.experiences).filter((experience) => experience.checked).map((experience) => experience.key)
    const prevCheckedExperiences = Object.values(prevState.experiences).filter((experience) => experience.checked).map((experience) => experience.key)
    let params = ``

    if (
      this.state.selectedCountries && this.state.selectedCountries.label !== prevState.selectedCountries?.label ||
      this.state.selectedCities && this.state.selectedCities.label !== prevState.selectedCities?.label ||
      JSON.stringify(prevState.selectedSkills) !== JSON.stringify(this.state.selectedSkills) ||
      this.state.directApproach !== prevState.directApproach ||
      JSON.stringify(prevCheckedExperiences) !== JSON.stringify(checkedExperiences) ||
      this.state.searchValue !== prevState.searchValue ||
      this.state.searchValuePipeline !== prevState.searchValuePipeline ||
      this.state.rankedValue[0] !== prevState.rankedValue[0] ||
      this.state.rankedValue[1] !== prevState.rankedValue[1] 
     ) {
      this.getFilteredCandidates()
    }

    if (prevState.currentPage !== this.state.currentPage && this.state.currentPage <= this.state.totalCandidates) {
      this.getCandidatesProfiles(`${this.state.searchParams}&search_key=${this.state.searchValue}&page=${this.state.currentPage}`);
    }
    if (prevState.currentPagePipeline !== this.state.currentPagePipeline && this.state.currentPagePipeline <= this.state.totalCandidatesPipeline) {
      this.getTalentPipelineCandidatesProfiles(`${this.state.searchParams}&search_key=${this.state.searchValuePipeline}&page=${this.state.currentPagePipeline}`)
    }

    if (this.observer) {
      
      const elements = document.querySelectorAll('.candidate-list-item');
      const elementsPipeline = document.querySelectorAll('.candidate-list-item-pipeline');

      if (elements.length > 0) {
        const lastElement = elements[elements.length - 1];
        this.observer.observe(lastElement);
      }
      if(elementsPipeline.length > 0) {
        const lastElement = elementsPipeline[elementsPipeline.length - 1];
        this.observer.observe(lastElement);
      }
    }
  }

  getFileExtension = (fileUrl: string): string => {
    const parts = fileUrl.split('.');
    if (parts.length > 1) {
        const extension = parts.pop()?.toLowerCase();
        return extension === "pdf" ? "pdf" : "doc";
    } else {
        return "unknown";
    }
  }

  async getCandidatesProfiles (params?: string) {
    const authToken = await getStorageData("authToken")
    let coordsUrl = ''
    if(this.state.coords.latitude && this.state.coords.longitude ) {
      coordsUrl += `&latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (params?.includes("page")) {
      this.candidatesProfileNewPageGetApiCallId = requestMessage.messageId;
      this.setState({
        isInfiniteLoading: true
      })
    } else {
      this.candidatesProfileGetApiCallId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.candidateListEndPoint}?${params}${coordsUrl}`
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCandidatesProfile,
      token: authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getTalentPipelineCandidatesProfiles (params?: string) {    
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    if (params?.includes("page")) {
      this.talentPipelineCandidatesNewPageGetApiCallId = requestMessage.messageId;
      this.setState({
        isInfiniteLoading: true
      })
    } else {      
      this.talentPipelineCandidatesGetApiCallId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.talentPipelineCandidateEndPoint}?${params}`

    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCandidatesProfile,
      token: authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getAllJobs = async () => {
    const authToken = await getStorageData("authToken")
    
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCreatedJobsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllCreatedJobs}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async hideCandidate(event: React.MouseEvent<HTMLElement>, candidateId: number) {
    event.stopPropagation()
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hideCandidateApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.profilesEndPoint}/${candidateId}/hide_candidate`
    );

    const header = {
        token: authToken,
        "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async userProfileClick(event: React.MouseEvent<HTMLElement>, candidateId: number) {
    event.stopPropagation()
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.userProfileAPI}${candidateId}/view_profile`
      );

    const header = {
        token: authToken,
        "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async unHideCandidate(event: React.MouseEvent<HTMLElement>, candidateId: number) {
    event.stopPropagation()
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unHideCandidateApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.profilesEndPoint}/${candidateId}/unhide_candidate`
    );

    const header = {
        token: authToken,
        "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DeleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  async inviteCandidateToApply(candidateId: number, jobId: number, status?: string ) {
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.inviteCandidateToApplyApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.inviteToApplyJobApi}`
    );

    const header = {
        token: authToken,
        "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        candidate_id: candidateId,
        job_id: jobId,
        status: status
      })
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async addCandidateToPipeline(candidateId: number) {
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCandidateToPipelineApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.talentPipelineCandidateEndPoint}`
    );

    const header = {
        token: authToken,
        "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        candidate_id: candidateId
      })
    )

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async hidePipelineCandidate(event: React.MouseEvent<HTMLElement>, candidateId: number | string) {
    event.stopPropagation()
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hidePipelineCandidateApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.talentPipelineCandidateEndPoint}/${candidateId}`
    );

    const header = {
        token: authToken,
        "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DeleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleInputChangeDebounced = async () => {
    const authToken = await getStorageData("authToken")

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.skillsListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.skillsListingApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkForSubscription = async () => {
    const token = await getStorageData("authToken")

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkForSubscriptionAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handelCandidateProfileData = (response: any, loadMore?: boolean) => {
    this.setState({
      isInfiniteLoading: false
    })
    if (!response.errors && response?.candidates?.data) {
      this.setState({
        candidateDetails: loadMore ? [...this.state.candidateDetails, ...response?.candidates?.data] : response?.candidates?.data,
        totalCandidates: response?.total_candidates,
      });
    } else if (response.errors && (response.errors[0].token === "Token has Expired" || response.errors[0].token === "Invalid token")) {
      toast.error(response.errors[0].token);
    } else if (response.error) {
      toast.error(response.error);
    }
  };

  handelTalentPipelineCandidateProfileData = (response: any, loadMore?: boolean) => {
    this.setState({
      isInfiniteLoading: false
    })
    if (!response.errors && response?.pipeline_candidates) {
      this.setState({
        talentPipelineCandidateDetails: loadMore ? [...this.state.talentPipelineCandidateDetails, ...response?.pipeline_candidates?.data] : response?.pipeline_candidates?.data,
        totalCandidatesPipeline: response?.total_pipeline_candidates
      })
    };
  }

  checkForTalentPipelineAdded = (candidateId: number) => {
    return this.state.talentPipelineCandidateDetails.some((candidate) => {
      return candidateId === Number(candidate.attributes.candidate_detail.data.attributes.account_details.id)
    })
  }

  renderAppliedTime = (appliedJobDetails : {applied_time_ago : string}) => {
    if(appliedJobDetails) {
        return appliedJobDetails.applied_time_ago
    } else {
        return ""
    }
}

  handelTalentPipelineTabSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchValuePipeline: event.target.value
    })
  };


  handelTalentPollTabSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    const candidateData = this.state.candidateDetails.filter((item, index) => {

    })

    this.setState({
      searchValue: event.target.value
    })

};
  
  // Customizable Area End
}
