import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormHelperText, InputLabel, TextField } from '@material-ui/core';
//@ts-ignore
import { openMenuicon } from './openMenuWhite.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: "8px",
            position: 'relative',
            "& .MuiFormHelperText-contained": {
                marginLeft: '0px'
            },
            "& .MuiTextField-root": {
                "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "0px solid #9C9C9C"
                }
            },
            "& .MuiSvgIcon-root": {
                display: 'none'
            },
            "& .custom-dropdown-icon": {
                width: '24px',
                height: '24px',
                backgroundImage: `url(${openMenuicon})`,
                backgroundSize: 'cover',
                cursor: 'pointer',
                right: 0,
                position: 'absolute',
                pointerEvents: 'none',
                display: 'none'
            },
            "& .MuiOutlinedInput-input": {
                borderRadius: "8px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "rgba(31, 73, 125, 1)",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px",
                "&:hover": {
                    border: "0px solid rgba(31, 73, 125, 1)",
                },
                "&:focus": {
                    border: "0px solid #1F497D",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
            },
            "& .MuiOutlinedInput-root": {
                height: "44px",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
            "& .MuiAutocomplete-input" : {
                height: "0px"
            }
        },

        inputLabel: {
            color: '#1F497D',
            fontFamily: 'Arial',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            paddingBottom: '4px',
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginTop": "6px"
        },
        verifyButton: {
            width: '67px',
            height: '28px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            position: 'absolute',
            right: '13px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'rgba(31, 73, 125, 1)'
        }
    })
);

type Props = {
    placeHolder?: string;
    title?: JSX.Element;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => void;
    params: any;
    name?: string;
    errors?: any;
    touched?: boolean;
};

const AutoCompleteEducational: React.FC<Props> = ({
    placeHolder,
    title,
    onChange,
    params,
    name,
    errors,
    touched = true
}) => {
    const classes = useStyles();
    
        return (
        <>
            <InputLabel
                className={classes.inputLabel}
                htmlFor={params.id}
            >
                {title}
            </InputLabel>
            <div className={classes.root} style={{ height: "44px" }}>
                <TextField {...params}
                    name={name}
                    placeholder={placeHolder}
                    variant="outlined" 
                    onChange={onChange}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors[name || ""]}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <div className="custom-dropdown-icon" onClick={params.InputProps.onEndAdornmentClick}></div>
                        ),
                    }}
                />
            </div>
            {errors[name || ''] && touched && params.inputProps?.onBlur && (
                <FormHelperText className={classes.errorText}>
                    {errors[name || '']}
                </FormHelperText>
            )}
        </>
    );
};

export default AutoCompleteEducational;
