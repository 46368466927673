export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const standardMembershipLogo = require("../assets/standardMembershipLogo.png");
export const freeMember = require("../assets/freeMember.png");
export const premiumMember = require("../assets/premiumMember.png");
export const eliteMember = require("../assets/eliteMember.png");
export const listIcon = require("../assets/listIcon.png");
export const topRight = require("../assets/topRight.svg");
export const bottomLeft = require("../assets/bottomLeft.svg");
export const infoIcon = require("../assets/infoIcon.png");
export const DoneStep = require("../assets/DoneStep.svg");
export const ActiveStep = require("../assets/ActiveStep.svg");
export const DisabledStep = require("../assets/DisabledStep.svg");
export const ConfirmationIcon = require("../assets/confirmationIcon.svg");
export const faliIcon = require("../assets/faliIcon.svg");

