import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTimer } from "react-timer-hook";

interface MyTimerProps {
  expiryTimestamp: Date;
  handleShowVerifyEmail: any;
  handleOTPTimmer: any;
}

interface MyTimerHandlarProps {
  handleShowVerifyEmail: any;
  handleOTPTimmer: any;
}

function MyTimer({
  expiryTimestamp,
  handleShowVerifyEmail,
  handleOTPTimmer,
}: MyTimerProps) {
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => handleOTPTimmer(false),
  });
  const isTimeUp = seconds === 0 && minutes === 0;
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography
          style={{
            color: "#1F497D",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            fontFamily: "Arial"
          }}
        >
          {minutes}:{seconds} left
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            color: "#6D97C1",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            fontFamily: "Arial"
          }}
        >
          Didn’t receive OTP?{" "}
          <span
            onClick={() => {
              if (isTimeUp) {
                handleShowVerifyEmail();
                handleOTPTimmer(true);
                const time = new Date();
                time.setSeconds(time.getSeconds() + 180);
                restart(time);
              }
            }}
            data-test-id={"btnTimmerOTP"}
            style={{
              color: "#1F497D",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              fontFamily: "Arial",
              cursor: !isTimeUp ? "" : "pointer",
              opacity: !isTimeUp ? 0.5 : 1,
            }}
          >
            Resend
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function AppTimmer({
  handleShowVerifyEmail,
  handleOTPTimmer,
}: MyTimerHandlarProps) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);
  return (
    <MyTimer
      expiryTimestamp={time}
      handleShowVerifyEmail={handleShowVerifyEmail}
      handleOTPTimmer={handleOTPTimmer}
    />
  );
}
