export const defaultImage = require("../assets/defaultImage.png");
export const profileImage = require("../assets/profile-icon.png");
export const filterIcon = require("../assets/filterIcon.png");
export const searchIcon = require("../assets/searchIcon.png");
export const textLabel = require("../assets/textlabel.png");
export const badgeIcon = require("../assets/badge.png");
export const openWhiteMenuIcon = require("../assets/openWhiteMenuIcon.svg")
export const SearchIcon = require("../assets/search.svg");
export const EndSearchIcon = require("../assets/endsearch.svg");
export const noSubImg = require("../assets/no-sub.png");

