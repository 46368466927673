Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.JobFeedApiEndPoint = "bx_block_joblisting/jobs/landing_page_job_listing"
exports.FindingJobApiEndPoint = "bx_block_joblisting/jobs/search"
exports.landingPageJobDetailsApiEndPoint = "bx_block_joblisting/jobs"
exports.ApplyJobApiEndPointEndPoint = 'bx_block_joblisting/apply_jobs'
exports.appliedJobListingEndPoint = 'bx_block_joblisting/apply_jobs'
exports.savedJobListingEndPoint = 'bx_block_joblisting/jobs/get_saved_jobs'
exports.savedJobApiEndPoint = 'bx_block_joblisting/jobs'
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.apiContentType = "application/json";
exports.jobStatusApiEndPoint= 'bx_block_joblisting/apply_jobs'
exports.experinceOptions='bx_block_joblisting/jobs/get_experience_required'
exports.skillsOptions = 'bx_block_joblisting/jobs/skills_listing'
exports.jobOptions = 'bx_block_joblisting/jobs/get_post_expire_after'
exports.baseURL = require('../../../framework/src/config')
exports.getAllJobs = "/bx_block_joblisting/jobs/landing_page_job_listing"
exports.datePostedOptions = [{ id: 1, title: "Last 24 hrs" }, { id: 2, title: "Last 3 days" }, { id: 3, title: "Last 7 days" }, { id: 4, title: "Last 14 days" }, { id: 5, title: "Last 30 days" }];
exports.postedBy = [{ id: 1, title: "Work Centivo Platform" }, { id: 2, title: "External" }]
exports.deleteType = "DELETE"
exports.getUserProfileDetailsEndpoint = "bx_block_profile/profiles";
exports.checkForSubscriptionAPI = "bx_block_subscriptions/subscriptions/user_subscription_details"

// Customizable Area End