import { Box, Button, Popover } from "@material-ui/core"
import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

type Props = {
open: boolean,
onClick: ()=>void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContainer: {
            width: '256px',
            height: '48px',
            borderRadius: '8px',
            background: '#FFF',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
      },
      modalText: {
        color: "#17365D",
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Arial'
      },
      buttonStyles: {
        borderRadius: "8px",
        backgroundColor: "#1F497D",
        color: "#FFFFFF",
        fontFamily: 'Arial',
        fontSize: '14px',
        fontWeight: 700,
        width: "64px",
        height: '32px',
        textTransform: 'none'
      },
      popOverContainer: {

      },
      postSuccessModal: {
        '& .MuiPopover-paper': {
          top: "76 !important"
        },
        '& .MuiPaper-rounded' : {
          borderRadius: 8,
        }
      },
      "MuiPopover-paper": {
        top: "65px"
      }
    }),
)
export default function CreatePostSuccessModal(Props: Props) {
    const classes = useStyles();
    const {open, onClick} = Props

    return (
        <Box className={classes.popOverContainer}>
        <Popover
        open={open}
        disableScrollLock
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  className={classes.postSuccessModal}
  style={{position: 'inherit', transformOrigin: 'center top -100px'}}
>
<Box className={classes.modalContainer}>
           <Box className={classes.modalText}>
               Posted Successfully
            </Box>
            <Button
                  className={classes.buttonStyles}
                  variant="contained"
                  onClick={onClick}
                >
                  View   
                </Button>
        </Box>
</Popover>
</Box>
    )
}