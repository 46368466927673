import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemText: {
            fontFamily: "Arial",
            fontSize: "12px",
            fontWeight: "lighter",
            color: "#1F497D",
            padding: "9px 16px",
            margin: "3px",
            cursor: "pointer",
            "&:hover": {
                background: "#6D97C133",
                borderRadius: "8px",
            }
        },
        deleteItemText: {
            fontFamily: "Arial",
            fontSize: "12px",
            fontWeight: "bold",
            color: "#DC2626",
            padding: "9px 16px",
            margin: "3px",
            "&:hover": {
                background: "#6D97C133",
                borderRadius: "8px",
            }
        },
        root: {
            "& .MuiPopover-paper": {
                minWidth: "180px",
                border: "1px solid #E2E8F0",
                boxShadow: "0px 2px 8px 0px #00000014 !important",
                marginTop: "8px",
                borderRadius: "8px",
            }
        },
        root1: {
            "& .MuiPopover-paper": {
                minWidth: "180px",
                border: "1px solid #E2E8F0",
                boxShadow: "0px 2px 8px 0px #00000014 !important",
                marginTop: "16px",
                borderRadius: "8px"
            }
        }
    }),
);

type Props = {
    children: React.ReactNode,
    onView?: (id: number | string) => void;
    handleDelete?: (id: number | string) => void;
    handleEditClick?: (id: number | string) => void;
    job: any;
    styleOfIcon?: Object;
    options?: string[];
    handleoptionClick?: (value: string) => void;
    handleJobStatusClick?: (jobId: string, status: string) => void;
}

export default function PopoverModal({ children, onView = () => { }, handleDelete = () => { }, job, handleEditClick = () => { }, styleOfIcon = {}, options = [], handleoptionClick = (value: string) => { }, handleJobStatusClick = () => {} }: Props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation()
    };

    const handleClose = (event: any) => {
        setAnchorEl(null);
        event.stopPropagation()
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {
                options.length > 0 ? <div aria-describedby={id} onClick={handleClick} style={{ cursor: "pointer", border: open && options.includes("Newest") ? "1px solid #1F497D" : "", borderRadius: "8px" }}>{children}</div> : <IconButton aria-describedby={id} onClick={handleClick} style={styleOfIcon}>
                    {children}
                </IconButton>
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={options.length > 0 ? options.includes("Rejected") ? classes.root1 : classes.root : ""}
            >
                {
                    options.length > 0 ? options.map((item: string) => <Typography id="option-btn" onClick={() => {
                        handleoptionClick(item);
                        setAnchorEl(null);
                    }} className={classes.itemText}>{item}</Typography>) : <>
                        <Typography className={classes.itemText} onClick={(event) => {
                            onView(job.id);
                            handleClose(event);
                            event.stopPropagation();
                        }}>View</Typography>
                        <Typography className={classes.itemText} onClick={(event) => {
                            handleEditClick(job.id);
                            handleClose(event);
                            event.stopPropagation();
                        }}>Edit</Typography>
                        {
                           job.attributes.status !== "draft" && <Typography className={classes.itemText} onClick={(event) => {
                            handleClose(event);
                            handleJobStatusClick(job.id, job.attributes.status === "close" ? "open": "close")
                            event.stopPropagation();
                        }}>{job.attributes.status === "close" ? "Re-open Application" : "Close Application"}</Typography>
                        }
                        <Typography className={classes.deleteItemText} onClick={(event) => {
                            handleDelete(job.id);
                            handleClose(event);
                            event.stopPropagation();
                        }}>Delete</Typography>
                    </>
                }

            </Popover>
        </>
    );
}
