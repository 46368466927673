import React, { Component } from "react";
import CustomPost from "./CustomPost.web";
import { Post, UserDetails } from "../RecruiterProfileController.web";

interface Props {
  activityType: string,
  activity: {
    posts: [],
    comments: [],
    reactions: [],
  },
  postShowID: number | string,
  upvotePost: (id: string, type: string, activityType: string) => void,
  downvotePost: (id: string, type: string, activityType: string) => void,
  toggleComments: (post: Post) => void,
  createComment: any,
  upvotePostComment: (id: number | string, type: string) => void
  downvotePostComment: (id: number | string, type: string) => void
  userDetails: UserDetails
  getInitials: (value?: string) => string
  openPostDeleteDialog: (id: number | string) => void
  handleFeedModalOpen: (post: Post) => void
  handleSavePostApi: (id: number | string) => void
  progressComment:number;
  remainingTimeComment:number;
  loaderComment:boolean;
  handleDeleteComment: (id: string | number) => void;
  updateComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string) => void;
  expandedPosts?: string[],
  toggleReadMore?: (postId: string) => void,  
  handleUserNavigation: (accountId: number | string) => void
}

export default class ShowAllPosts extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      (this.props.activityType === 'posts' || this.props.activityType === 'comments' || this.props.activityType === 'reactions') &&
        (this.props.activity[this.props.activityType].length > 0) ?
        <CustomPost
          posts={this.props.activity[this.props.activityType]}
          upvotePost={this.props.upvotePost}
          downvotePost={this.props.downvotePost}
          activityType={this.props.activityType}
          toggleComments={this.props.toggleComments}
          postShowID={this.props.postShowID}
          createComment={this.props.createComment}
          upvotePostComment={this.props.upvotePostComment}
          downvotePostComment={this.props.downvotePostComment}
          openConfirmDialog={this.props.openPostDeleteDialog}
          userDetails={this.props.userDetails}
          openEditFeedModal={this.props.handleFeedModalOpen}
          handleSavePost={this.props.handleSavePostApi}
          getInitials={this.props.getInitials}
          isFullPage={true}
          progressComment = {this.props.progressComment}
          remainingTimeComment = {this.props.remainingTimeComment}
          loaderComment = {this.props.loaderComment}
          handleDeleteComment={this.props.handleDeleteComment}
          updateComment={this.props.updateComment}    
          showReadMore
          expandedPosts={this.props.expandedPosts}
          toggleReadMore={this.props.toggleReadMore} 
          handleNameClick={this.props.handleUserNavigation}
        />
        : null
    );
  }
}
