import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid } from '@material-ui/core';
import { crossBlueIcon } from '../assets';
import { Autocomplete } from '@material-ui/lab';
import AutoCompleteSearch from '../../../../../packages/components/src/AutoCompleteSearch';
import CustomCheckBoxComponent from '../../../../../packages/components/src/CustomCheckBoxComponent';
import { City, Country, ICity, ICountry } from 'country-state-city';
export const configJSON = require("../config");

const useStyles = makeStyles({
    root: {
        padiing: "56px",
        position: "relative",
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#1F497D",
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: "#1F497D",
        },
        "& .MuiPaper-root": {
            minWidth: "400px"
        }
    },
    root1: {
        "background": "#FFF",
        "padding": "0px 20px 0px 22px !important",
        "position": "relative",
        "marginTop": "10px"
    },
    title: {
        "color": "#17365D",
        "fontSize": "24px",
        "fontWeight": "bold",
        "lineHeight": "26px",
        "fontFamily": "Arial",
        "display": "flex",
        "alignItems": "center"
    },
    noBtn: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "1px solid  #1F497D",
        "background": "#FFF",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none",
        "width": "120px"
    },
    yesBtn: {
        "border": "1px solid #1F497D",
        "color": "#FFFFFF",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "#1F497D"
        },
        "borderRadius": "8px",
        "background": "#1F497D",
        "display": "flex",
        "width": "120px",
        "height": "56px",
        "padding": "16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "textTransform": "none",
        '&.Mui-disabled': {
            color: '#FFFFFF', // Adjust the color of disabled text
            background: '#CBD5E1', // Adjust the background color of disabled button
            border: '1px solid #CBD5E1', // Adjust the border color of disabled button
            '&:hover': {
                background: '#1F497D', // Keep the same background color on hover
            },
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        padding: "15px 20px !important"
    },
    btnContainer1: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        paddingTop: "170px !important"
    },
    filterTitle: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        marginBottom: "10px"
    },
    rootAutoComplete: {
        "& .MuiInputBase-root": {
            padding: "2px !important"
        },
    },
    filterContainer: {
        marginBottom: "25px"
    },
    filterContainerOne: {
        marginBottom: "5px",
    },
    filterContainerTwo: {
        paddingLeft: "5px",
        marginBottom: "25px"
    },
    paddTitle: {
        padding: "13px",
        paddingBottom: "50px"
    }
});


export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    countries: { value: string; label: string }[];
    cities: { value: string, label: string }[];
    tempCountry: { label: string, value: string, cId?: string };
    tempCity: { label: string, value: string };
    tempSkill: { label: string, value: string };
    selectedCountry: string;
    selectedCity: string;
    selectedSkills: string[];
    selectedExperience: string[];
    onChangeFilterModalsValue: (value: { value: string, label: string }, filterName: "Country" | "City" | "Skills" | "Experience" | "removeSkills", remove?: boolean) => void;
    filteredSlillsOption: { label: string, value: string }[];
    skillsListHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    getAllJobsForDetails: (page?: number) => void;
    handleClearFiler: () => void;
    isTalentPoolFilter: boolean;
    getAllCandidatesTalenPool: () => void;
    handleCountryChange: (value: { value: string; label: string; }[]) => void;
}

export default function FilterModal(props: SimpleDialogProps) {
    const classes = useStyles();
    const { handleCountryChange, onClose, handleClearFiler, isTalentPoolFilter, getAllCandidatesTalenPool, open, cities, countries, tempCity, selectedCity, selectedCountry, selectedExperience, selectedSkills, getAllJobsForDetails, tempCountry, tempSkill, onChangeFilterModalsValue, filteredSlillsOption, skillsListHandler } = props;

    return (
        <Dialog onClose={() => {
            onClose();
        }} aria-labelledby="simple-dialog-title" open={open} className={classes.root} >
            <Grid item container className={classes.root1} sm={12} >
                <Grid item container>
                    <Grid item sm={12} xs={12} className={classes.paddTitle}>
                        <Grid item container sm={12} style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography className={classes.title}>
                                Filters
                            </Typography>
                            <img src={crossBlueIcon} id="crossIcon" alt="icon" style={{ position: "absolute", right: "30px", top: "20px", cursor: "pointer" }} onClick={() => {
                                onClose();
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container sm={12} style={{ padding: "0px 13px" }}>
                    <Grid item sm={12} xs={12}>
                        <Typography className={classes.filterTitle}>
                            Country
                        </Typography>
                    </Grid>
                    <Grid className={classes.filterContainerOne} sm={6} xs={12}>
                        <Autocomplete
                            id="combo-box-demo"
                            className={classes.rootAutoComplete}
                            options={Country.getAllCountries().map((country: ICountry) => ({
                                value: country.name,
                                label: country.name,
                                cId: country.isoCode
                            }))}
                            autoSelect
                            onChange={(event, value) => {
                                onChangeFilterModalsValue(value || { value: "", label: "" }, "Country");
                                if (value?.cId) {
                                    let cities = ((City.getCitiesOfCountry(value.cId) as []).map((city: ICity) => ({
                                        value: city.name,
                                        label: city.name,
                                    })) as { value: string, label: string }[]);
                                    handleCountryChange(cities);
                                }
                            }}
                            value={tempCountry}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <AutoCompleteSearch
                                name="country"
                                title=""
                                params={params}
                                placeHolder="Search"
                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => { }}
                            />}
                        />
                    </Grid>
                    <Grid item container className={classes.filterContainerTwo} sm={12} xs={12}>
                        {
                            selectedCountry && <CustomCheckBoxComponent checked={true} title={selectedCountry} onClick={() => { onChangeFilterModalsValue({ value: selectedCountry, label: selectedCountry }, "Country", true) }} />
                        }
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography className={classes.filterTitle}>
                            City
                        </Typography>
                    </Grid>
                    <Grid className={classes.filterContainerOne} sm={6} xs={12}>
                        <Autocomplete
                            id="combo-box-demo"
                            className={classes.rootAutoComplete}
                            options={cities}
                            autoSelect
                            onChange={(event, value) => {
                                onChangeFilterModalsValue(value || { value: "", label: "" }, "City");
                            }}
                            getOptionLabel={(option) => option.label}
                            value={tempCity}
                            renderInput={(params) => <AutoCompleteSearch
                                name="city"
                                placeHolder="Search"
                                title=""
                                params={params}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { }}
                            />}
                        />
                    </Grid>
                    <Grid item container className={classes.filterContainerTwo} sm={12} xs={12}>
                        {
                            selectedCity && <CustomCheckBoxComponent checked={true} title={selectedCity} onClick={() => { onChangeFilterModalsValue({ value: selectedCity, label: selectedCity }, "City", true) }} />
                        }
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography className={classes.filterTitle}>
                            Skills
                        </Typography>
                    </Grid>
                    <Grid className={classes.filterContainerOne} sm={6} xs={12}>
                        <Autocomplete
                            id="combo-box-demo"
                            className={classes.rootAutoComplete}
                            options={filteredSlillsOption}
                            autoSelect
                            value={tempSkill}
                            onChange={(event, value) => {
                                onChangeFilterModalsValue(value || { value: "", label: "" }, "Skills");
                            }}
                            security=''
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <AutoCompleteSearch
                                name="skills"
                                placeHolder="Search"
                                title=""
                                params={params}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { skillsListHandler(event) }}
                            />}
                        />
                    </Grid>
                    <Grid item container className={classes.filterContainerTwo} sm={12} xs={12}>
                        {
                            selectedSkills.length > 0 && selectedSkills.map((skill) => <Grid item sm={4} xs={6} key={skill}>
                                <CustomCheckBoxComponent id="truePropCheckbox" checked={true} title={skill} onClick={() => { onChangeFilterModalsValue({ value: skill, label: skill }, "removeSkills", true) }} />
                            </Grid>)
                        }
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography className={classes.filterTitle}>
                            Experience
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.filterContainer} sm={12} xs={12}>
                        {
                            configJSON.experienceFILTER.map((value: { value: string, label: string }) => <Grid item sm={4} xs={6}>
                                <CustomCheckBoxComponent id="expCheckbox" checked={selectedExperience.includes(value.value)} title={value.value} onClick={() => { onChangeFilterModalsValue({ value: value.value, label: value.value }, "Experience") }} />
                            </Grid>)
                        }
                    </Grid>

                </Grid>
            </Grid>
            <Divider style={{ height: "1px", width: "100%" }}></Divider>
            <Grid item sm={12} xs={12} className={classes.btnContainer}>
                <Button disabled={false} className={classes.noBtn} onClick={() => {
                    handleClearFiler();
                    if (isTalentPoolFilter) {
                        getAllCandidatesTalenPool()
                    } else {
                        getAllJobsForDetails(1);
                    }
                }}>Clear</Button>
                <Button className={classes.yesBtn} type="submit" disabled={false} onClick={() => {
                    if (isTalentPoolFilter) {
                        getAllCandidatesTalenPool()
                    } else {
                        getAllJobsForDetails(1);
                    }
                    onClose();
                }}>Apply</Button>
            </Grid>
        </Dialog>
    );
}

