Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = '/bx_block_advanced_search/filter'
exports.errorTitle = 'Error'
exports.updateUserDetailsEndPoint = "bx_block_profile/profiles";
exports.getPostEndPoint = "bx_block_posts/posts"
exports.getCommentsEndPoint = "bx_block_comments/comments"
exports.upvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/upvote";
exports.downvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/downvote";
exports.savePostEndpoint = "bx_block_communityforum/posts"
exports.sendInvitationEndpoint = "bx_block_friends/create_connection_request";
exports.removeInvitationEndpoint = "bx_block_friends";
exports.savedJobApiEndPoint = 'bx_block_joblisting/jobs';
exports.followUserApiEndPoint = "bx_block_followers/follows";
exports.baseURL = require('../../../framework/src/config')

exports.resonEndPointApi ="bx_block_contentflag/contents/get_flag_reasons"
exports.addReasonsApiEndPoint ="bx_block_contentflag/contents"

// Customizable Area End
