export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgFilter = require("../assets/filter.svg");
export const imgArrowDownIcon = require("../assets/arrowDownIcon.svg");
export const imgViewCircle = require("../assets/view_circle.svg");
export const searchImg = require("../assets/image_search.svg");
export const text = require("../assets/text.svg");
export const imgCheckbox = require("../assets/checkbox.svg");
export const imgAddIcon = require("../assets/addIcon.svg");
export const imgProfile = require("../assets/profile.svg");
export const imgStop = require("../assets/stop.svg");
export const hiddenIcon = require("../assets/hiddenIcon.svg");
export const imgQuestion= require("../assets/question.svg");
export const imgMessage = require("../assets/message.svg");
export const imgDot = require("../assets/dot.svg");
export const imgDotBlue = require("../assets/imgDotBlue.svg");
export const imgCorrect = require("../assets/correct.svg");
export const imgButtonBack = require("../assets/button_back.svg");
export const imgSkillIcon = require("../assets/skill_icon.svg");
export const imgChipCross = require("../assets/chip_cross.svg");
export const imgEditIcon =  require("../assets/editIcon.svg");
export const imgBottomRight = require("../assets/bottomRight.svg");
export const imgTableClockIcon = require("../assets/tableClockIcon.svg");
export const imgDownload = require("../assets/download.svg");
export const imgDelete = require("../assets/delete.svg");
export const imgPdf = require("../assets/pdf.svg");
export const imgXlsx = require("../assets/xlsx.svg");
export const menuIcon =  require("../assets/menuIcon.svg");
export const tickIcon =  require("../assets/tickIcon.svg");
export const noPipelineAccess = require("../assets/noPipelineAccess.svg")