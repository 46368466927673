import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import { FieldProps } from 'formik';

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#9C9C9C",
    '&$checked': {
      color: "#6D97C1",
    },
    '&$checked + $track': {
      backgroundColor: "#EEECE1",
    },

  },
  checked: {},
  track: {
    backgroundColor: "#EEECE1",
    border: "1px solid #EEECE1"
  },
  "& .MuiSwitch-thumb": {
    "width": "15px",
    "height": "15px",
    "boxShadow": "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "borderRadius": "50%",
    "backgroundColor": "currentColor",
    "top": "20px",
    "marginTop": "3px",
    "marginLeft": "4px"
  }
})(Switch);


export default function CustomSwitchComponent({ field,
  form,
  title,
  controlledCheck,
  checked,
  onChangeControlled
}: FieldProps & { onChangeControlled?: (e: React.ChangeEvent<HTMLInputElement>) => void, controlledCheck?: boolean, checked: boolean, title: string, handleChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) }) {

  return (
    <FormControlLabel
      control={<PurpleSwitch {...field} checked={controlledCheck ?? checked} onChange={onChangeControlled ?? form.handleChange} />}
      label={<Typography style={{ color: "#1F497D", fontFamily: "Arial", fontSize: "16px" }}>{title}</Typography>}
    />
  );
}
