import React from "react";
import clsx from "clsx";

import { FormControlLabel, InputLabel, Radio, RadioGroup, RadioProps, Theme, Typography, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    textone: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#1F497D",
        fontWeight: "lighter"
    },
    texttwo: {
        fontFamily: "Arial",
        fontSize: "12px",
        color: "#9C9C9C",
        fontWeight: "lighter"
    },
    inputLabel: {
        color: '#1F497D',
        fontFamily: 'Arial',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        paddingBottom: '4px',
    },
    inputBtn: {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Arial',

    },
    errorText: {
        "color": "#DC2626",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "display": "flex"
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "white",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto #64748B",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,#1F497D,#1F497D)",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#64748B",
        },

    },
}))

type Props = {
    name: string;
    title: string;
    options: string[];
    required: boolean;
    error: string;
    value: string[];
    handleChange: (value: string) => void;
    isTouched: boolean;
    othersAnswer: {
        [key: string]: string
    }
    setOthersAnswer: (value: string, questionId: string) => void;
}

interface StyledRadioProps extends RadioProps {
    index: number;
    checked: boolean;
    handleChange: (value: string) => void;
    item: string;
    focusInput: () => void;
}

function StyledRadio(props: StyledRadioProps) {
    const classes = useStyles();

    if (props.item === "Other...") {
        return (<Box >
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                onClick={(_event) => {
                    props.handleChange(props.item);
                    !props.checked && props.focusInput()
                }}
                checked={props.checked}
            />

        </Box>)
    } else {
        return (<Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            onClick={(_event) => props.handleChange(props.item)}
            checked={props.checked}
        />
        );

    }


}

function MCQType(props: Props) {

    const classes = useStyles();
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    let focusInput = () => {
        inputRef.current?.focus();
    }
    return (
        <>
            <InputLabel
                className={classes.inputLabel}
            >
                {props.title} {props.required && <span style={{ color: '#f44336' }}>*</span>}
            </InputLabel>
            <RadioGroup
                defaultValue=""
                aria-label="Country"
                name="customized-radios"
                className={classes.inputBtn}
            >
                {
                    props.options.map((item, index) => <FormControlLabel
                        value=""
                        control={<StyledRadio index={index} checked={props.value.includes(item)} handleChange={props.handleChange} item={item} focusInput={focusInput} />}
                        label={item === "Other..." ? <Box style={{ display: "flex", flexDirection: "row" }}>
                            <Typography className={classes.textone}>{item === "Other..." ? "Other:" : item}</Typography>
                            &nbsp;
                            <input ref={inputRef} value={props.othersAnswer?.[props.name] || ""} onChange={(event) => {
                                if (!props.value.includes("Other...")) {
                                    props.handleChange("Other...")
                                }
                                props.setOthersAnswer(event.target.value, props.name);
                            }} style={{
                                border: "0px white",
                                color: "#1F497D",
                                cursor: "pointer",
                                borderBottom: "1px solid #1F497D"
                            }} />
                        </Box> : <Typography className={classes.textone}>{item}</Typography>
                        }
                    />)
                }
            </RadioGroup>
            {
                ((!!props.error && props.isTouched) || (props.value.length === 1 && props.value.includes("Other...") && props.othersAnswer?.[props.name]?.trim() === "")) && <Typography className={classes.errorText}>
                    {props.error}{props.value.length === 1 && props.value.includes("Other...") && props.othersAnswer?.[props.name]?.trim() === "" && "Question is required"}
                </Typography>
            }
        </>
    );
}

export default MCQType;