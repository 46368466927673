import React from "react";
import { Dialog, DialogContent, Grid, Typography } from "@material-ui/core"

const cancelIcon = require("./cancel.svg");

type Props = {
  open: boolean, 
  handleClose: () => void, 
  type?: string
}

export const ReportedSuccessDialogue: React.FC<Props> = ({ open, handleClose, type = "post",  }) => {
  return (
    <>
      <Dialog open={open} data-test-id="reportModal" onClose={handleClose}>
        <Grid item xs={12} style={{ width: "570px" }}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
              <img src={cancelIcon}
                onClick={handleClose}
                style={webStyles.dialogIconSize}
                data-test-id="reportDialogCancelIcon"
              />
            </Grid>
          </Grid>
        </Grid>
        <DialogContent style={webStyles.contentBoxReport}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" style={webStyles.deleteContentText}>
                  This {type} has been reported successfully.
                  <br></br>
                  Our team will soon connect with you.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

const webStyles = {
  dialogIconSize: {
    width: '24px',
    height: '24px',
    cursor: "pointer",
    paddingTop:'20px',
    paddingRight:'25px'
  },
  contentBox:{
    padding:'12px 40px 24px 40px'
  },
  contentBoxReport: {
    padding:'10px 40px 50px'
  },
  dialogActionStyles: {
    borderTop: "1px solid #F4F4F4",
    padding:'23px 40px 24px 40px'
  },
  deleteContentText: {
    color: "#17365D",
    fontFamily: "Arial",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
}